import React, {Component} from 'react';
import {resolveOptionClasses} from '../PractiseUtils';

class SignsQuestionFromImage extends Component {

  constructor(props) {
    super(props);
    this.setAnswer = this.props.setAnswer;
    this.state = {
      question: props.question,
      isAnswerResolved: false,
      answer: props.answer,
      options: [],
      index: props.index
    };
    this.ANSWER_DELAY_MS = 400;
  }

  componentDidUpdate(prevProps) {
    if (this.props.answer !== prevProps.answer) {
      this.setState({answer: this.props.answer});
    }
  }

  componentWillUnmount() {
    clearTimeout(this.answerDelay);
  }

  setAnswerDelayed(optionId) {
    if (this.state.answer) {
      return;
    }
    this.setState({
      answer: optionId
    });

    if (!this.state.answer) {
      this.answerDelay = setTimeout(() => {
        this.setState({
          isAnswerResolved: true
        });
        this.setAnswer(optionId);
      }, this.ANSWER_DELAY_MS);
    }
  }

  renderImageQuestion(question) {
    const src = question.imageUrl;
    return (
      <div className="practise-signs-image-question fade-in short-delay">
        <img src={src} />
      </div>);
  }

  renderTextOption(option, optionType) {
    const key = option.id + optionType;
    const classes = resolveOptionClasses(
      'practise-text-option', optionType, this.state.answer,
      this.state.isAnswerResolved, this.state.question.correctAnswer);
    return(
      <div id={'practise-signs-text-option-'+optionType} key={key} onClick={() => this.setAnswerDelayed(optionType)} className={classes}>
        {option.text}
      </div>);
  }

  render() {

    const questionElement = this.renderImageQuestion(this.state.question.question);

    // Options
    const options = [];
    Object.keys(this.state.question.options).forEach((optionType) => {
      options.push(this.renderTextOption(this.state.question.options[optionType], optionType));
    });
    
    return (
      <div className={'practise-question-wrapper ' + this.props.className}>
        {questionElement}
        <div className="fade-in delay">
          {options}
        </div>
      </div>
    )
  }
}

export default SignsQuestionFromImage;