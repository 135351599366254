import React, {Component} from 'react';
import {connect} from 'react-redux';
import Translated from './../feature/Translated';
import classNames from 'classnames';
import withTranslate from '../feature/withTranslate';
import FacebookConnect from './FacebookConnect';
import { IoCheckmarkSharp } from "react-icons/io5";

class FacebookSettings extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      showModal: false,
      inputEmail: '',
      isSending: false,
      responseData: null,
      error: null,
      errorData: null
    }
  }

  render() {
    let content;
    const hasFacebook = this.props.token.get('facebook');
    if (hasFacebook) {
      content = (
        <div>
        <Translated translationKey={'facebook_is_connected'}/>
        <IoCheckmarkSharp className="left-margin"/>
        </div>
      )
    } else {
      content = <FacebookConnect/>;
    }

    return (
      <div className={classNames('facebook-settings-wrapper', this.props.className)}>
        <h4><Translated translationKey={'facebook_settings_title'}/></h4>
        <div className="white-box">
          {content}
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return ({
    token: state.login.get('token'),
    locale: state.Intl.locale
  });
};

export default connect(mapStateToProps)(withTranslate(FacebookSettings));