import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import withTranslate from './withTranslate';
import Translated from './Translated';
import Spinner from './Spinner';
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import api from '../../api';
import {loadData, dataTypes, saveData} from '../../actions/apiDataActions';

class AutoCloseSettings extends Component {

  constructor(props, context) {
    super(props, context);
    this.handleSchoolInputChange = this.handleSchoolInputChange.bind(this);
    this.save = this.save.bind(this);
    this.state = {
      school: null
    }
  }

  componentDidMount() {
    const schoolId = this.props.token.get('schoolId');
    this.props.dispatch(loadData(dataTypes.SCHOOL_SETTINGS, api.getSchoolData, schoolId));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.settings !== this.props.settings) {
      const data = this.props.settings.data;
      this.setState({school: data && data.school})
    }
  }

  save() {
    const schoolId = this.props.token.get('schoolId');
    this.props.dispatch(saveData(dataTypes.SCHOOL_SETTINGS, api.setSchoolData, schoolId, this.state.school));
  }

  handleSchoolInputChange(e) {
    this.updateSchoolState(e.target.name, e.target.value);
  }

  updateSchoolState(field, value) {
    const fieldOverlay = {};
    fieldOverlay[field] = value;
    const newSchoolObj = Object.assign(this.state.school, fieldOverlay);

    const schoolId = this.props.token.get('schoolId');
    this.setState({isSending: true});
    this.props.dispatch(saveData(dataTypes.SCHOOL_SETTINGS, api.setSchoolData, schoolId, newSchoolObj))
        .then(() => {
          this.setState({isSending: false});
        });
  }

  buildAmortizationSelection() {
    const daysLabel = ' ' + this.props.translate('days');
    const selections = [2, 7, 15, 30, 45];
    const currentValue = this.state.school.userAmortizationPeriodDays;

    return (
        <ButtonToolbar className="settings-category-limit">
          <DropdownButton
              id='amortizationSelection'
              bsstyle="warning"
              title={currentValue + daysLabel}>
            {selections.map((selectableValue => {
                  return <Dropdown.Item
                      id={selectableValue}
                      key={selectableValue}
                      onClick={() => {
                        this.updateSchoolState('userAmortizationPeriodDays', selectableValue);
                      }}>
              <span>{selectableValue === currentValue
                  ? <span className="settings-selected-dropdown-value ">{selectableValue + daysLabel}</span>
                  : selectableValue + daysLabel}</span>
                  </Dropdown.Item>
                }
            ))}
          </DropdownButton>
        </ButtonToolbar>
    );
  }

  renderForm() {
    return (
        <div className="white-box">
          <form>
            <fieldset disabled={this.props.settings.isSending}>
              <p><Translated translationKey="user_amortization_info"/></p>
              {this.buildAmortizationSelection()}
            </fieldset>
            {this.state.isSending && <Spinner/>}
          </form>
        </div>
    );
  }

  render() {
    return (
        <div id="auto-close-settings" className={this.props.className}>
          <h3><Translated translationKey="user_amortization_title"/></h3>
          {this.props.settings.error && <Alert><Translated translationKey={this.props.settings.error}/></Alert>}
          {this.props.settings.isFetching && <Spinner/>}
          {this.state.school && this.renderForm()}
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    token: state.login.get('token'),
    settings: state.apiData[dataTypes.SCHOOL_SETTINGS],
  });
};

const composed = compose(
    connect(mapStateToProps),
    withTranslate
);

export default composed(AutoCloseSettings);
