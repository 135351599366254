import React, {Component} from 'react';
import {connect} from 'react-redux';
import FacebookSettings from '../feature/FacebookSettings';
import EmailSettings from '../feature/EmailSettings';
import LanguageSelector from '../feature/LanguageSelector';
import Translated from '../feature/Translated';
import UserCategorySetting from '../feature/UserCategorySetting'

export class UserSettingsPage extends Component {

  canAddCredentials() {
    if (!this.props.token){
      return null;
    }
    return this.props.token.get('loginMethod') !== 'integration' ;
  }

  renderFacebookSettings() {
    if (!this.canAddCredentials()) {
      return null;
    }
    return(<FacebookSettings/>);
  }

  renderEmailSettings() {
    if (!this.canAddCredentials()) {
      return null;
    }
    return(<EmailSettings/>);
  }

  render() {
    return (
      <div id="user-settings-page" className="page-container max-width-container">
        <h2><Translated translationKey="settings" /></h2>
        <div className="top-margin">
          <LanguageSelector drop="up"/>
        </div>
        <div className="top-margin">
          <UserCategorySetting className="large-top-margin"/>
        </div>
        {/* {this.renderFacebookSettings()} */}
        {this.renderEmailSettings()}
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return ({
    token: state.login.get('token')
  });
};

export default connect(mapStateToProps)(UserSettingsPage);
