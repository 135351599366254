export const TOKEN_UPDATED = 'TOKEN_UPDATED';
export const RESET_AUTHENTICATION = 'RESET_AUTHENTICATION';
export const REQUIRE_TOS = 'REQUIRE_TOS';
export const ACCEPT_TOS = 'ACCEPT_TOS';

export function tokenUpdated(token) {
  return {
    type: TOKEN_UPDATED,
    token: token
  }
}

export function resetAuth(errorCode) {
  return {
    type: RESET_AUTHENTICATION,
    error: errorCode
  }
}

export function requireTos(tosVersion) {
  return {
    type: REQUIRE_TOS,
    version: tosVersion
  }
}

export function acceptTos(tosVersion) {
  return {
    type: ACCEPT_TOS,
    version: tosVersion
  }
}