import React, {Component} from 'react';
import Translated from './../Translated';
import ResultFeedbackForm from './ResultFeedbackForm';
import {IoChatbubblesSharp} from 'react-icons/io5';
import { FcApproval , FcHighPriority } from "react-icons/fc";
import BookModalLink from '../BookModalLink';
import ZoomableImage from '../ZoomableImage';

class TestResultLiteral extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      showCommenting: false
    }
  }

  toggleCommenting() {
    this.setState({showCommenting: !this.state.showCommenting});
  }

  renderBookContent(question) {
    const bookReferencesMap = this.props.bookReferences;

    // Do not add book reference link if there is none, or user does not have them linked (no rights)
    if (!question.bookReferenceIds || question.bookReferenceIds.length === 0 || !bookReferencesMap || bookReferencesMap.size === 0) {
      return null;
    }
    return question.bookReferenceIds.map(refId => {
      const content = bookReferencesMap.get(refId);
      return <BookModalLink content={content} style="warning" key={refId} animate={true}/>
    });
  }

  renderCommenting() {
    if (!this.state.showCommenting) {
      return null;
    }
    const question = this.props.question;
    return (
      <ResultFeedbackForm questionType="literal" questionId={question.id} keepOpen={true}
                          className="large-top-margin"/>
    );
  }

  renderImage(question) {
    return question.imageUrl && (
      <ZoomableImage src={question.imageUrl} className="test-result-image-image"
                     modalClassName="test-result-image-modal"/>
    )
  }

  render() {
    const question = this.props.question;
    if (!question) {
      return null;
    }
    const correctText = question.options[question.correctAnswer];
    const answeredText = question.options[question.answer];
    const answer = answeredText === undefined ? <Translated translationKey="no_answer"/> : answeredText;
    const isCorrect = correctText === answeredText;

    return (
      <div className="blue-box reduced no-overflow relative">
        <div className="test-result-literal-wrapper">
          <div className="test-result-literal-question"><strong>{question.question}</strong></div>
          {this.renderImage(question)}
          <div className="test-result-literal-answers">
            <div className="test-result-literal-answer">
              {isCorrect && 
              <div className='answer-box'>
                <FcApproval className="test-result-icon" />&nbsp;
                <div>
                  <strong><Translated translationKey="correct_answer"/>:</strong>&nbsp;
                  <span className="test-result-literal-answer-text">{correctText}</span>
                </div>
              </div>}
              {!isCorrect && 
              <div className="answer-box" >
              <FcHighPriority fill="red" className="test-result-icon" />&nbsp;
                <div>
                  <div>
                    <strong><Translated translationKey="correct_answer"/>:</strong>&nbsp;
                    <span className="test-result-literal-answer-text">{correctText}</span>
                  </div>
                  <div className='gray-highlight'>
                    <strong><Translated translationKey="your_answer"/>:</strong>&nbsp;
                    <span className="test-result-literal-answer-text">{answer}</span>
                 </div>
                </div>
              </div>
              }
            </div>
            {this.renderBookContent(question)}
          </div>
        </div>
        <div className="blue-box-bottom padded-small" >
          <div className="test-result-question-bottom touchable" onClick={() => this.toggleCommenting()}>
              <Translated className="gray-highlight" translationKey="question_report" />
              <div className="test-result-question-bottom-item icon">
                <IoChatbubblesSharp className="larger pull-right"/>
              </div>
          </div>
          {this.renderCommenting()}
        </div>
      </div>
    )
  }
}

export default TestResultLiteral;
