import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withTranslate from '../withTranslate';
import Button from 'react-bootstrap/Button';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import Translated from '../../feature/Translated';
import Spinner from '../../feature/Spinner';
import api from '../../../api';
import {IoChatbubblesSharp} from 'react-icons/io5';

class ResultFeedbackForm extends Component {

  static propTypes = {
    questionType: PropTypes.string.isRequired,
    questionId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
    keepOpen: PropTypes.bool,
    placeholderKey: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);

    const isShown = this.props.keepOpen ? this.props.keepOpen : false;

    this.state = {
      show: isShown,
      message: '',
      submitting: false,
      submitted: false,
      error: null
    }
  }

  toggle() {
    this.setState({show: !this.state.show});
  }

  close() {
    this.setState({show: false});
  }

  handleInputChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit() {
    this.setState({submitting: true});
    api.sendQuestionReport(this.props.questionType, this.props.questionId, this.state.message)
      .then((resp) => {
        if (resp.error) {
          return this.setState({error: resp.error});
        }
        this.setState({submitted: true});
      })
      .then(() => {
        this.setState({submitting: false, show: false});
      });
  }

  render() {
    let content;
    let form;

    if (this.state.error) {
      content = <Translated translationKey={this.state.error}/>;

    } else if (this.state.submitted) {
      content = <Translated translationKey='feedback_sent'/>;

    } else if (this.state.submitting) {
      content = <Spinner />

    } else if (this.state.show) {
      let cancel;
      if (!this.props.keepOpen) {
        cancel = (
          <Button bsstyle="link" onClick={this.toggle}>
            <Translated translationKey="cancel"/>
          </Button>
        )
      }
      const placeHolderKey = this.props.placeholderKey || 'question_report_info';
      form = (
        <FormGroup onSubmit={this.handleSubmit}>
          <FormControl className="feedback-input" componentclass="textarea"
                       placeholder={this.props.translate(placeHolderKey)}
                       name="message"
                       value={this.state.message} onChange={this.handleInputChange}/>
          <div className="field-spacer"/>
          <div className="feedback-buttons">
            {cancel}
            <Button id="submit-result-feedback" type="submit" disabled={!this.state.message}
                    onClick={this.handleSubmit}>
              <Translated translationKey="send"/>
            </Button>
          </div>
        </FormGroup>
      )

    } else {
      content = (
        <div className="touchable" onClick={this.toggle}>
          <IoChatbubblesSharp className="larger text-bottom"/>
          &nbsp;&nbsp;
          <Translated translationKey="question_report" className="underlined"/>
        </div>
      );
    }

    return (
      <div className={classNames('question-feedback-form', this.props.className, {'full-width': this.state.show})}>
        {content}
        {form}
      </div>
    );
  }
}

export default withTranslate(ResultFeedbackForm);

