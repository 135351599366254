import React, {Component} from 'react';
import {connect} from 'react-redux'
import Translated from '../feature/Translated';
import withTranslate from '../feature/withTranslate';
import Help from '../feature/Help';
import UserCreatesPending from '../feature/UserCreatesPending';
import {Roles} from '../../data/roles';
import UserCreate from '../feature/UserCreate';

class UserAddPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.requestCreated = this.requestCreated.bind(this);
    this.state = {
      refreshNum: 0
    };
  }

  requestCreated() {
    this.setState({refreshNum: this.state.refreshNum + 1});
  }

  render() {
    return (
      <div>
        <h3>
          <Translated translationKey={'add_student'}/>
          <Help content="help_add_student" placement="bottom"/>
        </h3>
        <UserCreate role={Roles.STUDENT} schoolId={this.props.schoolId} requestCreatedCallback={this.requestCreated}/>
        <UserCreatesPending refreshNum={this.state.refreshNum} schoolId={this.props.schoolId} className="large-top-margin"/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    schoolId: state.login.get('token') ? state.login.get('token').get('schoolId') : null
  });
};

export default connect(mapStateToProps)(withTranslate(UserAddPage));
