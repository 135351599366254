import React, {Component} from 'react';
import Translated from './../Translated';
import ResultFeedbackForm from './ResultFeedbackForm';
import { FcApproval, FcHighPriority } from "react-icons/fc";
import {IoChatbubblesSharp} from 'react-icons/io5';

class TestResultSign extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      showCommenting: false
    }
  }

  renderQuestion() {
    return (
      <div className="test-result-literal-question">
        <strong><Translated translationKey="select_definition_for_sign"/></strong>
      </div>
    );
  }

  renderCorrectLiteralFeedback(correctOption) {
    return (
      <div className="test-result-literal-answer">
        <div className='answer-box'>
          <FcApproval className="test-result-icon" />&nbsp;
            <div>
              <strong><Translated translationKey="your_answer"/>:</strong>&nbsp;
              <span className="test-result-literal-answer-text">{correctOption.text}</span>
            </div>
        </div>
      </div>
      
    );
  }

  renderIncorrectLiteralFeedback(correctOption, answeredOption) {
    const answer = answeredOption === undefined ?
      <Translated translationKey="no_answer"/> :
      answeredOption.text;
    return (
      <div className="test-result-literal-answer">
        <div className="answer-box" >
          <FcHighPriority className="test-result-icon" />&nbsp;
            <div>
              <div>
                <strong><Translated translationKey="correct_answer"/>:</strong>&nbsp;
                <span className="test-result-literal-answer-text">{correctOption.text}</span>
              </div>
              <div className='gray-highlight'>
                <strong><Translated translationKey="your_answer"/>:</strong>&nbsp;
                <span className="test-result-literal-answer-text">{answer}</span>
            </div>
            </div>
        </div>
      </div>
    );
  }

  renderImage(question) {
    const src = question.question.imageUrl;
    return (
      <img className="test-result-sign-image" src={src}/>
    )
  }

  renderCommenting() {
    if (!this.state.showCommenting) {
      return null;
    }
    const question = this.props.question;
    return (
      <ResultFeedbackForm
        questionType="sign"
        questionId={question.id}
        keepOpen={true}
        className="large-top-margin"
      />
    );
  }

  toggleCommenting() {
    this.setState({showCommenting: !this.state.showCommenting});
  }

  renderResult(question) {
    const isCorrect = question.answer === question.correctAnswer;
    const correctOption = question.options[question.correctAnswer];
    const answeredOption = question.options[question.answer];
    const key = question.id;

    let feedback;
    if (isCorrect) {
      feedback = this.renderCorrectLiteralFeedback(correctOption);
    } else {
      feedback = this.renderIncorrectLiteralFeedback(correctOption, answeredOption);
    }

    return (
      <div className="blue-box reduced no-overflow relative">
        <div className="test-result-literal-wrapper" key={key}>
          {this.renderQuestion(question)}
          <div className="test-result-literal-answers">
            {this.renderImage(question)}
            {feedback}
          </div>
        </div>
        <div className="blue-box-bottom padded-small" >
          <div className="test-result-question-bottom touchable" onClick={() => this.toggleCommenting()}>
          <Translated className="gray-highlight" translationKey="question_report" />
              <div className=" test-result-question-bottom-item icon" >
                <IoChatbubblesSharp className="larger pull-right"/>
              </div>
            </div>
        {this.renderCommenting()}
        </div>
      </div>
    )
  }

  render() {
    const question = this.props.question;

    return (
      <div id="test-result-signs-container">
        {this.renderResult(question)}
      </div>
    );
  }
}

export default TestResultSign;
