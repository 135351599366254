import React, {Children, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import {loadData, dataTypes} from '../../actions/apiDataActions'
import api from '../../api';

const UserStatusLoaderWrapper = props => {

  const {userId, dispatch} = props;

  useEffect(() => {
    if (userId === null) {
      return;
    }
    dispatch(loadData(dataTypes.CATEGORY_STATUSES, api.getCategoryStatuses, userId));
  }, [userId, dispatch])

  const renderChildrenForCategory = () => {
    const categoryId = props.category.toLowerCase();
    const data = props.categoryStatuses.data;
    let status = data.find(status => status.category === categoryId);
    return Children.map(props.children, child => {
      const cloneProps = {
        role: props.role,
        userId: props.userId,
        category: categoryId,
        acceptedAmount: status.acceptedTestAmount,
        rejectedAmount: status.rejectedTestAmount,
        totalAcceptedRequired: status.acceptedTestsRequired,
      };
      return React.cloneElement(child, cloneProps);
    });
  }

  const renderChildrenForTotalResults = () => {
    let acceptedAmount = 0;
    let rejectedAmount = 0;
    const data = props.categoryStatuses.data;
    data.forEach(row => {
      acceptedAmount += row.acceptedTestAmount;
      rejectedAmount += row.rejectedTestAmount;
    });
    return Children.map(props.children, child => {
      const cloneProps = {
        role: props.role,
        userId: props.userId,
        acceptedAmount,
        rejectedAmount,
      };
      return React.cloneElement(child, cloneProps);
    });
  }

  if (props.userId === null) {
    return null;
  }

  if (!props.settings.get('isReceived')) {
    return <Spinner/>;
  }

  if (!props.categoryStatuses.data) {
    return (<Spinner/>)
  }

  return (
    <div id="user-status-container">
      {props.category ? renderChildrenForCategory() : renderChildrenForTotalResults()}
    </div>
  );

}

UserStatusLoaderWrapper.propTypes = {
  category: PropTypes.string
};

let mapStateToProps = (state) => {
  return ({
    settings: state.settings,
    categoryStatuses: state.apiData[dataTypes.CATEGORY_STATUSES],
    userId: state.login.get('token') ? state.login.get('token').get('userId') : null,
    role: state.login.get('token') ? state.login.get('token').get('role') : null
  });
};

export default connect(mapStateToProps)(UserStatusLoaderWrapper);
