//import mixpanel from 'mixpanel-browser';

const IS_IN_USE = false;//IS_PRODUCTION;

class TrackerAB {

  static initialize() {
    if (IS_IN_USE) {
      mixpanel.init(MIXPANEL_ID);
    }
  }

  static registerExperiment(experiment, variant, index) {
    if (IS_IN_USE) {
      let params = {
        [experiment]: variant
      };
      mixpanel.register(params);
    }
  }

  static register(params) {
    if (IS_IN_USE) {
      mixpanel.register(params);
    }
  }

  static track(eventId, props) {
    if (IS_IN_USE) {
      mixpanel.track(eventId, props);
    }
  }
}

export default TrackerAB;