import React, {Component} from 'react';
import withPendingOrderCheck from './withPendingOrderCheck';
import ShopCategorySelector from '../../feature/shop/ShopCategorySelector';
import Translated from '../../feature/Translated';
import withRouter from "../../feature/withRouter";

export class ShopFrontPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.selectCategory = this.selectCategory.bind(this);
  }

  selectCategory(category) {
    switch (category) {
      case 'b':
        return this.props.navigate('/opetuslupa');
      case 't':
        return this.props.navigate('/traktorikortti');
      case 'm':
        return this.props.navigate('/mopokortti');
      case 'be':
        return this.props.navigate('/be-kortti-pikku-e');
      case 'a':
        return this.props.navigate('/moottoripyorakortti');
      case 'am':
        return this.props.navigate('/mopoautokortti');
    }
  }

  render() {
    return (
      <div className="page-container max-width-container">
        <h1 className="phone-centered"><Translated translationKey="webshop_header"/></h1>
        <div className="blue-inner-box tight">
          <Translated translationKey="webshop_front_info" html={true}/>
        </div>
        <ShopCategorySelector onSelect={this.selectCategory}/>
      </div>
    )
  }
}

export default withRouter(withPendingOrderCheck(ShopFrontPage));
