import React, {Component} from 'react';
import {GiCarWheel} from 'react-icons/gi';

class Spinner extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
        <span>
          <GiCarWheel className="spin"/>
        </span>
    );
  }
}

export default Spinner

