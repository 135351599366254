const Roles = {
  STUDENT: 'student',
  TEACHER: 'teacher',
  ADMIN: 'admin'
};

const fillsRole = function (role, roleToFill) {
  const castRole = role ? role.toLowerCase() : null;
  switch (castRole) {

    case Roles.STUDENT:
      return (roleToFill === Roles.STUDENT);
      break;

    case Roles.TEACHER:
      return (roleToFill === Roles.STUDENT || roleToFill === Roles.TEACHER);
      break;

    case Roles.ADMIN:
      return true;
      break;

    default:
      return false;
  }
};

exports.Roles = Roles;
exports.fillsRole = fillsRole;

