import React, {Component} from 'react';
import {connect} from 'react-redux';
import withRouter from './withRouter';
import BookMenuItem from './BookMenuItem';
import MarkdownUtil from '../../utils/markdown';
import BookReadStatusMarker from './BookReadStatusMarker';
import ActiveBookStatus from './ActiveBookStatus';
import ResultFeedbackForm from './results/ResultFeedbackForm';
import BookNavigation from './BookNavigation';
import BookModalLink from './BookModalLink';

/**
 * Book browser. Shows book main menu and chapters.
 * This browser shows also book read status and possible additional content.
 */
class BookBrowser extends Component {

  constructor(props, context) {
    super(props, context);
    this.openChapter = this.openChapter.bind(this);
    this.state = {
      chapterIndex: 0
    }
  }

  static getDerivedStateFromProps(props, state) {
    const index = props.chapters.findIndex(chapter => chapter.id === props.chapterId);
    if (index !== state.chapterIndex) {
      return {chapterIndex: index}
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.chapterIndex !== prevState.chapterIndex) {
      window.scrollTo(0, 0);
    }
  }

  getCurrentChapter() {
    const i = this.state.chapterIndex;
    return i >= 0 ? this.props.chapters[i] : null;
  }

  openChapter(index) {
    if (index >= 0) {
      const chapter = this.props.chapters[index];
      this.props.navigate('/book/' + chapter.id);
    } else {
      this.props.navigate('/book');
    }
  }

  render() {
    if (this.state.chapterIndex > 0) {
      return this.renderChapter();
    } else {
      return this.renderMenu();
    }
  }

  renderChapter() {
    const chapter = this.getCurrentChapter();
    const navi = (
      <BookNavigation openChapter={this.openChapter}
                      current={this.state.chapterIndex}
                      total={this.props.chapters.length - 1}/>
    );
    const status = this.getChapterStatus(chapter.id);
    const isRead = status.progress === 1;
    return (
      <div className="book-content">
        {navi}
        <BookReadStatusMarker key={chapter.id + '-status-top'}
                              chapter={chapter}
                              isRead={isRead}
                              showProgress={false}
                              sendStatus={false}/>
          <div>
            {MarkdownUtil.render(chapter.markdown, chapter.id)}
            {this.renderChapterExtras(chapter)}
          </div>
        <BookReadStatusMarker key={chapter.id + '-status-bottom'}
                              chapter={chapter}
                              isRead={isRead}
                              showProgress={true}
                              sendStatus={true}/>
        <ResultFeedbackForm key={chapter.id + '-feedback'} questionType="book-chapter" questionId={chapter.id}
                            placeholderKey={'comment_book_chapter'}/>
        {navi}
      </div>
    )
  }

  renderChapterExtras(chapter) {
    // TODO Remove this hard coding when JSX Parser is usable again.
    // Add BookModalLink if chapter id ends with 'book_signs'
    if (/book_signs$/.test(chapter.id)) {
      const chapter = this.getCurrentChapter();
      const match = chapter.markdown.match(/<BookModalLink title="(.*)" contentId="public_all_signs".*\/>/);
      const title = Array.isArray(match) && match.length > 1 && match[1];

      return title && <BookModalLink title={title} contentId="public_all_signs" materialId="public"/>;
    }
    return null;
  }

  renderMenu() {
    let useFlash = true;
    let menuItems = this.props.chapters.map((chapter, i) => {
      if (i === 0) {
        return null;
      }
      const status = this.getChapterStatus(chapter.id);

      let className;
      if (status.progress < 1 && useFlash) {
        className = 'border-flash';
        useFlash = false;
      }

      return <BookMenuItem key={i}
                           index={i}
                           title={chapter.title}
                           onClick={() => this.openChapter(i)}
                           progress={status.progress}
                           showStatus={true}
                           className={className}/>;
    });

    return (
      <div className="fade-in">
        <ActiveBookStatus hideButton={true}/>
        <div className="book-menu-items">
          {menuItems}
        </div>
      </div>
    )
  }

  getChapterStatus(chapterId) {
    return this.props.status.chapterProgress.find(progress => progress.chapterId === chapterId);
  }

}

let mapStateToProps = (state) => {
  return ({
    locale: state.Intl.locale,
  });
};

export default withRouter(connect(mapStateToProps)(BookBrowser));
