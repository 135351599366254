import dateFormat from 'dateformat';

class DateFormat {

  /**
   *  Format dates to current locale time.
   *  dateFormat uses Javascript's getTimezoneOffset() for resolving local offset.
   */

  static date(dateTime) {
    if (dateTime === undefined || dateTime === null) {
      return '';
    }
    try {
      return dateFormat(dateTime, 'd.m.yyyy');
    } catch (e) {
      return '';
    }
  }

  static dateTime(dateTime) {
    if (dateTime === undefined || dateTime === null) {
      return '';
    }
    try {
      return dateFormat(dateTime, 'd.m.yyyy HH:MM');
    } catch (e) {
      return '';
    }
  }

  static getYear(dateTime) {
    if (dateTime) {
      const split = dateTime.split('-');
      return parseInt(split[0]);
    }
  }

  static getRemainingDays(expirationDate) {
    const now = new Date();
    const expiry = new Date(expirationDate);
    const daysLeft = Math.ceil((expiry.getTime() - now.getTime()) / 1000 / 60 / 60 / 24);

    return daysLeft;
  }

}

export default DateFormat;