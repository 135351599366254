import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withTranslate from './withTranslate';
import {connect} from 'react-redux'
import Button from 'react-bootstrap/Button';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Translated from '../feature/Translated';
import Spinner from './../feature/Spinner';
import withRouter from './withRouter';
import api from '../../api';
import {IoAlertOutline} from 'react-icons/io5';
import classNames from 'classnames'

export class EmailSetPassword extends Component {

  constructor(props, context) {
    super(props, context);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      password1: '',
      password2: '',
      isFetching: false,
      isCreated: false,
      error: null,
      errorData: null,
      errorMessage: ''
    }
  }

  static propTypes = {
    email: PropTypes.string.isRequired,
    requestToken: PropTypes.string.isRequired,
  };

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.isFetching) {
      return;
    }
    if (this.state.password1 !== this.state.password2) {
      return this.setState({
        isCreated: false,
        error: 'password_mismatch',
        errorData: null,
        errorMessage: ''
      });
    }

    this.setState({
      isFetching: true,
      isCreated: false,
      error: null,
      errorData: null,
      errorMessage: ''

    });
    api.userEmailRequestSetPassword(this.props.requestToken, this.state.password1, this.props.locale)
      .then(response => {
        if (response.error) {
          this.setState({
            password1: '',
            password2: '',
            isFetching: false,
            isCreated: false,
            error: response.error,
            errorData: response.data,
            errorMessage: response.message
          });
        } else {
          this.setState({
            password1: '',
            password2: '',
            isFetching: false,
            isCreated: true,
            error: null,
            errorData: null,
            errorMessage: ''
          });
        }
      })
  }

  updateField = (field) => (e) => {
    this.setState({[field]: e.target.value});
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }

    const errorMsg = this.state.errorMessage
      ? <span>{this.state.errorMessage}</span>
      : <Translated translationKey={this.state.error} values={this.state.errorData}/>;

    return this.state.error
      ? <Alert>
        <IoAlertOutline/> {errorMsg}
      </Alert>
      : null;
  }

  renderDone() {
    return(
      <div>
        <Alert>
          <Translated translationKey="password_is_set"/> <Translated translationKey="login_with_new_password"/>
        </Alert>
        <Button onClick={() => this.props.navigate('/')}>
          <Translated translationKey="ok"/>
        </Button>
      </div>
    );
  }

  render() {
    if (this.state.isCreated) {
      return this.renderDone();
    }

    const t = this.props.translate;
    const passwordLabel1 = <Translated translationKey="new_password"/>;
    const passwordLabel2 = <Translated translationKey="password_again"/>;
    const inputEmpty = !this.state.password1 || !this.state.password2;

    return (
      <div className={classNames('centered', 'large-top-margin', this.props.className)}>
        <Card id="create-email-password-panel" className="login-form-well" header={this.props.header}>
          <Card.Body>
            <h3><Translated translationKey="create_email_password_info" values={{email: this.props.email}}/></h3>
            <form id="email-login-form" onSubmit={this.handleSubmit} method="post">
              <FormGroup onSubmit={this.handleSubmit}>
                {passwordLabel1}
                <FormControl id="create-password-1" disabled={this.state.isFetching} type="password" name="password1"
                             placeholder={t('new_password')}
                             value={this.state.password1}
                             autoComplete="new-password"
                             onChange={this.updateField('password1')}/>
                <div className="field-spacer"/>
                {passwordLabel2}
                <FormControl id="create-password-2" disabled={this.state.isFetching} type="password" name="password2"
                             placeholder={t('password_again')}
                             value={this.state.password2}
                             autoComplete="new-password"
                             onChange={this.updateField('password2')}/>

                <div className="field-spacer"/>
                {this.renderError()}
              </FormGroup>
              <Button
                id="email-request-create-password-submit"
                type="submit"
                disabled={this.state.isFetching || inputEmpty}
                className="submit-login-button strong-text"
                onClick={this.handleSubmit}>
                {this.state.isFetching ? <Spinner/> : t('save')}
              </Button>
            </form>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return ({
    locale: state.Intl.locale
  });
};

export default withRouter(connect(mapStateToProps)(withTranslate(EmailSetPassword)));
