import React, {Component} from 'react';
import hasTouch from '../../utils/touch';
import {BsZoomIn} from 'react-icons/bs';
import Modal from 'react-overlays/Modal';
import classNames from 'classnames';
import Tracker from '../../utils/tracker';

const pinchIcon = require('../../assets/pinch-icon.png');

class ZoomableImage extends Component {

  constructor(props, context) {
    super(props, context);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.state = {
      showPinch: false,
      showModal: false
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  showModal() {
    Tracker.logEvent('image', 'image-zoomed-modal');
    this.setState({showModal: true});
  }

  hideModal() {
    this.setState({showModal: false});
  }

  render() {
    let zoomComponent;
    let clickHandler;

    if (hasTouch()) {
      if (this.state.showPinch) {
        zoomComponent = <img src={pinchIcon} className='pinch-icon'/>;
        clickHandler = () => {
          this.setState({showPinch: false});
          clearTimeout(this.timeout);
          this.timeout = null;
        }
      } else {
        zoomComponent = null;
        clickHandler = () => {
          this.setState({showPinch: true});
          this.timeout = setTimeout(() => this.setState({showPinch: false}), 1500);
        }
      }
    } else {
      zoomComponent = (
        <div id="image-modal" className={classNames('zoom-icon', this.props.modalClassName)}>
          <BsZoomIn
                     onClick={this.showModal}
                     className="touchable"/>
          <Modal show={this.state.showModal}
                 onHide={this.hideModal}
                 className="image-modal"
                 backdropclassname="image-modal-backdrop">
            <img src={this.props.src}
                 className="image-modal-content touchable"
                 onClick={this.hideModal}/>
          </Modal>
        </div>
      );

      clickHandler = () => {
        this.setState({showModal: true});
      }
    }

    return (
      <div id="zoomable-image-container" className="zoomable-image-container">
        <img src={this.props.src} className={classNames(this.props.className, 'touchable')}
             onClick={clickHandler} {...this.props.imgProps}/>
        {zoomComponent}
      </div>
    )
  }

}

export default ZoomableImage;

