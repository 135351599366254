import React from 'react';
import Button from 'react-bootstrap/Button';

const VersionPage = () => {
  return (
      <div>
        Build date: {BUILD_VERSION}
        <br/>
        App version: {APP_VERSION}
        <br/>
        Production build: {IS_PRODUCTION ? 'YES' : 'NO'}
        <div className="top-margin">
          <Button onClick={(event) => {
            console.log('Intentionally crashing for testing purposes!');
            setTimeout(() => {
              event.thisFunctionCrashesIntentionally();
            }, 500);
          }}>
            Test crash
          </Button>
        </div>
      </div>
  );
}

export default VersionPage

