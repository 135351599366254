import "./styles/style.scss";

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import VersionPage from "./components/pages/VersionPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import store from "./store";
import api from "./api";
import TranslationProvider from "./components/feature/TranslationProvider";
import Logger from "./utils/logger";
import TagManager from "react-gtm-module";

// Google Consent Mode setup.
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("consent", "default", {
  ad_storage: "denied",
  analytics_storage: "denied",
  wait_for_update: 500,
});
gtag("set", "ads_data_redaction", true);

TagManager.initialize({
  gtmId: GTM_CONTAINER_ID,
});

Logger.initialize();

// Feed store to api so that there is no cyclic dependency in imports.
api.store = store;

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <TranslationProvider>
      <CookiesProvider>
        <Router>
          <Routes>
            <Route path="/build/version" element={<VersionPage />} />
            <Route path="/*" element={<App />} />
          </Routes>
        </Router>
      </CookiesProvider>
    </TranslationProvider>
  </Provider>
);
