export const RECEIVE_NAV_DATA = 'RECEIVE_NAV_DATA';
export const TOGGLE_NAV = 'TOGGLE_NAV';
export const CLOSE_NAV = 'CLOSE_NAV';

export const CONNECTION_STATUS = 'CONNECTION_STATUS';
export const MAINTENANCE_STATUS = 'MAINTENANCE_STATUS';

export const FB_READY = 'FB_READY';
export const FB_PROCESSING = 'FB_PROCESSING';

export const PAGE_DATA = 'PAGE_DATA';
export const USERS_PAGE_ID = 'USERS_PAGE_ID';
export const SCHOOL_SETTINGS_PAGE_ID = 'SCHOOL_SETTINGS_PAGE_ID';

/**
 * Navigation
 */
export function toggleNavigation() {
  return {
    type: TOGGLE_NAV
  }
}

export function closeNavigation() {
  return {
    type: CLOSE_NAV
  }
}

function receiveNavigationData(data) {
  return {
    type: RECEIVE_NAV_DATA,
    data
  }
}

export function fetchNavigationData() {
  return function (dispatch, getState) {
    let state = getState();
    if (!state.navigation.data) {
      //dispatch(requestNavigationData);

      // TODO return fetch(...).then(...)
      let navData = require('../data/navigationData').default;
      dispatch(receiveNavigationData(navData))
    }
  }
}

export function connectionStatus(hasConnection) {
  return {
    type: CONNECTION_STATUS,
    hasConnection
  }
}

export function maintenanceStatus(hasMaintenance) {
  return {
    type: MAINTENANCE_STATUS,
    hasMaintenance
  }
}

export function fbReady(isReady) {
  return {
    type: FB_READY,
    isReady
  }
}

export function fbProcessing(isProcessing) {
  return {
    type: FB_PROCESSING,
    isProcessing
  }
}

export function pageData(pageId, data) {
  return {type: PAGE_DATA, pageId, data};
}