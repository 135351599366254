import React, {Component} from 'react';

class FullTestTimer extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      elapsed: this.getElapsedTime()
    };
    this.tick = this.tick.bind(this);
  }

  componentDidMount() {
    if (this.state.elapsed < this.props.durationSec) {
      this.timer = setInterval(this.tick, 100);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  tick() {
    let elapsed = this.getElapsedTime();
    if (elapsed > this.props.durationSec) {
      this.props.onComplete();
      elapsed = this.props.durationSec;
      clearInterval(this.timer);
    }
    this.setState({elapsed});
  }

  getElapsedTime() {
    return Math.round((Date.now() - this.props.start) / 1000);
  }

  render() {
    const secondsLeft = Math.max(this.props.durationSec - this.state.elapsed, 0);
    const minutes = Math.floor(secondsLeft / 60);
    let seconds = secondsLeft - minutes * 60;
    seconds = ("0" + seconds).slice(-2);
    return (
        <div id="full-test-timer-container" className="timer" onClick={this.props.onClick} ref={this.props.innerRef}>
          {minutes + ':' + seconds}
        </div>
    )
  }
}

export default FullTestTimer;
