import React, {Component} from 'react';

class ChatBubble extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <div className="chat-bubble">
          <div className="chat-bubble-text">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default ChatBubble;
