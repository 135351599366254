import React, {Component} from 'react';
import Translated from '../feature/Translated';

class PreloadMiddlePage extends Component {

  render() {
    return(
      <div className="page-container preload-wrapper plain">
      {this.props.error
        ? <div className="white"><Translated translationKey={this.props.error}/></div>
        : (<div className="preload-spinner primary">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>)
      }
    </div>
    );
  }

}

export default PreloadMiddlePage

