import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import Alert from 'react-bootstrap/Alert';
import api from '../../../api';
import Spinner from '../../feature/Spinner';
import withTranslate from '../../feature/withTranslate';
import Translated from '../../feature/Translated';
import Cart from '../../feature/shop/Cart';
import CustomerInput from '../../feature/shop/CustomerInput';
import PaymentMethods from '../../feature/shop/PaymentMethods';
import Button from 'react-bootstrap/Button';
import ContactForm from '../../feature/ContactForm';
import LocalStorageUtil from '../../../utils/localStorageUtil';
import Collapse from 'react-bootstrap/Collapse';
import TrackerAB from '../../../utils/trackerAB';
import {getAllLocalReferralsObj} from '../../../actions/referralActions';
import Scroll from 'react-scroll';
import withPendingOrderCheck from './withPendingOrderCheck';
import ProductDetails from '../../feature/shop/ProductDetails';
import ShopPaymentTermsNote from '../../feature/shop/ShopPaymentTermsNote';
import ShopCancellingTermsNote from '../../feature/shop/ShopCancellingTermsNote';
import withRouter from "../../feature/withRouter";
import {Navigate} from 'react-router-dom';

export const PRODUCT_PAGE_ID = 'product_page';
const SCROLL_OPTIONS = {smooth: true, duration: 1000};

export class ProductPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.onAddToCart = this.onAddToCart.bind(this);
    this.onRemoveFromCart = this.onRemoveFromCart.bind(this);
    this.onSetCustomerInput = this.onSetCustomerInput.bind(this);
    this.cancelPurchase = this.cancelPurchase.bind(this);
    this.closePage = this.closePage.bind(this);

    this.state = {
      cart: [],
      customerData: null,
      isPurchaseStarted: false,
      isFetchingPaymentMethods: false,
      paymentMethods: null,
      paymentMethodsError: null,
      order: null,
      showContactForm: false,
      contactFormSubmitted: false,
      showPaymentTypes: true,
      paymentBannerUrl: '',
      showImagePromo: false
    }
  }

  componentWillUnmount() {
    clearTimeout(this.paymentBannerTimeout);
  }

  componentDidMount() {
    this.paymentBannerTimeout = setTimeout(
      () => this.setState({paymentBannerUrl: 'https://banners.checkout.fi/33f0744d685d65b33fe483ec20cfaf51/300x300.png'}), 5000);
  }

  startPurchase() {
    TrackerAB.track('webshop-start-purchase');
    this.setState({
      isPurchaseStarted: true,
      isFetchingPaymentMethods: true,
      paymentMethodsError: null
    }, Scroll.animateScroll.scrollToTop(SCROLL_OPTIONS));

    const lang = this.props.locale;
    const localReferralsObj = getAllLocalReferralsObj();

    api.purchaseProducts(this.state.cart, this.state.customerData, lang, this.props.referralId, this.props.adId, localReferralsObj)
      .then(response => {
        if (response.error) {
          return this.setState({
            isFetchingPaymentMethods: false,
            paymentMethodsError: response.error,
            isPurchaseStarted: false
          });
        }

        LocalStorageUtil.setForUser('anon', 'pending-order', {
          id: response.order.id,
          token: response.order.token
        });

        this.setState({
          isFetchingPaymentMethods: false,
          paymentMethods: response.paymentMethods,
          order: response.order
        });
      })
  }

  cancelPurchase() {
    TrackerAB.track('webshop-cancel-purchase');
    this.setState({
      isPurchaseStarted: false,
      isFetchingPaymentMethods: false
    });
  }

  closePage() {
    // if (this.props.navigate.length > 1) {
    //   this.props.navigate(-1);
    // } else {
      this.props.navigate('/kauppa');
    //}
  }

  onSetCustomerInput(customerData) {
    TrackerAB.track('webshop-customer-data-input');
    this.setState({
      customerData,
    }, this.startPurchase);
  }

  onAddToCart(product, inputs) {
    TrackerAB.track('webshop-product-added-to-cart');
    const cart = this.state.cart;
    const filledProduct = Object.assign({}, product, {inputs});
    cart.push(filledProduct);
    this.setState({
      cart
    }, () => Scroll.animateScroll.scrollTo('webshop-products', SCROLL_OPTIONS));
  }

  onRemoveFromCart(product) {
    TrackerAB.track('webshop-product-removed-from-cart');
    const cart = this.state.cart.slice();
    for (let i = cart.length - 1; i >= 0; i--) {
      let p = cart[i];
      if (p.id === product.id) {
        cart.splice(i, 1);
      }
    }
    this.setState({
      cart
    });
  }

  renderContactForm() {
    const buttonKey = this.state.showContactForm ? 'cancel' : 'contact';
    let content;
    if (this.state.showContactForm) {
      content = (
        <div className="top-margin contact-wrapper white-box slide-in">
          <ContactForm email onSubmitted={() => this.setState({contactFormSubmitted: true, showContactForm: false})}/>
        </div>
      )
    } else if (this.state.contactFormSubmitted) {
      content = <Translated translationKey="message_sent"/>;
    }

    return (
      <div className="large-top-margin webshop-contact-wrapper">
        <Button aria-label="Send feedback" role="presentation" onClick={() => this.setState({
          showContactForm: !this.state.showContactForm,
          contactFormSubmitted: false
        })}>
          <Translated translationKey={buttonKey}/>
        </Button>
        {content}
      </div>
    )
  }

  renderProducts() {
    let content;
    if (this.state.isLoadingProducts) {
      content = <div><Spinner/></div>
    } else if (this.props.product) {
      content = <ProductDetails className='slide-in'
                                product={this.props.product}
                                onCancel={this.closePage}
                                onAddToCart={this.onAddToCart}/>;
    }
    return (
      <div className="medium-top-margin medium-bottom-margin">
        {content}
      </div>
    )
  }

  renderStateShopping() {
    if (this.state.productsError) {
      return <Alert bsstyle="danger"><Translated translationKey={this.state.productsError}/></Alert>
    } else {
      return this.renderProducts();
    }
  }

  renderStateCart() {
    let error = null;
    if (this.state.paymentMethodsError) {
      error = <Alert bsstyle="danger"><Translated translationKey={this.state.paymentMethodsError}/></Alert>
    }
    return (
      <div>
        <Cart products={this.state.cart} onRemoveFromCart={this.onRemoveFromCart}/>
        <CustomerInput className="slide-in" setInput={this.onSetCustomerInput} data={this.state.customerData}/>
        {error}
      </div>
    )
  }

  renderStatePayment() {
    if (this.state.paymentMethodsError) {
      return <Alert bsstyle="danger"><Translated translationKey={this.state.paymentMethodsError}/></Alert>
    }
    return (
      <div>
        {this.state.paymentMethods && this.state.order
          ? <PaymentMethods className="slide-in" paymentMethods={this.state.paymentMethods} order={this.state.order}/>
          : <Spinner/>
        }
        <Button aria-label="Cancel" role="presentation" onClick={this.cancelPurchase}><Translated
          translationKey="cancel"/></Button>
      </div>
    );
  }

  renderPaymentBanner() {
    if (this.state.showContactForm || this.state.isPurchaseStarted) {
      return null;
    }

    const banner = (
      <div className="semi-dark-inner-box tight fade-in delay top-margin-small">
        {this.state.showPaymentTypes
          ? null
          : <div className="centered">
            <Button bsstyle="link" aria-label="Show payment types" role="presentation" onClick={() => {
              TrackerAB.track('webshop-show-payment-types');
              this.setState({showPaymentTypes: true})
            }}>
              <Translated translationKey="payment_types"/>...
            </Button>
          </div>
        }
        <Collapse in={this.state.showPaymentTypes}>
          <div className="top-margin">
            <div className="centered fade-in">
              <Translated translationKey="payment_types" className="strong"/>
              <br/>
              <img src={this.state.paymentBannerUrl}/>
              <br/>
              <Translated translationKey="payment_operator_info"/>
              <ShopPaymentTermsNote /> <ShopCancellingTermsNote />
            </div>
          </div>
        </Collapse>
      </div>
    );

    return banner;
  }

  renderCompany() {
    return (
      <div className="centered large-top-margin semi-transparent touchable"
           onClick={() => this.props.navigate('/privacy-policy')}>
        <strong>Ajokaista Oy</strong>
        <br/>
        Katajapolku 14<br/>
        00780 Helsinki
      </div>
    )
  }

  hasItemsInCart() {
    return this.state.cart && this.state.cart.length > 0;
  }

  render() {

    if (!this.props.product) {
      return <Navigate to="/kauppa" replace={true}/>;
    }

    return this.renderSplittedInfo()
  }

  renderSplittedInfo() {
    let stateContent;

    if (this.state.isPurchaseStarted) {
      // Has filled information and is making a payment
      stateContent = this.renderStatePayment();

    } else if (this.hasItemsInCart()) {
      // Has something in cart, show cart and customer details form
      stateContent = this.renderStateCart();

    } else {
      // Has not chosen product(s), show all products
      stateContent = this.renderStateShopping();
    }

    return (
      <div id="shop-page" className="page-container max-width-container">
        <Scroll.Element name={'webshop-products'}>
          {stateContent}
        </Scroll.Element>
        {this.renderPaymentBanner()}
        <div className="vertical-middle even phone-column">
          {this.renderContactForm()}
          {this.renderCompany()}
        </div>
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return ({
    locale: state.Intl.locale,
    adId: state.referral.get('adId'),
    referralId: state.referral.get('referralId'),
    product: state.pages[PRODUCT_PAGE_ID]
  });
};

const composed = compose(
  withRouter,
  connect(mapStateToProps),
  withPendingOrderCheck,
  withTranslate
);

export default composed(ProductPage);
