import React, {Component} from 'react';
import {connect} from 'react-redux';
import Translated from '../../Translated';
import CategoryStart from './../../CategoryStart';

class ImagesStart extends Component {

  render() {
    return (
      <div className="max-width-container">
        <div>
          <div>
            <h3><Translated translationKey="images_title"/></h3>
            <Translated translationKey="images_description"/>
          </div>
          <CategoryStart id="practice-images-start"
                         category={this.props.category}
                         start={this.props.start}/>
        </div>
      </div>
    );
  }
}

export default ImagesStart;