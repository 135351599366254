import React, {Component} from 'react';
import api from '../../api';
import Translated from '../feature/Translated';
import Spinner from './../feature/Spinner';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import withTranslate from '../feature/withTranslate';
import {IoPerson} from 'react-icons/io5';
import {IoMail} from 'react-icons/io5';
import {BsFillTelephoneFill} from 'react-icons/bs'
import Button from 'react-bootstrap/Button';
import {getAllLocalSettingsObj} from '../../actions/settingsActions';

class ContactForm extends Component {

  constructor(props, context) {
    super(props, context);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitContact = this.submitContact.bind(this);

    this.state = {
      sending: false,
      submitted: false,
      error: null,
      form: {
        contactPerson: '',
        phoneNumber: '',
        email: '',
        message: ''
      }
    }
  }

  submitContact() {
    if (this.isFormValid()) {
      this.setState({
        sending: true,
        error: null
      });

      // Include local settings in feedback. This helps to identify the users in case he is not logged in.
      const localSettings = getAllLocalSettingsObj();
      api.sendFeedback(this.state.form.contactPerson, this.state.form.phoneNumber, this.state.form.email, this.state.form.message, {settings: localSettings})
          .then((response) => {
            if (response.error) {
              return this.setState({
                sending: false,
                submitted: false,
                error: response.error
              });
            }
            this.setState({
              sending: false,
              submitted: true
            });

            if (this.props.onSubmitted) {
              this.props.onSubmitted();
            }
          });
    }
  }

  isFormValid() {
    const requiredFields = ['message'];
    let isValid = true;
    requiredFields.forEach(key => {
      const value = this.state.form[key];
      if (value === '') {
        isValid = false;
      }
    });
    return isValid;
  }

  handleInputChange(e) {
    let formState = Object.assign(this.state.form, {[e.target.name]: e.target.value});
    this.setState({form: formState});
  }

  renderForm() {
    const errorMessage = this.state.error ?
        <Alert bsstyle="warning"><Translated translationKey={this.state.error}/></Alert> :
        null;

    return (
        <Form>
          <fieldset disabled={this.state.sending}>
            {this.props.name && (
                <Form.Group id="person" className="mb-3" >
                  <InputGroup>
                    <InputGroup.Text>
                      <IoPerson className="white"/>
                    </InputGroup.Text>
                    <Form.Control
                        type="text"
                        name="contactPerson"
                        placeholder={this.props.translate('name')}
                        value={this.state.form.contactPerson}
                        onChange={(e) => this.handleInputChange(e)}/>
                  </InputGroup>
                </Form.Group>
            )}

            {this.props.phone && (
                <Form.Group id="phone" className="mb-3" >
                  <InputGroup>
                    <InputGroup.Text>
                      <BsFillTelephoneFill className="white"/>
                    </InputGroup.Text>
                    <Form.Control
                        type="text"
                        name="phoneNumber"
                        placeholder={this.props.translate('phone_number')}
                        value={this.state.form.phoneNumber}
                        onChange={(e) => this.handleInputChange(e)}/>
                  </InputGroup>
                </Form.Group>
            )}

            {this.props.email && (
                <Form.Group id="email" controlId="formBasicEmail" className="mb-3" >
                  <InputGroup>
                    <InputGroup.Text>
                      <IoMail className="white"/>
                    </InputGroup.Text>
                    <Form.Control
                        type="text"
                        name="email"
                        placeholder={this.props.translate('email')}
                        value={this.state.form.email}
                        onChange={(e) => this.handleInputChange(e)}/>
                  </InputGroup>
                </Form.Group>
            )}

            <Form.Group controlId="message" className="mb-3" >
              <Form.Control
                  name="message"
                  as="textarea"
                  rows={5}
                  value={this.state.form.message}
                  placeholder={this.props.translate('message')}
                  onChange={(e) => this.handleInputChange(e)}
                  className="contact-message-text-area"/>
            </Form.Group>

            <div className="centered">
              {errorMessage}
              <Button aria-label="Submit" role="presentation" id="submit-contact"
                      disabled={!this.isFormValid() || this.state.sending}
                      onClick={() => this.submitContact()}>
                <Translated translationKey="send"/>
              </Button>{this.state.sending ? <Spinner className="primary"/> : null}
            </div>
          </fieldset>
        </Form>
    );
  }

  render() {
    return (
        <div>
          {this.renderForm()}
        </div>
    );
  }


}

export default withTranslate(ContactForm);
