import React, {Component} from 'react';
import {connect} from 'react-redux'
import {loadData, dataTypes} from '../../actions/apiDataActions'
import Spinner from '../feature/Spinner';
import Translated from '../feature/Translated';
import withTranslate from '../feature/withTranslate';
import Alert from 'react-bootstrap/Alert';
import classNames from 'classnames';
import api from '../../api';
import TeacherList from '../feature/TeacherList';
import UserCreate from '../feature/UserCreate';
import {Roles} from '../../data/roles';
import withRouter from "../feature/withRouter";

class TeachersListPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  getSchoolId() {
    if (this.props.match && this.props.match.params.schoolId) {
      return this.props.match.params.schoolId;
    } else {
      return this.props.token.get('schoolId')
    }
  }

  fetch() {
    const schoolId = this.getSchoolId();
    this.props.dispatch(loadData(dataTypes.LIST_TEACHERS, api.listTeachers, schoolId));
  }

  renderUserList() {
    if (!this.props.hasFetched) {
      return null;
    }
    if (this.props.isFetching) {
      return <Spinner className="primary"/>;
    }
    return (
      <div>
        <TeacherList users={this.props.users} noUsersTranslationKey="no_search_results" className="fade-in"/>
      </div>
    )
  }

  render() {
    if (!this.props.token) {
      return null;
    }
    const classes = classNames('page-container max-width-container fade-in', this.props.className);
    return (
      <div id="user-list-page" className={classes}>
        <h3>
          <Translated translationKey='add_teacher'/>
        </h3>
        <Translated translationKey="help_add_teacher" className="top-margin medium-bottom-margin strong" />
        {this.props.error
          ? <Alert><Translated translationKey={this.props.error}/></Alert>
          : null
        }
        <UserCreate role={Roles.TEACHER} schoolId={this.getSchoolId()} requestCreatedCallback={this.fetch} />
        <h3><Translated translationKey='teachers' /></h3>
        {this.renderUserList()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const search = state.apiData[dataTypes.LIST_TEACHERS];
  return {
    users: search.data,
    isFetching: search.isFetching,
    hasFetched: search.data && !search.isFetching,
    error: search.error,
    token: state.login.get('token')
  }
};

export default withRouter(connect(mapStateToProps)(withTranslate(TeachersListPage)));
