import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import BookContentModal from './BookContentModal';
import { FiBook } from "react-icons/fi";
import Translated from './Translated';
import {connect} from 'react-redux';
import {loadData, dataTypes} from '../../actions/apiDataActions';
import api from '../../api';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import classNames from 'classnames';
import {Roles, fillsRole} from '../../data/roles';

class BookModalLink extends Component {

  static allowPopover = true;
  static setAllowPopover(allow) {
    BookModalLink.allowPopover = allow;
  }

  constructor(props, context) {
    super(props, context);
    this.onHide = this.onHide.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
    const {isRead, showPopover} = this.getIsRead();
    this.state = {
      isRead,
      showModal: false,
      showPopover
    };
  }

  componentDidMount() {
    if (this.props.role === Roles.STUDENT && !this.props.bookStatus.data && !this.props.bookStatus.isFetching) {
      this.props.dispatch(loadData(dataTypes.BOOK_STATUSES, api.getBookStatuses, this.props.userId, this.props.locale));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.bookStatus !== prevProps.bookStatus) {
      this.setState(this.getIsRead());
    }
  }

  getIsRead() {
    const content = this.props.content;
    const contentId = content ? content.id : this.props.contentId;
    let isRead = true;
    let showPopover = false;
    const bookStatus = this.getBookStatus();
    if (bookStatus) {
      const chapterId = content ? content.chapterId : this.props.chapterId;
      isRead = bookStatus.readContent.some(readContentId => readContentId === contentId || readContentId === chapterId);

      if (bookStatus.progress === 0 && BookModalLink.allowPopover && this.props.role === Roles.STUDENT) {
        showPopover = true;
        BookModalLink.allowPopover = false;
      }
    }
    return {isRead, showPopover};
  }

  getBookStatus() {
    const content = this.props.content;
    const materialId = content ? content.materialId : this.props.materialId;
    if (this.props.bookStatus.data && materialId) {
      return this.props.bookStatus.data.find(status => status.bookId === materialId);
    }
    return null;
  }

  onButtonClick(e) {
    this.setState({showModal: true});
  }

  render() {
    let modal = null;
    const content = this.props.content;
    const contentId = content ? content.id : this.props.contentId;
    if (this.state.showModal) {
      modal = <BookContentModal contentId={contentId}
                                content={content}
                                onClose={() => this.setState({showModal: false})}
                                bookStatus={this.getBookStatus()}
                                showId={this.props.showId}
                                showStatus={this.props.role === Roles.STUDENT}/>
    }

    let title;
    if (this.props.title) {
      title = <span>{this.props.title}</span>
    } else if (this.props.showId) {
      title = <span>{contentId}</span>
    } else {
      if (content && content.title) {
        title = <span>{this.shorten(content.title)}</span>;
      } else {
        title = <Translated translationKey="more_info"/>;
      }
    }

    const style = this.state.isRead || fillsRole(this.props.role, Roles.TEACHER)? 'link' : 'warning';

    let popover;
    if (this.state.showPopover && this.state.button) {
      popover = (
        <Overlay
          show={this.state.showPopover}
          target={this.state.button}
          placement="bottom"
          onHide={this.onHide}
          rootClose>
          <Popover id="book-link-popover">
            <Popover.Body>
              <Translated translationKey="unread_book_section"/>
            </Popover.Body>
          </Popover>
        </Overlay>
      )
    }

    return (
      <div className="book-modal-link book-link">
        <Button bsstyle={style}
                onClick={this.onButtonClick}
                ref={e => this.state.button || this.setState({button: e})}
                className={classNames({'floatY': !this.state.isRead && this.props.animate})}>
          <FiBook className="larger text-bottom"/>{title}
        </Button>
        {modal}
        {popover}
      </div>
    );
  }

  onHide() {
    this.setState({
      showPopover: false
    });
  }

  shorten(text) {
    text = text.trim();
    const words = text.split(' ');
    let result = words[0];
    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const currentLength = result.length;
      const nextLength = currentLength + word.length + 1;
      if (currentLength > 15 || nextLength > 22) {
        break;
      }
      result += ' ' + word;
    }
    if (text !== result) {
      result += '...';
    }
    return result;
  }
}

BookModalLink.propTypes = {
  title: PropTypes.string,
  contentId: PropTypes.string,
  materialId: PropTypes.string
};

const mapStateToProps = (state) => {
  const token = state.login.get('token');
  return ({
    userId: token && token.get('userId'),
    role: token && token.get('role'),
    bookStatus: state.apiData[dataTypes.BOOK_STATUSES],
    locale: state.Intl.locale
  });
};

export default connect(mapStateToProps)(BookModalLink);

