import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import VehicleInfo from './VehicleInfo';

class VehicleInfoTrigger extends Component {

  static propTypes = {
    category: PropTypes.string
  };

  static defaultShow = true;

  constructor(props, context) {
    super(props, context);
    this.state = {
      showOverlay: false
    }
  }

  hideOverlay() {
    VehicleInfoTrigger.defaultShow = false;
    this.setState({showOverlay: false});
  }

  render() {
    const catImgSrc = require('../../assets/category/' + this.props.category + '_white.png');
    const placement = this.props.placement || 'bottom';
    return (
      <div>
        <div className="touchable"
             onClick={() => this.setState({showOverlay: true})}
             ref={e => this.overlayTarget = e}>
          <img src={catImgSrc} className="vehicle-info-icon"/>
        </div>
        <Overlay defaultOverlayShown={VehicleInfoTrigger.defaultShow}
                 show={(this.state.showOverlay || VehicleInfoTrigger.defaultShow) && this.overlayTarget !== null}
                 target={this.overlayTarget}
                 placement={placement}
                 onHide={() => this.hideOverlay()}
                 rootClose>
          <Popover id="vehicle-info">
            <Popover.Body>
              <VehicleInfo category={this.props.category}/>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    );
  }
}

export default VehicleInfoTrigger
