import React from 'react';
import PropTypes from 'prop-types';
import Translated from './Translated';
import classNames from 'classnames';
import DateUtil from '../../utils/date';
import {IoPerson, IoLockClosedSharp, IoMail} from "react-icons/io5";

import Alert from 'react-bootstrap/Alert';
import {useNavigate} from 'react-router-dom';

const UserList = props => {
  const navigate = useNavigate();

  const renderUsersElements = () => {
    if (!props.users) {
      return null;
    }

    const elements = [];
    props.users.forEach(user => {
      user.pictureUrl = user.facebook ? user.facebook.picture : user.picture_url;
      let isClosed = user.isClosed || user.isClosedByDate;
      let classes = classNames('ct-table-row touchable', isClosed ? 'ct-table-row semi-transparent' : null);
      elements.push(
        <div
          className={classes}
          key={user.id}
          onClick={() => {
            navigate("/user/" + user.id);
          }}
        >
          <div className="ct-table-body-cell">
            {user.pictureUrl ? (
              <img src={user.pictureUrl} className="user-image-thumb" />
            ) : null}
          </div>
          <div className="ct-table-body-cell">
            <span className="ct-mobile-only">
              <IoPerson />
            </span>
            &nbsp;
            <Translated
              translationKey={user.name}
              options={{ onlyIfKey: true }}
            />
            <span className="ct-mobile-only">
              {isClosed && <IoLockClosedSharp />}
            </span>
            {user.email && (
              <div className="semi-transparent">
                <span className="ct-mobile-only">
                  <IoMail />
                </span>
                &nbsp;{user.email}
              </div>
            )}
          </div>
          <div className="ct-table-body-cell">
            <strong className="ct-mobile-only">
              <Translated translationKey="user_id" />:
            </strong>
            &nbsp;#{user.id}
          </div>
          <div className="ct-table-body-cell">
            <strong className="ct-mobile-only">
              <Translated translationKey="taken_into_use" />:
            </strong>
            &nbsp;{DateUtil.date(user.activationDate)}
          </div>
          <div className="ct-table-body-cell">
            <span className="ct-desktop-only">
              {isClosed && <IoLockClosedSharp />}
            </span>
          </div>
        </div>
      );
    });
    return elements;
  }

  if (!props.users || props.users.length === 0) {
    return (
      <Alert>
        <Translated translationKey={props.noUsersTranslationKey}/>
      </Alert>
    );
  } else {
    return (
      <div className={props.className}>
        <div className="white-box tight">
          <div className="custom-table full-width">
            <div className="custom-table-header">
              <div className='ct-column-name'><IoPerson/></div>
              <div className='ct-column-name'><Translated translationKey="name"/></div>
              <div className='ct-column-name'><Translated translationKey="user_id"/></div>
              <div className='ct-column-name'><Translated translationKey="taken_into_use"/></div>
              <div className='ct-column-name'><IoLockClosedSharp/></div>
            </div>
        <div className='ct-table-body'>
        {renderUsersElements()}
        </div>
    </div>
        </div>
      </div>
    );
  }

}

UserList.propTypes = {
  noUsersTranslationKey: PropTypes.string.isRequired
};

export default UserList;
