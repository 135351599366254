import {combineReducers} from 'redux';

import availableLangs from './availableLangsReducer';
import login from './loginReducer';
import navigation from './navigationReducer';
import settings from './settingsReducer';
import translations from './translationsReducer';
import network from './networkReducer';
import apiData from './apiDataReducer';
import pages from './pageDataReducer';
import referral from './referralReducer';
import {LOGOUT_COMPLETE} from '../actions/loginActions';

const appReducer = combineReducers({
  availableLangs,
  Intl: translations,
  login,
  navigation,
  settings,
  network,
  apiData,
  pages,
  referral,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_COMPLETE) {
    // Save some pieces of state, drop all the rest
    state = {
      availableLangs: state.availableLangs,
      Intl: state.Intl,
      navigation: state.navigation
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
