import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Translated from './Translated';

export class VehicleInfo extends Component {
  static propTypes = {
    category: PropTypes.string.isRequired
  };

  render() {
    return (
      <div>
        <p>
          <Translated translationKey={'vehicle_info_common'} html={true}/>
        </p>
        <p>
          <Translated translationKey={`vehicle_info_${this.props.category}`} html={true}/>
        </p>
      </div>
    )
  }
}

export default VehicleInfo;
