import React, {Component} from 'react';
import {connect} from 'react-redux';
import TextInput from '../feature/TextInput';
import Translated from '../feature/Translated';
import api from '../../api';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from '../feature/Spinner';
import ProductInputEmailCredential from '../feature/shop/ProductInputEmailCredential';
import withTranslate from '../feature/withTranslate';

const TEACHER_COMP = {
  id: 'teacher',
  title: 'teacher_credentials'
};

const STUDENT_COMP = {
  id: 'student',
  title: 'student_email_credentials'
};

class CodeRedeemPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.addComponentInput = this.addComponentInput.bind(this);

    this.state = {
      isSending: false,
      code: '',
      inputs: {},
      showErrors: false,
      redeemSuccess: false
    }
  }

  validateCode() {
    this.setState({isSending: true});
    api.validateCode(this.state.code)
      .then(resp => {
        if (resp.error) {
          return this.setState({
            error: resp.error,
            isSending: false
          })
        } else {
          return this.setState({
            deal: resp,
            isSending: false,
            error: null
          })
        }
      })
  }

  hasValidInputs() {
    switch (this.state.deal.actionId) {
      case 1:
        return !!(this.state.inputs[TEACHER_COMP.id] && this.state.inputs[STUDENT_COMP.id]);
      case 2:
        return !!(this.state.inputs[STUDENT_COMP.id]);
      default:
        return false;
    }
  }

  redeemCode() {
    this.setState({showErrors: true});

    if(!this.hasValidInputs()) {
      return;
    }

    this.setState({isSending: true});
    api.redeemCode(this.state.deal.code, this.state.inputs)
      .then(resp => {
        if (resp.error) {
          return this.setState({
            error: resp.error,
            errorEmail: resp.data && resp.data.email,
            isSending: false
          })
        } else {
          return this.setState({
            isSending: false,
            error: null,
            redeemSuccess: true
          })
        }
      })
  }

  addComponentInput(id, input) {
    const inputs = Object.assign({}, this.state.inputs);
    inputs[id] = input;
    this.setState({inputs});
  }

  renderDeal(deal) {
    switch (deal.actionId) {
      case 1:
        return this.renderTeacherAndStudent(deal);
      case 2:
        return this.renderStudent(deal);
      default:
        return <Alert><Translated translationKey="unknown-error"/></Alert>;
    }
  }

  renderTeacherAndStudent(deal) {
    let errorEmail = this.state.errorEmail && <div>{this.state.errorEmail}</div>;
    let error = this.state.error && <Alert>{errorEmail}<Translated translationKey={this.state.error}/></Alert>;
    let spinner = this.state.isSending && <Spinner/>;

    return (
      <div>
        <div>{deal.code}</div>
        <div className="blue-inner-box tight">
          {deal.info}
        </div>

        <div className="blue-inner-box tight">
          <ProductInputEmailCredential component={TEACHER_COMP} addComponentInput={this.addComponentInput}
                                       showErrors={this.state.showErrors}/>
        </div>
        <div className="blue-inner-box tight">
          <ProductInputEmailCredential component={STUDENT_COMP} addComponentInput={this.addComponentInput}
                                       showErrors={this.state.showErrors}/>
        </div>
        {error}
        {spinner}
        <Button onClick={() => this.redeemCode()}><Translated
          translationKey="send"/></Button>
      </div>
    )
  }

  renderStudent(deal) {
    let errorEmail = this.state.errorEmail && <div>{this.state.errorEmail}</div>;
    let error = this.state.error && <Alert>{errorEmail}<Translated translationKey={this.state.error}/></Alert>;
    let spinner = this.state.isSending && <Spinner/>;

    return (
      <div>
        <div>{deal.code}</div>
        <div className="blue-inner-box tight">
          {deal.info}
        </div>

        <div className="blue-inner-box tight">
          <ProductInputEmailCredential component={STUDENT_COMP} addComponentInput={this.addComponentInput}
                                       showErrors={this.state.showErrors}/>
        </div>
        {error}
        {spinner}
        <Button onClick={() => this.redeemCode()}><Translated
          translationKey="send"/></Button>
      </div>
    )
  }


  renderCodeInput() {
    let error = this.state.error && <Alert><Translated translationKey={this.state.error}/></Alert>;
    let spinner = this.state.isSending && <Spinner/>;
    let buttonDisabled = this.state.isSending || this.state.code.length === 0;
    let inputLabelText = this.props.translate('deal_code_input');
    return (
      <div className="blue-inner-box tight">
        <TextInput listener={code => {this.setState({code})}}
                   label={inputLabelText}
                   placeholder={''}
                   value={this.state.code}
                   className="long-input-field uppercase"/>
        {error}
        {spinner}
        <Button onClick={() => this.validateCode()} disabled={buttonDisabled}><Translated
          translationKey="send"/></Button>
      </div>
    )
  }

  renderSuccess() {
    return (
      <div>
        <Translated translationKey="deal_code_success"/>
      </div>
    )
  }

  render() {
    let content;
    if (!this.state.deal) {
      content = this.renderCodeInput();
    } else if (!this.state.redeemSuccess) {
      content = this.renderDeal(this.state.deal);
    } else {
      content = this.renderSuccess();
    }

    return (
      <div id="practise-images-page" className="page-container max-width-container">
        <h1><Translated translationKey="deal_code_title"/></h1>
        {content}
      </div>
    )
  }
}

export default withTranslate(CodeRedeemPage);
