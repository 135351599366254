import React, {Component} from 'react';
import {connect} from 'react-redux'
import Alert from 'react-bootstrap/Alert';

class PreloadPage extends Component {

  renderError(error) {
    if (error === 'old-app-version') {
      return (
        <div className="full-view-height-wrapper page-container preload-wrapper">
          <Alert>Vanha versio, päivitä sivu!<br />Old version, please refresh page!</Alert>
        </div>
      );
    } else {
      return (
        <div className="full-view-height-wrapper page-container preload-wrapper">
          <Alert>ERROR: {error}</Alert>
          <div><a href={this.getLinkToHost()}>Ajokaista &gt;&gt;</a></div>
        </div>
      );
    }
  }

  renderInfo(info) {
    return (
      <div className="full-view-height-wrapper page-container preload-wrapper">
        <Alert>{info}</Alert>
        <div><a href={this.getLinkToHost()}>Ajokaista &gt;&gt;</a></div>
      </div>
    );
  }

  getLinkToHost() {
    return window.location.protocol+'//'+window.location.host;
  }

  render() {
    if (this.props.error) {
      return this.renderError(this.props.error);
    }

    if (this.props.loginError) {
      return this.renderError(this.props.loginError);
    }

    if (this.props.info) {
      return this.renderInfo(this.props.info);
    }

    return (
      <div className="full-view-height-wrapper page-container preload-wrapper">
        <div className="preload-spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </div>)
  }

}

const mapStateToProps = (state) => {
  return {
    loginError: state.login.get('error')
  }
};

export default connect(mapStateToProps)(PreloadPage);

