import React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics';
import {useNavigate, useLocation, useParams} from "react-router-dom";

const getComponentDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
};

export default (WrappedComponent) => {
  const WithRouter = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    return (<WrappedComponent {...props} navigate={navigate} location={location} match={{params}}/>)
  };
  WithRouter.displayName = `withRouter(${getComponentDisplayName(WrappedComponent)})`;

  return hoistNonReactStatics(WithRouter, WrappedComponent)
}