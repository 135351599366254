import React, {Component} from 'react';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import Translated from './Translated';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';
import DateUtil from '../../utils/date';
import {loadData, dataTypes} from '../../actions/apiDataActions'
import api from '../../api';

class News extends Component {

  componentDidMount() {
    this.props.dispatch(loadData(dataTypes.NEWS, api.getNews, this.props.locale));
  }

  renderLoader() {
    return (
      <div>
        <Spinner className="primary"/>
      </div>
    );
  }

  renderNewsItem(item) {
    return(
      <div key={item.id} className="news-item-wrapper top-margin">
        <div className="news-date">
          {DateUtil.date(item.date)}
        </div>
        <div className="news-content">
          {item.text}
        </div>
      </div>
    );
  }

  render() {
    if (this.props.news.isFetching) {
      return this.renderLoader();
    }

    const news = this.props.news.data;
    if (!news || news.length === 0) {
      return null;
    }

    const newsItems = news.map(item => {
      return this.renderNewsItem(item);
    });

    let classes = classNames('semi-dark-inner-box', this.props.className);

    if (this.props.simple) {
      return (
        <div className={classes}>
          <h3 className="news-header"><Translated translationKey="news"/></h3>
          {newsItems}
        </div>
      );

    } else {
      return (
        <Card className={classes}>
          <h3 className="news-header"><Translated translationKey="news"/></h3>
          {newsItems}
        </Card>
      );
    }
  }
}

let mapStateToProps = (state) => {
  return ({
    locale: state.Intl.locale,
    news: state.apiData[dataTypes.NEWS],
  });
};

export default connect(mapStateToProps)(News);
