import React, {useContext} from 'react';
import {connect} from 'react-redux';
import {fetchLocalizations, setClientSideLocalizations} from '../../actions/localizationActions'
import PreloadPage from '../pages/PreloadPage';
import queryString from 'query-string';

export const TranslationContext = React.createContext(null);

export const useTranslate = () => useContext(TranslationContext).translate;

export class TranslationProvider extends React.Component {

  constructor(props) {
    super(props);
    if (!props.translations || !props.locale) {
      let namePart = this.constructor.displayName ? ' of ' + this.constructor.displayName : '';
      throw new Error('Could not find translations or locale on this.props ' + namePart)
    }
    this.translate = this.translate.bind(this);
    this.state = {
      loadError: null,
      context: {
        translate: this.translate
      }
    };
    if (this.props.injectedLocalizations) {
      setClientSideLocalizations(this.props.injectedLocalizations);
    }
  }

  componentDidMount() {
    this.checkLocalizations(this.props.locale, this.props.translations);
  }

  componentDidUpdate() {
    this.checkLocalizations(this.props.locale, this.props.translations);
  }

  checkLocalizations(locale, translations) {
    if (!translations[locale]) {
      const promise = this.props.dispatch(fetchLocalizations(locale));
      if (promise) {
        promise.catch(error => {
          this.setState({
            loadError: error
          })
        });
      }
    }
  }

  /**
   * Translate key if it is wrapped into {}, otherwise return the key
   * @param possibleKey
   * @param placeholders
   */
  translateIfKey(possibleKey, placeholders) {
    if (possibleKey && possibleKey.indexOf('{') === 0 && possibleKey.indexOf('}') === (possibleKey.length - 1)) {
      const key = possibleKey.substr(1, possibleKey.length - 2);
      return this.translate(key, placeholders);
    }
    return possibleKey;
  }

  /**
   * Translate the key to current locale and replace placeholders
   * @param key
   * @param placeholders optional, Object with key value pairs.
   * @param options optional {onlyIfKey: true}
   * @returns {*}
   */
  translate(key, placeholders, options) {
    if (options) {
      if (options.onlyIfKey) {
        return this.translateIfKey(key, placeholders);
      }
    }

    const translations = this.props.translations[this.props.locale];
    let result = key;

    if (translations && translations['messages'] && translations['messages'][key]) {
      result = translations['messages'][key];
    }

    if (!placeholders) {
      return result
    }

    // New placeholders with {}
    for (let p in placeholders) {
      if (placeholders.hasOwnProperty(p)) {
        result = result.replace(new RegExp('{' + p + '}', 'g'), placeholders[p]);
      }
    }

    // Legacy placeholders with %%
    for (let p in placeholders) {
      if (placeholders.hasOwnProperty(p)) {
        result = result.replace(new RegExp('%' + p + '%', 'g'), placeholders[p]);
      }
    }

    return result
  };

  render() {
    if (this.state.loadError === 'no-database') {
      return <PreloadPage info="Huoltopäivitys, odota hetki. Service is being updated, please wait."/>
    } else if (this.state.loadError) {
      return <PreloadPage info={this.state.loadError}/>
    }
    if (!this.props.translations[this.props.locale]) {
      return <PreloadPage/>;
    }
    return (
      <TranslationContext.Provider value={this.state.context}>
        {this.props.children || null}
      </TranslationContext.Provider>
    )
  }
}

export function setLocale(locale) {
  const params = queryString.parse(window.location.search);
  params.lang = locale;
  window.location.search = queryString.stringify(params);
}

export function mapStateToProps(state) {
  const {Intl} = state;
  return {
    ...Intl,
    key: Intl.locale
  }
}

export default connect(mapStateToProps)(TranslationProvider)
