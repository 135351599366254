import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import PreloadMiddlePage from './../pages/PreloadMiddlePage';
import Line from '../feature/ProgressBarLine';
import Translated from '../feature/Translated';
import LiteralsStart from '../feature/practise/literals/LiteralsStart';
import LiteralsQuestion from '../feature/practise/literals/LiteralsQuestion';
import PractiseResults from '../feature/practise/PractiseResults';
import {loadData, dataTypes} from '../../actions/apiDataActions'
import api from '../../api';
import ResultFeedbackForm from '../feature/results/ResultFeedbackForm';
import MapUtil from '../../utils/map';
import BookModalLink from '../feature/BookModalLink';
import {IoChatbubblesSharp} from 'react-icons/io5';
import withPracticeTypes from '../feature/withPracticeTypes';

class PracticeLiteralsPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.setAnswer = this.setAnswer.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.start = this.start.bind(this);
    this.state = {
      started: false,
      questionIndex: 0,
      answers: [],
      seenIds: [],
      showFeedback: false
    };
  }

  setAnswer(optionId) {
    const answers = this.state.answers.slice();
    answers.push(optionId);

    const seenIds = this.state.seenIds.slice();
    const question = this.props.questions.data.questions[this.state.questionIndex];
    // Mark as seen if answered correctly, otherwise it may be asked even on the very next round again.
    if (question.correctAnswer === optionId) {
      if (this.state.seenIds.indexOf(question.id) === -1) {
        seenIds.push(question.id);
        if (seenIds.length > 30) {
          seenIds.shift();
        }
      }
    }

    this.setState({answers, seenIds});
  }

  nextQuestion() {
    const nextIndex = this.state.questionIndex + 1;
    this.setState({
      questionIndex: nextIndex,
      showFeedback: false
    });

    if (nextIndex === this.props.questions.data.questions.length) {
      let faults = 0;
      this.props.questions.data.questions.forEach((question, index) => {
        let answer = this.state.answers[index];
        if (answer !== question.correctAnswer) {
          faults++;
        }
      });
      this.setState({faults});
      api.sendPracticeLog(this.props.settings.get('vehicleCategory'), 'literals', this.props.questions.data.questions.length, faults);
    }
  }

  fetchQuestions() {
    const category = this.props.settings.get('vehicleCategory');
    const locale = this.props.locale;
    this.props.dispatch(loadData(dataTypes.PRACTICE_LITERALS, api.createPracticeLiterals, category, locale, this.state.seenIds));
  }

  start() {
    BookModalLink.setAllowPopover(true);
    this.fetchQuestions();
    this.setState({
      started: true,
      questionIndex: 0,
      answers: []
    });
  }

  getBookReferencesMap() {
    const bookReferences = this.props.questions.data.bookReferences;
    return MapUtil.mapFromObject(bookReferences);
  }

  renderBookContent(question, answer) {
    const bookReferencesMap = this.getBookReferencesMap();

    // Do not add book reference link if there is none, or user does not have them linked (no rights)
    if (question.bookReferenceIds.length === 0 || !bookReferencesMap || bookReferencesMap.size === 0) {
      return null;
    }
    const animate = question.correctAnswer !== answer;
    const bookLinks = question.bookReferenceIds.map(refId => {
      const content = bookReferencesMap.get(refId);
      return <BookModalLink content={content} animate={animate} key={refId}/>;
    });

    return (
      <div className="book-links fade-in">
        {bookLinks}
      </div>
    );
  }

  renderAnswerContent(question, answer) {
    let feedbackForm;
    if (this.state.showFeedback) {
      feedbackForm = <ResultFeedbackForm keepOpen questionType="literal" questionId={question.id} className="fade-in"/>
    }

    return (
      <div>
        {this.renderBookContent(question, answer)}
        <div className="min-button-space literals-buttons-container">
          <Button bsstyle="link" className="fade-in long-delay" onClick={() => this.setState({showFeedback: !this.state.showFeedback})}>
            <IoChatbubblesSharp className="larger text-bottom"/><Translated translationKey="question_report"/>
          </Button>
          <Button id="practise-literals-next-button" onClick={this.nextQuestion} className="fade-in delay">
            <Translated translationKey="next"/>
          </Button>
        </div>
        {feedbackForm}
      </div>
    );
  }

  renderQuestion() {
    const i = this.state.questionIndex;
    const questions = this.props.questions.data.questions;
    const question = questions[i];
    const answer = i < this.state.answers.length ? this.state.answers[i] : null;
    const current = i + 1;
    const total = questions.length;
    const progressRatio = current / total;

    return (
      <div id="practise-literals-question-container" className="practise-wrapper">
        <div className="practise-progress-wrapper fade-in long-delay">
          <Line
            className="yellow"
            percentage={progressRatio * 100}
          />
        </div>
        <LiteralsQuestion question={question}
                          index={this.state.questionIndex}
                          setAnswer={this.setAnswer}
                          answer={answer}
                          key={question.id}
        />

        {answer
          ? this.renderAnswerContent(question, answer)
          : <div className="min-button-space"></div>
        }
      </div>
    )
  }

  renderResults() {
    const total = this.props.questions.data.questions.length;
    const correctCount = total - this.state.faults;
    return <PractiseResults correct={correctCount}
                            total={total}
                            restart={this.start}
                            buttonId="literals-results-restart"/>
  }

  render() {

    let content;

    if (!this.props.settings.get('isReceived')) {
      content = <PreloadMiddlePage/>
    } else if (!this.state.started) {
      content = <LiteralsStart start={this.start}/>
    } else if (this.props.questions.error) {
      content = <PreloadMiddlePage error={this.props.questions.error}/>
    } else if (this.props.questions.isFetching || !this.props.questions.data.questions) {
      content = <PreloadMiddlePage/>
    } else if (this.state.questionIndex < this.props.questions.data.questions.length) {
      content = this.renderQuestion();
    } else {
      content = this.renderResults();
    }

    return (
      <div id="practise-literals-page" className="page-container page-gutter max-width-container">
        {content}
      </div>
    )
  }

}

let mapStateToProps = (state) => {
  return ({
    settings: state.settings,
    questions: state.apiData[dataTypes.PRACTICE_LITERALS],
    locale: state.Intl.locale,
  });
};

export default connect(mapStateToProps)(withPracticeTypes(PracticeLiteralsPage));
