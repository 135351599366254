import React from 'react';
class ImageSet extends React.Component {

  constructor(props) {
    super(props);
    this.req = require.context('../../assets/', true);
  }

  resolveMultipliedName(multiplier, source) {
    let nameArr = source.split('.');
    let name = nameArr[nameArr.length -2];
    name = name + '@' + multiplier + 'x';
    nameArr[nameArr.length-2] = name;
    return nameArr.join('.');
  };

  filterPath(source) {
    return source.replace('../../assets/', '');
  }

  render() {

    let src = this.filterPath(this.props.src);
    let src2x = this.resolveMultipliedName(2, src);
    let imageProps = Object.assign({}, this.props);
    imageProps['src'] = this.req('./'+src);
    imageProps['srcSet'] = this.req('./'+src2x) + ' 2x';

    return (
      <img
        {...imageProps}
      />);
  }
}

export default (ImageSet)
