import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import Translated from '../Translated';
import {IoThumbsUpSharp} from 'react-icons/io5';
import withTranslate from '../withTranslate';
import ImagesComponent from '../shop/ImagesComponent';
//import FeedbackPoll from '../FeedbackPoll';

class DemoPractiseResults extends Component {

  render() {
    const total = this.props.total;
    const correctCount = this.props.correct;
    const resultType = correctCount === total ? 'positive' : 'neutral';
    const feedbackKey = correctCount === total ? 'demo_success' : 'demo_fail';
    const decorator = resultType === 'positive' ?
      <IoThumbsUpSharp className="practise-result-decorator fade-in long-delay" /> : null;

    const adText = this.props.translate('demo_ad');

    return (
      <div className="centered fade-in long-delay">
        <h3><Translated translationKey="correct_from_total" values={{'correct': correctCount, 'total': total}}/></h3>
        {decorator}
        <h4 className="top-margin"><Translated translationKey={feedbackKey}/></h4>
        <div className="blue-inner-box tight large-top-margin">
          <span dangerouslySetInnerHTML={{__html: adText}}/>
        </div>
        <Button bsstyle="warning" id={this.props.buttonId}
                onClick={() => this.props.toShop()}
                className="top-margin">
          <Translated translationKey="back_to_shop"/>
        </Button>
        <div className="medium-top-margin">
          <ImagesComponent config={{setId: this.getImageSetId()}}/>
        </div>
        {/*<FeedbackPoll faults={total-correctCount}/>*/}
      </div>
    )
  }

  getImageSetId() {
    switch (this.props.category) {
      case 't':
        return 't_class';
      case 'm':
        return 'm_class';
      default:
        return 'generic';
    }
  }
}

export default withTranslate(DemoPractiseResults);

