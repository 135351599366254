import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import Spinner from './Spinner';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import withTranslate from './withTranslate';
import Translated from './Translated';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import api from '../../api';
import {loadData, dataTypes, saveData} from '../../actions/apiDataActions';

class PractiseLimits extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      categoriesWithChanges: []
    };
  }

  componentDidMount() {
    const schoolId = this.props.schoolId || this.props.token.get('schoolId');
    this.props.dispatch(loadData(dataTypes.SCHOOL_CATEGORY_SETTINGS, api.getSchoolCategorySettings, schoolId));
  }

  updateLimit(category, property, value) {
    const schoolId = this.props.settings.data.schoolId;
    const currentSettings = this.props.settings.data.settings[category];
    const newSettings = Object.assign({}, currentSettings, {[property]: value});

    this.setState({
      isSending: true,
      categoriesWithChanges: this.state.categoriesWithChanges.concat([category])
    });

    this.props.dispatch(saveData(dataTypes.SCHOOL_CATEGORY_SETTINGS, api.setSchoolCategorySettings, schoolId, category, newSettings))
      .then(() => {
        this.setState({
          isSending: false,
          categoriesWithChanges: []
        })
      });
  }

  renderLoadingContent() {
    return (<div><Spinner/></div>);
  }

  renderErrorContent() {
    return (<div><Alert><Translated translationKey={this.props.settings.error}/></Alert></div>);
  }

  buildDropDown(id, min, max, value, labelKey, isCloseToBottom, onChange) {
    const label = this.props.translate(labelKey);

    let selections = [];
    for (let i = min; i <= max; i++) {
      selections.push(i);
    }

    const dropDir = isCloseToBottom ? 'up' : 'down';

    return (
      <ButtonToolbar className="settings-category-limit">
        <DropdownButton
          id={id}
          bsstyle="warning"
          drop={dropDir}
          title={label + ' ' + value}>
          {selections.map((selectableValue => {
            return <Dropdown.Item
              id={selectableValue}
              key={selectableValue}
              onClick={() => {
                onChange(selectableValue)
              }}>
              <span>{selectableValue === value ?
                <span className="settings-selected-dropdown-value ">{selectableValue}</span> : selectableValue}</span>
            </Dropdown.Item>
          }))}
        </DropdownButton>
      </ButtonToolbar>
    );
  }

  buildPanelContent(categoryData, isCloseToBottom) {
    const category = categoryData.id;
    const limits = this.props.settings.data.limits;
    const litMin = limits.minSettableLiteralFaults;
    const litMax = limits.maxSettableLiteralFaults;
    const imgMin = limits.minSettableImageFaults;
    const imgMax = limits.maxSettableImageFaults;
    const riskMin = limits.minSettableRiskFaults;
    const riskMax = limits.maxSettableRiskFaults;
    const minReq = limits.minSettableRequired;
    const maxReq = limits.maxSettableRequired;

    const settings = this.props.settings.data.settings[category];
    const litCurr = settings.literalFaultsMax;
    const imgCurr = settings.imageFaultsMax;
    const riskCurr = settings.riskFaultsMax;
    const reqCurr = settings.requiredPasses;

    const savingThisCategory = this.state.categoriesWithChanges.indexOf(category) !== -1 && this.state.isSending;

    return (
      <fieldset disabled={this.state.isSending}>
        <div id={'practise-limits-category-' + category} className="settings-category-container">
          <div className="settings-category-section">
            {categoryData.literals && this.buildDropDown(category + 'lit', litMin, litMax, litCurr, 'literals', isCloseToBottom, (value) => {
                this.updateLimit(category, 'literalFaultsMax', value)
              })}
            {categoryData.images && this.buildDropDown(category + 'img', imgMin, imgMax, imgCurr, 'images', isCloseToBottom, (value) => {
                this.updateLimit(category, 'imageFaultsMax', value)
              })}
            {categoryData.risks && this.buildDropDown(category + 'risk', riskMin, riskMax, riskCurr, 'risks', isCloseToBottom, (value) => {
              this.updateLimit(category, 'riskFaultsMax', value)
            })}
          </div>
          <div className="settings-category-section">
            {this.buildDropDown(category + 'req', minReq, maxReq, reqCurr, 'required', isCloseToBottom, (value) => {
              this.updateLimit(category, 'requiredPasses', value)
            })}
          </div>
          <div className="settings-category-section">
            {savingThisCategory && <Spinner/>}
          </div>
        </div>
      </fieldset>
    );
  }

  buildPanelHeader(category) {
    const imageSrc = require('../../assets/category/' + category.id + '.svg');
    const categoryKey = 'category_' + category.id.toLowerCase();
    return (
      <div className="gray">
        <span className="settings-panel-header-icon-wrapper"><img className="settings-panel-header-icon touchable"
                                                                  src={imageSrc}/></span>
        <Translated className="touchable" translationKey={categoryKey}/>
      </div>
    );
  }

  renderCategory(category, index, isCloseToBottom) {
    return (
      <Card key={index} eventkey={index} className="bottom-margin">
        <Card.Header>
          {this.buildPanelHeader(category)}
        </Card.Header>
        <Card.Body>
          {this.buildPanelContent(category, isCloseToBottom)}
        </Card.Body>
      </Card>
    );
  }

  renderCategories() {
    return (
      <div className="fade-in">
        {this.props.settings.data.openCategories.map((category, index, arr) => {
          let isCloseToBottom = (index > arr.length - 3);
          return this.renderCategory(category, index, isCloseToBottom);
        })}
      </div>
    );
  }

  render() {
    let content;
    const settings = this.props.settings;
    if (settings.isFetching) {
      content = this.renderLoadingContent();
    } else if (this.props.settings.error) {
      content = this.renderErrorContent();
    } else if (settings.data) {
      content = this.renderCategories();
    } else {
      content = null;
    }

    return (
      <div id="practise-limits" className={this.props.className}>
        <h3><Translated translationKey="practise_settings"/></h3>
        <p><Translated translationKey="practise_settings_info"/></p>
        {content}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return ({
    token: state.login.get('token'),
    settings: state.apiData[dataTypes.SCHOOL_CATEGORY_SETTINGS],
  });
};

const composed = compose(
  connect(mapStateToProps),
  withTranslate
);

export default composed(PractiseLimits);
