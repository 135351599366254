import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import {IoCheckmarkSharp, IoAlertOutline} from "react-icons/io5";
import Alert from 'react-bootstrap/Alert';
import Translated from './../feature/Translated';
import classNames from 'classnames';
import withTranslate from '../feature/withTranslate';
import api from '../../api';
import EmailConnect from './EmailConnect';

class EmailSettings extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      showModal: false,
      inputEmail: '',
      isSending: false,
      responseData: null,
      error: null,
      errorData: null
    }
  }

  resetPassword() {
    const email = this.props.token.get('email');
    const lang = this.props.locale;

    this.setState({
      error: null,
      errorData: null,
      isSending: true,
      responseData: null,
    });

    api.userEmailRequestEmailRecovery(email, lang)
      .then(response => {
        if (response.error) {
          return this.setState({
            error: response.error,
            errorData: response.data,
            isSending: false,
            responseData: null,
          });
        } else {
          return this.setState({
            error: null,
            errorData: null,
            isSending: false,
            responseData: response.isSent,
          });
        }
      })
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }
    return (
      <Alert>
        <div>
          <IoAlertOutline />
          <Translated translationKey={this.state.error}/>
        </div>
      </Alert>
    );
  }

  renderReceipt() {
    if (!this.state.responseData) {
      return null;
    }
    return (
      <Alert bsstyle="success" className="top-margin">
        <Translated translationKey="password_reset_is_sent"/>
      </Alert>
    );
  }

  render() {
    let content;
    const email = this.props.token.get('email');
    if (email) {
      content = (
        <div className="white-box">
          <div>
            <Translated translationKey="email_is_connected"/>
            <IoCheckmarkSharp className="left-margin"/>
          </div>
          <div className="top-margin">
            {email}
            <Button onClick={() => this.resetPassword()} className="left-margin">
              <Translated translationKey="email_reset_password"/>
            </Button>
          </div>
          {this.renderError()}
          {this.renderReceipt()}
        </div>
      )
    } else {
      content = <EmailConnect/>;
    }

    return (
      <div className={classNames('email-settings-wrapper', this.props.className)}>
        <h4><Translated translationKey={'email_settings_title'}/></h4>
        {content}
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return ({
    token: state.login.get('token'),
    locale: state.Intl.locale
  });
};


export default connect(mapStateToProps)(withTranslate(EmailSettings));