import React, {Component} from 'react';
import ImageSet from '../ImageSet';
import Translated from '../Translated';
import classNames from 'classnames';
import api from '../../../api';
import Spinner from '../Spinner';
import Alert from 'react-bootstrap/Alert';
import {IoAlertOutline} from 'react-icons/io5';

class ShopCategorySelector extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: false,
      error: null,
      categories: null
    }
  }

  componentDidMount() {
    this.setState({isLoading: true});
    api.getSubcategories('teaching_permit')
      .then(resp => {
        if (resp.error) {
          this.setState({
            isLoading: false,
            error: resp.error
          })
        } else {
          this.setState({
            isLoading:false,
            categories: resp.subCategories
          })
        }
      });
  }


  render() {
    if (this.state.isLoading) {
      return <Spinner/>
    } else if (this.state.error) {
      return <Alert><IoAlertOutline /> <Translated translationKey={this.state.error}/></Alert>
    } else if (!this.state.categories) {
      return null;
    }

    const elements = this.state.categories.map(category => {
      const imageAltText = 'License category icon ' + category;
      const imageSrc = '../../assets/category/' + category.toLowerCase() + '_white.png';
      return (
        <div className="shop-category-selector-item touchable" onClick={() => this.props.onSelect(category)} key={category}>
          <div className="heavy-bump-once">
            <ImageSet src={imageSrc} alt={imageAltText}/>
            <div className="title"><Translated translationKey={'category_' + category}/></div>
          </div>
        </div>
      );
    });
    const useNarrow = this.state.categories.length % 2 === 0;
    const classes = classNames('shop-category-selector-items fade-in', {'narrow': useNarrow});
    return (
      <div className={classes}>
        {elements}
      </div>
    )
  }
}

export default ShopCategorySelector;

