import React from 'react';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import PropTypes from 'prop-types';
import ProgressProvider from "./ProgressProvider";

const ProgressBarCircle = (props) => {

  const initialAnimation = props.initialAnimate !== undefined ? props.initialAnimate : true;

  return (
      <ProgressProvider valueStart={initialAnimation ? 0 : props.percentage} valueEnd={props.percentage}>
        {(value) => {
          return (
              <CircularProgressbar
                  value={value}
                  className={props.className}
                  strokeWidth={6}
                  text={props.progressText}
                  styles={buildStyles({
                    // Rotation of path and trail, in number of turns (0-1)
                    rotation: 0,

                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: 'butt',

                    // Text size
                    textSize: '1.5rem',

                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 1.5,

                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',

                    // Colors
                    pathColor: '#3797f1',
                    textColor: '#4f4f4f',
                    trailColor: '#e3e3e3'
                  })}
              />
          );
        }}
      </ProgressProvider>
  );
}

ProgressBarCircle.propTypes = {
  percentage: PropTypes.number,
  progressText: PropTypes.string,
  initialAnimate: PropTypes.bool,
};

export default ProgressBarCircle

