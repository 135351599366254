import React, {Component} from 'react';
import {connect} from 'react-redux';
import {connectFacebook, resetFacebookConnection, setFbAccessToken, logout} from '../../actions/loginActions'
import FacebookLoginButton from '../feature/FacebookLoginButton';
import Spinner from './Spinner';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import {IoAlertCircle} from 'react-icons/io5';
import Button from 'react-bootstrap/Button';
import Translated from './../feature/Translated';
import withTranslate from '../feature/withTranslate';
import Tracker from '../../utils/tracker';
import withRouter from './withRouter';
import queryString from 'query-string';

class FacebookConnect extends Component {

  constructor(props, context) {
    super(props, context);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.translate = this.props.translate;
    this.state = {
      showFbLogoutInfo: false
    }
  }

  componentDidMount() {
    const params = queryString.parse(window.location.hash);
    let token = params['access_token'];
    if (token) {
      this.props.dispatch(setFbAccessToken(token));
      this.connectFacebookWithToken(token, false);
    }
  }

  responseFacebook(response) {
    const token = response.accessToken;
    if (!token) {
      return;
    }
    this.props.dispatch(setFbAccessToken(token));
    this.connectFacebookWithToken(token, false);
  };

  connectFacebookWithToken(fbAccessToken, forceTransfer) {
    this.props.dispatch(connectFacebook(fbAccessToken, forceTransfer));
  }

  switchToLogoutInfo() {
    Tracker.logEvent('facebook', 'connect-logout-suggestion');
    this.setState({
      showFbLogoutInfo: true
    });
  }

  resetConnection() {
    this.props.dispatch(resetFacebookConnection());
    this.setState({
      showFbLogoutInfo: false
    });
  }

  logout() {
    this.props.dispatch(logout(this.props.navigate));
  }

  renderClashAlert() {
    const facebookName = this.props.fbConnectErrorInfo ? this.props.fbConnectErrorInfo.get('existingFbName') : '';
    const facebookPicture = this.props.fbConnectErrorInfo ? this.props.fbConnectErrorInfo.get('existingFbPicture') : null;
    return (
      <Modal show={true} className="centered">
        <Modal.Header>
          <Modal.Title className="red"><IoAlertCircle/> <Translated translationKey="alert"/></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {facebookPicture != null
            ? <img src={facebookPicture}/>
            : null
          }
          {facebookName != ''
            ? <h4>{facebookName}</h4>
            : null
          }
          <Translated translationKey="facebook-connected-to-other-user"
                      values={{'facebookName': facebookName, 'userName': this.props.userName}}/>
        </Modal.Body>

        <Modal.Footer className="centered">
          <Button bsstyle='primary' onClick={() => this.connectFacebookWithToken(this.props.fbAccessToken, true)}>
            <Translated translationKey="yes_force_transfer"/>
          </Button>
          <Button onClick={() => this.switchToLogoutInfo()}>
            <Translated translationKey="no_change_user"/>
          </Button>
        </Modal.Footer>

      </Modal>
    )
  }

  renderLogoutInfo() {
    const facebookName = this.props.fbConnectErrorInfo ? this.props.fbConnectErrorInfo.get('existingFbName') : '';
    return (
      <Modal show={true} className="centered">
        <Modal.Header>
          <Modal.Title className="red"><IoAlertCircle/> <Translated translationKey="alert"/></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Translated translationKey="facebook_logout_other_info" values={{'facebookName': facebookName}}/>
        </Modal.Body>

        <Modal.Footer className="centered">
          <Button onClick={() => this.resetConnection()}>
            <Translated translationKey="ok"/>
          </Button>
        </Modal.Footer>

      </Modal>
    )
  }

  renderAccountClashError() {
    if (this.state.showFbLogoutInfo) {
      return this.renderLogoutInfo();
    } else {
      return this.renderClashAlert();
    }
  }

  renderAlreadyConnectedError() {
    const facebookName = this.props.fbConnectErrorInfo ? this.props.fbConnectErrorInfo.get('existingFbName') : '';
    const facebookPicture = this.props.fbConnectErrorInfo ? this.props.fbConnectErrorInfo.get('existingFbPicture') : null;

    return (
      <Modal show={true} className="centered">
        <Modal.Header>
          <Modal.Title className="red"><IoAlertCircle/> <Translated translationKey="alert"/></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {facebookPicture != null
            ? <img src={facebookPicture}/>
            : null
          }
          {facebookName != ''
            ? <h4>{facebookName}</h4>
            : null
          }
          <Translated translationKey="facebook-already-connected" values={{'facebookName': facebookName}}/>
        </Modal.Body>

        <Modal.Footer className="centered">
          <Button onClick={() => this.logout()}>
            <Translated translationKey="ok"/>
          </Button>
        </Modal.Footer>

      </Modal>
    )
  }

  render() {
    let error = null;
    if (this.props.fbConnectError) {
      if (this.props.fbConnectError === 'facebook-connected-to-other-user') {
        return (this.renderAccountClashError());
      }
      else if (this.props.fbConnectError === 'facebook-already-connected') {
        return this.renderAlreadyConnectedError();
      }
      else {
        error = (<div><Translated translationKey={this.props.fbConnectError}/></div>);
      }
    }

    const connectTxt = this.translate('connect_to_facebook');
    let fbConnect;
    const isFbConnected = this.props.token ? (this.props.token.get('facebook')) : false;

    if (!isFbConnected) {
      if (this.props.isFbConnecting) {
        fbConnect = <div className="facebook-connect-wrapper"><Spinner /></div>;
      } else {
        fbConnect =
          <div id="facebook-connect-container">
            <FacebookLoginButton callback={this.responseFacebook} label={connectTxt}/>
            {error
              ? <Alert>{error}</Alert>
              : (
                <div className="top-margin">
                  <Translated translationKey='connect_to_facebook_note'/>
                </div>
              )
            }
          </div>
      }
    } else {
      // Show disconnect?
      fbConnect = <Translated translationKey={'facebook_connected_already'}/>
    }

    return (fbConnect)
  }

}

let mapStateToProps = (state) => {
  return ({
    token: state.login.get('token'),
    userName: state.login.get('token') ? state.login.get('token').get('userName') : '',
    isFbConnecting: state.login.get('isFbConnecting'),
    fbConnectError: state.login.get('fbConnectError'),
    fbConnectErrorInfo: state.login.get('fbConnectErrorInfo'),
    fbAccessToken: state.login.get('fbAccessToken'),
    locale: state.Intl.locale
  });
};

export default withRouter(connect(mapStateToProps)(withTranslate(FacebookConnect)));