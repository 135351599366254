class LocalStorageUtil {

  static getForUser(userId, propName) {
    try {
      let catObj = JSON.parse(localStorage.getItem(propName));
      if (!catObj){
        return null;
      }
      const cat = catObj[userId];
      return cat ? cat : null;
    } catch (err) {
      LocalStorageUtil.setForUser(propName, userId, null);
      return null;
    }
  }

  static setForUser(userId, propName, value) {
    let catObj;
    try {
      catObj = JSON.parse(localStorage.getItem(propName));
    } catch (e) {
      catObj = {};
    }

    if (!catObj) {
      catObj = {};
    }

    try {
      catObj[userId] = value;
      localStorage.setItem(propName, JSON.stringify(catObj));
    }
    catch (error) {
      // Do nothing, probably in private browser mode...
    }
  }

}

export default LocalStorageUtil;

