import React, {Component} from 'react';
import {resolveOptionClasses} from '../PractiseUtils';
import classNames from 'classnames';

class LiteralsQuestion extends Component {

  constructor(props) {
    super(props);
    this.setAnswer = this.props.setAnswer;
    this.state = {
      question: props.question,
      isAnswerResolved: false,
      answer: null,
      options: [],
      index: props.index
    };
    this.ANSWER_DELAY_MS = 400;
  }

  componentDidUpdate(prevProps) {
    if (this.props.answer !== prevProps.answer) {
      this.setState({answer: this.props.answer});
    }
  }

  componentWillUnmount() {
    clearTimeout(this.answerDelay);
  }

  setAnswerDelayed(optionId) {
    if (this.state.answer) {
      return;
    }
    this.setState({
      answer: optionId
    });

    if (!this.state.answer) {
      this.answerDelay = setTimeout(() => {
        this.setState({
          isAnswerResolved: true
        });
        this.setAnswer(optionId);
      }, this.ANSWER_DELAY_MS);
    }
  }

  render() {
    const options = [];
    Object.keys(this.state.question.options).forEach((optionType) => {
        let className = resolveOptionClasses(
          'practise-text-option', optionType, this.state.answer,
          this.state.isAnswerResolved, this.state.question.correctAnswer);

        const key = this.state.question.id.toString() + optionType;
        options.push(
          <div id={'literals-question-' + optionType} key={key} onClick={() => this.setAnswerDelayed(optionType)}
               className={className}>
            {this.state.question.options[optionType]}
          </div>
        );
      }
    );

    const imageUrl = this.state.question.imageUrl;
    const image = imageUrl && (
      <div>
        <img src={imageUrl} className='practise-literal-image'/>
      </div>
    );

    const questionNum = this.state.index + 1;
    const classes = classNames(this.props.className);

    return (
      <div className={classes}>
        <div className="practise-text-question fade-in short-delay">
          {questionNum}. {this.state.question.question}
          {image}
        </div>
        <div className="fade-in delay">
          {options}
        </div>
      </div>
    )
  }
}

export default LiteralsQuestion;