import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import Translated from './Translated';
import classNames from 'classnames';
import {loadData, forceReloadData, dataTypes} from '../../actions/apiDataActions'
import api from '../../api';
import ImageSet from '../feature/ImageSet';
import FacebookLike from './FacebookLike';

export class ProfileAutoliitto extends Component {

  static propTypes = {
  };

  static defaultProps = {
  };

  componentDidMount() {
    if (this.props.token) {
      this.fetchProfile(this.props.token.get('userId'), false);
    }
  }

  fetchProfile(userId, reload) {
    const actionCreator = reload ? forceReloadData : loadData;
    this.props.dispatch(actionCreator(dataTypes.PROFILE, api.getProfile, userId, reload));
  }

  render() {

    const logoSource = '../../assets/AL-logo.png';

    if (this.props.profile.error) {
      return <div className="white padded"><Translated translationKey={this.props.profile.error}/></div>
    } else if (this.props.profile.isFetching || !this.props.profile.data || this.props.isFbConnecting) {
      return (<Spinner className="padded primary"/>);

    } else {
      const classes = classNames(this.props.className, 'user-profile-container', 'fade-in');

      return (
        <div id="profile-student-container" className={classes}>
          <div className="user-profile-data">
            <br />
            <ImageSet src={logoSource}/>
            <h5>&nbsp;<Translated translationKey="user_id"/>: {this.props.profile.data.userData.name}</h5>
            <FacebookLike />
          </div>
        </div>
      );
    }
  }
}

let mapStateToProps = (state) => {
  return ({
    token: state.login.get('token'),
    profile: state.apiData[dataTypes.PROFILE],
    isFbConnecting: state.login.get('isFbConnecting')
  });
};

export default connect(mapStateToProps)(ProfileAutoliitto);
