import {availableLangsData} from '../data/availableLangsData';

function availableLangs(state = availableLangsData, action) {
    switch (action.type) {
        default:
            return state;
    }
}

export default availableLangs;
