import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {IoAlertOutline, IoClose, IoMail} from 'react-icons/io5';
import Alert from 'react-bootstrap/Alert';
import Translated from './../feature/Translated';
import withTranslate from '../feature/withTranslate';
import TextInput from './../feature/TextInput';
import api from '../../api';
import dateFormat from '../../utils/date';

class EmailConnect extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      showModal: false,
      inputEmail: '',
      isSending: false,
      responseData: null,
      error: null,
      errorData: null
    }
  }

  connectEmail(forceConnect = false) {
    const email = this.state.inputEmail;
    const lang = this.props.locale;

    if (!email) {
      return this.setState({
        error: 'invalid_input',
        isSending: false,
        responseData: null,
      });
    }

    this.setState({
      error: null,
      errorData: null,
      isSending: true,
      responseData: null,
    });

    api.connectEmail(email, lang, forceConnect)
      .then(response => {
        if (response.error) {
          return this.setState({
            error: response.error,
            errorData: response.data,
            isSending: false,
            responseData: null,
          });
        } else {
          return this.setState({
            error: null,
            errorData: null,
            isSending: false,
            responseData: response.data,
          });
        }
      });
  }

  updateEmail(email) {
    this.setState({
      inputEmail: email,
      error: null,
      errorData: null,
      isSending: false,
      responseData: null
    });
  }

  closeModal() {
    this.updateEmail('');
    this.setState({showModal: false});
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }
    let errorElem;
    switch (this.state.error) {
      case 'email-already-connected':
        errorElem = (
          <div>
            <Translated translationKey={this.state.error}/>
            <br/>
            <Translated translationKey="school_name"/>: {this.state.errorData.schoolName}
            <br/>
            <Translated translationKey="creation_date"/>: {dateFormat.date(this.state.errorData.createdDate)}
            <br/>
            <div className="large-top-margin centered">
              <div className="bottom-margin">
                <strong><Translated translationKey="email_force_connect"/></strong>
              </div>
              <div className="top-margin">
                <Button bsstyle='primary' onClick={() => this.setState({showModal: false})}>
                  <Translated translationKey="no"/>
                </Button>
                <Button onClick={() => this.connectEmail(true)} className="left-margin">
                  <Translated translationKey="yes"/>
                </Button>
              </div>
            </div>
          </div>
        );
        break;

      default:
        errorElem = (
          <div>
            <IoAlertOutline />
            <Translated translationKey={this.state.error}/>
          </div>
        );
    }

    return (
      <Alert>
        {errorElem}
      </Alert>
    );
  }

  renderSentRequest() {
    if (!this.state.responseData) {
      return null;
    }
    return (
      <Alert bsstyle="success" className="top-margin">
        <Translated translationKey="user_email_request_sent" values={{'email': this.state.responseData.email}}/>
      </Alert>
    );
  }

  renderForm() {
    const emailText = this.props.translate('email');
    return (
      <div>
        <div>
          <Translated translationKey="connect_email_instructions"/>
        </div>
        <TextInput listener={(email) => this.updateEmail(email)}
                   placeholder={emailText}
                   type="email"
                   value={this.state.inputEmail}
                   className="long-input-field top-margin"/>
        {this.renderError()}
        <Button onClick={() => this.connectEmail()} disabled={this.state.isSending} className="top-margin">
          <Translated translationKey="send"/>
        </Button>
      </div>
    );
  }

  renderModal() {
    return (
        <Modal show={this.state.showModal} onHide={() => this.setState({showModal: false})}>
        <Modal.Header closeButton>
          <Translated translationKey={'connect_email_credentials'}/>
        </Modal.Header>
        <Modal.Body>
          {this.state.responseData ? this.renderSentRequest() : this.renderForm()}
        </Modal.Body>
        </Modal>
    )
  }

  render() {
    const email = this.props.token.get('email');
    if (email) {
      return null;
    }
    return (
      <div className="white-box">
        <Button onClick={() => this.setState({showModal: true})} size="lg">
          <IoMail />
          <Translated translationKey="connect_email"/>
        </Button>
        <div className="top-margin">
          <Translated translationKey={'connect_email_info'}/>
        </div>
        {this.renderModal()}
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return ({
    token: state.login.get('token'),
    locale: state.Intl.locale
  });
};

export default connect(mapStateToProps)(withTranslate(EmailConnect));