import React, {Component} from 'react';
import Translated from './../Translated';
import withRouter from '../withRouter';

class ShopPaymentTermsNote extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <a href='https://www.paytrail.com/kuluttaja/maksupalveluehdot' target="_blank" rel="noopener noreferrer">
          <Translated translationKey='payment_terms_label' />
        </a>
      </div>
    );
  }
}

export default withRouter(ShopPaymentTermsNote);
