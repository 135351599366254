import React, {Component} from 'react';
import {connect} from 'react-redux';
import Translated from '../feature/Translated';
import Card from 'react-bootstrap/Card';
import withTranslate from '../feature/withTranslate';
import {IoMail} from 'react-icons/io5';
import Button from 'react-bootstrap/Button';
import ContactForm from '../feature/ContactForm';
import DataPrivacyNote from '../feature/DataPrivacyNote';
import withRouter from "../feature/withRouter";

class ContactPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      submitted: false,
    }
  }

  renderForm() {
    const header = <div className="centered"><Translated translationKey="contact"/></div>;

    const trial = !this.props.token && (
      <div className="semi-dark-inner-box large-top-margin">
        <div>
          <Translated translationKey="contact_description"/>
        </div>
        <Button className="medium-top-margin" aria-label="Request trial" role="button"  onClick={() => this.props.navigate('/trial')}>
          <Translated translationKey="trial_request"/>
        </Button>
      </div>
    );

    return (
      <div id="contact-page" className="contact-wrapper fade-in">
        <Card className="contact-panel" header={header}>
          <Card.Body>
            <ContactForm name phone email onSubmitted={() => this.setState({submitted: true})}/>
          </Card.Body>
        </Card>
        {trial}
      </div>
    );
  }

  renderSubmittedMessage() {
    return (
      <div id="contact-submitted" className="contact-wrapper fade-in">
        <Card className="blue-box">
          <h3 className="centered">
            <IoMail />&nbsp;&nbsp;<Translated translationKey="message_sent"/>
          </h3>
          <Button aria-label="ok" role="button" onClick={() => this.setState({submitted: false})}><Translated translationKey="ok"/></Button>
        </Card>
      </div>
    );
  }

  render() {
    let content;
    if (this.state.submitted) {
      content = this.renderSubmittedMessage();
    } else {
      content = this.renderForm();
    }

    return (
      <div className="page-container max-width-container">
        <h1 className="centered"><Translated translationKey="feedback_placeholder"/></h1>
        <div className="centered"><Translated translationKey="send_feedback_info"/></div>
        <div className="front-center-wrapper medium-top-margin">
          {content}
        </div>
        <DataPrivacyNote className="semi-dark-inner-box centered"/>
      </div>
    );
  }

}

let mapStateToProps = (state) => {
  return ({
    token: state.login.get('token')
  });
};

export default withRouter(connect(mapStateToProps)(withTranslate(ContactPage)));
