import React, {Children} from 'react';
import LanguageSelectorForm from '../feature/LanguageSelectorForm';
import withTranslate from '../feature/withTranslate';
import Nav from 'react-bootstrap/Nav';
import Translated from '../feature/Translated';

const NavigationPanel = props => {

  const handleListItemClick = eventId => {
    props.listener(eventId);
  }

  const buildNavItem = itemData => {
    const loc = props.translate(itemData.titleKey);
    const classes = "full-nav-item touchable";
    return (
      <div
        className={classes}
        eventkey={itemData.id}
        key={itemData.id}
        onClick={() => handleListItemClick(itemData.id)}>
        <span className="top-margin-small small-bottom-margin">{loc}</span>
      </div>);
  }

  const buildTitleItem = titleData => {
    return (
      <TitleWrapper key={titleData.titleKey}>
        <div className="full-nav-topic"><Translated translationKey={titleData.titleKey}/></div>
      </TitleWrapper>
    );
  }

  const renderLanguageSelector = () => {
    if (props.languageSelector) {
      return (<LanguageSelectorForm className="login-nav-item"/>);
    } else {
      return null;
    }
  }

  let itemData = props.data;
  const menuItems = [];
  itemData.forEach(item => {
    if (item.id === 'TITLE') {
      menuItems.push(buildTitleItem(item));
    } else {
      menuItems.push(buildNavItem(item));
    }
  });

  return (
    <Nav bsstyle="pills" className="nav-vertical">
      {menuItems}
      {renderLanguageSelector()}
    </Nav>
  );

}

// Nav component passes custom props to all children so regular DOM elements
// must be wrapped to avoid 'Unknown prop' warning being fired.
const TitleWrapper = (props) => (
  Children.only(props.children)
);

export default withTranslate(NavigationPanel);