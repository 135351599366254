import React, {Component} from 'react';
import withTranslate from './withTranslate';
import {connect} from 'react-redux'

import {login, resetLoginError} from '../../actions/loginActions'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Translated from '../feature/Translated';
import Spinner from './../feature/Spinner';
import withRouter from './withRouter';
import classNames from 'classnames';

export class LoginForm extends Component {

  constructor(props, context) {
    super(props, context);

    this.handleLoginTap = this.handleLoginTap.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      name: props.name || '',
      schoolId: props.schoolId || '',
      loginToken: props.loginToken || ''
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.token && !prevProps.token) {
      // Login happened
      this.props.navigate('/', {state: this.props.location.state, replace: true});
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetLoginError());
  }

  handleLoginTap(e) {
    e.preventDefault();
    if (!this.state.name || !this.state.schoolId || !this.state.loginToken) {
      return;
    }
    // TODO Validate input values
    this.props.dispatch(login(this.state.name, this.state.schoolId, this.state.loginToken));
  }

  handleInputChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  render() {
    const t = this.props.translate;
    const shake = this.props.loginError && !this.props.isFetching;
    const formClasses = classNames('login-form-well', {shake}, this.props.className);

    let nameLabel;
    let schoolLabel;
    let codeLabel;
    if (this.props.loginError) {
      nameLabel = <Translated className="input-label" translationKey="login_student_name"/>;
      schoolLabel = <Translated className="input-label" translationKey="school_code"/>;
      codeLabel = <Translated className="input-label" translationKey="login_code"/>;
    }
    const inputEmpty = !this.state.name || !this.state.schoolId || !this.state.loginToken;

    let errorMessage;
    if (this.props.loginError) {
      let errorContent;
      if (this.props.loginError === 'one-time-key-used') {
        if (this.props.loginErrorData.size > 0) {
          errorContent = <Translated translationKey="one-time-key-used"
                                     values={{'hint': this.props.loginErrorData.get(0).toUpperCase()}}/>
        } else {
          errorContent = <Translated translationKey="all-one-time-keys-used"/>
        }
      } else {
        errorContent = <Translated translationKey={this.props.loginError}/>
      }
      errorMessage = (
        <Alert className={this.props.isFetching ? 'transparent' : null}>
          <Translated className="strong-text" translationKey="error-login"/><br/>
          {errorContent}
        </Alert>
      )
    }

    return (
      <Card id="login-form" className={formClasses}>
        <Card.Header>{this.props.header}</Card.Header>
        <Card.Body>
          <Form onSubmit={this.handleLoginTap} method="post">
            <Form.Group onSubmit={this.handleLoginTap}>
              {nameLabel}
              <Form.Control id="login-form-name" disabled={this.props.isFetching} type="text" name="name"
                           placeholder={t('login_student_name')}
                           value={this.state.name} onChange={this.handleInputChange}/>
              <div className="field-spacer"></div>
              {schoolLabel}
              <Form.Control id="login-form-id" disabled={this.props.isFetching} type="text" name="schoolId"
                           placeholder={t('school_code')}
                           value={this.state.schoolId} onChange={this.handleInputChange}
                           className="uppercase"/>

              <div className="field-spacer"></div>
              {codeLabel}
              <Form.Control id="login-form-code" disabled={this.props.isFetching} type="text" name="loginToken"
                           placeholder={t('login_code')}
                           value={this.state.loginToken} onChange={this.handleInputChange}
                           autoComplete="off"
                           className="uppercase"/>
              <div className="field-spacer"></div>
              {errorMessage}
            </Form.Group>
            <Button
              id="login-form-submit"
              type="submit"
              disabled={this.props.isFetching || inputEmpty}
              className="submit-login-button strong-text"
              onClick={this.handleLoginTap}>
              {this.props.isFetching ? <Spinner/> : t('login')}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    )
  }
}

function mapStateToProps(state) {
  return {
    token: state.login.get('token'),
    loginError: state.login.get('error'),
    loginErrorData: state.login.get('errorData'),
    isFetching: state.login.get('isFetching')
  }
}

export default withRouter(connect(mapStateToProps)(withTranslate(LoginForm)));
