import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Translated from './Translated';
import classNames from 'classnames';
import {IoLockClosedSharp, IoMail, IoPerson} from 'react-icons/io5';
import withRouter from './withRouter';
import Alert from 'react-bootstrap/Alert';

class TeacherList extends Component {

  constructor(props, context) {
    super(props, context);
  }

  static propTypes = {
    noUsersTranslationKey: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired
  };

  render() {
    if (!this.props.users || this.props.users.length === 0) {
      return (
        <Alert>
          <Translated translationKey={this.props.noUsersTranslationKey}/>
        </Alert>
      );
    } else {
      return (
        <div className={this.props.className}>
          <div className="white-box tight">
            <div className="custom-table full-width">
              <div className="custom-table-header">
                <div className="ct-column-name"><Translated translationKey="name" /></div>
                <div className="ct-column-name"><Translated translationKey="email" /></div>
                <div className="ct-column-name"><IoLockClosedSharp /></div>
              </div>
              <div className="ct-table-body">{this.renderUsersElements()}</div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderUsersElements() {
    const elements = [];
    this.props.users.forEach(user => {
      const isClosed = user.isClosed;
      let classes = classNames('ct-table-row touchable', isClosed ? 'ct-table-row semi-transparent strike-through' : null);
      elements.push(
        <div
          className={classes}
          key={user.id}
          onClick={() => {
            this.props.navigate("/user/" + user.id);
          }}
        >
          <div className="ct-table-body-cell">
            <span className="ct-mobile-only">
              <IoPerson />
            </span>
            &nbsp;{user.name}
            <span className="ct-mobile-only">
              {isClosed && <IoLockClosedSharp />}
            </span>
          </div>
          <div className="ct-table-body-cell">
            <IoMail className="ct-mobile-only" />
            &nbsp;{user.email}
          </div>
          <div className="ct-table-body-cell">
            <span className="ct-desktop-only">
              {isClosed && <IoLockClosedSharp />}
            </span>
          </div>
        </div>
      );
    });
    return elements;
  }
}

export default withRouter(TeacherList);
