import React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics';
import {TranslationContext} from './TranslationProvider';

const getComponentDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
};

export default (WrappedComponent) => {
  const WithTranslate = (props) => {
    return (
      <TranslationContext.Consumer>
        {value => <WrappedComponent {...props} translate={value.translate}/>}
      </TranslationContext.Consumer>
    )
  };
  WithTranslate.displayName = `withTranslate(${getComponentDisplayName(WrappedComponent)})`;

  return hoistNonReactStatics(WithTranslate, WrappedComponent)
}