import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import Translated from './Translated';
import classNames from 'classnames';
import DateUtil from '../../utils/date';
import {loadData, forceReloadData, dataTypes} from '../../actions/apiDataActions'
import api from '../../api';

class ProfileTeacher extends Component {

  static propTypes = {
    allowFacebook: PropTypes.bool
  };

  static defaultProps = {
    allowFacebook: false,
  };

  componentDidMount() {
    if (this.props.token) {
      this.fetchProfile(this.props.token.get('userId'), false);
    }
  }

  fetchProfile(userId, reload) {
    const actionCreator = reload ? forceReloadData : loadData;
    this.props.dispatch(actionCreator(dataTypes.PROFILE, api.getProfile, userId, reload));
  }

  componentDidUpdate(prevProps) {
    if (this.props.token) {
      const isFbConnected = (this.props.token.get('facebook'));
      const wasFbConnected = prevProps.token ? (prevProps.token.get('facebook')) : false;
      if ((isFbConnected !== wasFbConnected) && !this.props.profile.isFetching) {
        this.fetchProfile(this.props.token.get('userId'), true);
      }
    }
  }

  render() {
    if (this.props.profile.isFetching || !this.props.profile.data || this.props.isFbConnecting) {
      return(<Spinner className="padded primary"/>);

    } else {
      const classes = classNames(this.props.className, 'user-profile-container', 'fade-in');
      // Do not use image for teacher for now. Regular teacher should not have Facebook possibility. Only 'dev' teachers.
      const userImageUrl = null; //this.props.profile.data.facebookUser ? this.props.profile.data.facebookUser.pictureUrl : null;
      const userImage = userImageUrl
        ? <div>
          <img src={userImageUrl}  className="user-profile-image fade-in"/>
        </div>
        : null;

      return(
        <div id="profile-teacher-container" className={classes}>
          {userImage}
          <div className="user-profile-data">
            <h1 className="uppercase"><Translated translationKey={this.props.profile.data.userData.name} options={{'onlyIfKey':true}}/></h1>
            <h4>{this.props.profile.data.userData.schoolName} <i className="semi-transparent smaller"> @{this.props.profile.data.userData.schoolId.toUpperCase()}</i></h4>
            <br />
            {this.props.profile.data.userData.closingDate
              ? <div className="user-profile-usage-valid">
                <Translated translationKey="license_valid"
                            values={{'valid_until': DateUtil.date(this.props.profile.data.userData.closingDate)}}/>
              </div>
              : null
            }
          </div>
        </div>
      );
    }

  }
}

let mapStateToProps = (state) => {
  return ({
    token: state.login.get('token'),
    profile: state.apiData[dataTypes.PROFILE],
    isFbConnecting: state.login.get('isFbConnecting')
  });
};

export default connect(mapStateToProps)(ProfileTeacher);
