import React, {Component} from 'react';
import {connect} from 'react-redux'
import Alert from 'react-bootstrap/Alert';
import Translated from '../feature/Translated';
import withRouter from './withRouter';
import Spinner from './../feature/Spinner';

/**
 * Used in login to show login errors and isFetching status when no form or other suitable element exists
 * (e.g. Facebook button).
 */
export class LoginStatusDisplay extends Component {

  render() {
    let errorElem = null;
    if (this.props.loginError) {
      errorElem = (
        <Alert className={this.props.isFetching ? 'transparent' : null}>
          <Translated className="strong-text" translationKey="error-login"/><br/>
          <Translated translationKey={this.props.loginError}/>
        </Alert>
      );
    }

    let spinner = null;
    if (this.props.isFetching) {
      spinner = (
        <div>
          <Spinner/>
        </div>
      )
    }

    if (!spinner && !errorElem) {
      return null;
    }

    return (
      <div>
        {spinner}
        {errorElem}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loginError: state.login.get('error'),
    isFetching: state.login.get('isFetching')
  }
}

export default connect(mapStateToProps)(LoginStatusDisplay);
