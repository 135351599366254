import React, {Component} from 'react';
import {connect} from 'react-redux';
import BookBrowser from '../feature/BookBrowser';
import Translated from '../feature/Translated';
import PreloadMiddlePage from './PreloadMiddlePage';
import api from '../../api';
import Alert from 'react-bootstrap/Alert';
import {IoAlertOutline} from 'react-icons/io5';
import {loadData, dataTypes} from '../../actions/apiDataActions'
import {Roles} from '../../data/roles';
import CategorySelector from '../feature/CategorySelector';
import SettingsUtil from '../../utils/settingsUtil';
import withRouter from "../feature/withRouter";

/**
 * Book page that reads users selected category and language and selects book accordingly.
 * Uses browser that shows read status.
 * Used for students.
 */
class BookPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      initialized: false,
      bookData: null
    }
  }

  componentDidMount() {
    if (this.props.role === Roles.STUDENT) {
      this.props.dispatch(loadData(dataTypes.BOOK_STATUSES, api.getBookStatuses, this.props.userId));
    }
    this.tryInit();
  }

  componentDidUpdate() {
    this.tryInit();
  }

  tryInit() {
    if (!this.props.settings.get('vehicleCategory')) {
      return;
    }

    if (this.state.initialized || !this.props.settings.get('isReceived') || !this.props.bookStatus.data) {
      return;
    }

    this.setState({
      initialized: true
    });

    const category = this.props.settings.get('vehicleCategory');
    const books = this.props.settings.get('data').get('availableBooks');
    const booksForCategory = books.filter(book => SettingsUtil.categoryContainsBookCategory(category, book.get('category')));
    if (booksForCategory.size === 0) {
      return this.setState({
        error: 'no-book-for-category'
      });
    }
    const book = books.find(book => {
      if (book.get('lang') !== this.props.locale) {
        return false;
      }
      // Ugly olb => b hack!
      let bCat = book.get('category');
      return (bCat === category || (bCat === 'b' && category === 'olb'));
    });

    if (!book) {
      return this.setState({
        error: 'no-book-for-language'
      });
    }
    if (!book.get('hasLicense')) {
      return this.setState({
        error: 'insufficient-licenses'
      });
    }
    this.setState({
      bookId: book.get('id')
    });
    api.getBookIndex(book.get('id'), this.props.locale)
      .then(bookIndex => {
        if (bookIndex.error) {
          return this.setState({
            error: bookIndex.error
          })
        }
        bookIndex.content.sort((a, b) => a.positionIndex - b.positionIndex);
        this.setState({
          bookData: bookIndex.content
        })
      });
  }

  render() {
    let content;
    const category = this.props.settings.get('vehicleCategory');
    if (!category) {
      content = <CategorySelector className="fade-in"/>;
    } else if (this.state.error) {
      content = <Alert><IoAlertOutline /> <Translated translationKey={this.state.error}/></Alert>;
    } else if (!this.state.initialized || !this.state.bookData || (!this.props.bookStatus.data && this.props.role === Roles.STUDENT)) {
      content = <PreloadMiddlePage/>
    } else {
      let bookStatus;
      if (this.props.bookStatus.data) {
        bookStatus = this.props.bookStatus.data.find(status => status.bookId === this.state.bookId);
      }
      content = <BookBrowser chapters={this.state.bookData}
                             status={bookStatus}
                             chapterId={this.props.match.params.chapterId}/>
    }

    return (
      <div id="book-page" className="page-container">
        <div className="max-width-container centered">
          {content}
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  const token = state.login.get('token');
  return ({
    settings: state.settings,
    locale: state.Intl.locale,
    userId: token ? token.get('userId') : -1,
    role: token ? token.get('role') : null,
    bookStatus: state.apiData[dataTypes.BOOK_STATUSES],
    key: state.settings.get('vehicleCategory')
  });
};

export default withRouter(connect(mapStateToProps)(BookPage));

