import React, {Component} from 'react';
import {connect} from 'react-redux';
import {setLocale} from './TranslationProvider';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

class LanguageSelector extends Component {

  constructor(props, context) {
    super(props, context);
    this.dropdownLangs = this.props.dropdownLangs
  }

  render() {
    let currentLangLocalization = null;
    const langCodes = Object.keys(this.props.availableLangs);
    if (langCodes.length <= 1) {
      return null;
    }
    const langItems = [];
    langCodes.forEach(langCode => {
      if (this.dropdownLangs && !this.dropdownLangs.includes(langCode)) {
        return;
      }

      const langLocalization = this.props.availableLangs[langCode];
      if (langCode === this.props.locale) {
        currentLangLocalization = langLocalization;
      }
      langItems.push(
          <Dropdown.Item key={langCode} onClick={() => {setLocale(langCode)}}>
            <span className={classNames('language', {'selected': langCode === this.props.locale})}>
                {langLocalization}
            </span>
          </Dropdown.Item>
      );
    });

    return (
          <DropdownButton title={currentLangLocalization} id="languageSelectorButton"
                          drop={this.props.drop}>
            {langItems}
          </DropdownButton>
    );
  }
}

let mapStateToProps = (state) => {
  return ({
    availableLangs: state.availableLangs,
    locale: state.Intl.locale
  });
};

export default connect(mapStateToProps)(LanguageSelector);
