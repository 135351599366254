const studentHeader = [
  {
    'id': 'book',
    'titleKey': 'menu_book',
  },
  {
    'id': 'fulltest',
    'titleKey': 'menu_full_test',
  },
  {
    'id': 'literals',
    'titleKey': 'menu_literals',
  },
  {
    'id': 'images',
    'titleKey': 'menu_images',
  },
  {
    'id': 'signs',
    'titleKey': 'menu_signs',
  }
];

const studentNavigation = [
  {
    'id': 'TITLE',
    'titleKey': 'menu_training'
  },
  {
    'id': 'book',
    'titleKey': 'menu_book',
  },
  {
    'id': 'fulltest',
    'titleKey': 'menu_full_test',
  },
  {
    'id': 'literals',
    'titleKey': 'menu_literals',
  },
  {
    'id': 'images',
    'titleKey': 'menu_images',
  },
  {
    'id': 'signs',
    'titleKey': 'menu_signs',
  },
  {
    'id': 'TITLE',
    'titleKey': 'menu_me'
  },
  {
    'id': 'settings',
    'titleKey': 'settings',
  },
  {
    'id': 'contact',
    'titleKey': 'send_feedback',
  },
  {
    'id': 'logout',
    'titleKey': 'menu_logout',
  }
];

const studentMeMenu = [
  {
    'id': 'settings',
    'titleKey': 'settings',
  },
  {
    'id': 'contact',
    'titleKey': 'send_feedback',
  },
  {
    'id': 'logout',
    'titleKey': 'menu_logout',
  }
];

const teacherHeader = [
  {
    'id': 'users',
    'titleKey': 'users'
  },
  {
    'id': 'orders',
    'titleKey': 'orders',
  },
  {
    'id': 'school-settings',
    'titleKey': 'settings'
  },
  {
    'id': 'books',
    'titleKey': 'menu_book',
  },
];


const teacherMeMenu = [
  {
    'id': 'contact',
    'titleKey': 'send_feedback',
  },
  {
    'id': 'logout',
    'titleKey': 'menu_logout',
  }
];

const teacherNavigation = [
  {
    'id': 'users',
    'titleKey': 'users'
  },
  {
    'id': 'orders',
    'titleKey': 'orders',
  },
  {
    'id': 'school-settings',
    'titleKey': 'settings'
  },
  {
    'id': 'books',
    'titleKey': 'menu_book',
  },
  {
    'id': 'contact',
    'titleKey': 'send_feedback',
  },
  {
    'id': 'logout',
    'titleKey': 'menu_logout',
  }
];

const anonymousNavigation = [
  {
    'id': 'login',
    'titleKey': 'menu_login',
  },
  {
    'id': 'kauppa',
    'titleKey': 'menu_shop',
  },
  {
    'id': 'liikennemerkit',
    'titleKey': 'menu_signs',
  },
  {
    'id': 'contact',
    'titleKey': 'contact',
  }
];

const anonymousHeader = [
  {
    'id': 'login',
    'titleKey': 'menu_login',
  },

  {
    'id': 'kauppa',
    'titleKey': 'menu_shop',
  },
  {
    'id': 'liikennemerkit',
    'titleKey': 'menu_signs',
  },
  {
    'id': 'contact',
    'titleKey': 'contact',
  }
];

export default {
  studentNavigation, studentHeader, studentMeMenu,
  teacherNavigation, teacherHeader, teacherMeMenu,
  anonymousNavigation, anonymousHeader
};