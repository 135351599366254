import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import Translated from './Translated';
import classNames from 'classnames';
import DateUtil from '../../utils/date';
import {loadData, forceReloadData, dataTypes} from '../../actions/apiDataActions'
import api from '../../api';
import FacebookLike from './FacebookLike';

export class ProfileStudent extends Component {

  static propTypes = {
    allowFacebook: PropTypes.bool
  };

  static defaultProps = {
    allowFacebook: false,
  };

  componentDidMount() {
    if (this.props.token) {
      this.fetchProfile(this.props.token.get('userId'), false);
    }
  }

  fetchProfile(userId, reload) {
    const actionCreator = reload ? forceReloadData : loadData;
    this.props.dispatch(actionCreator(dataTypes.PROFILE, api.getProfile, userId, reload));
  }

  componentDidUpdate(prevProps) {
    if (this.props.token) {
      const isFbConnected = (this.props.token.get('facebook'));
      const wasFbConnected = prevProps.token ? (prevProps.token.get('facebook')) : false;
      if ((isFbConnected !== wasFbConnected) && !this.props.profile.isFetching) {
        this.fetchProfile(this.props.token.get('userId'), true);
      }
    }
  }

  renderUserImage() {
    const userImageUrl = this.props.profile.data.facebookUser
      ? this.props.profile.data.facebookUser.pictureUrl
      : null;

    if (!userImageUrl) {
      return null;
    }
    return (
      <div className="user-profile-image-wrapper">
        <img src={userImageUrl} className="user-profile-image fade-in"/>
      </div>
    );
  }

  renderClosingDate() {
    if (!this.props.showClosingDate) {
      return null;
    }

    const closingDate = this.props.profile.data.userData.closingDate;
    if (!closingDate) {
      return null;
    }

    return (
      <div className="user-profile-usage-valid">
        <Translated translationKey="license_valid"
                    values={{'valid_until': DateUtil.date(this.props.profile.data.userData.closingDate)}}/>
      </div>
    );
  }

  render() {
    if (this.props.profile.error) {
      return <div className="white padded"><Translated translationKey={this.props.profile.error}/></div>
    }

    if (this.props.profile.isFetching || !this.props.profile.data || this.props.isFbConnecting) {
      return (<Spinner className="padded primary"/>);
    }

    const classes = classNames(this.props.className, 'user-profile-container', 'fade-in');

    return (
      <div id="profile-student-container" className={classes}>
        {this.renderUserImage()}
        <div className="user-profile-data">
          <h2 className="uppercase">{this.props.profile.data.userData.name}</h2>
          <h3>{this.props.profile.data.userData.schoolName}</h3>
          {this.renderClosingDate()}
          {/*<FacebookLike />*/}
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return ({
    token: state.login.get('token'),
    profile: state.apiData[dataTypes.PROFILE],
    isFbConnecting: state.login.get('isFbConnecting')
  });
};

export default connect(mapStateToProps)(ProfileStudent);
