import {RECEIVE_NAV_DATA, TOGGLE_NAV, CLOSE_NAV} from '../actions/actionCreators';

const initialState = {
  active: false,
  data: null
};

function navigation(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NAV_DATA:
      return Object.assign({}, state, {
        data: action.data
      });

    case TOGGLE_NAV:
      return Object.assign({}, state, {
        active: !state.active
      });

    case CLOSE_NAV:
      return Object.assign({}, state, {
        active: false
      });

    default:
      return state;
  }
}

export default navigation;
