import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Translated from '../Translated';
import Button from 'react-bootstrap/Button';
import FullTestTimer from './FullTestTimer';
import classNames from 'classnames';
import FullTestButton from './FullTestButton';
import Spinner from '../Spinner';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Overlay from 'react-bootstrap/Overlay';
import Modal from 'react-bootstrap/Modal';
import Tracker from '../../../utils/tracker';
import ZoomableImage from '../ZoomableImage';
import FullTestSubmit from './FullTestSubmit';
import FullTestQuestionSelector from './FullTestQuestionSelector';
import FullTestQuestionSelectorForm from './FullTestQuestionSelectorForm';
import {sendFullTestAnswers} from '../../../actions/fullTestApiUtils';
import withRouter from '../withRouter';
import VehicleInfoTrigger from '../VehicleInfoTrigger';
import {hasVehicleInfo} from '../../../data/vehicleInfoCategories';

class FullTestMain extends Component {

  static propTypes = {
    test: PropTypes.object.isRequired,
    removeSave: PropTypes.func,
    clear: PropTypes.func,
    savedState: PropTypes.object
  };

  static DURATION_SECS = 1800;
  static TIMER_INFO_DELAY_SECS = 900;

  constructor(props, context) {
    super(props, context);
    this.setAnswer = this.setAnswer.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.goBackToQuestions = this.goBackToQuestions.bind(this);
    this.selectQuestion = this.selectQuestion.bind(this);
    this.complete = this.complete.bind(this);
    this.resizeImage = this.resizeImage.bind(this);
    this.submitAnswers = this.submitAnswers.bind(this);

    if (props.initState) {
      this.state = props.initState;
    } else {
      this.state = {
        completed: false,
        questionIndex: 0,
        answers: {},
        startTime: Date.now(),
      };
    }
    this.state.imageReady = false;
    this.state.answersSubmitError = null;
    this.state.isSendingAnswers = false;
    this.state.showMarkButtonOverlay = false;

    this.timerRef = React.createRef();
  }

  componentDidMount() {
    this.preloadImages();
    this.checkImageLoadStatus();
    window.addEventListener('resize', this.resizeImage);
    this.timerInfoTimeout = setTimeout(() => {
      this.setState({showTimerOverlay: true});
      Tracker.logEventValue('full-test-timer', 'automatic-view', FullTestMain.TIMER_INFO_DELAY_SECS);
    }, FullTestMain.TIMER_INFO_DELAY_SECS * 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.save && (prevState.answers !== this.state.answers || prevState.questionIndex !== this.state.questionIndex)) {
      this.props.save(this.state);
    }
    if (this.state.questionIndex != prevState.questionIndex) {
      this.preloadImages();
      this.checkImageLoadStatus();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerInfoTimeout);
    window.removeEventListener('resize', this.resizeImage);
  }

  submitAnswers() {
    this.setState({
      isSendingAnswers: true,
      answersSubmitError: null
    });
    const testId = this.props.test.id;
    sendFullTestAnswers(testId, this.props.test.questions, this.state.answers)
      .then(response => {
        if (response.error) {
          return this.setState({
            isSendingAnswers: false,
            answersSubmitError: response.error
          });
        }
        this.props.removeSave();
        this.props.navigate('/result/' + response.resultId);
      });
  }

  render() {
    let topContent;
    let middleContent;
    let bottomContent;
    const imageRotateIcon = require('../../../assets/rotate-phone.svg');

    if (this.state.completed) {
      topContent = (
        <div id="full-test-top-completed" className="top align-right">
          {this.renderTimer()}
        </div>
      );

      middleContent = this.renderSubmit();
    } else {
      const question = this.props.test.questions[this.state.questionIndex];

      topContent = (
        <div id="full-test-top" className="top" ref={elem => this.topDiv = elem}>
          {this.renderQuestionNum()}
          {this.renderVehicleInfoTrigger(question)}
          {this.renderQuestion(question)}
          {this.renderTimer()}
        </div>
      );

      middleContent = (
        <div className="middle" ref={elem => this.middleDiv = elem}>
          {this.renderQuestionContent(question)}
        </div>
      );

      if (!this.state.assertion) {
        bottomContent = (
          <div id="full-test-bottom" className="bottom" ref={elem => this.bottomDiv = elem}>
            {this.renderMarkButton(question)}
            {this.renderQuestionSelector()}
            {this.renderQuestionSelectorForm()}
            {this.renderYesButton(question)}
            {this.renderNoButton(question)}
            {this.renderNextButton()}
          </div>
        );
      }
    }

    return (
      <div id="full-test-container">
        <div id="full-test">
          {topContent}
          {middleContent}
          {bottomContent}
          {this.renderTimerOverlay()}
          {this.renderTimerModal()}
        </div>
        <img src={imageRotateIcon} className="rotate-icon swing"/>
      </div>
    );
  }

  renderQuestionNum() {
    return (
      <div className="question-num">
        {(this.state.questionIndex + 1) + '/' + this.props.test.questions.length}
      </div>
    )
  }

  renderVehicleInfoTrigger(question) {
    const category = this.props.test.category;

    if (question.type !== 'image' || this.state.assertion || !hasVehicleInfo(category)) {
      return null;
    }

    return (
      <div className="vehicle-info">
        <VehicleInfoTrigger category={category}/>
      </div>
    )

  }

  renderTimer() {
    const clickHandler = () => {
      Tracker.logEvent('full-test-timer', 'manual-view');
      this.setState(
        {showTimerOverlay: true}
      )
    };
    const completeHandler = () => {
      Tracker.logEventValue('full-test-timer', 'automatic-view', FullTestMain.DURATION_SECS);
      this.setState({
        showTimerModal: true
      })
    };

    return (
      <FullTestTimer key="full-test-timer" start={this.state.startTime}
                     durationSec={FullTestMain.DURATION_SECS}
                     onClick={clickHandler}
                     innerRef={this.timerRef}
                     onComplete={completeHandler}/>
    )
  }

  renderTimerOverlay() {
    const onHide = () => {
      clearTimeout(this.timerInfoTimeout);
      this.setState({showTimerOverlay: false});
    };
    return (
      <Overlay
        show={this.state.showTimerOverlay}
        target={this.timerRef}
        placement="bottom"
        onHide={onHide}
        rootClose>
        <Popover id="timer-popover">
          <Popover.Body>
            <Translated translationKey="timer_info"/>
          </Popover.Body>
        </Popover>
      </Overlay>
    )
  }

  renderTimerModal() {
    return (
      <Modal show={this.state.showTimerModal}>
        <Modal.Body>
          <Translated translationKey="timer_info_full"/>
        </Modal.Body>
        <Modal.Footer>
          <Button id="full-test-close-modal" onClick={() => this.setState({showTimerModal: false})}><Translated
            translationKey="ok"/></Button>
        </Modal.Footer>
      </Modal>
    )
  }

  renderQuestion(question) {
    if (this.state.assertion) {
      return null;
    }
    let content;
    switch (question.type) {
      case 'literal':
      case 'risk':
        content = question.question;
        break;
      case 'sign':
        content = <Translated translationKey="select_definition_for_sign"/>;
        break;
      case 'image':
        content = question.assertion;
        break;
    }
    return (
      <div className="question-text">
        {content}
      </div>
    )
  }

  renderQuestionContent(question) {
    if (this.state.assertion) {
      return this.renderAssertion();
    }

    switch (question.type) {
      case 'literal':
      case 'risk':
        return this.renderLiteral(question);
      case 'sign':
        return this.renderSign(question);
      case 'image':
        return this.renderImage(question);
      default:
        return null;
    }
  }

  renderAssertion() {
    return (
      <div className="next-assertion-container">
        <div className="next-assertion">
          {this.state.assertion}
        </div>
        <div>
          <Button id="full-test-assert-continue" onClick={() => this.setState({assertion: null})}
                  className="uppercase"><Translated
            translationKey="continue"/></Button>
        </div>
      </div>
    )
  }

  renderLiteral(question) {
    const answer = this.state.answers[question.id];

    let image;
    if (question.imageUrl) {
      const imgProps = {
        width: 0,
        height: 0,
        ref: (e) => this.image = e,
        onLoad: this.resizeImage,
        draggable: 'false'
      };
      image = <div>
        <ZoomableImage src={question.imageUrl} imgProps={imgProps}/>
      </div>
    }

    return (
      <div key={this.state.questionIndex} className={classNames({"padding": !question.imageUrl}, 'fade-in')}>
        {image}
        {Object.keys(question.options).map((optionId, index) => (
          <div key={index} onClick={() => this.setAnswer(question.id, optionId)}
               className={classNames('literal-option', {'selected bump': answer === optionId})}>
            {optionId + ') ' + question.options[optionId]}
          </div>
        ))}
      </div>
    )
  }

  renderSign(question) {
    const answer = this.state.answers[question.id];
    const src = question.question.imageUrl;

    return (
      <div key={this.state.questionIndex} className="sign-container padding fade-in">
        <img key={this.state.questionIndex} src={src} className="sign-image"/>
        <div className="sign-options-container">
          {Object.keys(question.options).map((optionId, index) => (
            <div key={index} onClick={() => this.setAnswer(question.id, optionId)}
                 className={classNames('literal-option', {'selected bump': answer === optionId})}>
              {optionId + ') ' + question.options[optionId].text}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderImage(question) {
    const imgProps = {
      width: 0,
      height: 0,
      ref: (e) => this.image = e,
      onLoad: this.resizeImage,
      draggable: 'false'
    };

    return (
      <div className="image-container fade-in">
        {this.state.imageReady ?
          <ZoomableImage src={question.imageUrl} imgProps={imgProps} modalClassName="fulltest-image-modal"/>
          :
          <Spinner/>}
      </div>
    );
  }

  resizeImage() {
    let h = document.documentElement.clientHeight;
    let w = document.documentElement.clientWidth;
    if (w != this.viewportW) {
      this.viewportW = w;
      this.viewportH = h;
    } else if (h > this.viewportH) {
      this.viewportH = h;
    } else {
      h = this.viewportH;
    }

    if (!this.image || !this.topDiv || !this.bottomDiv) {
      return;
    }

    const freeH = h - this.topDiv.clientHeight - this.bottomDiv.clientHeight;
    const freeW = Math.min(this.middleDiv.clientWidth, w);
    const img = this.image;
    const imgH = img.naturalHeight;
    const imgW = img.naturalWidth;

    if (imgW > freeW || imgH > freeH) {
      const imageRatio = imgW / imgH;
      const containerRatio = freeW / freeH;
      if (imageRatio > containerRatio) {
        img.width = freeW;
        img.height = freeW / imageRatio;
      } else {
        img.height = freeH;
        img.width = freeH * imageRatio;
      }
    } else {
      img.width = img.naturalWidth;
      img.height = img.naturalHeight;
    }
  }

  renderYesButton(question) {
    if (question.type != 'image') {
      return null;
    }
    const answer = this.state.answers[question.id];
    return (<FullTestButton id="full-test-yes-button" onClick={() => this.setAnswer(question.id, 'a')}
                            label="yes"
                            selected={answer === 'a'}/>);
  }

  renderNoButton(question) {
    if (question.type != 'image') {
      return null;
    }
    const answer = this.state.answers[question.id];
    return (<FullTestButton id="full-test-no-button" onClick={() => this.setAnswer(question.id, 'c')}
                            label="no"
                            selected={answer === 'c'}/>);
  }

  renderMarkButton(question) {
    const answer = this.state.answers[question.id];
    const clickHandler = () => {
      this.setState({showMarkButtonOverlay: true});
      this.setAnswer(question.id, 'd');
    };
    return (
      <div>
        <div ref={e => this.markButtonRef = e}>
          <FullTestButton id="full-test-mark-question"
                          onClick={clickHandler}
                          selected={answer === 'd'}
                          label="full_test_mark_question">
          </FullTestButton>
        </div>

        <Overlay show={this.state.showMarkButtonOverlay}
                 target={this.markButtonRef}
                 placement="top"
                 onHide={() => this.setState({showMarkButtonOverlay: false})}
                 rootClose>
          <Popover id="mark-button-tooltip">
            <Popover.Body>
              <Translated translationKey="mark_tooltip"/>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    )
  }

  renderNextButton() {
    const question = this.props.test.questions[this.state.questionIndex];
    const isAnswered = this.state.answers[question.id] !== undefined;
    const allAnswered = Object.values(this.state.answers).length === this.props.test.questions.length;
    let label;
    let action;
    if (allAnswered || this.state.questionIndex === this.props.test.questions.length - 1) {
      label = 'ready';
      action = this.complete;
    } else {
      label = 'next';
      action = this.nextQuestion;
    }
    return (<FullTestButton onClick={action} disabled={!isAnswered} label={label} id={`full-test-${label}-button`}/>)
  }

  renderQuestionSelector() {
    return <FullTestQuestionSelector answers={this.state.answers}
                                     questions={this.props.test.questions}
                                     index={this.state.questionIndex}
                                     select={this.selectQuestion}/>;
  }

  renderQuestionSelectorForm() {
    return <FullTestQuestionSelectorForm answers={this.state.answers}
                                         questions={this.props.test.questions}
                                         index={this.state.questionIndex}
                                         select={this.selectQuestion}/>
  }

  renderSubmit() {
    return <FullTestSubmit answers={this.state.answers}
                           error={this.state.answersSubmitError}
                           isSending={this.state.isSendingAnswers}
                           review={this.goBackToQuestions}
                           submit={this.submitAnswers}/>;
  }

  setAnswer(questionId, answer) {
    if (this.state.answers[questionId] === answer) {
      return;
    }
    const answers = Object.assign({}, this.state.answers);
    answers[questionId] = answer;
    this.setState({answers: answers})
  }

  nextQuestion() {
    const index = this.state.questionIndex;
    if (index < this.props.test.questions.length - 1) {
      const currentQuestion = this.props.test.questions[index];
      const nextQuestion = this.props.test.questions[index + 1];
      let assertion;
      if (nextQuestion.assertion !== currentQuestion.assertion) {
        assertion = nextQuestion.assertion;
      }
      this.setState({
        assertion: assertion
      });
      this.selectQuestion(index + 1);
    } else {
      this.complete();
    }
  }

  complete() {
    this.setState({
      completed: true
    });
  }

  goBackToQuestions() {
    let index = this.props.test.questions.findIndex(question => this.state.answers[question.id] === 'd');
    index = Math.max(index, 0);
    this.setState({
      completed: false,
      questionIndex: index,
      answersSubmitError: null
    })
  }

  selectQuestion(index) {
    this.setState({
      questionIndex: index
    })
  }

  checkImageLoadStatus() {
    const question = this.props.test.questions[this.state.questionIndex];
    if (question && question.type === 'image') {
      const img = new Image();
      img.src = question.imageUrl;
      if (this.state.imageReady !== img.complete) {
        this.setState({
          imageReady: img.complete
        });
      }
      if (!img.complete) {
        img.onload = () => {
          this.setState({
            imageReady: true
          });
        }
      }
    }
  }

  preloadImages(count = 5) {
    for (let i = 1; i <= count; i++) {
      const questionIndex = this.state.questionIndex + i;
      if (questionIndex < this.props.test.questions.length) {
        const question = this.props.test.questions[questionIndex];
        if (question.type === 'image') {
          const img = new Image();
          img.src = question.imageUrl;
        } else if (question.type === 'sign') {
          const img = new Image();
          const src = question.question.imageUrl;
          img.src = src;
        }
      }
    }
  }

}

export default withRouter(FullTestMain);