import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import Button from 'react-bootstrap/Button';
import {IoSearch} from 'react-icons/io5';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import withTranslate from './withTranslate';

class TextInputForm extends Component {

  constructor(props, context) {
    super(props, context);
    this.getInputPlaceholderText = this.getInputPlaceholderText.bind(this);
    this.getSubmitText = this.getSubmitText.bind(this);
    this.state = {
      fieldValue: this.props.initialValue ? this.props.initialValue : ''
    }
  }

  static propTypes = {
    onSubmit: PropTypes.func,
    placeholderText: PropTypes.string,
    submitText: PropTypes.string,
    minLength: PropTypes.number,
    iconAddon: PropTypes.string,
    className: PropTypes.string,
    initialValue: PropTypes.string
  };

  getInputPlaceholderText() {
    return this.props.placeholderText ? this.props.placeholderText : '';
  }

  getSubmitText() {
    return this.props.submitText ? this.props.submitText  : '';
  }

  isInputValid() {
    const minLength = this.props.minLength ? this.props.minLength : 1;
    return (this.state.fieldValue && this.state.fieldValue.length >= minLength);
  }

  onSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    if (this.isInputValid()) {
      this.props.onSubmit(this.state.fieldValue);
    }
  }

  renderIcon(iconId) {
    switch(iconId) {
      case "search":
        return <IoSearch />
      default:
        return null;
    }
  }

  renderAddon() {
    if (this.props.iconAddon) {
      return (
        <InputGroup.Text>{this.renderIcon(this.props.iconAddon)}</InputGroup.Text>
      );
    }
    return null;
  }

  renderSubmit() {
    return (
      <InputGroup>
        <Button
          id="text-field-input"
          disabled={this.props.disabled || !this.isInputValid()}
          onClick={(e) => this.onSubmit(e)}
        >
          {this.getSubmitText()}
        </Button>
      </InputGroup>
    );
  }

  renderField() {
    return (
      <FormControl disabled={this.props.disabled} type="text" name="fieldValue" placeholder={this.getInputPlaceholderText()}
                   value={this.state.fieldValue} onChange={(e) => this.setState({fieldValue: e.target.value})}/>
    );
  }

  render() {
    return (
      <div>
        <form onSubmit={(e) => this.onSubmit(e)} className={this.props.className}>
          <FormGroup>
            <InputGroup>
              {this.renderAddon()}
              {this.renderField()}
              {this.renderSubmit()}
            </InputGroup>
          </FormGroup>
        </form>
      </div>
    );
  }
}

export default connect()(withTranslate(TextInputForm));
