import React, {Component, useEffect} from 'react';
import {Route, Routes, useNavigate, useLocation} from 'react-router-dom'
import {Roles, fillsRole} from '../data/roles';
import MePage from './pages/MePage';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import ContactPage from './pages/ContactPage';
import EditUserPage from './pages/EditUserPage';
import FullTestPage from './pages/FullTestPage';
import LoginFbNotConnectedPage from './pages/LoginFbNotConnectedPage';
import LoginTeacherPage from './pages/LoginTeacherPage';
import LoginEautokouluPage from './pages/LoginEautokouluPage';
import LoginEA2Page from './pages/LoginEA2Page';
import LoginAAIntegrationPage from './pages/LoginAAIntegrationPage';
import LoginAATeoriaIntegrationPage from './pages/LoginAATeoriaIntegrationPage';
import LoginAutoliittoPage from './pages/LoginAutoliittoPage';
import OrdersPage from './pages/OrdersPage';
import PracticeLiteralsPage from './pages/PracticeLiteralsPage';
import PracticeSignsPage from './pages/PracticeSignsPage';
import PracticeImagesPage from './pages/PracticeImagesPage';
import ResultPage from './pages/ResultPage';
import ResultsPage from './pages/ResultsPage';
import SubPagesContainer from './pages/SubPagesContainer';
import BookPage from './pages/BookPage';
import BookSimplePage from './pages/BookSimplePage';
import BookSelectionPage from './pages/BookSelectionPage';
import EmailRequestPage from './pages/EmailRequestPage';
import EmailRequestRedirectPage from './pages/EmailRequestRedirectPage';
import EmailRecoveryPage from './pages/EmailRecoveryPage';
import UserSettingsPage from './pages/UserSettingsPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import UserAddPage from './pages/UserAddPage';
import UsersListPage from './pages/UsersListPage';
import TeachersListPage from './pages/TeachersListPage';
import UsersSearchPage from './pages/UsersSearchPage';
import SchoolContactInfoPage from './pages/SchoolContactInfoPage';
import SchoolLimitsPage from './pages/SchoolLimitsPage';
import ShopFrontPage from './pages/shop/ShopFrontPage';
import OrderStatusPage from './pages/OrderStatusPage';
import ContactTrialPage from './pages/ContactTrialPage';
import DemoImagesPage from './pages/DemoImagesPage';
import CodeRedeemPage from './pages/CodeRedeemPage';
import TrackerAB from '../utils/trackerAB';
import TeachingPermitPage from './pages/shop/TeachingPermitPage';
import TractorPage from './pages/shop/TractorPage';
import MotorcyclePage from './pages/shop/MotorcyclePage';
import MopedCarPage from './pages/shop/MopedCarPage';
import ProductPage from './pages/shop/ProductPage';
import MopedPage from './pages/shop/MopedPage';
import TrailerPage from './pages/shop/TrailerPage';
import LicenseAgreement from './pages/LicenseAgreement';
import ShopDeliveryTermsPage from './pages/shop/ShopDeliveryTermsPage';
import LoginLiikenneopetusPage from "./pages/LoginLiikenneopetusPage";

const RequireLoginIndex = ({token, children}) => {
  return token ? children : <HomePage/>
};

const renderIndexRoute = (path, Component, token) => (
  <Route path={path} element={
    <RequireLoginIndex token={token}>
      <Component/>
    </RequireLoginIndex>
  }/>
)

const RequireLogin = ({token, children}) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!token) {
      return navigate('/login', {state: {redirect: location}})
    }
  }, [token, navigate, location])

  return token ? children : null;
}

const renderRequireLoginRoute = (path, Component, token) => (
  <Route path={path} element = {
    <RequireLogin token={token}>
      <Component/>
    </RequireLogin>
  }/>
)

const AnonymousRoute = ({children}) => {
  const path = null;
  TrackerAB.track('Anon path: ' + path);
  return children;
};

const renderAnonymousRoute = (path, Component) => (
  <Route path={path} element={
    <AnonymousRoute>
      <Component/>
    </AnonymousRoute>
  }/>
)

const RequireTeacherLogin = ({token, children, ...rest}) => {

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!token || !fillsRole(token.get('role'), Roles.TEACHER)) {
      return navigate('/login-teacher', {state: {redirect: location}})
    }
  }, [token, navigate, location])

  return token ? children : null;
};

const renderRequireTeacherLoginRoute = (path, Component, token, componentProps) => (
  <Route path={path} element={
    <RequireTeacherLogin token={token}>
      <Component {...componentProps}/>
    </RequireTeacherLogin>
  }/>
)

const AppRoutes = ({token}) => (
  <Routes>

    {renderIndexRoute('/', MePage, token)}

    {renderAnonymousRoute('/login', LoginPage)}
    {renderAnonymousRoute('/contact', ContactPage)}
    {renderAnonymousRoute('/contact', ContactPage)}
    {renderAnonymousRoute('/trial', ContactTrialPage)}
  
    {renderAnonymousRoute('/trial', ContactTrialPage)}
    {renderAnonymousRoute('/login-fb-not-connected', LoginFbNotConnectedPage)}
    {renderAnonymousRoute('/login-teacher', LoginTeacherPage)}
    {renderAnonymousRoute('/opettajat', LoginTeacherPage)}
    {renderAnonymousRoute('/opettaja', LoginTeacherPage)}
    {renderAnonymousRoute('/req', EmailRequestPage)}
    {renderAnonymousRoute('/req/:requestToken', EmailRequestRedirectPage)}
    {renderAnonymousRoute('/email-recovery', EmailRecoveryPage)}

    {renderAnonymousRoute('/kauppa', ShopFrontPage)}
    {renderAnonymousRoute('/opetuslupa', TeachingPermitPage)}
    {renderAnonymousRoute('/ajokorttikoulu', TeachingPermitPage)}
    {renderAnonymousRoute('/traktorikortti', TractorPage)}
    {renderAnonymousRoute('/moottoripyorakortti', MotorcyclePage)}
    {renderAnonymousRoute('/mopoautokortti', MopedCarPage)}
    {renderAnonymousRoute('/mopokortti', MopedPage)}
    {renderAnonymousRoute('/be-kortti-pikku-e', TrailerPage)}
    {renderAnonymousRoute('/tuoteinfo', ProductPage)}

    {renderAnonymousRoute('/liikennemerkit', PracticeSignsPage)}

    {renderAnonymousRoute('/order/:orderId/:token', OrderStatusPage)}
    {renderAnonymousRoute('/demo/images/:category', DemoImagesPage)}
    {renderAnonymousRoute('/redeem', CodeRedeemPage)}

    {renderAnonymousRoute('/login-ea2', LoginEA2Page)}
    {renderAnonymousRoute('/login-eautokoulu', LoginEautokouluPage)}
    {renderAnonymousRoute('/login-aateoria', LoginAATeoriaIntegrationPage)}
    {renderAnonymousRoute('/login-aa', LoginAAIntegrationPage)}
    {renderAnonymousRoute('/aa/sessions', LoginAAIntegrationPage)}
    {renderAnonymousRoute('/login-autoliitto', LoginAutoliittoPage)}
    {renderAnonymousRoute('/autoliitto/sessions', LoginAutoliittoPage)}
    {renderAnonymousRoute('/login-liikenneopetus', LoginLiikenneopetusPage)}

    {renderRequireLoginRoute('/fulltest', FullTestPage, token)}
    {renderRequireLoginRoute('/literals', PracticeLiteralsPage,token)}
    {renderRequireLoginRoute('/me', MePage, token)}
    {renderRequireLoginRoute('/result/:id', ResultPage, token)}
    {renderRequireLoginRoute('/signs', PracticeSignsPage, token)}
    {renderRequireLoginRoute('/images', PracticeImagesPage, token)}
    {renderRequireLoginRoute('/results', ResultsPage, token)}
    {renderRequireLoginRoute('/book', BookPage, token)}
    {renderRequireLoginRoute('/book/:chapterId', BookPage, token)}
    {renderRequireLoginRoute('/settings', UserSettingsPage, token)}

    {renderRequireTeacherLoginRoute('/users', SubPagesContainer, token, subMenuConfigs.users)}
    {renderRequireTeacherLoginRoute('/users/orders', OrdersPage, token)}
    {renderRequireTeacherLoginRoute('/users/:subPage' ,SubPagesContainer, token, subMenuConfigs.users)}
    {renderRequireTeacherLoginRoute('/user/:userId' ,EditUserPage, token)}
    {renderRequireTeacherLoginRoute('/orders' ,OrdersPage, token)}
    {renderRequireTeacherLoginRoute('/school-settings' ,SubPagesContainer, token,subMenuConfigs.schoolSettings)}
    {renderRequireTeacherLoginRoute('/school-settings/:subPage' ,SubPagesContainer, token,subMenuConfigs.schoolSettings)}
    {renderRequireTeacherLoginRoute('/results/:userId' ,ResultsPage, token)}
    {renderRequireTeacherLoginRoute('/books' ,BookSelectionPage, token)}
    {renderRequireTeacherLoginRoute('/books/:bookId/:language/:chapterId', BookSimplePage, token)}
    {renderRequireTeacherLoginRoute('/books/:bookId/:language/', BookSimplePage, token)}

    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
    <Route path="/cancelling-terms" element={<ShopDeliveryTermsPage/>}/>
    <Route path="/license-agreement" element={<LicenseAgreement/>}/>
    <Route path="*" element={<NotFoundPage/>}/>
  </Routes>
);

const subMenuConfigs = {
  users: {
    containerId: 'teacher-users',
    baseUrl: 'users/',
    subPageData: [
      {pageId: 'search', localizationId: 'search', component: UsersSearchPage},
      {pageId: 'add', localizationId: 'add', component: UserAddPage},
      {pageId: 'list', localizationId: 'list_users', component: UsersListPage},
      {pageId: 'teachers', localizationId:  'teachers', component: TeachersListPage},
    ],
    key: 'users'
  },
  schoolSettings: {
    containerId: 'teacher-school-settings',
    baseUrl: 'school-settings/',
    subPageData: [
      {pageId: 'contact', localizationId: 'school_contact_info', component: SchoolContactInfoPage},
      {pageId: 'limits', localizationId: 'practise_settings', component: SchoolLimitsPage}
    ],
    key:'school-settings'
  }
};

export default AppRoutes;
