import React, {Component} from 'react';
import withRouter from '../feature/withRouter';
import {connect} from 'react-redux'
import withTranslate from '../feature/withTranslate';
import {pageData} from '../../actions/actionCreators';
import SubMenu from '../feature/SubMenu';

/**
 * Wrap sub pages from router configs under one SubMenu.
 * Sub pages are just regular Pages with gutter automatically removed.
 */
class SubPagesContainer extends Component {

  constructor(props, context) {
    super(props, context);
    this.subMenuListener = this.subMenuListener.bind(this);
  }

  componentDidMount() {
    // Check if the current path overrides stored selected page index
    // or navigate to default if there is none set yet and path does not mandate anything
    const paramsSubId = this.props.match.params.subPage;
    if (paramsSubId) {
      // Set selected index to what path params define
      this.setSelectedIndex(this.getMenuIndex(paramsSubId));
    } else {
      if (this.props.selectedMenuIndex === undefined) {
        // No index defined yet, navigate to 0 index path
        this.setSelectedIndex(0);
        this.props.navigate(this.getSubPageFullPath(0), {replace: true});
      } else {
        this.props.navigate(this.getSubPageFullPath(this.props.selectedMenuIndex), {replace: true});
      }
    }
  }

  componentDidUpdate(prevProps) {
    const paramsSubId = this.props.match.params.subPage;
    if (paramsSubId) {
      const paramsIndex = this.getMenuIndex(paramsSubId);
      if (paramsIndex !== prevProps.selectedMenuIndex) {
        this.setSelectedIndex(paramsIndex);
      }
    }
  }

  getContainerId() {
    return this.props.containerId;
  }

  setSelectedIndex(index) {
    this.props.dispatch(pageData(this.getContainerId(), {selectedMenuIndex: index}));
  }

  getMenuIndex(subPageId) {
    let foundIndex = -1;
    this.props.subPageData.forEach((pageData, index) => {
      if (pageData.pageId === subPageId) {
        foundIndex = index;
      }
    });
    return foundIndex;
  }

  getSubPageData(menuIndex) {
    return this.props.subPageData[menuIndex];
  }

  getSubPageComponent(menuIndex) {
    const data = this.getSubPageData(menuIndex);
    return data ? data.component : null;
  }

  getSubPageFullPath(menuIndex) {
    const data = this.getSubPageData(menuIndex);
    return '/' + this.props.baseUrl + data.pageId;
  }

  subMenuListener(selectedIndex) {
    this.setSelectedIndex(selectedIndex);
    this.props.navigate(this.getSubPageFullPath(selectedIndex));
  }

  render() {
    const SubPageComp = this.getSubPageComponent(this.props.selectedMenuIndex);
    const subPageElem = SubPageComp ? <SubPageComp className='no-page-gutter'/> : null;

    return (
      <div className="page-container max-width-container">
        <SubMenu selectedIndex={this.props.selectedMenuIndex}
                 selectionListener={this.subMenuListener}
                 items={this.props.subPageData}
                 key={this.getContainerId()}
        />
        {subPageElem}
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  const containerId = ownProps.containerId;
  const pageState = state.pages[containerId] || {};
  const selectedMenuIndex = pageState.selectedMenuIndex;

  return {
    selectedMenuIndex: selectedMenuIndex,
  }
};

export default connect(mapStateToProps)(withRouter(withTranslate(SubPagesContainer)));
