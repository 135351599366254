import React from 'react';
import Button from 'react-bootstrap/Button';
import Translated from '../feature/Translated';
import {useNavigate} from 'react-router-dom';

const StartTrainingButton = (props) => {
  const navigate = useNavigate();
  return (
    <Button
      className={props.className}
      bsstyle={props.bsStyle}
      onClick={() => navigate('/fulltest')}>
      <Translated translationKey="start_training"/>
    </Button>
  )
};

export default StartTrainingButton;
