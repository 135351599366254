import React, {Component} from 'react';
import withTranslate from './withTranslate';
import {connect} from 'react-redux'
import { Link } from 'react-router-dom';

import {loginWithEmail, resetLoginError} from '../../actions/loginActions'
import Button from 'react-bootstrap/Button';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Translated from '../feature/Translated';
import Spinner from './../feature/Spinner';
import withRouter from './withRouter';
import classNames from 'classnames';

export class LoginFormEmail extends Component {

  constructor(props, context) {
    super(props, context);

    this.handleLoginTap = this.handleLoginTap.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.routeToPasswordRecovery = this.routeToPasswordRecovery.bind(this);

    this.state = {
      email: props.email || '',
      password: props.password || '',
      profileSchoolId: null
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetLoginError());
  }

  routeToPasswordRecovery(e) {
    this.props.navigate('/email-recovery');
  }

  handleLoginTap(e) {
    e ? e.preventDefault() : null;
    if (!this.state.email || !this.state.password) {
      return;
    }
    // TODO Validate input values
    this.props.dispatch(loginWithEmail(this.state.email, this.state.password, this.state.profileSchoolId));
  }

  handleInputChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  renderProfileSelection(profiles) {
    const shake = this.props.loginError && !this.props.isFetching;
    const formClasses = classNames('login-form-well', {shake}, this.props.className);

    let errorMessage;
    if (this.props.loginError) {
      errorMessage = (
          <Alert className={this.props.isFetching ? 'transparent' : null}>
            <Translated className="strong-text" translationKey="error-login"/><br/>
            <Translated translationKey={this.props.loginError}/>
          </Alert>
      )
    }

    const profileElems = profiles.map(p => {
      const id = p.get('schoolId');
      const name = p.get('schoolName');
      return (
          <div key={id} className="centered">
            <Button onClick={() => this.setState({profileSchoolId: id}, this.handleLoginTap)}>
              {name}
            </Button>
            <br/>
          </div>);
    });

    return (
        <Card id="login-form-email" className={formClasses}>
          <Card.Header>{this.props.header}</Card.Header>
          <Card.Body>
            <form id="email-login-form" onSubmit={this.handleLoginTap} method="post">
              <FormGroup onSubmit={this.handleLoginTap} className="centered">
                <div className="centered medium-bottom-margin">
                  <Translated translationKey="select_profile_school"/>
                </div>
                {profileElems}
                {errorMessage}
              </FormGroup>
            </form>
          </Card.Body>
        </Card>
    );
  }

  renderRecoveryLink(disabled) {
    if (disabled) {
      return null;
    }
    return (
        <div className="top-margin-small">
          <a onClick={this.routeToPasswordRecovery} className="touchable">
            <Translated translationKey="did_forget_password"/>
          </a>
          <div className="field-spacer"/>
        </div>
    );
  }

  render() {
    const t = this.props.translate;
    const shake = this.props.loginError && !this.props.isFetching;
    const formClasses = classNames('login-form-well', {shake}, this.props.className);

    let emailLabel;
    let passwordLabel;
    if (this.props.loginError) {
      emailLabel = <Translated className="input-label" translationKey="email"/>;
      passwordLabel = <Translated className="input-label" translationKey="password"/>;
    }
    const inputEmpty = !this.state.email || !this.state.password;

    let errorMessage;
    if (this.props.loginError) {
      if (this.props.isFetching) {
        errorMessage = null;
      } else {
        errorMessage = (
            <Alert className="top-margin-small">
              <Translated className="strong-text" translationKey="error-login"/>
              <br/>
              <Translated translationKey={this.props.loginError}/>
              <br/>
              {this.props.loginError === "user-account-expired-tp" && (
                  <Link to="/kauppa">
                    <Button>
                      <Translated translationKey="menu_shop"/>
                    </Button>
                  </Link>
              )}
            </Alert>
        );
      }
    }

    if (this.props.profiles) {
      return this.renderProfileSelection(this.props.profiles);
    }

    return (
        <Card id="login-form-email" className={formClasses}>
          <Card.Header>{this.props.header}</Card.Header>
          <Card.Body>
            <form id="email-login-form" onSubmit={this.handleLoginTap} method="post">
              <FormGroup onSubmit={this.handleLoginTap}>
                {emailLabel}
                <FormControl id="email-login-form-email" disabled={this.props.isFetching} type="email" name="email"
                             autoComplete="username"
                             placeholder={t('email')}
                             value={this.state.name} onChange={this.handleInputChange}/>
                <div className="field-spacer"/>
                {passwordLabel}
                <FormControl id="email-login-form-password" disabled={this.props.isFetching}
                             type="password" name="password" autoComplete="current-password"
                             placeholder={t('password')}
                             value={this.state.password} onChange={this.handleInputChange}/>

                {errorMessage}
                {this.renderRecoveryLink(this.props.disableRecovery)}
              </FormGroup>
              <Button
                  id="email-login-form-submit"
                  type="submit"
                  disabled={this.props.isFetching || inputEmpty}
                  className="submit-login-button strong-text"
                  onClick={this.handleLoginTap}>
                {this.props.isFetching ? <Spinner/> : t('login')}
              </Button>
            </form>
          </Card.Body>
        </Card>
    )
  }
}

function mapStateToProps(state) {
  return {
    token: state.login.get('token'),
    loginError: state.login.get('error'),
    loginErrorData: state.login.get('errorData'),
    loginProfiles: state.login.get('profiles'),
    isFetching: state.login.get('isFetching'),
    profiles: state.login.get('profiles')
  }
}

export default withRouter(connect(mapStateToProps)(withTranslate(LoginFormEmail)));
