import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Translated from './Translated';
import DateUtil from '../../utils/date';
import withRouter from './withRouter';
import Alert from 'react-bootstrap/Alert';
import {loadData, dataTypes} from '../../actions/apiDataActions'
import api from '../../api';
import Spinner from './Spinner';
import { IoMail, IoPerson } from 'react-icons/io5';

class UserCreatesPending extends Component {

  constructor(props, context) {
    super(props, context);
  }

  static propTypes = {
    schoolId: PropTypes.string.isRequired,
    refreshNum: PropTypes.number.isRequired
  };

  componentDidMount() {
    this.fetch(this.props.schoolId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.refreshNum !== this.props.refreshNum) {
      this.fetch(this.props.schoolId);
    }
  }

  fetch(schoolId) {
    this.props.dispatch(loadData(dataTypes.PENDING_USER_CREATES, api.pendingUserCreates, schoolId));
  }

  renderError(error) {
    return (
      <Alert><Translated translationKey={error}/></Alert>
    );
  }

  render() {
    if (this.props.isFetching) {
      return <Spinner />;
    }

    let error = this.props.error || null;
    if (error) {
      return this.renderError(error);
    }

    if (!this.props.requests) {
      return null;
    }

    error = this.props.error || null;
    if (error) {
      return this.renderError(error);
    }

    const pendingArr = this.props.requests;
    if (!pendingArr || pendingArr.length === 0) {
      return null;
    } else {
      return (
        <div className={this.props.className}>
          <h4><Translated translationKey="unactivated_requests"/></h4>
          <div className="white-box tight">
            <div className="custom-table full-width">
              <div className="custom-table-header">
                <div className="ct-column-name"><Translated translationKey="name"/></div>
                <div className="ct-column-name"><Translated translationKey="email"/></div>
                <div className="ct-column-name"><Translated translationKey="date"/></div>
                <div className="ct-column-name"><Translated translationKey="valid_until"/></div>
              </div>
              <div className="ct-table-body">{this.renderRequestElements(pendingArr)}</div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderRequestElements(pendingArr) {
    return pendingArr.map((req) => {
      let classes = req.hasExpired ? 'ct-table-row line-through' : 'ct-table-row';
      return (
        <div className={classes} key={req.id}>
          <div className="ct-table-body-cell">
            <span className="ct-mobile-only">
              <IoPerson />
            </span>
            &nbsp;{req.userName}
          </div>
          <div className="ct-table-body-cell">
            <span className="ct-mobile-only">
              <IoMail />
            </span>
            &nbsp;{req.email}
          </div>
          <div className="ct-table-body-cell">
            <strong className="ct-mobile-only">
              <Translated translationKey="date" />:
            </strong>
            &nbsp;{DateUtil.date(req.requestDate)}
          </div>
          <div className="ct-table-body-cell">
            <strong className="ct-mobile-only">
              <Translated translationKey="valid_until" />:
            </strong>
            &nbsp;{DateUtil.date(req.expiresAt)}
          </div>
        </div>
      );
    });
  }
}


let mapStateToProps = (state) => {
  const search = state.apiData[dataTypes.PENDING_USER_CREATES];
  return ({
    requests: search.data && search.data.requests,
    isFetching: search.isFetching,
    hasFetched: search.data && !search.isFetching,
    error: search.error
  });
};


export default connect(mapStateToProps)(withRouter(UserCreatesPending));
