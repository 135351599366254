import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {BsQuestion} from 'react-icons/bs';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import Translated from '../Translated';

class FullTestQuestionSelector extends Component {

  static propTypes = {
    answers: PropTypes.object.isRequired,
    questions: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    select: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showOverlay: false
    };
  }

  componentDidMount() {
    this.updateScroll();
  }

  componentDidUpdate() {
    this.updateScroll();
  }

  render() {
    const allowSelecting = Object.values(this.props.answers).length === this.props.questions.length;
    const items = this.props.questions.map((q, i) => {
      const isSelected = i === this.props.index;
      const isAnswered = this.props.answers[q.id] !== null;
      const isMarked = this.props.answers[q.id] === 'd';
      const classes = classNames('item', {'answered': isAnswered, 'selected': isSelected});
      const onClick = (e) => {
        if (allowSelecting || e.nativeEvent.altKey && e.nativeEvent.metaKey) {
          this.props.select(i);
        } else if (!this.state.showOverlay) {
         this.overlayTarget = e.target;
         this.setState({showOverlay: true});
         }
      };
      const onRef = e => {
        if (isSelected) {
          this.selectedItem = e;
        }
      };
      return (
          <div id="full-test-question-selector" key={i} className={classes} onClick={onClick} ref={onRef}>
            {isMarked ? <BsQuestion className="marked-icon"/> : <span>{(i + 1)}</span>}
          </div>
      )
    });
    return (
        <div id="full-test-question-selector-container" className="question-selector hide-xs"
             ref={e => this.questionSelector = e}>
          {items}
          {this.renderOverlay()}
        </div>
    )
  }

  renderOverlay() {
    const onHide = () => {
      this.setState({showOverlay: false});
    };
    return (
        <Overlay
            show={this.state.showOverlay}
            target={this.overlayTarget}
            placement="top"
            onHide={onHide}
            rootClose>
          <Popover id="question-selector-tooltip">
            <Popover.Body>
              <Translated translationKey="select_question_info"/>
            </Popover.Body>
          </Popover>
        </Overlay>
    )
  }

  updateScroll() {
    if (this.questionSelector && this.selectedItem) {
      const item = this.selectedItem;
      // Using item.scrollIntoView() causes unwanted vertical scrolling so scroll manually.
      if (item.offsetLeft < this.questionSelector.scrollLeft) {
        this.questionSelector.scrollLeft = item.offsetLeft;
      } else if (item.offsetLeft + item.clientWidth - this.questionSelector.scrollLeft > this.questionSelector.clientWidth) {
        this.questionSelector.scrollLeft = item.offsetLeft + item.clientWidth - this.questionSelector.clientWidth;
      }
    }
  }

}

export default FullTestQuestionSelector;