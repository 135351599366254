import React, {Component} from 'react';
import {connect} from 'react-redux';
import BookMenuItem from './BookMenuItem';
import MarkdownUtil from '../../utils/markdown';
import BookNavigation from './BookNavigation';
import ResultFeedbackForm from './results/ResultFeedbackForm';
import BookReadStatusMarkerSimple from './BookReadStatusMarkerSimple';
import withRouter from "./withRouter";

/**
 * Book browser. Shows book main menu and chapters.
 * This browser does not show book read status or possible additional content.
 */
class BookBrowserSimple extends Component {

  constructor(props, context) {
    super(props, context);
    this.openChapter = this.openChapter.bind(this);
    this.state = {
      chapterIndex: 0
    }
  }

  static getDerivedStateFromProps(props, state) {
    const index = props.chapters.findIndex(chapter => chapter.id === props.chapterId);
    if (index !== state.chapterIndex) {
      return {chapterIndex: index}
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.chapterIndex !== prevState.chapterIndex) {
      window.scrollTo(0, 0);
    }
  }

  getCurrentChapter() {
    const i = this.state.chapterIndex;
    return i >= 0 ? this.props.chapters[i] : null;
  }

  openChapter(index) {
    index = (index >= 0 && index < this.props.chapters.length) ? index : 0;
    const chapter = this.props.chapters[index];
    this.props.navigate(`/books/${this.props.bookId}/${this.props.language}/${chapter.id}`);
  }

  render() {
    if (this.state.chapterIndex > 0) {
      return this.renderChapter();
    } else {
      return this.renderMenu();
    }
  }

  renderChapter() {
    const chapter = this.getCurrentChapter();
    const navi = <BookNavigation openChapter={this.openChapter}
                                 current={this.state.chapterIndex}
                                 total={this.props.chapters.length - 1}/>;

    return (
      <div>
        <div className="book-content">
            {navi}
            {MarkdownUtil.render(chapter.markdown, chapter.id)}
          <ResultFeedbackForm key={chapter.id + '-feedback'} questionType="book-chapter" questionId={chapter.id} placeholderKey={'comment_book_chapter'}/>
          {navi}
          <BookReadStatusMarkerSimple key={chapter.id + '-status'} chapter={chapter}/>
        </div>
      </div>
    )
  }

  renderMenu() {
    let menuItems = this.props.chapters.map((chapter, i) => {
      if (i === 0) {
        return null;
      }
      return <BookMenuItem key={i}
                           index={i}
                           title={chapter.title}
                           onClick={() => this.openChapter(i)}
                           showStatus={false} />;
    });

    const menuChapter = this.props.chapters[0];

    return (
      <div className="fade-in">
        <h2>{menuChapter.title}</h2>
        <div className="book-menu-items">
          {menuItems}
        </div>
      </div>
    )
  }

  isRead(chapterId) {
    return this.state.status.some(status => status.materialContentId === chapterId);
  }
}

let mapStateToProps = (state) => {
  return ({
    locale: state.Intl.locale,
  });
};

export default withRouter(connect(mapStateToProps)(BookBrowserSimple));
