import React, {Component} from 'react';
import {connect} from 'react-redux';

export class FacebookLike extends Component {

  componentDidMount() {
    if (window.FB && this.props.fbReady) {
      window.FB.XFBML.parse();
    }
  }

  componentDidUpdate(prevProps) {
    if (window.FB && !prevProps.fbReady && this.props.fbReady) {
      window.FB.XFBML.parse();
    }
  }

  render() {
    const likeUrl = FACEBOOK_LIKE_URL;
    return (
      <div className="fb-like"
           data-href={likeUrl}
           data-layout="button"
           data-action="like"
           data-size="large"
           data-show-faces="false"
           data-share="false"
           data-colorscheme="dark">
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    fbReady: state.network.get('fbReady')
  };
};

export default connect(mapStateToProps)(FacebookLike);