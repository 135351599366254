import {localeToFbLocale} from '../data/availableLangsData';

class FacebookUtil {

  init(callback, locale, deferMs) {
    if (this.isInitialized) {
      callback();
    }

    this.props = {
      appId: FACEBOOK_APP_ID,
      language: localeToFbLocale[locale],
      fields: 'name',
      scope: 'public_profile, email',
      xfbml: true,
      version: 'v18.0',
      responseType: 'token'
    };
    this.initCallback = callback;
    this.setFbAsyncInit();

    if (!deferMs) {
      return this.setFbScript(this.props.language, this.props.version, this.props.appId);
    }

    setTimeout(this.setFbScript, deferMs, this.props.language, this.props.version, this.props.appId);
  }

  setFbScript(language, version, appId) {
    ((d, s, id) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      js.async = true; // Optimize page load speed
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = `https://connect.facebook.net/${language}/sdk.js#xfbml=1&version=${version}&appId=${appId}`;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  setFbAsyncInit() {
    const {appId, xfbml, version} = this.props;

    const params = {
      version,
      appId,
      xfbml
    };

    window.fbAsyncInit = () => {
      window.FB.init(params);
      this.isInitialized = true;
      this.initCallback();
    };
  }

  getMeData = (authResponse) => {
    const fields = this.props.fields;
    window.FB.api('/me', {fields}, (me) => {
      Object.assign(me, authResponse);
      this.loginCallback(me);
    });
  };

  loginHandler = (response) => {
    if (response.authResponse) {
      this.getMeData(response.authResponse);
    } else {
      if (this.loginCallback) {
        this.loginCallback({status: response.status});
      }
    }
  };

  login(callback, redirectState) {
    if (!window.FB) {
      return;
    }
    this.loginCallback = callback;
    const {scope, appId, responseType, language, version} = this.props;

    const redirectUri = window.location.origin + window.location.pathname;

    // let isMobile = false;
    // try {
    //   isMobile = ((window.navigator && window.navigator.standalone) || navigator.userAgent.match('CriOS') || navigator.userAgent.match(/mobile/i));
    // } catch (ex) {
    //   // continue regardless of error
    // }

    const params = {
      client_id: appId,
      redirect_uri: redirectUri,
      scope,
      response_type: responseType,
      locale: language,
      state: redirectState
    };

    // Mobile redirect should only used when javascript is disabled. In our case: never.
    // Remove mobile branch if all is working ok.
    // if (isMobile) {
    //   //window.location.href = `//www.facebook.com/${version}/dialog/oauth?${queryString.stringify(params)}`;
    // } else {
    //   window.FB.login(this.loginHandler, {scope, auth_type: params.auth_type});
    // }
    window.FB.login(this.loginHandler, {scope, auth_type: params.auth_type});
  };

  logout() {
    if (window.FB) {
      // WARNING: FB.logout does not seem to work!
      window.FB.getLoginStatus(resp => {
        if (resp.status === 'connected') {
          window.FB.logout(logoutResp => {});
        }
      });
    }
  }
}

const FBUtil = new FacebookUtil();

export default FBUtil;