import React, {Component} from 'react';
import ProductInCart from './ProductInCart';
import Tracker from '../../../utils/tracker';

class Cart extends Component {

  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    const productIds = this.props.products.map(p => p.id);
    Tracker.logEventLabel('webshop', 'cart-view', productIds.join());
  }

  render() {
    const productElems = this.props.products.map(product => {
      return (<ProductInCart key={product.id} product={product} onRemoveFromCart={this.props.onRemoveFromCart}/>)
    });

    return (
      <div className="product-cart-items fade-in">
        {productElems}
      </div>
    )
  }

}

export default Cart;

