import queryString from 'query-string';
import {
    LOCALIZATION_REQUEST,
    LOCALIZATION_SUCCESS,

} from '../actions/localizationActions';
import {availableLangsData} from '../data/availableLangsData';

const DEFAULT_LOCALE = BUILD_DEFAULT_LOCALE;

function setPersistedLocaleCode(localeCode) {
  try {
    localStorage.setItem('localeCode', localeCode);
  } catch (error) {
    // Most likely private browsing
  }
}

function getPersistedLocaleCode() {
  const locale = localStorage.getItem('localeCode');
  if (!locale || locale === '') {
    return DEFAULT_LOCALE;
  }
  return locale;
}

function getInitialLocaleCode() {
  const params = queryString.parse(window.location.search);
  let locale = params.lang;
  locale = locale ? locale : getPersistedLocaleCode();
  locale = validateLocale(locale);
  setPersistedLocaleCode(locale);
  return locale;
}

function validateLocale(locale) {
  locale = locale.toLowerCase();
  return availableLangsData.hasOwnProperty(locale) ? locale : DEFAULT_LOCALE;
}

const initialState = {
  locale: getInitialLocaleCode(),
  translations: {},
  fetchingLocale: null
};

function translationsReducer(state = initialState, action) {
  switch (action.type) {
    case LOCALIZATION_REQUEST:
      return {
          ...state,
          fetchingLocale: action.locale
      };

    case LOCALIZATION_SUCCESS:
      const translations = {...state.translations};
      translations[action.locale] = {messages: action.localizations};
      return {
          ...state,
          translations: translations,
          fetchingLocale: null
      };

    default:
      return state;
  }
}

export default translationsReducer;
