import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Translated from '../Translated';
import CategorySelector from '../CategorySelector';
import CategoryStart from '../CategoryStart';
import Spinner from '../Spinner';
import Alert from 'react-bootstrap/Alert';
import ResetVehicleCategoryButton from "../ResetVehicleCategoryButton";

class FullTestStart extends Component {

  static propTypes = {
    start: PropTypes.func.isRequired
  };

  renderContent() {
    if (!this.props.settings.get('isReceived')) {
      return <Spinner/>
    }

    const category = this.props.settings.get('vehicleCategory');
    if (!category) {
      return <CategorySelector/>;
    }

    const settingsData = this.props.settings.get('data');
    const categorySettings = settingsData.get('categorySettings').get(category);
    const isLanguageSupported = categorySettings.get('langs').indexOf(this.props.locale) !== -1;
    if (!isLanguageSupported) {
      return(<Alert><Translated translationKey="category_unsupported_lang"/></Alert>);
    } else {
      return (
          <div id="full-test-start-container">
            <div>
              <h3><Translated translationKey="full_test_title"/></h3>
              <Translated translationKey="full_test_description"/>
            </div>
            <br />
            <CategoryStart id="full-test-start" category={category} start={this.props.start}/>
            <ResetVehicleCategoryButton bsstyle="link"/>
          </div>
      )
    }
  }

  render() {
    return (
        <div id="full-test-start-page" className="max-width-container">
          {this.renderContent()}
        </div>
    )
  }
}

let mapStateToProps = (state) => {
  return ({
    locale: state.Intl.locale,
    settings: state.settings,
    token: state.login.get('token')
  });
};

export default connect(mapStateToProps)(FullTestStart);