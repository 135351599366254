import React, {Component} from 'react';
import LoginFormEmail from '../feature/LoginFormEmail'
import {connect} from 'react-redux';
import Translated from '../feature/Translated';
import withTranslate from '../feature/withTranslate';
import News from '../feature/News';
import Button from 'react-bootstrap/Button'
import LicenseAgreementNote from '../feature/LicenseAgreementNote';
import withRouter from "../feature/withRouter";

class LoginTeacherPage extends Component {

  constructor(props, context) {
    super(props, context);
  }

  componentDidUpdate(prevProps) {
    if (this.props.token && !prevProps.token) {
      // Login happened
      this.props.navigate('/', {state: this.props.location.state, replace: true});
    }
  }

  render() {
    return (
      <div id="teacher-login-page" className="page-container page-gutter max-width-container">
        <div className="front-center-wrapper top-margin">
          <h1><Translated translationKey="teacher"/></h1>
          <div className="login-form-container fade-in">
            <LoginFormEmail key='email-form' header={<Translated translationKey="login_teacher"/>}/>
            <br/>
            <div className="semi-dark-inner-box medium-top-margin">
              <Button aria-label="Request trial" role="button" bsstyle="link" onClick={()=> this.props.navigate('/trial')}>
                <Translated translationKey="trial_request"/>
              </Button>
            </div>
          </div>
          <News simple className="large-top-margin fade-in long-delay"/>
        </div>
        <LicenseAgreementNote className="semi-transparent large-top-margin bottom-margin self-centered"/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.login.get('token')
  }
}

export default withRouter(connect(mapStateToProps)(withTranslate(LoginTeacherPage)));
