import React, {Component} from 'react';
import ImageSet from '../ImageSet';
import PriceDisplay from './PriceDisplay';
import Translated from '../Translated';
import Button from 'react-bootstrap/Button';
import ProductInput from './ProductInput'
import BookPromoComponent  from './BookPromoComponent';
import ImagesComponent from './ImagesComponent';
import Tracker from '../../../utils/tracker';
import {IoClose} from 'react-icons/io5';
import withTranslate from './../withTranslate';
import classNames from 'classnames';
import TrackerAB from '../../../utils/trackerAB';
import saleImg from '../../../assets/webshop/ale_rot_left.png';

class ProductDetails extends Component {

  constructor(props, context) {
    super(props, context);
    this.onSetProductInputs = this.onSetProductInputs.bind(this);
    this.onInputsOpened = this.onInputsOpened.bind(this);
    this.onAddToCart = this.onAddToCart.bind(this);

    this.state = {
      productInputs: null,
      showInputErrors: false,
      showInputs: false
    }
  }

  componentDidMount() {
    Tracker.logEventLabel('webshop', 'product-details', this.props.product.id);

    const product = this.props.product;
    TrackerAB.track('webshop-show-product-details', {
      'productId': product.id,
      'productCategory': product.productData.category
    });
  }

  componentWillUnmount() {
    TrackerAB.track('webshop-show-product-details-closed');
  }

  onSetProductInputs(data) {
    this.setState({
      productInputs: data
    });
  }

  onInputsOpened() {
    TrackerAB.track('product-inputs-opened');
    this.setState({showInputs: true});
  }

  renderInputs(product) {
    if (!this.state.showInputs) {
      return <Button bsstyle="warning" onClick={this.onInputsOpened}><Translated className="strong-text" translationKey="buy"/></Button>
    }
    return (
      <div className="semi-dark-inner-box tight fade-in delay">
        <ProductInput setInput={this.onSetProductInputs} product={product} showErrors={this.state.showInputErrors}/>
      </div>
    );
  }

  renderPeriod(product) {
    const productData = product.productData;
    let content;
    if (productData.periodMonths) {
      content = <Translated translationKey="purchase_length_months" values={{periodMonths: productData.periodMonths}} />
    } else if (productData.periodDays) {
      content = <Translated translationKey="purchase_length_days" values={{periodDays: productData.periodDays}} />
    }
    return <div className="larger">{content}</div>
  }

  renderLeftSideComponents(product) {
    const components = product.productData ? product.productData.components : [];
    const componentElements = [];
    components.forEach(c => {
      switch (c.type) {
        case 'book':
          componentElements.push(<BookPromoComponent key={c.id} config={c}/>);
          break;
      }
    });
    return componentElements;
  }

  renderRightSideComponents(product) {
    const components = product.productData ? product.productData.components : [];
    const componentElements = [];
    components.forEach(c => {
      switch (c.type) {
        case 'image_preview':
          componentElements.push(<ImagesComponent key={c.id} config={c} />);
          break;
      }
    });
    return componentElements;
  }

  onAddToCart() {
    if (!this.state.productInputs) {
      return this.setState({showInputErrors: true})
    }

    this.props.onAddToCart(this.props.product, this.state.productInputs);
  }

  render() {
    const product = this.props.product;
    const category = product.productData.category;
    const imageSrc = '../../assets/category/' + category.toLowerCase() + '_white.png';
    const descriptionData = Object.assign({}, product, product.productData);
    const description = this.props.translate(product.localizations['description'], descriptionData);
    const classes = classNames('product-details-item', this.props.className);

    return (
      <div className={classes} key={product.id}>
        <div className='header'>
          <div>
            <ImageSet src={imageSrc} className='right-margin'/>
            <div className='title'>{product.localizations['title_short']}</div>
          </div>
          <div>
            <IoClose className='touchable largest'  onClick={this.props.onCancel}/>
          </div>
        </div>
        <div className='content-wrapper'>
          <div className='first-content'>
            <div dangerouslySetInnerHTML={{__html: description}}/>
            {this.renderPeriod(product)}
            {this.renderLeftSideComponents(product)}
          </div>
          <div className='second-content'>
            {this.renderRightSideComponents(product)}
            <div className='purchase-content top-margin'>
              <PriceDisplay size='large' price={product.vatPrice} className='inline'/>
            </div>
          </div>
        </div>
        <div>
          {this.renderInputs(product)}
          {this.state.showInputs
            ? (
            <Button onClick={this.onAddToCart} className='left-margin'>
              <Translated translationKey='next'/>
            </Button>
            ) : null}
        </div>
        {
          this.props.product.isSale
            ? <img src={saleImg} className="product-preview-sale-left top-margin-small"/>
            : null
        }
      </div>
    )
  }

}

export default withTranslate(ProductDetails);

