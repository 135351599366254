import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import withTranslate from '../feature/withTranslate';
import Translated from '../feature/Translated';
import Spinner from './../feature/Spinner';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ControlLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import DateUtil from '../../utils/date';
import classNames from 'classnames';
import api from '../../api';
import {loadData, dataTypes, saveData} from '../../actions/apiDataActions';

class SchoolContactInfoPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.handleSchoolInputChange = this.handleSchoolInputChange.bind(this);
    this.save = this.save.bind(this);
    this.state = {
      school: null
    }
  }

  componentDidMount() {
    const schoolId = this.props.token.get('schoolId');
    this.props.dispatch(loadData(dataTypes.SCHOOL_SETTINGS, api.getSchoolData, schoolId));
  }

  componentWillUnmount() {
    clearTimeout(this.okTimeout);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.settings !== this.props.settings) {
      const data = this.props.settings.data;
      if (data) {
        this.setState({school: data.school})
      }
    }
  }

  save() {
    const schoolId = this.props.token.get('schoolId');
    this.props.dispatch(saveData(dataTypes.SCHOOL_SETTINGS, api.setSchoolData, schoolId, this.state.school))
        .then(() => {
          if (this.props.settings.data) {
            this.okTimeout = setTimeout(() => this.setState({showOk: false}), 3000);
            this.setState({showOk: true})
          }
        })
  }

  handleSchoolInputChange(e) {
    this.updateSchoolState(e.target.name, e.target.value);
  }

  updateSchoolState(field, value) {
    const fieldOverlay = {};
    fieldOverlay[field] = value;
    const newSchoolObj = Object.assign(this.state.school, fieldOverlay);
    this.setState({school: newSchoolObj});
  }

  makeFormGroup(labelKey, fieldName, classes) {
    const label = this.props.translate(labelKey);
    classes = classNames('settings-field', classes);
    return (
        <Form.Group className="mb-3">
          <Form.Label>
            {label}
          </Form.Label>
          <Form.Control
              className={classes}
              name={fieldName}
              value={this.state.school[fieldName] ?? ''}
              onChange={this.handleSchoolInputChange}/>
        </Form.Group>
    );
  }

  renderForm() {
    const errorMessage = this.props.settings.error
        ? <Alert bsstyle="danger"><Translated translationKey={this.props.settings.error}/></Alert>
        : null;

    const saveOkMessage = this.state.showOk
        ? <Alert bsstyle="success"><Translated translationKey="ok"/></Alert>
        : null;

    const saveDisabled = this.props.settings.isSending;

    return (
        <div className="white-box">
          <form>

            <fieldset disabled={this.props.settings.isSending}>

              {this.makeFormGroup('street_address', 'streetAddress')}
              {this.makeFormGroup('zip_code', 'postalNumber', 'settings-limited-field')}
              {this.makeFormGroup('postal_location', 'postalLocation', 'settings-limited-field')}
              {this.makeFormGroup('phone_number', 'phoneNumber', 'settings-limited-field')}
              {this.makeFormGroup('email', 'email')}

              <h4 className="medium-top-margin"><Translated translationKey="invoice_details"/></h4>
              {this.makeFormGroup('invoice_receiver', 'invoiceName')}
              {this.makeFormGroup('street_address', 'invoiceStreetAddress')}
              {this.makeFormGroup('zip_code', 'invoicePostalNumber', 'settings-limited-field')}
              {this.makeFormGroup('postal_location', 'invoicePostalLocation', 'settings-limited-field')}
              {this.makeFormGroup('cost_center', 'invoiceCostCenter')}

              <h4 className="medium-top-margin"><Translated translationKey="e_invoice_details"/></h4>
              {this.makeFormGroup('e_invoice_address', 'eInvoiceAddress')}
              {this.makeFormGroup('e_invoice_operator_code', 'eInvoiceOperatorCode')}

              <div className="centered top-margin">
                {errorMessage
                    ? errorMessage
                    : saveOkMessage}
                <Button id="school-settings-submit-button" disabled={saveDisabled}
                        onClick={this.save}>
                  {this.props.settings.isSending ? <Spinner/> : <Translated translationKey="save"/>}
                </Button>
              </div>
            </fieldset>
          </form>
        </div>
    );
  }

  render() {
    const school = this.state.school;
    if (!school || this.props.settings.isFetching) {
      return null;
    }

    const closingDateElem = (school.closingDate && school.closingDate !== '')
        ? (<Translated translationKey="license_valid" values={{'valid_until': DateUtil.date(school.closingDate)}}/>)
        : null;

    return (
        <div id="school-settings-page"
             className={classNames('page-container max-width-container fade-in', this.props.className)}>
          <h3 className="medium-top-margin">{school.name} (ID: {school.id.toUpperCase()})</h3>
          <div>
            <Translated translationKey="vat_id"/>: {school.vatID}
          </div>
          {closingDateElem}
          {this.renderForm()}
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    token: state.login.get('token'),
    settings: state.apiData[dataTypes.SCHOOL_SETTINGS],
  });
};

const composed = compose(
    connect(mapStateToProps),
    withTranslate
);

export default composed(SchoolContactInfoPage);
