import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import PreloadMiddlePage from './../pages/PreloadMiddlePage';
import Line from '../feature/ProgressBarLine';
import Translated from '../feature/Translated';
import SignsStart from '../feature/practise/signs/SignsStart';
import SignsQuestionFromImage from '../feature/practise/signs/SignsQuestionFromImage';
import SignsQuestionFromText from '../feature/practise/signs/SignsQuestionFromText';
import PractiseResults from '../feature/practise/PractiseResults';
import {loadData, dataTypes} from '../../actions/apiDataActions'
import api from '../../api';
import withPracticeTypes from '../feature/withPracticeTypes';

class PracticeSignsPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.setAnswer = this.setAnswer.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.start = this.start.bind(this);
    this.state = {
      started: false,
      questionIndex: 0,
      answers: [],
      seenIds: []
    }
  }

  setAnswer(optionId) {
    const answers = this.state.answers.slice();
    answers.push(optionId);

    const seenIds = this.state.seenIds.slice();
    const question = this.props.signs.data.questions[this.state.questionIndex];
    // Mark as seen if answered correctly, otherwise it may be asked even on the very next round again.
    if (question.correctAnswer === optionId) {
      if (this.state.seenIds.indexOf(question.id) === -1) {
        seenIds.push(question.id);
        if (seenIds.length > 30) {
          seenIds.shift();
        }
      }
    }

    this.setState({answers, seenIds});
  }

  nextQuestion() {
    const nextIndex = this.state.questionIndex + 1;
    this.setState({
      questionIndex: nextIndex
    });

    if (nextIndex === this.props.signs.data.questions.length) {
      let faults = 0;
      this.props.signs.data.questions.forEach((question, index) => {
        let answer = this.state.answers[index];
        if (answer !== question.correctAnswer) {
          faults++;
        }
      });
      this.setState({faults});
      if (this.props.hasToken && this.props.settings.get('isReceived')) {
        api.sendPracticeLog(this.props.settings.get('vehicleCategory'), 'signs', this.props.signs.data.questions.length, faults);
      }
    }
  }

  fetchQuestions() {
    this.props.dispatch(loadData(dataTypes.PRACTICE_SIGNS, api.createPracticeSigns, this.props.locale, this.state.seenIds));
  }

  start() {
    this.fetchQuestions();
    this.setState({
      started: true,
      questionIndex: 0,
      answers: []
    });
  }

  renderQuestion() {
    const question = this.props.signs.data.questions[this.state.questionIndex];
    const answer = this.state.questionIndex < this.state.answers.length ? this.state.answers[this.state.questionIndex] : null;
    const current = this.state.questionIndex + 1;
    const total = this.props.signs.data.questions.length;
    const progressRatio = current / total;

    const format = this.state.questionIndex % 2 === 0 ? 'text-question' : 'image-question';
    let questionElement = null;

    if (format === 'text-question') {
      questionElement = (
        <SignsQuestionFromText
          question={question}
          index={this.state.questionIndex}
          setAnswer={this.setAnswer}
          answer={answer}
          key={question.id}
        />
      );
    } else {
      questionElement = (
        <SignsQuestionFromImage
          question={question}
          index={this.state.questionIndex}
          setAnswer={this.setAnswer}
          answer={answer}
          key={question.id}
        />
      );
    }

    return (
      <div className="page-container">
        <div className="max-width-container">
          <div className="practise-wrapper">
            <div className="practise-progress-wrapper fade-in long-delay">
              <Line
                className="yellow"
                percentage={progressRatio * 100}
              />
            </div>
            {questionElement}
            {answer !== null
              ? (
                <div className="practise-next-wrapper min-button-space pull-right">
                  <Button id="practise-signs-next-button" onClick={this.nextQuestion} className="fade-in delay">
                    <Translated translationKey="next"/>
                  </Button>
                </div>)
              : <div className="practise-next-wrapper min-button-space"></div>}
          </div>
        </div>
      </div>
    )
  }

  renderPublicStart() {
    return (
      <Fragment>
        <h1><Translated translationKey="public_signs_title"/></h1>
        <div className="blue-inner-box tight">
          <Translated translationKey="public_signs_description"/>
        </div>
        <Button id="practise-signs-start" onClick={this.start} className="self-centered horizontal-padding medium-top-margin">
          <Translated translationKey="start"/>
        </Button>
      </Fragment>
    );
  }

  renderResults() {
    const total = this.props.signs.data.questions.length;
    const correctCount = total - this.state.faults;
    return <PractiseResults correct={correctCount}
                            total={total}
                            restart={this.start}
                            buttonId="practise-signs-restart"/>
  }

  render() {
    let content;
    if (this.props.hasToken && !this.props.settings.get('isReceived')) {
      content = <PreloadMiddlePage/>
    } else if (!this.state.started) {
      if (this.props.hasToken) {
        content = <SignsStart start={this.start}/>
      } else {
        content = this.renderPublicStart();
      }
    } else if (this.props.signs.error) {
      content = <PreloadMiddlePage error={this.props.signs.error}/>
    } else if (this.props.signs.isFetching || !this.props.signs.data.questions) {
      content = <PreloadMiddlePage/>
    } else if (this.state.questionIndex < this.props.signs.data.questions.length) {
      content = this.renderQuestion();
    } else {
      content = this.renderResults();
    }

    return (
      <div id="practise-signs-page" className="page-container max-width-container">
        {content}
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return ({
    settings: state.settings,
    signs: state.apiData[dataTypes.PRACTICE_SIGNS],
    locale: state.Intl.locale,
    hasToken: !!state.login.get('token')
  });
};

export default connect(mapStateToProps)(withPracticeTypes(PracticeSignsPage));
