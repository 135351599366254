import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Translated from './../feature/Translated';
import withRouter from './withRouter';
import Tracker from '../../utils/tracker';

class ErrorModal extends Component {

  componentDidMount() {
    Tracker.logEventLabel('error', 'error-modal-shown', this.props.errorKey);
  }

  onCloseClick() {
    if (this.props.onClick) {
      this.props.onClick();
    } else {
      this.props.navigate('/');
    }
  }

  render() {
    return (
      <div>
        <Modal show={this.props.show}>
          <Modal.Body>
            <Translated translationKey={this.props.errorKey}/>
          </Modal.Body>
          <Modal.Footer>
            <Button id="close-error-modal-button" onClick={() => this.onCloseClick()}>
              <Translated translationKey="close"/>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default withRouter(ErrorModal);
