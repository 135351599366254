import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ProgressBarLine extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const percentage = this.props.percentage;
    const barClasses = classNames('bar', this.props.className);

    return (
      <div className="progressbar-line">
        <div
          style={{width: percentage + '%'}}
          className={barClasses}
        >
        </div>
      </div>
    );
  }
}

ProgressBarLine.propTypes = {
  percentage: PropTypes.number,
};

export default ProgressBarLine

