import React, {Component} from 'react';
import {connect} from 'react-redux';
import MarkdownUtil from '../../utils/markdown';
import PreloadMiddlePage from './PreloadMiddlePage';
import Translated from '../feature/Translated';
import Button from 'react-bootstrap/Button';
import { FiBook } from "react-icons/fi";
import withRouter from "../feature/withRouter";

/**
 * Menu page to select a book from all available books.
 * Books in different languages show up as different books.
 */
class BookSelectionPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.selectBook = this.selectBook.bind(this);
  }

  selectBook(bookId, language) {
    this.props.navigate(`/books/${bookId}/${language}`);
  }

  renderBookItem(book) {
    const key = book.get('id') + book.get('lang');
    return (
      <div key={key} className="book-content centered cover medium-bottom-margin">
        {MarkdownUtil.render(book.get('markdown'))}
        <Button onClick={() => this.selectBook(book.get('id'), book.get('lang'))}>
          <FiBook />
          <Translated translationKey="read_book"/>
        </Button>
      </div>
    )
  }

  render() {
    if (!this.props.settings.get('isReceived')) {
      return <PreloadMiddlePage />;
    }

    const books = this.props.settings.get('data').get('availableBooks');
    const booksWithLicense = books.filter(book => book.get('hasLicense') === true);
    const bookItems = [];
    booksWithLicense.forEach(book => {
      bookItems.push(this.renderBookItem(book));
    });

    return (
      <div id="book-page" className="page-container">
        <div className="max-width-container centered">
          {bookItems}
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return ({
    settings: state.settings,
  });
};

export default withRouter(connect(mapStateToProps)(BookSelectionPage));

