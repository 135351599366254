import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ProfileStudent from '../feature/ProfileStudent';
import ProfileAutoliitto from '../feature/ProfileAutoliitto';
import ActiveBookStatus from '../feature/ActiveBookStatus';
import News from '../feature/News';
import UserStatusLoaderWrapper from '../feature/UserStatusLoaderWrapper';
import UserResultsPreview from '../feature/UserResultsPreview';
import UserCategoryStatus from '../feature/UserCategoryStatus';
import CategorySelector from './../feature/CategorySelector';
import StartTrainingButton from '../feature/StartTrainingButton';
import ResetVehicleCategoryButton from '../feature/ResetVehicleCategoryButton';

class MeIntegrationPage extends Component {

  static propTypes = {
    integrationPlatform: PropTypes.string,
    allowFacebook: PropTypes.bool
  };

  static defaultProps = {
    integrationPlatform: 'none',
    allowFacebook: false,
  };

  renderCategoryStatus() {
    const selectedCategory = this.props.settings.get('vehicleCategory');
    if (selectedCategory === null) {
      return (<CategorySelector className="fade-in delay"/>)
    }

    return (
      <div>
        <UserStatusLoaderWrapper category={selectedCategory}>
          <UserCategoryStatus className="fade-in delay">
            <StartTrainingButton />
            <ResetVehicleCategoryButton bsstyle="link"/>
          </UserCategoryStatus>
        </UserStatusLoaderWrapper>
        <ActiveBookStatus className="fade-in delay"/>
        <UserStatusLoaderWrapper category={selectedCategory}>
          <UserResultsPreview className="fade-in long-delay"/>
        </UserStatusLoaderWrapper>
      </div>
    );
  }

  render() {
    const integrationPlatform = this.props.integrationPlatform;

    let profile;
    if (integrationPlatform === 'autoliitto') {
      profile = <ProfileAutoliitto allowFacebook={this.props.allowFacebook} className="user-profile-wrapper max-width-container page-gutter"/>
    } else if (integrationPlatform === 'aaintegration') {
      profile = <ProfileStudent showClosingDate={false} allowFacebook={this.props.allowFacebook} className="user-profile-wrapper max-width-container page-gutter"/>
    } else  {
      profile = <ProfileStudent showClosingDate={true} allowFacebook={this.props.allowFacebook} className="user-profile-wrapper max-width-container page-gutter"/>
    }

    return (
      <div id="me-student-page" className="page-container max-width-container no-page-gutter">
        <div className="user-profile-bg fade-in">
          {profile}
        </div>
        <div className="max-width-container page-gutter">
          {this.renderCategoryStatus()}
          <News/>
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return ({
    settings: state.settings,
  });
};


export default connect(mapStateToProps)(MeIntegrationPage);
