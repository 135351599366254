import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ProfileStudent from '../feature/ProfileStudent';
import UserStatusLoaderWrapper from '../feature/UserStatusLoaderWrapper';
import UserResultsPreview from '../feature/UserResultsPreview';
import UserCategoryStatus from '../feature/UserCategoryStatus';
import CategorySelector from './../feature/CategorySelector';
import ActiveBookStatus from '../feature/ActiveBookStatus';
import StartTrainingButton from '../feature/StartTrainingButton';
import News from '../feature/News';
import ResetVehicleCategoryButton from '../feature/ResetVehicleCategoryButton';

export class MeStudentPage extends Component {

  static propTypes = {
    allowFacebook: PropTypes.bool
  };

  static defaultProps = {
    allowFacebook: false,
  };

  renderCategoryStatus() {
    const selectedCategory = this.props.settings.get('vehicleCategory');
    if (selectedCategory === null) {
      return (<CategorySelector/>)
    }

    return (
      <div>
        <UserStatusLoaderWrapper category={selectedCategory}>
          <UserCategoryStatus className="fade-in delay">
            <StartTrainingButton />
            <ResetVehicleCategoryButton bsstyle="link"/>
          </UserCategoryStatus>
        </UserStatusLoaderWrapper>
        <ActiveBookStatus className="fade-in delay"/>
        <UserStatusLoaderWrapper>
          <UserResultsPreview role="student" className="fade-in long-delay"/>
        </UserStatusLoaderWrapper>
      </div>
    );
  }

  render() {
    return (
      <div id="me-student-page" className="page-container no-page-gutter">
        <div className="user-profile-bg fade-in">
          <ProfileStudent showClosingDate={true} allowFacebook={this.props.allowFacebook}
                          className="user-profile-wrapper max-width-container page-gutter"/>
        </div>
        <div className="max-width-container page-gutter">
          <News/>
          {this.renderCategoryStatus()}
        </div>
      </div>
    );
  }
}


let mapStateToProps = (state) => {
  return ({
    settings: state.settings,
  });
};

export default connect(mapStateToProps)(MeStudentPage);
