class EmailUtil {
  /**
   * Really simple email validation
   * @param email
   * @returns {boolean}
   */
  static isEmail (email) {
    const atIndex = email.indexOf('@');
    if (atIndex === -1) {
      return false;
    }
    const lastDotIndex = email.lastIndexOf('.');
    return (lastDotIndex !== -1 && lastDotIndex > atIndex);
  }

  static resolveNameFromEmail(email) {
    let name = email.substr(0, email.indexOf('@'));
    name = name.replace('.', ' ');
    // Capital first letters
    return name.replace(/\w\S*/g, function(name){return name.charAt(0).toUpperCase() + name.substr(1).toLowerCase();});
  }

}

export default EmailUtil;