import React from 'react';
import Translated from './Translated';
import {useNavigate} from 'react-router-dom';

const LicenseAgreementNote = (props) => {
  const navigate = useNavigate();
    return (
      <div className={props.className}>
        <Translated translationKey="license_agreement_label" className="touchable" onClick={() => navigate('/license-agreement')}/>
      </div>
    );
}

export default LicenseAgreementNote;
