class SettingsUtil {

  static hasBookForCurrentCategory(settings) {
    let selectedCategory = settings.get('vehicleCategory');
    if (selectedCategory === null) {
      return false;
    } else {
      selectedCategory = selectedCategory.toLocaleLowerCase();
    }

    const data = settings.get('data');
    if (!data) {
      return null;
    }
    const books = data.get('availableBooks');

    return books.some(book => {
      if (book.get('hasLicense')) {
        const bookCategory = book.get('category').toLowerCase();
        return SettingsUtil.categoryContainsBookCategory(selectedCategory, bookCategory);
      } else {
        return false;
      }
    });
  };

  static categoryContainsBookCategory(selectedCategory, bookCategory) {
    // Book's category must match the selected category (OLB can use B book though, ugly bypass here).
    return bookCategory === selectedCategory || (bookCategory === 'b' && selectedCategory === 'olb');
  }

  static hasLicensedBooks(settings) {
    const data = settings.get('data');
    if (!data) {
      return null;
    }
    const books = data.get('availableBooks');
    if (!books) {
      return null;
    }
    return books.some(book => {
      return book.get('hasLicense') === true;
    });
  }
}

export default SettingsUtil;

