import React, {Component} from 'react';
import Translated from './Translated';
import withRouter from './withRouter'

class DataPrivacyNote extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <Translated translationKey="data_privacy_label" className="touchable" onClick={() => this.props.navigate('/privacy-policy')}/>
      </div>
    );
  }
}

export default withRouter(DataPrivacyNote);
