import React from 'react';

const LicenseAgreement = () => {
  return content;
};

const content = (
  <div className="page-container max-width-container">
    <p><strong><u>Ajokaista käyttöoikeussopimus 24.6.2019</u></strong></p>

    <p>
      <strong>1.Ehtojen soveltaminen ja voimassaolo</strong><br/>
      Näitä sopimusehtoja sovelletaan Ajokaistan (jäljempänä palveluntarjoaja) asiakkailleen
      toimittamaan ajokaista.com palveluun ja sen käyttäjätunnuksiin.<br/>
      Ehdot astuvat voimaan 24.6.2019 ja ovat voimassa toistaiseksi.</p>

    <p><strong>2. Sopimuksen syntyminen ja sopimuksen siirtäminen.</strong><br/>
      Sopimus syntyy kun asiakas tilaa palvelun käyttöönsä ja asiakkaalle toimitetaan palvelun käyttöön tarvittavat opettajan käyttäjätunnukset.
      Palveluntarjoajalla on oikeus siirtää sopimus ja/tai palvelut
      kolmannelle osapuolelle, säilyttäen kuitenkin palvelun olennaisen sisällön ja palvelun tason.
    </p>

    <p><strong>3. Sopimuskauden pituus ja sopimuksen purku</strong><br/>
      Sopimus on voimassa toistaiseksi, jonka aikana käyttöönotetut oppilaiden tunnukset ovat veloitettavia käyttöönottohetkellä olevan hinnaston mukaisesti.
      Asiakas voi pyytää tunnustensa sulkemista ylläpidolta, jonka jälkeen uusia tunnuksia ei enää voi ottaa käyttöön.
      Jo käyttöönotetut tunnukset ovat voimassa enintään 12 kuukautta käyttöönotosta, mutta niiden pituutta voi rajoittaa myös harjoittelun
      hyväksyminen (vaadittujen harjoitusmäärien hyväksytty suorittaminen) tai koko koulun tunnusten sulkeutuminen.</p>

    <p>Asiakkaalla on oikeus purkaa sopimus, mikäli sovittua palvelua ei voida ylläpitää. Tämä
      ei kuitenkaan koske Internet-verkkoa ja sen liikenteeseen liittyviä satunnaisia häiriöitä tai
      laitevikoja, jotka kohtuullisessa ajassa on korjattavissa.</p>

    <p>Palveluntarjoaja varaa oikeuden irtisanoa sopimuksen ilman irtisanomisaikaa, mikäli asiakas rikkoo
      sopimusta tai palvelun tuottamista ei ole mahdollista jatkaa palveluntarjoajasta riippumattomista syistä
      (esimerkiksi kansallinen hätätila tai muu force majeure - tilanne). Tällöin asiakkaalle
      hyvitetään sopimuskauden käyttämättä jääneen osan palvelumaksu.</p>

    <p><strong>4. Palvelun toimittaminen</strong><br/>
      Palveluntarjoaja avaa palvelun asiakkaalle tilauksen jälkeen ja toimittaa viipymättä palvelun käytössä
      tarvittavan määrän avaimia, sekä opettajan käyttötunnukset. Asiakas hyväksyy
      toimituksen, ellei siitä reklamoida kirjallisesti välittömästi.</p>

    <p><strong>5. Palvelun sisältö ja tuottaminen, asiakkaan oikeudet ja velvollisuudet</strong><br/>
      Palvelun tarjoaja tuottaa palvelun haluamallaan tavalla ja varaa oikeuden muuttaa palvelun sisältöä
      esim. vastaamaan muuttunutta kuljettajantutkinnon teoriakoetta tai päivittää sisältöä
      korjatakseen virheitä ja lisätäkseen materiaalia. Olennaisesti palvelua muuttavat toimenpiteet
      palveluntarjoaja tiedottaa etukäteen pääsäntöisesti opettajan sivun kautta tai sähköpostilla.
      Välittömästi tehtäviä muutoksia voidaan tehdä ilman tiedotetta, mikäli ne
      korjaavat tietoturvaa tai palvelun laatua. Tällöin ilmoitus tehdään jälkikäteen ja
      mahdollisesti palvelussa tarvittavat uudet tunnukset tms. toimitetaan asiakkaalle viipymättä.</p>

    <p>Asiakas on velvollinen ilmoittamaan palvelussa havaitut ongelmat ensi tilassa kirjallisesti. Palveluntarjoajan
      velvollisuus korvauksiin rajoittuu enintään yhden (1) kuukauden aikana käyttöönotettuihin tunnuksiin hinnaston mukaisesti. Tätä
      pidemmissä tapauksissa asiakkaalla on oikeus irtisanoa sopimus.</p>

    <p>Palveluntarjoaja toimittaa palvelun, jossa asiakas ja asiakkaan opetuksessa olevat oppilaat voivat harjoitella
      kuljettajantutkinnon teoriaosaa. Palvelu pyrkii valmentamaan Traficomin:n järjestämään teoriakokeeseen
      T-, AM120-, AM121-, A-, B-, BE-, C1, C1E-, C-ja D-ajokorttiluokan tasoilla. Palvelun tarjoaja pyrkii oikeellisuuteen
      materiaalissa, mutta ei vastaa sisällössä mahdollisesti olevien virheiden aiheuttamasta
      haitasta.</p>

    <p>Asiakas on velvollinen valvomaan mahdollisuuksien rajoissa käyttäjätunnusten käyttöä ja luovuttaa käyttäjätunnukset vain
      kertaalleen yhdelle oppilaalle. Asiakas myös vastaa kertakäyttöavainten turvallisesta säilytyksestä ja on
      velvollinen korvaamaan kadotetuista tai varastetuista avaimista aiheutuneet kulut. Asiakkaan vastuu avaimista
      päättyy tämän ilmoitettua kadonneet avaimet palveluntarjoajalle. Käyttäjätunnukset ovat oppilaan henkilökohtaiset, joilla
      oppilas voi itsenäisesti harjoitella. Palveluntarjoajalla on oikeus sulkea käyttämättömiä
      kertakäyttöavaimia kahdentoista kuukauden kuluttua avainten toimituksesta, käytettyjä käyttäjätunnuksia kahdentoista
      kuukauden kuluttua edellisestä kyseisellä tunnuksella kirjautumisesta tai mitä tahansa käyttäjätunnuksia välittömästi
      sopimuksen päätyttyä. Käyttäjätunnukset ovat otettu käyttöön, kun niiden käyttäjä on ensimmäisen kerran
      kirjautunut palveluun.</p>

    <p>Asiakas tekee sopimuksen vain itselleen, eikä asiakkaalla ole oikeutta tarjota palvelua edelleen muille kuin
      oppilaskirjassa mainituille oppilailleen.
      <br/>
      <br/>
      Tarvittaessa palveluntarjoaja voi velvoittaa asiakkaan toimittamaan kirjallisen kopion osasta oppilaskirjaa, jolla
      oppilasmäärä todistetaan. Käytettyjä tunnuksia ei lunasteta takaisin.</p>

    <p>Palvelun materiaali on asiakkaan käytössä vain sopimuskauden aikana ja vain palvelua käyttämällä.
      Kuvan, tekstin tai muun materiaalin irroittaminen palvelusta on kiellettyä. Palveluntarjoaja omistaa oikeudet
      kaikkeen materiaaliin.</p>

    <p>Mikäli asiakkaan Internet-liikenteen tai serveriresurssien käyttö poikkeaa huomattavasti
      tavanomaisesta ja aiheuttaa ylimääräisiä kustannuksia palveluntarjoajalle, on
      palveluntarjoajalla oikeus sulkea avaimia tai tehdä teknisiä muutoksia rajoittamaan käyttö
      normaalille tasolle. Pakottavassa tilanteessa voidaan irtisanoa sopimus.</p>

    <p>Asiakas on velvollinen seuraamaan palveluista annettavia tiedotuksia, jotka pääsääntöisesti
      annetaan Palvelun opettajan sivun kautta tai sähköpostilla.</p>

    <p><strong>6. Palveluun vaadittava laitteisto</strong><br/>
      Palvelua voidaan käyttää tietokoneella jossa on:</p>
    <ul>
      <li>Internet-yhteys ja enintään kolme vuotta vanha internet-selain</li>
      <li>Evästeet käytössä (engl. cookies)</li>
      <li>Javascript kytkettynä käyttöön.</li>
    </ul>

    <p><strong>7. Yksityisyyden suoja</strong><br/>
      Palveluntarjoaja pitää tietokantaa palvelua käyttävien oppilaiden nimistä helpottaakseen
      asiakkaan mahdollista oppilaiden seurantaa ja vähentääkseen palvelun väärinkäyttöä.
      Oppilas antaa nimensä kirjautuessaan palveluun. Nimi poistuu tietokannasta kun käyttäjätunnus poistetaan. Mahdollisista
      turvakopioista nimi ei välttämättä poistu. Palveluntarjoajan ei ole luvallista myydä käyttäjien
      tietoja eteenpäin, muutoin kuin sopimuksen siirtämistarkoituksessa. Palvelussa on mahdollista esittää
      mainoksia, joiden esittäminen ei perustu yksityistietoihin ja jotka ovat yleiset mainosperiaatteet huomioon
      ottaen sopivia.</p>

    <p><strong>8. Laskutus</strong><br/>
      Laskutus tapahtuu neljä kertaa vuodessa. Asiakas
      vastaa laskujen maksusta niiden eräpäivään mennessä.</p>

    <p>Palveluntarjoajalla on oikeus katkaista palvelu ilman eri korvausta, mikäli maksua ei ole suoritettu eräpäivään
      mennessä. Palveluntarjoaja varaa myös oikeuden irtisanoa sopimuksen, mikäli maksua ei ole suoritettu
      välittömästi huomautuksen jälkeen.</p>

    <p>Palveluntarjoajalla on oikeus periä viivästyneistä suorituksista korkolain mukainen viivästyskorko,
      sekä kulloinkin voimassaolevan hinnaston mukainen maksukehoitusmaksu. Asiakas on myös velvollinen
      korvaamaan maksujensa laiminlyönnistä mahdollisesti aiheutuvat perintätoimistojen palkkiot ja muut oikeudelliset
      kulut.</p>

    <p>Palvelun tarjoajalla on oikeus muuttaa palvelun hintoja yksi kuukausi ennen uuden hinnaston astumista voimaan.
      Asiakkaalla on tällöin oikeus irtisanoa palvelu päättymään ennen
      uuden hinnoittelun voimaan astumista. Tiedoitus hinnaston muutoksesta tapahtuu pääasiassa sähköpostitse.</p>

    <p><strong>9. Muut ehdot</strong><br/>
      Sopimukseen sovelletaan Suomen lakia. Erimielisyydet pyritään ensisijaisesti ratkaisemaan neuvottelemalla.
      Ellei tämä ole mahdollista, oikeuspaikkana on Espoon käräjäoikeus.</p>
  </div>
);

export default LicenseAgreement;

