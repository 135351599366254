import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import api from '../../api';
import Spinner from '../feature/Spinner';
import Translated from '../feature/Translated';
import Alert from 'react-bootstrap/Alert';
import DateFormat from '../../utils/date';
import Tracker from '../../utils/tracker';
import LocalStorageUtil from '../../utils/localStorageUtil';
import TrackerAB from '../../utils/trackerAB';
import withRouter from "../feature/withRouter";

export class OrderStatusPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.goToShop = this.goToShop.bind(this);
    TrackerAB.track('webshop-receipt');
    this.state = {
      isValidating: true,
      error: null,
      status: this.props.match.params.status,
      receipt: this.props.receipt
    };
  }

  componentDidMount() {
    // Clear the pending order from local storage
    LocalStorageUtil.setForUser('anon', 'pending-order', null);
    if (this.state.receipt) {
      return;
    }

    this.setState({isValidating: true});
    const {orderId, token} = this.props.match.params;
    api.getOrder(orderId, token)
      .then(resp => {
        if (resp.error) {
          return this.setState({
            isValidating: false,
            error: resp.error
          })
        }

        if (!resp.data && !resp.status) {
          // No receipt data nor status -> order cancelled before going to bank
          return this.goToShop();
        }

        this.setState({
          isValidating: false,
          receipt: resp
        });

        if (resp.data) {
          const orderId = resp.data.orderId;
          const totalPrice = resp.data.totalPrice;
          Tracker.logWebshopPurchase(orderId, totalPrice);
        }
      });
  }

  goToShop() {
    this.props.navigate('/kauppa', {replace: true});
  }

  render() {
    let stateContent;
    if (this.state.error) {
      stateContent = <Alert bsstyle="danger"><Translated translationKey={this.state.error}/></Alert>;
    } else if (this.state.isValidating) {
      stateContent = <Spinner/>
    } else if (this.state.receipt) {
      const receipt = this.state.receipt;
      if (receipt.data) {
        stateContent = this.renderReceipt();
      } else if (receipt.status === 'fail') {
        stateContent = this.renderCancelled();
      } else {
        stateContent = this.renderPending();
      }
    }
    return (
      <div id="shop-page" className="page-container max-width-container">
        {stateContent}
      </div>
    )
  }

  renderReceipt() {
    const receipt = this.state.receipt.data;
    const actions = this.state.receipt.actions;
    return (
      <div>
        <h2><Translated translationKey="purchase_completed_title"/></h2>
        {this.renderActionResults(actions)}

        <Translated translationKey="order_id"/>: {receipt.orderId}
        <br/>
        <Translated translationKey="delivery_date"/>: {DateFormat.dateTime(receipt.deliveryDate)}

        {this.renderProducts(receipt.products)}

        <div className="top-margin">
          <Translated translationKey="order_total_price" values={{totalPrice: receipt.totalPrice.toFixed(2)}}/>
          <br/>
          <Translated translationKey="payment_method"/>: {receipt.paymentMethod}
        </div>

        <div className="top-margin">
          <Translated translationKey="receipt_sent" values={{email: receipt.customerEmail}}/>
        </div>

        <Button onClick={this.goToShop} className="large-top-margin">
          <Translated translationKey="back_to_shop"/>
        </Button>
      </div>
    )
  }

  renderActionResults(actions) {
    return actions.map((action, i) => {
      switch (action.type) {
        case 'create_email_credentials':
          return (
            <div className="blue-inner-box tight" key={i}>
              <Translated translationKey="registration_link_sent" values={{email: action.email}} html={true}/>
            </div>
          )
      }
    });
  }

  renderProducts(products) {
    const productElems = products.map((product, i) => (
        <div className="receipt-product" key={i}>
          {product.title}
          <Translated translationKey="order_product_price"
                      values={{vatPrice: product.vatPrice.toFixed(2), vatPercentage: product.vatPercentage}}/>
        </div>
      )
    );
    return (
      <div className="top-margin">
        <Translated translationKey="order_products"/>
        {productElems}
      </div>
    )
  }

  renderCancelled() {
    return (
      <div>
        <h2><Translated translationKey="purchase_cancelled_title"/></h2>
        <div className="blue-inner-box tight">
          <Translated translationKey="purchase_cancelled_info"/>
        </div>
        <div>
          <Button onClick={this.goToShop}>
            <Translated translationKey="back_to_shop"/>
          </Button>
        </div>
      </div>
    )
  }

  renderPending() {
    return (
      <div>
        <h2><Translated translationKey="purchase_pending_title"/></h2>
        <div className="blue-inner-box tight">
          <Translated translationKey="purchase_pending_info"/>
        </div>
        <div>
          <Button onClick={this.goToShop}>
            <Translated translationKey="back_to_shop"/>
          </Button>
        </div>
      </div>
    )
  }

}

export default withRouter(OrderStatusPage);
