import {createStore, applyMiddleware} from 'redux';
import { thunk } from "redux-thunk";
import rootReducer from './reducers/index';

const store = createStore(rootReducer, applyMiddleware(thunk));

// TODO Check if this working or not
if (module.hot) {
  module.hot.accept('./reducers/', () => {
    const nextRootReducer = require('./reducers/index').default;
    store.replaceReducer(nextRootReducer);
  });
}

export default store;
