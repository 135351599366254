import React, {Component} from 'react';
import {connect} from 'react-redux'
import {pageData} from '../../actions/actionCreators';
import {loadData, dataTypes} from '../../actions/apiDataActions'
import OptionsMenu from '../feature/OptionsMenu';
import Spinner from '../feature/Spinner';
import Translated from '../feature/Translated';
import withTranslate from '../feature/withTranslate';
import Alert from 'react-bootstrap/Alert';
import classNames from 'classnames';
import api from '../../api';
import Tracker from '../../utils/tracker';
import UserList from '../feature/UserList';

const PAGE_ID = 'USERS_LIST_PAGE';

class UsersListPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.onRuleSelected = this.onRuleSelected.bind(this);
    this.itemData = [
      {id: 'latest-accepted-students', localizationId:'last_approved_students'},
      {id: 'latest-activated-students', localizationId:'latest_activated_students'},
      {id: 'all-active-students', localizationId:'all_active_students'},
      {id: 'latest-added-students', localizationId:'latest_added_students'},
    ];
  }

  componentDidMount() {
    if (!this.props.selectedRuleIndex) {
      this.onRuleSelected(0, this.itemData[0].id);
    }
  }

  onRuleSelected(index, id) {
    const schoolId = this.props.token.get('schoolId');
    this.setState({selectedRuleIndex: index});
    this.props.dispatch(pageData(PAGE_ID, {selectedRuleIndex: index}));

    switch (id) {
      case 'latest-added-students':
        this.props.dispatch(loadData(dataTypes.LIST_USERS, api.listLatestAddedStudents, schoolId));
        Tracker.logEvent('search', 'list-latest-added-students');
        break;
      case 'latest-activated-students':
        this.props.dispatch(loadData(dataTypes.LIST_USERS, api.listLatestActivatedStudents, schoolId));
        Tracker.logEvent('search', 'list-latest-activated-students');
        break;
      case 'latest-accepted-students':
        this.props.dispatch(loadData(dataTypes.LIST_USERS, api.getLatestAcceptedUsers, schoolId));
        Tracker.logEvent('search', 'list-latest-added-students');
        break;
      case 'all-active-students':
        this.props.dispatch(loadData(dataTypes.LIST_USERS, api.getAllActiveStudents, schoolId));
        Tracker.logEvent('search', 'list-all-active-students');
        break;
    }
  }

  renderUserList() {
    if (!this.props.hasFetched) {
      return null;
    }
    if (this.props.isFetching) {
      return <Spinner className="primary"/>;
    }
    return (
      <div>
        {/*<h4><Translated translationKey={this.itemData[this.state.selectedRuleIndex].localizationId}/></h4>*/}
        <UserList users={this.props.users} noUsersTranslationKey="no_search_results" className="fade-in"/>
      </div>
    )
  }

  render() {
    if (!this.props.token) {
      return null;
    }
    if (this.props.selectedRuleIndex === undefined) {
      return null;
    }
    const classes = classNames('page-container max-width-container fade-in', this.props.className);
    return (
      <div id="user-list-page" className={classes}>
        {/*<h3><Translated translationKey='list_students_by_rules'/></h3>*/}
        <OptionsMenu
          itemData={this.itemData}
          selectedIndex={this.props.selectedRuleIndex}
          onSelect={this.onRuleSelected}
          className="blue-inner-box tight white"
        />
        {this.props.error
          ? <Alert><Translated translationKey={this.props.error}/></Alert>
          : null
        }
        {this.renderUserList()}
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  const pageState = state.pages[PAGE_ID] || {};
  const selectedRuleIndex = pageState.selectedRuleIndex;
  const search = state.apiData[dataTypes.LIST_USERS];
  return {
    users: search.data ? search.data.users : null,
    isFetching: search.isFetching,
    hasFetched: search.data && !search.isFetching,
    error: search.error,
    token: state.login.get('token'),
    selectedRuleIndex,
  }
};

export default connect(mapStateToProps)(withTranslate(UsersListPage));
