import React, {Component} from 'react';
import {IoLogoFacebook} from 'react-icons/io5';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import FacebookUtil from '../../utils/FacebookUtil';
import {fbProcessing} from '../../actions/actionCreators';

class FacebookLoginButton extends Component {

  static propTypes = {
    callback: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    redirectState: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);
    this.onClick = this.onClick.bind(this);
  }

  render() {
    return (
      <Button aria-label="Login with Facebook" role="button"
              disabled={!this.props.fbReady || this.props.fbProcessing}
              onClick={this.onClick} size="lg" className={this.props.className}>
        <IoLogoFacebook />
        <span>{this.props.label}</span>
      </Button>
    )
  }

  onClick() {
    this.props.dispatch(fbProcessing(true));
    FacebookUtil.login(response => {
      this.props.dispatch(fbProcessing(false));
      this.props.callback(response);
    }, this.props.redirectState);
  }
}

function mapStateToProps(state) {
  return {
    fbReady: state.network.get('fbReady'),
    fbProcessing: state.network.get('fbProcessing')
  }
}

export default connect(mapStateToProps)(FacebookLoginButton);