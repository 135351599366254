import React, {Component} from 'react';
import withTranslate from '../withTranslate';
import TextInput from '../TextInput';
import EmailUtil from '../../../utils/EmailUtil';
import Translated from '../Translated';
import api from '../../../api';
import DateUtil from '../../../utils/date';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from '../Spinner';

class ProductInputEmailCredential extends Component {

  constructor(props, context) {
    super(props, context);
    this.clearEmails = this.clearEmails.bind(this);
    this.state = {
      email: '',
      emailAgain: '',
      name: '',
      userData: null,
      isVerifying: false,
      isVerified: false,
      error: 'invalid-input'
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isVerified && this.state.isVerified && this.areInputsValid(this.state)) {
      this.props.addComponentInput(this.props.component.id, {email: this.state.email.trim(), name: this.state.name.trim()});
    }
  }

  areInputsValid(state) {
    let error;
    if (!state.name.trim()) {
      error= 'invalid-input';
    } else if (!EmailUtil.isEmail(state.email.trim())) {
      error = 'invalid_email_form';
    } else if (state.email.trim().toLowerCase() !== state.emailAgain.trim().toLowerCase()) {
      error = 'email-mismatch';
    }
    if (error) {
      this.setState({error});
    }
    return !error;
  }

  verifyEmail(email) {
    this.setState({isVerifying: true});
    api.verifyEmail(email)
      .then(response => {
        if (response.error) {
          return this.setState({
            error: response.error,
            isVerifying: false
          });
        }
        if (response.email !== this.state.email) {
          this.setState({
            isVerifying: false,
            email: response.email,
            emailAgain: response.email
          });
        }
        if (response.userData) {
          this.setState({
            userData: response.userData,
            isVerified: (response.userData.schoolId === 'teachingpermit'), //Previous account from a webshop purchase should not display an error.
            isVerifying: false,
            error: null
          });
        } else {
          this.setState({
            isVerified: true,
            isVerifying: false,
            error: null
          })
        }
      })
  }

  updateInputValue(prop, value) {
    const newState = {...this.state, [prop]: value};
    this.setState(newState);
    if (this.areInputsValid(newState)) {
      this.verifyEmail(newState.email);
    } else {
      this.props.addComponentInput(this.props.component.id, null);
      this.setState({userData: null, isVerified: false})
    }
  }

  renderComponent(component) {
    const emailText = this.props.translate('email_address');
    const emailAgainText = this.props.translate('email_address_again');
    const nameText = this.props.translate('name');

    return (
      <div key={component.id}>
        <h3><Translated translationKey={component.title}/></h3>

        <div className="first-content bottom-margin">
          <Translated translationKey="email_credentials_info"/>
        </div>

        <TextInput listener={name => this.updateInputValue('name', name)}
                   label={nameText}
                   placeholder={nameText}
                   value={this.state.name}
                   className="long-input-field"/>

        <TextInput
          listener={email => {
            email = email.replace(/ /g, '');
            this.updateInputValue('email', email);
          }}
          type="email"
          label={emailText}
          placeholder={emailText}
          value={this.state.email}
          className="long-input-field"/>

        <TextInput
          listener={email => {
            email = email.replace(/ /g, '');
            this.updateInputValue('emailAgain', email);
          }}
          type="email"
          label={emailAgainText}
          placeholder={emailAgainText}
          value={this.state.emailAgain}
          className="long-input-field"/>
        {this.renderError()}
        {this.renderEmailVerification()}
      </div>
    );
  }

  clearEmails() {
    this.setState({email: '', emailAgain: '', userData: null, isVerified: false})
  }

  renderError() {
    return this.props.showErrors && this.state.error && <Alert><Translated translationKey={this.state.error}/></Alert>;
  }

  renderEmailVerification() {
    if (this.state.isVerifying) {
      return <Spinner/>
    }

    const userData = this.state.userData;
    if (!userData || this.state.isVerified) {
      return null;
    }

    return (
      <Alert>
        <Translated
          translationKey="email_override_warning_shop"
          values={{
            email: this.state.email,
            userName: userData.userName,
            schoolName: userData.schoolName,
            createdDate: DateUtil.date(userData.createdDate),
          }}/>
        <div className="top-margin">
          <Button bsstyle="info" onClick={() => this.setState({isVerified: true})}>
            <Translated translationKey="continue"/>
          </Button>

          <Button className='left-margin' onClick={this.clearEmails}>
            <Translated translationKey="cancel"/>
          </Button>
        </div>

      </Alert>
    );
  }

  render() {
    return (
      <div>
        {this.renderComponent(this.props.component)}
      </div>
    )
  }

}

export default withTranslate(ProductInputEmailCredential);

