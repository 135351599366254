import React, {Component} from 'react';
import {Navigate} from 'react-router-dom';
import withRouter from "../feature/withRouter";

class EmailRequestRedirectPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      requestToken: this.props.match.params.requestToken,
    };
  }

  render() {
    return <Navigate to={`/req?token=${this.state.requestToken}`} replace={true} />
  }
}

export default withRouter(EmailRequestRedirectPage);
