import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Header from './feature/Header';
import Footer from './feature/Footer';
import FullTestFooter from './feature/fulltest/FullTestFooter';
import {connectionStatus, maintenanceStatus, fbReady} from '../actions/actionCreators';
import Modal from 'react-bootstrap/Modal';
import Translated from './feature/Translated';
import Button from 'react-bootstrap/Button';
import Tracker from '../utils/tracker';
import {getSettings, resetSettings} from '../actions/settingsActions';
import FacebookUtil from '../utils/FacebookUtil';
import withRouter from './feature/withRouter';
import AppRoutes from './AppRoutes';
import LoginMethodsModal from './feature/LoginMethodsModal';
import queryString from 'query-string';
import {setAdId, setReferral, setLocallyStoredReferral} from '../actions/referralActions';
import WebFont from 'webfontloader';
import {withCookies} from 'react-cookie';
import TrackerAB from '../utils/trackerAB';
import {tokenUpdated} from '../actions/authActions';
import api from '../api';
import TermsOfServicePage from './pages/TermsOfServicePage';

const App = (props) => {
  const {dispatch, locale, location, token, cookies, history, hasConnection} = props;
  const [locationState, setLocationState] = useState(null);

  // TODO Move this to index.js?
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Ubuntu:300,400,700'],
        urls: 'https://fonts.googleapis.com/css?'
      }
    });
  }, []);

  // Initialize FB Util once we have a locale
  useEffect(() => {
    if (locale) {
      FacebookUtil.init(() => dispatch(fbReady(true)), locale, 2000);
    }
  }, [locale, dispatch, token])

  // Parse AD and referral ids from url params.
  useEffect(() => {
    if (!location) {
      return;
    }
    const urlParams = queryString.parse(location.search);
    const adId = urlParams['adid'];
    const referralId = urlParams['referralid'];
    const authToken = urlParams['jwt'];

    const machineId = cookies.get('mid');
    if (authToken) {
      dispatch(tokenUpdated(authToken));
      api.logDirectTokenLogin(authToken);
    }
    if (adId) {
      dispatch(setAdId(adId));
    }
    if (referralId) {
      dispatch(setReferral(referralId));
    }
    setLocallyStoredReferral(referralId, adId);
    TrackerAB.register({adId, machineId, referralId});
  }, [location, dispatch, cookies])

  // Everytime location changes
  useEffect(() => {
    const pathname = location.pathname;
    Tracker.logPageView(pathname);

    setLocationState(location.state);
  }, [location])

  useEffect(() => {
    if (token) {
      Tracker.setUser(token.get('userId'));
    }
    dispatch(resetSettings());
  }, [token, dispatch])

  useEffect(() => {
    const hasToken = !!token;
    const path = location.pathname;
    const isOnLoginRoute = path.indexOf('/login') === 0 || path.indexOf('/sessions') !== -1;

    if (hasToken && !isOnLoginRoute) {
      // Load settings after token is received
      // but do not do it on any of the login pages, as there might be an old token available.
      dispatch(getSettings(token.get('userId')));
    }
  }, [token, location, dispatch])

  useEffect(() => {
    if (token) {
      const userId = token.get('userId');
      Tracker.setUser(userId);
    } else {
      Tracker.setUser();
    }
  }, [token]);

  useEffect(() => {
    if (!hasConnection) {
      Tracker.logEvent('error', 'no-connection');
    }
  }, [hasConnection])

  const showModal = !props.hasConnection || props.hasMaintenance;
  let modalMessage;
  let modalAction;
  if (showModal) {
    if (!props.hasConnection) {
      modalMessage = 'network-error';
      modalAction = connectionStatus(true);
    } else {
      modalMessage = 'maintenance';
      modalAction = maintenanceStatus(false);
    }
  }

  const showTos = props.requiredTos && props.requiredTos !== props.acceptedTos
  const pages = showTos ? <TermsOfServicePage/> : <AppRoutes token={props.token}/>;
  const footer = props.location.pathname === '/fulltest' ? <FullTestFooter/> : <Footer/>;
  return (
    <div className="body-container-wrapper">
      <React.StrictMode>
        <div className="body-container">
          <Header className="header-container" location={props.location}/>
          {pages}
          {footer}
        </div>
        <LoginMethodsModal/>
        <Modal show={showModal}>
          <Modal.Body>
            {modalMessage && <Translated translationKey={modalMessage}/>}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => props.dispatch(modalAction)}>
              <Translated translationKey="close"/>
            </Button>
          </Modal.Footer>
        </Modal>
      </React.StrictMode>
    </div>
  )

}

let mapStateToProps = (state) => {
  return {
    token: state.login.get('token'),
    hasConnection: state.network.get('hasConnection'),
    hasMaintenance: state.network.get('hasMaintenance'),
    locale: state.Intl.locale,
    requiredTos: state.login.get('requiredTos'),
    acceptedTos: state.login.get('acceptedTos')
  };
};

export default withCookies(withRouter(connect(mapStateToProps)(App)));
