import React, {Component} from 'react';
import {connect} from 'react-redux';
import Translated from './Translated';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BookContent from './BookContent';
import ResultFeedbackForm from './results/ResultFeedbackForm';
import { FiBook } from "react-icons/fi";
import Circle from './ProgressBarCircle';
import classNames from 'classnames';

class BookContentModal extends Component {

  constructor(props, context) {
    super(props, context);
    if (this.props.bookStatus) {
      const progress = this.props.bookStatus.progress;
      this.state = {
        initialPercentage: this.getPercentage(progress),
        progress: progress
      }
    } else {
      this.state = {}
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bookStatus !== this.props.bookStatus && this.props.bookStatus) {
      clearTimeout(this.progressTimeout);
      this.progressTimeout = setTimeout(() => {
        this.setState({progress: this.props.bookStatus.progress})
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.progressTimeout);
  }

  close() {
    this.props.onClose();
  }

  getPercentage(progress) {
    return Math.ceil(progress * 100); // Ceiling is used to get immediate progress for user
  }

  renderBookContent() {
    const source = this.props.content;
    return (
      <div className="book-content">
        <BookContent contentId={this.props.contentId} source={source}/>
      </div>
    );
  }

  renderHeader() {
    let content;

    if (this.props.showId) {
      content = <Modal.Title>{this.props.contentId}</Modal.Title>;
    } else if (this.props.showStatus && this.props.bookStatus) {
      const percentage = this.getPercentage(this.state.progress);
      const animate = percentage !== this.state.initialPercentage;
      const progressClasses = classNames("progressbar-small progressbar-light-bg", {'pop delay': animate});

      content = (
        <div className="book-modal-header">
          <FiBook />&nbsp;{this.props.bookStatus.title}
          <div className="left-margin">
            <Circle percentage={Math.max(percentage, 1)}
                    className={progressClasses}
                    initialAnimate={animate}
                    progressText={percentage + '%'}/>
          </div>
        </div>
      )
    }
    return (
      <Modal.Header closeButton>
        {content}
      </Modal.Header>
    )
  }

  renderFeedbackForm() {
    if (!this.props.token) {
      return null;
    }
    return <ResultFeedbackForm questionType="book-section"
                               questionId={this.props.contentId}
                               placeholderKey={'comment_book_chapter'}/>
  }

  render() {
    return (
      <Modal id="book-content-modal" show={true} className="book-content-modal gray-light" onHide={() => this.close()}>
        {this.renderHeader()}
        <Modal.Body>
          {this.renderBookContent()}
        </Modal.Body>
        <Modal.Footer>
          {this.renderFeedbackForm()}
          <Button
            id="close"
            onClick={() => {
              this.close()
            }}
          >
            <Translated translationKey="close"/>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

let mapStateToProps = (state) => {
  return ({
    token: state.login.get('token')
  });
};

export default connect(mapStateToProps)(BookContentModal);

