import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fetchFullTest} from '../../actions/fullTestApiUtils';
import classNames from 'classnames';
import PreloadMiddlePage from './PreloadMiddlePage';
import FullTestMain from '../feature/fulltest/FullTestMain';
import FullTestStart from '../feature/fulltest/FullTestStart';
import FullTestContinue from '../feature/fulltest/FullTestContinue';
import Logger from '../../utils/logger';

export class FullTestPage extends Component {

  static MAX_AGE_HOURS = 48;
  static SAVE_REVISION = 1;

  constructor(props, context) {
    super(props, context);
    this.tryInit = this.tryInit.bind(this);
    this.start = this.start.bind(this);
    this.restart = this.restart.bind(this);
    this.restore = this.restore.bind(this);
    this.saveState = this.saveState.bind(this);
    this.state = {
      initialized: false
    };
  }

  tryInit() {
    if (this.state.initialized || !this.props.token || !this.props.settings.get('isReceived')) {
      return;
    }
    const savedState = this.loadState();
    if (savedState) {
      this.setState({
        showContinue: true,
        saveData: savedState
      });
    } else {
      this.setState(FullTestPage.getInitialState());
    }
    this.setState({initialized: true});
  }

  static getInitialState() {
    return {
      isFetching: false,
      test: null,
      started: false,
      showContinue: false,
      loadError: null,
      saveData: null
    }
  }

  componentDidMount() {
    this.tryInit();
  }

  componentDidUpdate() {
    if (!this.state.initialized) {
      return this.tryInit();
    }
  }

  saveState(testState) {
    if (!this.props.token) {
      return;
    }
    try {
      const category = this.props.settings.get('vehicleCategory');
      const userId = this.props.token.get('userId');
      const data = {
        timestamp: Date.now(),
        userId: userId,
        category: category,
        testState: testState,
        testData: this.state.test,
        rev: FullTestPage.SAVE_REVISION
      };
      const jsonString = JSON.stringify(data);
      localStorage.setItem('fulltest_state', jsonString);
    } catch (e) {
      // Ignore, possible private mode
      // Logger.info('Could not set full test state to local storage', e);
    }
  }

  removeSavedState() {
    try {
      localStorage.removeItem('fulltest_state');
    } catch (e) {
      Logger.info('Could not remove full test saved state from local storage', e);
      // Ignore, possible private mode
    }
  }

  loadState() {
    if (!this.props.token) {
      return null;
    }

    try {
      const jsonString = localStorage.getItem('fulltest_state');
      if (jsonString) {
        const data = JSON.parse(jsonString);
        const category = this.props.settings.get('vehicleCategory');
        const userId = this.props.token.get('userId');
        if (!data || data.userId !== userId || data.category !== category) {
          return null;
        }
        if (data.rev !== FullTestPage.SAVE_REVISION) {
          this.removeSavedState();
          return null;
        }
        if (Date.now() - data.timestamp < FullTestPage.MAX_AGE_HOURS * 3600 * 1000) {
          return data;
        } else {
          this.removeSavedState();
        }
      }
    } catch (e) {
      this.removeSavedState();
      return null;
    }
    return null;
  }

  start() {
    this.setState({isFetching: true});
    fetchFullTest(this.props.settings.get('vehicleCategory'), this.props.locale)
        .then(testData => {
          this.setState({
            isFetching: false,
            test: testData,
            started: true,
          });

        })
        .catch(e => {
          this.setState({
            isFetching: false,
            loadError: e
          });
        });
  }

  restart() {
    this.removeSavedState();
    this.setState(FullTestPage.getInitialState());
  }

  restore() {
    this.setState({
      showContinue: false,
      started: true,
      test: this.state.saveData.testData
    });
  }

  render() {
    if (!this.state.initialized || this.state.isFetching || this.state.loadError) {
      return <PreloadMiddlePage error={this.state.loadError}/>
    }
    let content;
    let className;
    if (this.state.showContinue) {
      content = <FullTestContinue restart={this.restart}
                                  restore={this.restore}/>;
    } else if (!this.state.started) {
      content = <FullTestStart start={this.start}/>;
    } else {
      const state = this.state.saveData ? this.state.saveData.testState : null;
      content = <FullTestMain test={this.state.test}
                              save={this.saveState}
                              removeSave={this.removeSavedState}
                              initState={state}/>;
      className = 'no-page-gutter';
    }
    return (
        <div className={classNames('page-container full-test-page', className)}>
          {content}
        </div>
    );
  }

}

let mapStateToProps = (state) => {
  return ({
    settings: state.settings,
    locale: state.Intl.locale,
    token: state.login.get('token')
  });
};

export default connect(mapStateToProps)(FullTestPage);
