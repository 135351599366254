import React from 'react';

function getPriceParts(price) {
  const integerPart = Math.floor(price);
  let centsPart = Math.round((price - integerPart) * 100);

  const integerStr = integerPart + '';
  const centsStr = centsPart < 10 ? centsPart + '0' : centsPart;

  return {
    integerPart: integerStr,
    centsPart: centsStr
  }
}

export default function PriceDisplay(props) {
  const parts = getPriceParts(props.price);
  const size = props.size ? props.size : 'large';
  const sizeStyle = 'price-display-' + size;
  const classes =  [sizeStyle, props.className].join(' ');
  return (
    <div className={classes}>
      <span className="integer-part">{parts.integerPart}</span>
      <span className="cents-part">{parts.centsPart}</span>
    </div>
  )
}

