import React, {Component} from 'react';
import {connect} from 'react-redux';
import Translated from '../../Translated';
import CategorySelector from './../../CategorySelector';
import CategoryStart from './../../CategoryStart';
import Spinner from '../../Spinner';
import Alert from 'react-bootstrap/Alert';
import ResetVehicleCategoryButton from "../../ResetVehicleCategoryButton";

class LiteralsStart extends Component {

  render() {
    if (!this.props.settings.get('isReceived')) {
      return <Spinner/>
    }

    const category = this.props.settings.get('vehicleCategory');
    if (category === null) {
      return (
        <CategorySelector/>
      );
    }

    const settingsData = this.props.settings.get('data');
    const settings = settingsData.get('categorySettings').get(category);
    const isLanguageSupported = settings.get('langs').indexOf(this.props.locale) !== -1;
    if (!isLanguageSupported) {
      return (
        <div>
          <Alert>
            <Translated translationKey="category_unsupported_lang"/>
          </Alert>
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <h3><Translated translationKey="literals_title"/></h3>
            <Translated translationKey="literals_description"/>
          </div>
          <br/>
          <CategoryStart id="practice-literals-start" category={category} start={this.props.start}/>
          <ResetVehicleCategoryButton bsstyle="link"/>
        </div>
      );
    }
  }
}

let mapStateToProps = (state) => {
  return ({
    locale: state.Intl.locale,
    settings: state.settings,
    token: state.login.get('token')
  });
};

export default connect(mapStateToProps)(LiteralsStart);