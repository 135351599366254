import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import Alert from 'react-bootstrap/Alert';
import Spinner from '../../feature/Spinner';
import withTranslate from '../../feature/withTranslate';
import ProductSelector from '../../feature/shop/ProductSelector';
import Translated from '../../feature/Translated';
import Button from 'react-bootstrap/Button';
import ContactForm from '../../feature/ContactForm';
import ChatBubble from '../../feature/ChatBubble';
import Collapse from 'react-bootstrap/Collapse';
import ActivityCount from '../../feature/ActivityCount';
import TrackerAB from '../../../utils/trackerAB';
import ImagesComponent from '../../feature/shop/ImagesComponent';
import withPendingOrderCheck from './withPendingOrderCheck';
import {PRODUCT_PAGE_ID} from './ProductPage';
import {pageData} from '../../../actions/actionCreators';
import withProductLoader from './withProductLoader';
import OtherProductCategories from '../../feature/shop/OtherShopCategories'
import ShopPaymentTermsNote from '../../feature/shop/ShopPaymentTermsNote';
import ShopCancellingTermsNote from '../../feature/shop/ShopCancellingTermsNote';
import withRouter from "../../feature/withRouter";

const SUB_CATEGORY = 'be';

export class TrailerPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.selectProduct = this.selectProduct.bind(this);

    this.state = {
      showContactForm: false,
      contactFormSubmitted: false,
      showPaymentTypes: true,
      paymentBannerUrl: '',
      showImagePromo: false
    }
  }

  componentWillUnmount() {
    clearTimeout(this.paymentBannerTimeout);
    clearTimeout(this.imagePromoTimeout);
  }

  componentDidMount() {
    TrackerAB.track('webshop-shown');
    this.imagePromoTimeout = setTimeout(
      () => this.setState({showImagePromo: true}), 4000);
    this.paymentBannerTimeout = setTimeout(
      () => this.setState({paymentBannerUrl: 'https://banners.checkout.fi/33f0744d685d65b33fe483ec20cfaf51/300x300.png'}), 5000);
  }

  selectProduct(product) {
    this.props.dispatch(pageData(PRODUCT_PAGE_ID, product));
    this.props.navigate('/tuoteinfo');
  }

  renderInfo(key) {
    return (
      <div className="blue-inner-box tight">
        <Translated translationKey={key} html={true}/>
      </div>
    );
  }

  renderContactForm() {
    const buttonKey = this.state.showContactForm ? 'cancel' : 'contact';
    let content;
    if (this.state.showContactForm) {
      content = (
        <div className="top-margin contact-wrapper white-box slide-in">
          <ContactForm email onSubmitted={() => this.setState({contactFormSubmitted: true, showContactForm: false})}/>
        </div>
      )
    } else if (this.state.contactFormSubmitted) {
      content = <Translated translationKey="message_sent"/>;
    }

    return (
      <div className="large-top-margin webshop-contact-wrapper">
        <Button aria-label="Send feedback" role="presentation" onClick={() => this.setState({
          showContactForm: !this.state.showContactForm,
          contactFormSubmitted: false
        })}>
          <Translated translationKey={buttonKey}/>
        </Button>
        {content}
      </div>
    )
  }

  renderProducts() {
    let content;
    if (this.props.productsError) {
      content = <Alert bsstyle="danger"><Translated translationKey={this.props.productsError}/></Alert>
    } else if (this.props.isLoadingProducts) {
      content = <div><Spinner/></div>
    } else if (this.props.products) {
      content = <ProductSelector products={this.props.products} onSelect={this.selectProduct}/>
    }
    return (
        <div className="medium-top-margin medium-bottom-margin">
          {content}
        </div>
    )
  }

  renderChatBubblesMobile() {
    if (this.state.showContactForm || this.state.isPurchaseStarted) {
      return null;
    }

    return (
      <div className="medium-top-margin medium-bottom-margin">
        <div>
          <ChatBubble className="fade-in delay">
            <p>
              <span>Kiitos tästä loistavasta harjoittelusivustosta. Teoriakoe meni kerralla läpi. Voin suositella.</span>
              <br/>
              <i>Markku Myllylä, 17.1.2018</i>
            </p>
          </ChatBubble>
        </div>
      </div>
    );
  }

  renderChatBubbles() {
    if (this.state.showContactForm || this.state.isPurchaseStarted) {
      return null;
    }

    return (
      <div className="medium-top-margin medium-bottom-margin fade-in longer-delay">
        <div>
          <ChatBubble>
            <p>
              <span>Kiitos tästä loistavasta harjoittelusivustosta. Teoriakoe meni kerralla läpi. Voin suositella.</span>
              <br/>
              <i>Markku Myllylä, 17.1.2018</i>
            </p>
          </ChatBubble>
        </div>
      </div>
    );
  }

  renderPaymentBanner() {
    if (this.state.showContactForm || this.state.isPurchaseStarted) {
      return null;
    }

    const banner = (
      <div className="semi-dark-inner-box tight fade-in delay top-margin-small">
        {this.state.showPaymentTypes
          ? null
          : <div className="centered">
            <Button bsstyle="link" aria-label="Show payment types" role="presentation" onClick={() => {
              TrackerAB.track('webshop-show-payment-types');
              this.setState({showPaymentTypes: true})
            }}>
              <Translated translationKey="payment_types"/>...
            </Button>
          </div>
        }
        <Collapse in={this.state.showPaymentTypes}>
          <div className="top-margin">
            <div className="centered fade-in">
              <Translated translationKey="payment_types" className="strong"/>
              <br/>
              <img src={this.state.paymentBannerUrl}/>
              <br/>
              <Translated translationKey="payment_operator_info"/>
              <ShopPaymentTermsNote /> <ShopCancellingTermsNote />
            </div>
          </div>
        </Collapse>
      </div>
    );

    return banner;
  }

  renderPromoImages() {
    if (!this.state.showImagePromo) {
      return null;
    }
    return (
      <div className="semi-dark-inner-box tight centered">
        <div className="images-promo-on-page centered">
          <ImagesComponent config={{setId: 'be_class'}}/>
        </div>
      </div>
    );
  }

  renderCompany() {
    return (
      <div className="centered large-top-margin semi-transparent touchable"
           onClick={() => this.props.navigate('/privacy-policy')}>
        <strong>Ajokaista Oy</strong>
        <br/>
        Katajapolku 14<br/>
        00780 Helsinki
      </div>
    )
  }

  renderActivity() {
    return (
      <div className="centered semi-dark-inner-box tight fade-in delay top-margin-small">
        <ActivityCount type="large"/>
      </div>
    );
  }

  renderActivity2() {
    return (
      <div className="fade-in delay medium-top-margin pull-right phone-centered">
        <ActivityCount type="large" className="phone-centered"/>
      </div>
    );
  }

  render() {
    const lang = this.props.locale;
    if (lang !== 'fi') {
      return this.renderMonolithInfo();
    }
    return this.renderSplittedInfo();
  }

  renderMonolithInfo() {
    return (
      <div id="shop-page" className="page-container max-width-container">
        <h1><Translated translationKey="webshop_trailer_header"/></h1>
        <div className="hide-sm-and-gt">{this.renderChatBubblesMobile()}</div>
        {this.renderActivity()}
        {this.renderInfo('webshop_trailer_info')}
        {this.renderProducts()}
        <OtherProductCategories/>
        {this.renderPaymentBanner()}
        {this.renderPromoImages()}
        <div className="hide-xs">{this.renderChatBubbles()}</div>
        {this.renderContactForm()}
        {this.renderCompany()}
      </div>
    )
  }

  renderSplittedInfo() {
    return (
      <div id="shop-page" className="page-container max-width-container">
        <div className="vertical-middle on-sides phone-column">
          <h1 className="phone-centered"><Translated translationKey="webshop_trailer_header"/></h1>
          {this.renderActivity2()}
        </div>
        {this.renderInfo('webshop_trailer_info_a')}
        {this.renderProducts()}
        <OtherProductCategories/>
        {this.renderInfo('webshop_money_back')}
        <div className="hide-sm-and-gt">{this.renderChatBubblesMobile()}</div>
        <div className="vertical-middle phone-column">
          {this.renderInfo('webshop_trailer_info_b')}
          {this.renderPromoImages()}
        </div>
        {this.renderPaymentBanner()}
        <div className="hide-xs">{this.renderChatBubbles()}</div>
        <div className="vertical-middle even phone-column">
          {this.renderContactForm()}
          {this.renderCompany()}
        </div>
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return ({
    locale: state.Intl.locale
  });
};

const composed = compose(
  withRouter,
  connect(mapStateToProps),
  withPendingOrderCheck,
  withProductLoader(SUB_CATEGORY),
  withTranslate
);

export default composed(TrailerPage);
