import React, {Component} from 'react';
import Translated from '../../Translated';
import CategoryStart from './../../CategoryStart';
import Button from 'react-bootstrap/Button';

class DemoImagesStart extends Component {

  render() {
    return (
      <div>
        <div>
          <h3><Translated translationKey="demo_images_title"/></h3>
          <Translated translationKey="demo_images_description"/>
        </div>
        <br/>

        <CategoryStart id="practice-images-start"
                       category={this.props.category}
                       start={this.props.start}/>
        <Button id={this.props.buttonId}
                onClick={() => this.props.toShop()}
                className="large-top-margin">
          <Translated translationKey="back_to_shop"/>
        </Button>
      </div>
    );
  }
}

export default DemoImagesStart;