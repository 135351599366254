export const SET_REFERRAL = 'SET_REFERRAL';
export const SET_AD_ID = 'SET_ID_ID';

export function getAllLocalReferralsObj() {
  try {
    let refObj = JSON.parse(localStorage.getItem('referrals'));
    if (!refObj) {
      return {};
    }
    return refObj;
  } catch (err) {
    return {}
  }
}

export function setLocallyStoredReferral(referralId, adId) {
  if (!referralId) {
    return false;
  }
  let refObj;
  try {
    refObj = JSON.parse(localStorage.getItem('referrals'));
  } catch (e) {
    refObj = {};
  }

  if (!refObj) {
    refObj = {};
  }

  try {
    if (!refObj[referralId]) {
      refObj[referralId] = [];
    }
    const idsArr = refObj[referralId];
    const now = new Date();
    idsArr.push('[' + adId + ']' + ' ' + now);
    refObj[referralId] = idsArr;
    localStorage.setItem('referrals', JSON.stringify(refObj));
    return true;
  }
  catch (error) {
    // Do nothing, probably in private browser mode...
    return false;
  }
}

export function setReferral(referralId) {
  return {
    type: SET_REFERRAL,
    referralId: referralId
  }
}

export function setAdId(adId) {
  return {
    type: SET_AD_ID,
    adId: adId
  }
}
