import React, {Component} from 'react';
import {connect} from 'react-redux';
import PreloadMiddlePage from './PreloadMiddlePage';
import Translated from './../feature/Translated';
import TestResultStatus from '../feature/results/TestResultStatus';
import TestResultLiteral from '../feature/results/TestResultLiteral';
import TestResultSign from '../feature/results/TestResultSign';
import TestResultImage from '../feature/results/TestResultImage';
import DateFormat from '../../utils/date';
import ErrorModal from '../feature/ErrorModal';
import {loadData, dataTypes} from '../../actions/apiDataActions';
import api from '../../api';
import {Roles} from '../../data/roles';
import MapUtil from '../../utils/map';
import ActiveBookStatus from '../feature/ActiveBookStatus';
import BookModalLink from '../feature/BookModalLink';
import UserStatusLoaderWrapper from '../feature/UserStatusLoaderWrapper';
import UserCategoryStatus from '../feature/UserCategoryStatus';
import StartTrainingButton from '../feature/StartTrainingButton';
import VehicleInfo from "../feature/VehicleInfo";
import {hasVehicleInfo} from '../../data/vehicleInfoCategories';
import withRouter from "../feature/withRouter";
import Button from 'react-bootstrap/Button';
import {AiOutlineDown, AiOutlineUp} from "react-icons/ai"

class ResultPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      showCorrectAnswers: false,
    }
  }

  componentDidMount() {
    BookModalLink.setAllowPopover(true);
    const id = this.props.match.params.id;
    this.props.dispatch(loadData(dataTypes.RESULT, api.getResult, id, this.props.locale));
  }

  filterLiteralQuestions(questions, includeCorrect, includeIncorrect) {
    if (!questions) {
      return [];
    }
    
    if (includeCorrect) {
      return questions.filter(
        (question) => question.answer === question.correctAnswer
      );
    }

    if (includeIncorrect) {
      return questions.filter(
        (question) => question.answer !== question.correctAnswer
      );
    }
  }

  renderLiterals(result, filteredLiterals, bookReferencesMap) {
    if (!result.literalQuestions && !result.signQuestions) {
      return null;
    }
    
    const hasVisibleItems = (filteredLiterals.literals.length + filteredLiterals.signs.length) > 0;

    if (!hasVisibleItems) {
      return null;
    }

    return (
      <div id="result-literals-wrapper">
        <h3><Translated translationKey="literals"/></h3>
        {filteredLiterals.literals.map(question => <TestResultLiteral question={question} key={question.id}
                                                             bookReferences={bookReferencesMap}/>)}
        {filteredLiterals.signs.map(question => <TestResultSign question={question} key={question.id}
                                                       bookReferences={bookReferencesMap}/>)}
      </div>
    );
  }

  renderRisks(result, filteredRisks, bookReferencesMap) {
    if (!result.riskQuestions) {
      return null;
    }

  
    if (filteredRisks.length === 0) {
      return null;
    }

    return (
      <div id="result-risks-wrapper">
        <h3><Translated translationKey="risks"/></h3>
        {filteredRisks.map(question => <TestResultLiteral question={question} key={question.id}
                                                          bookReferences={bookReferencesMap}/>)}
      </div>
    );
  }

  filterImageQuestions(questions, showCorrect, showIncorrect) {
    if (!questions || questions.length === 0) {
      return [];
    }

    if (showCorrect) {
      return questions.filter(question => question.answer === question.isAssertionTruthy);
    }

    if(showIncorrect) {
      return questions.filter(question => question.answer !== question.isAssertionTruthy);
    }
  }

  renderImages(result, filteredImages, bookReferencesMap) {
    if (!result.imageQuestions) {
      return null;
    }

    if (filteredImages.length === 0) {
      return null;
    }

    const vehicleInfo = hasVehicleInfo(result.category) && (
      <div className="blue-box tight">
        <VehicleInfo category={result.category}/>
      </div>
    );

    const elements = filteredImages.map((question, i) => <TestResultImage key={i} question={question}
                                                                    bookReferences={bookReferencesMap}/>);

    return (
      <div id="result-images-wrapper">
        <h3><Translated translationKey="images"/></h3>
        {vehicleInfo}
        <div id="test-result-images-container">
          {elements}
        </div>
      </div>
    );
  }

  renderUserData(result) {
    if (this.props.userId === result.userId) {
      return null;
    }

    return (
      <div>
        <div className="blue-box">
          <div>
            <strong><Translated translationKey="user_id"/>:</strong> {result.userId}
          </div>
          <div>
            <strong><Translated translationKey="date"/>:</strong> {DateFormat.dateTime(result.endDateTime)}
          </div>
        </div>
        <br/>
      </div>
    );
  }

  renderUserStatus() {
    const selectedCategory = this.props.settings.get('vehicleCategory');
    if (!selectedCategory) {
      return null;
    }
    return (
      <UserStatusLoaderWrapper category={selectedCategory}>
        <UserCategoryStatus>
          <StartTrainingButton/>
        </UserCategoryStatus>
      </UserStatusLoaderWrapper>
    );
  }

  renderAnswers(result, bookReferencesMap, showCorrect) {
    const filterStatements = showCorrect ? [true, false] : [false, true];
    const filteredLiterals = {
      literals: this.filterLiteralQuestions(result.literalQuestions, filterStatements[0], filterStatements[1]),
      signs: this.filterLiteralQuestions(result.signQuestions, filterStatements[0], filterStatements[1])
    } 
    const filteredRisks = this.filterLiteralQuestions(result.riskQuestions, filterStatements[0], filterStatements[1]);
    const filteredImages = this.filterImageQuestions(result.imageQuestions, filterStatements[0], filterStatements[1]);

    const renderCommon = (
      <>
        {this.renderLiterals(result, filteredLiterals, bookReferencesMap)}
        {this.renderRisks(result, filteredRisks, bookReferencesMap)}
        {this.renderImages(result, filteredImages, bookReferencesMap)}
      </>
    );

    if (showCorrect) {
      return <div className={`no-overflow top-margin ${this.state.showCorrectAnswers ? "animate-extend-height" : "animate-retract-height" }`}>
      {renderCommon}
      </div> 
    }

    return renderCommon;
  }

  renderCorrectAnswers(result, bookReferencesMap) {
    return this.renderAnswers(result, bookReferencesMap, true)
  }

  renderIncorrectAnswers(result, bookReferencesMap) {
    return this.renderAnswers(result, bookReferencesMap, false)
  }

  render() {
    if (this.props.result.error) {
      return (
        <div>
          <ErrorModal show={true} errorKey={this.props.result.error}/>
          &nbsp;
        </div>
      );
    }

    if (!this.props.result.data) {
      return <PreloadMiddlePage/>
    }

    const result = this.props.result.data.result;
    const bookReferences = this.props.result.data.bookReferences;
    const bookReferencesMap = MapUtil.mapFromObject(bookReferences);

    return (
      <div id="result-page" className="page-container">
        <div className="max-width-container centered">
          {this.renderUserData(result)}
          <TestResultStatus result={result}/>
          <div className="test-results-answers-list-wrapper">
            {this.renderIncorrectAnswers(result, bookReferencesMap)}
            <br/>
            <Button onClick={() => this.setState({showCorrectAnswers: !this.state.showCorrectAnswers})}>
              {this.state.showCorrectAnswers ? 
              <><Translated translationKey="hide_correct_answers" /><AiOutlineUp className='animate-rotate-180' /></> :
              <><Translated translationKey="show_correct_answers" /><AiOutlineDown className='animate-rotate-180'  /></>}
            </Button> 
            {this.renderCorrectAnswers(result, bookReferencesMap)}
            {this.props.role === Roles.STUDENT ? this.renderUserStatus() : null}
            {this.props.role === Roles.STUDENT ? <ActiveBookStatus/> : null}
            <br/>
          </div>
        </div>
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  const token = state.login.get('token');
  return ({
    settings: state.settings,
    result: state.apiData[dataTypes.RESULT],
    locale: state.Intl.locale,
    userId: token ? token.get('userId') : -1,
    role: token ? token.get('role') : Roles.STUDENT
  });
};

export default withRouter(connect(mapStateToProps)(ResultPage));

