import React, {Component} from 'react';
import {connect} from 'react-redux';
import TestResultsList from '../feature/results/TestResultsList';
import Translated from '../feature/Translated';
import withRouter from "../feature/withRouter";


class ResultsPage extends Component {

  render() {
    const userId = this.props.match.params.userId ? this.props.match.params.userId : this.props.ownUserId;

    const userInfo = !this.props.match.params.userId
      ? null
      :(<div className="blue-box vertical-margins touchable" onClick={() => this.props.navigate('/student/'+userId)}>
        <Translated translationKey="user_id"/>: {userId}
      </div>);

    return (
      <div id="results-page" className="page-container max-width-container">
        <h3><Translated translationKey="all_test_results" /></h3>
        {userInfo}
        <TestResultsList userId={userId}/>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return ({
    ownUserId: state.login.get('token') ? state.login.get('token').get('userId') : null
  });
};

export default withRouter(connect(mapStateToProps)(ResultsPage));
