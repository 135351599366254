import api from './api';
import queryString from 'query-string';

const API_VERSION = '/api/v1';
const BASE_URL = '' + API_VERSION;

const CLEAR_CACHES_URL = BASE_URL + '/admin/caches-clear';

const SCHOOLS_SEARCH = BASE_URL + '/admin/search/schools';
const SCHOOLS_ADD = BASE_URL + '/admin/school/';
const SCHOOL_COMMENTS = BASE_URL + '/admin/school-comments/:schoolId';
const USER_COMMENTS = BASE_URL + '/admin/user-comments/:userId';
const LITERALS = BASE_URL + '/admin/questions/literals/:category/:lang?id=:id';
const LITERAL = BASE_URL + '/admin/questions/literal/:id';
const IMAGES = BASE_URL + '/admin/questions/images/:category/:lang?id=:id';
const BOOK_REFERENCE = BASE_URL + '/admin/book-reference/:questionType/:questionId/:category/:referenceId';
const GET_TOKEN_FOR_USER_ID = BASE_URL + '/admin/login-as-any-user/user-id/:userId';
const GET_TOKEN_FOR_USER_EMAIL = BASE_URL + '/admin/login-as-any-user/user-email/:email';
const PRECRETE_ONE_TIME_KEYS = BASE_URL + '/admin/one-time-keys-precreate';
const LIST_INVOICEABLE_PERIODS = BASE_URL + '/admin/list-invoiceable-periods';
const CREATE_INVOICES = BASE_URL + '/admin/create-invoices';
const LIST_INVOICES = BASE_URL + '/admin/list-invoices';
const EXPORT_INVOICES = BASE_URL + '/admin/export-invoices';
const SEARCH_ORDERS = BASE_URL + '/admin/search/webshop-orders';
const SEARCH_USERS = BASE_URL + '/admin/search/user';
const SEARCH_EMAIL_EVENTS = BASE_URL + '/admin/search/email/email-events/:email'
const SEARCH_USER_LICENSES = BASE_URL + '/admin/user-licenses/:userId'
const SEARCH_USER_SUB_PERIODS = BASE_URL + '/admin/user/subscription-periods/:userId'
const SEARCH_USER_LOGIN_LOGS = BASE_URL + '/admin/login-logs/:userId'
const ACCEPT_WEBSHOP_ORDER = BASE_URL + '/admin/order/:orderId/accept-order'
const ADD_CATEGORY_LICENSE = BASE_URL + '/admin/user-licenses/:userId/category-license'
const ADD_MATERIAL_LICENSE = BASE_URL + '/admin/user-licenses/:userId/material-license'
const REMOVE_LICENSE = BASE_URL + '/admin/user-licenses/:userId/remove'
const EVENT_LOGS = BASE_URL + '/admin/event-logs?page=:page&&term=:term&targetId=:targetId&targetType=:targetType'

const CHANGE_EMAIL = BASE_URL + '/admin/user/:userId/change-email'
const DISCONNECT_EMAIL = BASE_URL + '/admin/user/:userId/disconnect-email'
const REMOVE_EMAIL_PASSWORD  = BASE_URL + '/admin/user/:userId/remove-email-password'
// Cache time in seconds
const CACHE_TIME_INFINITY = 0;
const CACHE_TIME_NEVER = -1;
const CACHE_TIME_VERY_SHORT = 3;
const CACHE_TIME_SHORT = 60;
const CACHE_TIME_LONG = 60 * 15;

const REGULAR_TIMEOUT_MS = 30000;
const EXTENDED_TIMEOUT_MS = 60000;


class AjokaistaApiAdminClient {

  static clearCaches() {
    return api.post(CLEAR_CACHES_URL, undefined, REGULAR_TIMEOUT_MS);
  }

  static searchSchools(searchTerm) {
    return api.post(SCHOOLS_SEARCH, {term: searchTerm}, REGULAR_TIMEOUT_MS);
  }

  static addSchool(schoolId, schoolEmail) {
    return api.post(SCHOOLS_ADD, {schoolId: schoolId.toLowerCase(), schoolEmail: schoolEmail}, EXTENDED_TIMEOUT_MS);
  }

  static getSchoolComments(schoolId) {
    let url = SCHOOL_COMMENTS.replace(':schoolId', schoolId.toLowerCase());
    return api.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  static getUserComments(userId) {
    let url = USER_COMMENTS.replace(':userId', userId.toLowerCase());
    return api.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  static addSchoolComment(schoolId, comment) {
    let url = SCHOOL_COMMENTS.replace(':schoolId', schoolId.toLowerCase());
    return api.post(url, {comment}, REGULAR_TIMEOUT_MS);
  }

  static addUserComment(userId, comment) {
    let url = USER_COMMENTS.replace(':userId', userId.toLowerCase());
    return api.post(url, {comment}, REGULAR_TIMEOUT_MS);
  }

  static listLiteralQuestions(category, lang, id) {
    let url = LITERALS;
    url = url.replace(':category', category);
    url = url.replace(':lang', lang);
    url = url.replace(':id', id);
    return api.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  static modifyLiteral(literalQuestion) {
    let url = LITERAL;
    url = url.replace(':id', literalQuestion.id);
    return api.post(url, {literalQuestion}, REGULAR_TIMEOUT_MS);
  }

  static listImageQuestions(category, lang, id) {
    let url = IMAGES;
    url = url.replace(':category', category);
    url = url.replace(':lang', lang);
    url = url.replace(':id', id);
    return api.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  static addBookReference(questionType, questionId, category, referenceId) {
    let url = BOOK_REFERENCE;
    url = url.replace(':questionType', questionType);
    url = url.replace(':questionId', questionId);
    url = url.replace(':category', category);
    url = url.replace(':referenceId', referenceId);
    return api.post(url, {}, REGULAR_TIMEOUT_MS);
  }

  static getTokenForUserIdLogin(userId) {
    let url = GET_TOKEN_FOR_USER_ID;
    url = url.replace(':userId', encodeURIComponent(userId));
    return api.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER);
  }

  static getTokenForUserEmailLogin(email) {
    let url = GET_TOKEN_FOR_USER_EMAIL;
    url = url.replace(':email', email);
    return api.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER);
  }

  static precreateOneTimeKeys(schoolIdsArr, amountPerSchool) {
    const url = PRECRETE_ONE_TIME_KEYS;
    const data = {
      schoolIds: schoolIdsArr,
      keyAmount: amountPerSchool
    };
    return api.post(url, data, EXTENDED_TIMEOUT_MS);
  }

  static getInvoiceablePeriods(filters) {
    let url = LIST_INVOICEABLE_PERIODS + '?' + queryString.stringify(filters);
    return api.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER);
  }

  static createInvoices(schoolIds, filters, invoiceDate, dueDate) {
    return api.post(CREATE_INVOICES, {schoolIds, ...filters, invoiceDate, dueDate}, REGULAR_TIMEOUT_MS);
  }

  static getInvoices(filters) {
    let url = LIST_INVOICES + '?' + queryString.stringify(filters);
    return api.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER);
  }

  static exportInvoices(invoiceIds) {
    return api.post(EXPORT_INVOICES, {invoiceIds}, REGULAR_TIMEOUT_MS);
  }

  static searchWebshopOrders(term) {
    const url = SEARCH_ORDERS + '?' + queryString.stringify({term});
    return api.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER);
  }
  static searchWebshopOrdersByEmail(userEmail) {
    const url = SEARCH_ORDERS + '?' + queryString.stringify({userEmail});
    return api.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER);
  }

  static searchUsers(term) {
    const url = SEARCH_USERS + '?' + queryString.stringify({term});
    return api.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_VERY_SHORT);
  }

  static searchEmailEvents(email) {
    const url = SEARCH_EMAIL_EVENTS.replace(":email", email);
    return api.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_VERY_SHORT);
  }

  static getUserLicenses(userId) {
    const url = SEARCH_USER_LICENSES.replace(":userId", userId)
    return api.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER);
  }

  static getUserSubscriptionPeriods(userId) {
    const url = SEARCH_USER_SUB_PERIODS.replace(":userId", userId)
    return api.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_VERY_SHORT);
  }

  static getUserLoginLogs(userId) {
    const url = SEARCH_USER_LOGIN_LOGS.replace(":userId", userId)
    return api.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_VERY_SHORT);
  }

  static changeOrderStatusToOk(orderId) {
    const url = ACCEPT_WEBSHOP_ORDER.replace(":orderId", orderId)
    return api.post(url, {orderId}, REGULAR_TIMEOUT_MS);
  }
  
  static changeEmail(userId, email) {
    let url = CHANGE_EMAIL.replace(":userId", userId)
    return api.post(url, { email }, REGULAR_TIMEOUT_MS);
  }
  
  static disconnectEmail(userId) {
    let url = DISCONNECT_EMAIL.replace(":userId", userId)
    return api.post(url, {}, REGULAR_TIMEOUT_MS);
  }
 
  static removeEmailPassword(userId) {
    let url = REMOVE_EMAIL_PASSWORD.replace(":userId", userId)
    return api.post(url, {}, REGULAR_TIMEOUT_MS);
  }

  static addCategoryLicense(userId, data) {
    let url = ADD_CATEGORY_LICENSE.replace(":userId", userId)
    return api.post(url, data, REGULAR_TIMEOUT_MS);
  }
  
  static addMaterialLicense(userId, data) {
    let url = ADD_MATERIAL_LICENSE.replace(":userId", userId)
    return api.post(url, data, REGULAR_TIMEOUT_MS);
  }

  static removeLicense(userId, data) {
    let url = REMOVE_LICENSE.replace(":userId", userId)
    return api.post(url, data, REGULAR_TIMEOUT_MS);
  }
  
  static getEventLogs(page, searchTerm, targetId, targetType) {
    let url = EVENT_LOGS;
    url = url.replace(":page", page);
    url = url.replace(":term", searchTerm);
    url = url.replace(":targetId", targetId || "");
    url = url.replace(":targetType", targetType || "");

    return api.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_VERY_SHORT);
  }

}

export default AjokaistaApiAdminClient;
