import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Translated from './../feature/Translated';
import {resetLoginError, resetFacebookConnection} from '../../actions/loginActions'
import FacebookUtil from '../../utils/FacebookUtil';
import withRouter from "../feature/withRouter";

/**
 * Show a dialog that user has not yet connected Facebook account to Ajokaista.
 * Suggest login and then connecting FB.
 * Automatic connecting to FB after login is now disabled due to multiple login methods.
 */
class LoginFbNotConnectedPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.onOkClick = this.onOkClick.bind(this);
  }

  onOkClick(e) {
    FacebookUtil.logout();
    this.props.dispatch(resetFacebookConnection());
    this.props.dispatch(resetLoginError());
    this.props.navigate('/login');
  }

  render() {
    return (
      <div className="page-container">
        <div className="max-width-container centered">
          <Alert>
            <Translated translationKey="login_facebook_not_connected" values={{name: this.props.fb ? this.props.fb.get('fbUserName') : ''}}/>
          </Alert>
          <Button onClick={this.onOkClick}><Translated translationKey='ok'/></Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.login.get('isFetching'),
    loginError: state.login.get('error'),
    fb: state.login.get('fb')
  }
}

export default withRouter(connect(mapStateToProps)(LoginFbNotConnectedPage));
