import React, {Component} from 'react';
import withTranslate from '../withTranslate';
import TextInput from '../TextInput';
import EmailUtil from '../../../utils/EmailUtil';
import Translated from '../Translated';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames';

class CustomerInput extends Component {

  constructor(props, context) {
    super(props, context);
    this.setInput = this.setInput.bind(this);
    this.state = {
      email: props.data ? props.data.email : '',
      name: props.data ? props.data.name : '',
      phone: props.data ? props.data.phone: '',
      error: null
    }
  }

  areInputsValid(state) {
    return EmailUtil.isEmail(state.email.trim()) && state.name.trim();
  }

  updateInputValue(prop, value) {
    const newState = {...this.state, [prop]: value};
    this.setState(newState);
  }

  setInput() {
    if (this.areInputsValid(this.state)) {
      this.setState({error: null});
      const trimmed = {
        email: this.state.email.trim(),
        name: this.state.name.trim(),
        phone: this.state.phone.trim()
      };
      this.props.setInput(trimmed);
    } else {
      this.setState({error: 'invalid-input'});
    }
  }

  renderError() {
    if(!this.state.error) {
      return null;
    }
    return (
      <div className="top-margin"><Alert><Translated translationKey={this.state.error}/></Alert></div>
    );
  }

  render() {
    const emailText = this.props.translate('email_address');
    const nameText = this.props.translate('name');
    const phoneText = this.props.translate('phone_number');
    const classes = classNames(this.props.className);

    return (
      <div className={classes}>
        <h3><Translated translationKey="purchaser_contact_details"/></h3>
        <div className="blue-inner-box tight">
          <TextInput listener={name => this.updateInputValue('name', name)}
                     label={nameText}
                     placeholder={nameText}
                     value={this.state.name}
                     className="long-input-field"/>

          <TextInput listener={email => this.updateInputValue('phone', email)}
                     label={phoneText}
                     placeholder={phoneText}
                     value={this.state.phone}
                     className="long-input-field"/>

          <TextInput listener={email => this.updateInputValue('email', email)}
                     type="email"
                     label={emailText}
                     placeholder={emailText}
                     value={this.state.email}
                     className="long-input-field"/>

          <Button onClick={this.setInput}><Translated translationKey="next"/></Button>
          {this.renderError()}
          </div>
      </div>
  )
  }
}

export default withTranslate(CustomerInput);

