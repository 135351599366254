import React, {Component} from 'react';
import Translated from '../Translated';
import classNames from 'classnames';
import api from '../../../api';
import Alert from 'react-bootstrap/Alert';
import {IoAlertOutline} from 'react-icons/io5';
import Spinner from './../../feature/Spinner';

class PaymentMethods extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = this.getDefaultState();
  }

  getDefaultState() {
    return {
      error: null,
      isSending: false,
    }
  }

  doPaymentMethod(paymentMethodId, order) {
    if (this.state.isSending) {
      return false;
    }
    this.setState({isSending: true, error: null});
    return api.updatePaymentMethodId(order.id, order.token, paymentMethodId)
        .then(response => {
          if (response.error) {
            this.setState({isSending: false, error: response.error});
          } else {
            this.setState({isSending: false});
            this.refs[paymentMethodId].submit();
          }
        })
  }

  renderPaymentMethods(paymentMethods, order) {
    const spinner = this.state.isSending ? <Spinner className={'larger'}/> : null;

    let methodsElem = (
        <div>
          <div className="white-box payment-methods">
            {paymentMethods.map(method => {
                  return (
                      <div key={method.name} className="method-icon">
                        <form action={method.url} method="post" ref={method.id}>
                          {method.parameters.map(param => {
                            return <input type="hidden" name={param.name} value={param.value} key={param.name}/>;
                          })}
                          <img alt={method.name} src={method.icon} onClick={() => this.doPaymentMethod(method.id, order)}
                               className="touchable"/>
                        </form>
                      </div>
                  )
                }
            )}
          </div>
          {spinner}
        </div>
    );

    return (
        <div>
          <h3><Translated translationKey="payment_method"/></h3>
          {methodsElem}
        </div>
    )
  }

  renderError() {
    return (
        <Alert><IoAlertOutline/> <Translated translationKey={this.state.error}/></Alert>
    )
  }

  render() {
    const classes = classNames(this.props.className);
    return (
        <div className={classes}>
          {this.state.error ? this.renderError() : this.renderPaymentMethods(this.props.paymentMethods, this.props.order)}
        </div>
    )
  }
}

export default PaymentMethods;

