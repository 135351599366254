import React from 'react';
import Button from 'react-bootstrap/Button';
import Translated from '../Translated';
import {IoThumbsUpSharp} from 'react-icons/io5';

export default (props) => {
  const total = props.total;
  const correctCount = props.correct;
  const resultType = correctCount / total >= 0.9 ? 'positive' : 'neutral';
  const feedbackKey = 'practise_feedback_' + resultType;
  const decorator = resultType === 'positive' ? <IoThumbsUpSharp className="practise-result-decorator fade-in long-delay" /> : null;
  return (
    <div className="centered fade-in long-delay">
      <h3><Translated translationKey="correct_from_total" values={{'correct': correctCount, 'total': total}}/></h3>
      {decorator}
      <h4><Translated translationKey={feedbackKey}/></h4>
      <br />
      <Button id={props.buttonId} onClick={props.restart}><Translated translationKey="practise_restart"/></Button>
    </div>
  )
};