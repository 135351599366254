import api from '../api';

export function fetchFullTest(category, lang) {
  return api.getFullTest(category, lang)
      .then(response => {
        if (response.error) {
          return Promise.reject(response.error);
        }
        const questions = [];
        appendQuestions(questions, response.literalQuestions, 'literal');
        appendQuestions(questions, response.signQuestions, 'sign');
        appendQuestions(questions, response.riskQuestions, 'risk');
        appendQuestions(questions, response.imageQuestions, 'image');

        const {id, category} = response;
        return {id, category, questions};
      })
}

export function sendFullTestAnswers(testId, questions, allAnswers) {
  const answers = {};
  // Group answers by question type
  questions.forEach(question => {
        let answer = allAnswers[question.id];
        if (!answer) {
          answer = 'd';
        }
        const prop = question.type + 'Answers';
        if (!answers.hasOwnProperty(prop)) {
          answers[prop] = [];
        }
        answers[prop].push(answer)
      }
  );
  return api.sendFullTestAnswers(testId, answers);
}

function appendQuestions(target, questions, type) {
  if (!questions) {
    return;
  }
  questions.forEach(question => {
    question.type = type;
    target.push(question);
  })
}