import React, {Component} from 'react';
import ProductInputEmailCredential from './ProductInputEmailCredential'

const KNOWN_INPUTS = ['email_credential'];

class ProductInput extends Component {

  constructor(props, context) {
    super(props, context);
    this.addComponentInput = this.addComponentInput.bind(this);
    const preparedInputs = this.prepareInputs(props.product);
    this.state = {
      inputs: preparedInputs
    };
    if (preparedInputs === null) {
      this.props.setInput(true);
    }
  }

  areComponentsReady(inputs) {
    let ready = true;
    for (let id in inputs) {
      let input = inputs[id];
      if (input === null) {
        ready = false;
      }
    }
    return ready;
  }

  prepareInputs(product) {
    let componentInputs = {};
    let componentConfigs = product.productData ? product.productData.components : [];
    let requiresInputs = false;
    componentConfigs.forEach(c => {
      if (KNOWN_INPUTS.indexOf(c.type) !== -1) {
        componentInputs[c.id] = null;
        requiresInputs = true;
      }
    });
    if (!requiresInputs) {
      return null;
    }
    return componentInputs;
  }

  addComponentInput(id, input) {
    const inputs = Object.assign({}, this.state.inputs);
    inputs[id] = input;
    this.setState({inputs});
    if(this.areComponentsReady(inputs)) {
      this.props.setInput(inputs);
    } else {
      this.props.setInput(null);
    }
  }

  renderComponents(product) {
    const componentElems = [];
    let componentConfigs = product.productData ? product.productData.components : [];

    let componentInputs = {};
    componentConfigs.forEach(c => {
      switch (c.type) {
        case 'email_credential':
          componentElems.push(<ProductInputEmailCredential key={c.id} component={c} addComponentInput={this.addComponentInput} showErrors={this.props.showErrors}/>);
          componentInputs[c.id] = null;
          break;
      }
    });

    return componentElems;
  }

  render() {
    return (
      <div>
        {this.renderComponents(this.props.product)}
      </div>
    )
  }

}

export default ProductInput;

