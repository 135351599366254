import React, {Component} from 'react';
import {connect} from 'react-redux';
import PreloadMiddlePage from './../pages/PreloadMiddlePage';
import ImagesStart from '../feature/practise/images/ImagesStart';
import PractiseResults from '../feature/practise/PractiseResults';
import PracticeImagesMain from '../feature/practise/images/PracticeImagesMain';
import withPracticeTypes from '../feature/withPracticeTypes';
import Translated from '../feature/Translated';
import CategorySelector from "../feature/CategorySelector";
import Button from 'react-bootstrap/Button';
import ResetVehicleCategoryButton from "../feature/ResetVehicleCategoryButton";

class PracticeImagesPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.setResult = this.setResult.bind(this);
    this.state = {
      started: false,
      result: null
    }
  }

  start() {
    this.setState({
      started: true,
      result: null
    });
  }

  setResult(result) {
    this.setState({result});
  }

  renderStart() {
    if (this.props.practiceTypes['images']) {
      const category = this.props.settings.get('vehicleCategory');
      return (
        <div>
          <ImagesStart category={category} start={() => this.start(false)}/>
          <ResetVehicleCategoryButton bsstyle="link"/>
        </div>
      );

    } else {
      return <div className="blue-inner-box"><Translated translationKey="category_has_no_images"/></div>
    }
  }

  render() {
    let content;
    if (!this.props.settings.get('isReceived')) {
      content = <PreloadMiddlePage/>
    } else if (!this.props.settings.get('vehicleCategory')) {
      content = <CategorySelector/>
    } else if (!this.props.practiceTypes) {
      content = <PreloadMiddlePage/>
    } else if (!this.state.started) {
      content = this.renderStart();
    } else if (!this.state.result) {
      content = <PracticeImagesMain category={this.props.settings.get('vehicleCategory')}
                                    setResult={this.setResult}/>
    } else {
      content = <PractiseResults correct={this.state.result.correct}
                                 total={this.state.result.total}
                                 restart={() => this.start()}
                                 buttonId="practise-images-restart"/>
    }

    return (
      <div id="practise-images-page" className="page-container max-width-container">
        {content}
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return ({
    settings: state.settings,
    locale: state.Intl.locale
  });
};

export default connect(mapStateToProps)(withPracticeTypes(PracticeImagesPage));
