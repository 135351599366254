import React, {Component} from 'react';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import CategorySelector from './CategorySelector';
import withRouter from './withRouter';
import UserCategoryDisplay from './UserCategoryDisplay';
import Translated from './Translated';
import ResetVehicleCategoryButton from './ResetVehicleCategoryButton';

class UserCategorySetting extends Component {

  render() {
    if (!this.props.settings.get('isReceived')) {
      return <Spinner/>;
    }

    const selectedCategory = this.props.settings.get('vehicleCategory');
    if (selectedCategory === null) {
      return (<CategorySelector className="fade-in"/>)
    }

    return (
      <div>
        <h4><Translated translationKey="license_class"/></h4>
        <div className="white-box vertical-middle on-sides">
          <UserCategoryDisplay
            category={selectedCategory}
          />
          <ResetVehicleCategoryButton userId={this.props.userId} />
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return ({
    settings: state.settings,
  });
};

export default withRouter(connect(mapStateToProps)(UserCategorySetting));
