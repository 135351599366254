/**
 * Created by anttikaseva on 02/06/2017.
 */
class MapUtil {

  /**
   * Map given content by id field
   * @param keyFieldName the name of the field
   * @param contentArr
   * @returns {Map}
   */
  static mapFromArr(keyFieldName, contentArr) {
    const map = new Map();
    contentArr.forEach(content => {
      map.set(content[keyFieldName], content);
    });
    return map;
  }

  static objectFromMap(map) {
    let obj = Object.create(null);
    for (let [k, v] of map) {
      // We don’t escape the key '__proto__'
      // which can cause problems on older engines
      obj[k] = v;
    }
    return obj;
  }

  static mapFromObject(obj) {
    const map = new Map();
    Object.keys(obj).forEach(key => {
      map.set(key, obj[key]);
    });
    return map;
  }
}

export default MapUtil;