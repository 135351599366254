import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextInput from '../feature/TextInput';
import {connect} from 'react-redux'
import Translated from '../feature/Translated';
import withTranslate from '../feature/withTranslate';
import Alert from 'react-bootstrap/Alert';
import api from '../../api';
import {IoAlertOutline} from 'react-icons/io5';
import withRouter from './withRouter';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import dateFormat from '../../utils/date';

class UserAddEmail extends Component {

  constructor(props, context) {
    super(props, context);
    this.sendRequest = this.sendRequest.bind(this);
    this.state = {
      lang: this.props.locale,
      inputEmail: '',
      isSending: false,
      responseData: null,
      error: null,
      errorData: null
    }
  }

  static propTypes = {
    userId: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired
  };

  sendRequest(forceConnect) {
    const email = this.state.inputEmail;
    const lang = this.state.lang;

    if (!email) {
      return this.setState({
        error: 'invalid_input',
        isSending: false,
        responseData: null,
      })
    }

    this.setState(
      {
        error: null,
        errorData: null,
        isSending: true,
        responseData: null,
      }
    );

    api.userConnectEmail(this.props.userId, email, lang, forceConnect)
      .then(response => {
        if (response.error) {
          return this.setState({
            error: response.error,
            errorData: response.data,
            isSending: false,
            responseData: null,
          });
        } else {
          console.log(JSON.stringify(response.data));

          return this.setState({
            error: null,
            errorData: null,
            isSending: false,
            responseData: response.data,
          });
        }
      });
  }

  updateEmail(email) {
    this.setState(
      {
        inputEmail: email,
        error: null,
        errorData: null,
        isSending: false,
      }
    );
  }

  renderEmailField() {
    const emailText = this.props.translate('student_email');
    return (<TextInput
      listener={(email) => this.updateEmail(email)}
      placeholder={emailText}
      value={this.state.inputEmail}
      type="email"
      className="long-input-field"
    />);
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }
    let errorElem;
    switch (this.state.error) {
      case 'email-already-connected':
        errorElem = (
          <span>
            <Translated translationKey={this.state.error}/>
            <br/>
            <Translated translationKey="id"/>: {this.state.errorData.userId}
            <br/>
            <Translated translationKey="name"/>: {this.state.errorData.userName}
            <br/>
            <Translated translationKey="school_name"/>: {this.state.errorData.schoolName}
            <br/>
            <Translated translationKey="creation_date"/>: {dateFormat.date(this.state.errorData.createdDate)}
            <div className="large-top-margin centered">
              <div className="bottom-margin">
                <strong><Translated translationKey="email_force_connect_teacher"/></strong>
              </div>
              <div className="top-margin">
                <Button bsstyle='primary' onClick={() => this.updateEmail('')}>
                  <Translated translationKey="no"/>
                </Button>
                <Button onClick={() => this.sendRequest(true)} className="left-margin">
                  <Translated translationKey="yes"/>
                </Button>
              </div>
            </div>
          </span>
        );
        break;

      default:
        errorElem = (
          <Translated translationKey={this.state.error}/>
        );
    }

    return (
      <Alert>
        <IoAlertOutline/> {errorElem}
      </Alert>
    );
  }

  renderSentRequest() {
    return !this.state.responseData
      ? null
      : (
      <Alert bsstyle="success" className="top-margin">
        <Translated translationKey="user_email_request_sent" values={{'email': this.state.responseData.email}}/>
        <br/>
        <Translated translationKey="last_usage_date"
                    values={{date: dateFormat.date(this.state.responseData.expiresAt)}}/>
      </Alert>
    );
  }

  createLanguageItems() {
    const langCodes = Object.keys(this.props.availableLangs);
    if (langCodes.length <= 1) {
      return null;
    }

    return langCodes.map((langCode) => {
      return (
        <Dropdown.Item
          id={langCode}
          key={langCode}
          onClick={() => {
            this.setState({lang: langCode});
          }}>
          <span>{this.props.availableLangs[langCode]}</span>
        </Dropdown.Item>
      );
    });
  }

  renderLanguageSelection() {
    const langId = this.state.lang;
    const langLocale = this.props.availableLangs[langId];
    return (
      <ButtonToolbar className="settings-category-limit">
        <DropdownButton
          id='addUserLanguageSelection'
          bsstyle="warning"
          title={langLocale}>
          {this.createLanguageItems()}
        </DropdownButton>
      </ButtonToolbar>
    );
  }

  render() {
    return (
      <div>
        <div>
          <h4>
            {this.props.userName}
          </h4>
          <p>
            <Translated translationKey="help_connect_email"/>
          </p>
          {this.renderEmailField()}
          {this.renderLanguageSelection()}
          {this.renderError()}
          <br/>
          <Button onClick={() => this.sendRequest(false)} disabled={this.state.isSending || this.state.responseData}>
            <Translated translationKey="send"/>
          </Button>
        </div>
        <div>
          {this.renderSentRequest()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    locale: state.Intl.locale,
    availableLangs: state.availableLangs,
  });
};

export default withRouter(connect(mapStateToProps)(withTranslate(UserAddEmail)));
