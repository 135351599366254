import api from '../api';

export const SETTINGS_REQUESTED = 'SETTINGS_REQUESTED';
export const SETTINGS_RECEIVED = 'SETTINGS_RECEIVED';
export const SETTINGS_ERROR = 'SETTINGS_ERROR';
export const SET_VEHICLE_CATEGORY = 'SET_VEHICLE_CATEGORY';
export const SETTINGS_RESET = 'SETTINGS_RESET';

export function getAllLocalSettingsObj() {
  try {
    let catObj = JSON.parse(localStorage.getItem('vehicle_category'));
    if (!catObj){
      return {};
    }
    return catObj;
  } catch (err) {
    return {}
  }
}

/**
 * Vehicle category is stored locally, so that users do not need to select the category on every login.
 * Multiple users share same object
 */
function getLocallyStoredCategory(userId) {
  try {
    let catObj = JSON.parse(localStorage.getItem('vehicle_category'));
    if (!catObj){
      return null;
    }
    const cat = catObj[userId];
    return cat ? cat : null;
  } catch (err) {
    setLocallyStoredCategory(null, userId);
    return null;
  }
}

function setLocallyStoredCategory(category, userId) {
  let catObj;
  try {
    catObj = JSON.parse(localStorage.getItem('vehicle_category'));
  } catch (e) {
    catObj = {};
  }

  if (!catObj) {
    catObj = {};
  }

  try {
    catObj[userId] = category;
    localStorage.setItem('vehicle_category', JSON.stringify(catObj));
  }
  catch (error) {
    // Do nothing, probably in private browser mode...
  }
}

function settingsRequested() {
  return {type: SETTINGS_REQUESTED}
}

function settingsReceived(settings) {
  return {
    type: SETTINGS_RECEIVED,
    settings
  }
}

function settingsError(error) {
  return {
    type: SETTINGS_ERROR,
    error
  }
}

function vehicleCategory(category, userId) {
  return {
    type: SET_VEHICLE_CATEGORY,
    category,
    userId
  }
}

export function setVehicleCategory(category, userId) {
  return function (dispatch, getState) {
    let currentCategory = getState().settings.get('vehicleCategory');
    if (category === currentCategory) {
      return;
    }
    setLocallyStoredCategory(category, userId);
    dispatch(vehicleCategory(category));
  }
}

export function getSettings(userId) {
  return function (dispatch, getState) {
    const settings = getState().settings;
    if (settings.get('data') || settings.get('isRequesting')) {
      return;
    }
    const storedCategory = getLocallyStoredCategory(userId);
    dispatch(vehicleCategory(storedCategory));
    dispatch(settingsRequested());
    api.getUserSettings()
      .then((response) => {
        if (response.error) {
          return dispatch(settingsError(response.error));
        }
        // Validate that currently selected vehicle category is selectable
        if (storedCategory && response.vehicleCategories.indexOf(storedCategory) === -1) {
          dispatch(vehicleCategory(null));
        }
        dispatch(settingsReceived(response));
      });
  }
}

export function resetSettings() {
  return {
    type: SETTINGS_RESET
  }
}
