import api from '../api';

export const DATA_REQUEST = 'DATA_REQUEST';
export const DATA_SUCCESS = 'DATA_SUCCESS';
export const DATA_ERROR = 'DATA_ERROR';
export const DATA_SAVE_REQUEST = 'DATA_SAVE_REQUEST';
export const DATA_SAVE_SUCCESS = 'DATA_SAVE_SUCCESS';

function dataRequest(dataType) {
  return {
    type: DATA_REQUEST,
    dataType
  }
}

function dataSuccess(dataType, data) {
  return {
    type: DATA_SUCCESS,
    dataType,
    data
  }
}

function dataSaveSuccess(dataType, data) {
  return {
    type: DATA_SAVE_SUCCESS,
    dataType,
    data
  }
}

function dataError(dataType, error) {
  return {
    type: DATA_ERROR,
    dataType,
    error
  }
}

function dataSaveRequest(dataType) {
  return {
    type: DATA_SAVE_REQUEST,
    dataType
  }
}

export function saveData(dataType, apiMethod, ...args) {
  return (dispatch) => {
    dispatch(dataSaveRequest(dataType));
    return apiMethod.call(api, ...args)
      .then(response => {
        if (response.error) {
          return dispatch(dataError(dataType, response.error));
        }
        return dispatch(dataSaveSuccess(dataType, response));
      })
  }
}

// Force reload only in the sense that is skips checking the isFetching status..
export function forceReloadData(dataType, apiMethod, ...args) {
  return (dispatch) => {
    return load(dispatch, dataType, apiMethod, ...args);
  }
}

export function loadData(dataType, apiMethod, ...args) {
  return (dispatch, getState) => {
    if (getState().apiData[dataType].isFetching) {
      return;
    }
    return load(dispatch, dataType, apiMethod, ...args);
  }
}

function load(dispatch, dataType, apiMethod, ...args) {
  dispatch(dataRequest(dataType));
  return apiMethod.call(api, ...args)
    .then((response => {
      if (response.error) {
        return dispatch(dataError(dataType, response.error));
      }
      return dispatch(dataSuccess(dataType, response));
    }));
}

/**
 * Same as loadData but doesn't dispatch the dataRequest action.
 */
export function overrideData(dataType, apiMethod, ...args) {
  return (dispatch) => {
    return apiMethod.call(api, ...args)
      .then((response => {
        if (response.error) {
          return dispatch(dataError(dataType, response.error));
        }
        return dispatch(dataSuccess(dataType, response));
      }));
  }
}

export const dataTypes = {
  PRACTICE_SIGNS: 'practice-signs-data',
  PRACTICE_LITERALS: 'practice-literals-data',
  PRACTICE_IMAGES: 'practice-images-data',
  PROFILE: 'profile-data',
  CATEGORY_STATUSES: 'category-statuses-data',
  BOOK_STATUSES: 'book-statuses-data',
  NEWS: 'news-data',
  ACTIVITY_COUNT: 'activity-count',
  SEARCH_USERS: 'search-users-data',
  LIST_USERS: 'list-users-data',
  LIST_TEACHERS: 'list-teachers-data',
  LOAD_ORDERED_BATCHES: 'load-ordered-batches',
  RESULT: 'result-data',
  PENDING_USER_CREATES: 'pending-user-creates',
  SCHOOL_SETTINGS: 'school-settings',
  SCHOOL_CATEGORY_SETTINGS: 'school-category-settings',

  // Admin types. Note: Admin data type ids end up in regular build too.
  SCHOOL_SEARCH: 'school-search-data',
  USER_SEARCH: 'user-search-data',
  SCHOOL_ADD: 'school-add'
};