import React from 'react';
import {connect} from 'react-redux';
import LanguageSelector from '../feature/LanguageSelector';
import {useNavigate} from 'react-router-dom';
import {MdCopyright} from 'react-icons/md';
import FacebookLike from '../feature/FacebookLike';
import Translated from '../feature/Translated';

const Footer = props => {

  const navigate = useNavigate();

  let langSelector;
  if (props.loggedIn) {
    langSelector = (
      <div className="footer-item">
        <LanguageSelector dropdownLangs={props.dropdownLangs} drop="up" className="hide-xs"/>
      </div>
    )
  }

  const companyName = COMPANY_NAME;
  // Note: Added year to footer, so that site feels more up to date.

  return (
    <div id="footer-container" className="footer-container">
      <div className="footer-item">
        <span className="touchable" onClick={() => navigate('/privacy-policy')}>
          {companyName} <MdCopyright/> <Translated translationKey="dyn_year_inject"/>
        </span>
      </div>
      {/*<FacebookLike/>*/}
      {langSelector}
    </div>
  );

}

let mapStateToProps = (state) => {
  return {
    loggedIn: state.login.get('token') !== null,
  };
};

export default connect(mapStateToProps)(Footer);