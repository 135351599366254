import React, {Component} from 'react';
import {connect} from 'react-redux';
import api from '../../api';
import PreloadMiddlePage from '../pages/PreloadMiddlePage';
import EditUser from '../feature/EditUser';
import Alert from 'react-bootstrap/Alert';
import UserCategoryStatus from './../feature/UserCategoryStatus';
import TestResultsList from './../feature/results/TestResultsList';
import Translated from './../feature/Translated';
import {IoAlertCircle} from 'react-icons/io5';
import UserBookStatuses from '../feature/UserBookStatuses';
import {Roles, fillsRole} from '../../data/roles';
import withRouter from "../feature/withRouter";

class EditUserPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.saveUserData = this.saveUserData.bind(this);
    this.state = {
      userProfile: null,
      isFetching: true,
      isSaving: false,
      error: null
    }
  }

  saveUserData(userData, callback) {
    this.setState({
      isSaving: true
    });
    api.saveProfile(userData)
      .then(response => {
        if (response.error) {
          return this.setState({
            error: response.error,
            isSaving: false
          });
        }
        this.setState({
          userProfile: response,
          isSaving: false
        });
        if (callback) {
          callback();
        }
      });
  }

  componentDidMount() {
    this.fetchProfile(this.props.match.params.userId);
  }

  fetchProfile(userId) {
    this.setState({
      isFetching: true,
      userProfile: null,
      error: null
    });

    api.getProfile(userId, true)
      .then(response => {
        if (response.error) {
          this.setState({
            userProfile: null,
            isFetching: false,
            error: response.error
          })
        } else {
          this.setState({
            userProfile: response,
            isFetching: false,
            error: null
          })
        }
      });
  }

  renderError(error) {
    return (<Alert id="edit-student-error"><Translated translationKey={error} /></Alert>);
  }

  renderCategoryStatus(categoryStatuses) {
    if (categoryStatuses.length === 0) {
      return <Alert><IoAlertCircle />&nbsp;&nbsp;&nbsp;<Translated translationKey="no_exercises_done"/></Alert>
    }
    const userId = this.state.userProfile.userData.id;
    return categoryStatuses.map(catStatus => {
      return (
        <UserCategoryStatus
        userId={userId}
        category={catStatus.category}
        acceptedAmount={catStatus.acceptedTestAmount}
        totalAcceptedRequired={catStatus.acceptedTestsRequired}
        rejectedAmount={catStatus.rejectedTestAmount}
        simple={true}
        key={catStatus.category}
        />)
    });
  }

  renderStatus(profile) {
    const isTeacher = fillsRole(profile.userData.role, Roles.TEACHER);
    if (isTeacher) {
      return null;
    }
    return (
      <div>
        {this.renderCategoryStatus(profile.status.categoryStatus)}
        <UserBookStatuses userId={profile.userData.id}/>
        {profile.status.categoryStatus.length === 0 ? null : <TestResultsList userId={profile.userData.id}/>}
      </div>
    );
  }

  renderUser(profile) {
    const isTeacher = fillsRole(profile.userData.role, Roles.TEACHER);
    return (
      <div id="edit-student-page">
        {isTeacher ? <h2><Translated translationKey='teacher' /></h2> : null}
        <EditUser profile={profile} saveUserMethod={this.saveUserData} isSaving={this.state.isSaving}/>
        <br />
        {profile.userData.activationDate ? this.renderStatus(profile) : null }
      </div>
    );
  }

  render() {
    if (this.state.isFetching) {
      return (<PreloadMiddlePage />);
    }

    return (
      <div id="edit-student-page" className="page-container max-width-container">
        {this.state.error ? this.renderError(this.state.error) : null}
        {this.state.userProfile ? this.renderUser(this.state.userProfile) : null}
      </div>
    );
  }
}

export default withRouter(connect()(EditUserPage));
