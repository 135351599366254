import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Translated from '../Translated';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from '../Spinner';


class FullTestSubmit extends Component {

  static propTypes = {
    answers: PropTypes.object.isRequired,
    error: PropTypes.string,
    isSending: PropTypes.bool,
    review: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired
  };

  render() {
    let markedInfo;
    let error;
    const markedCount = Object.values(this.props.answers).reduce((sum, answer) => (answer === 'd' ? sum + 1 : sum), 0);
    if (markedCount > 0) {
      markedInfo = <div className="warning"><Translated translationKey="marked_questions"/></div>;
    }
    if (this.props.error) {
      error = <Alert><Translated translationKey={this.props.error}/></Alert>
    }
    let content;
    if (this.props.isSending) {
      content = <Spinner />
    } else {
      content = (
        <div>
          {markedInfo}
          {error}
          <ButtonToolbar className="buttons">
            <Button id="full-test-review-answers" onClick={this.props.review} className="uppercase">
              <Translated translationKey="full_test_review_answers"/>
            </Button>
            <Button id="full-test-submit-answers" onClick={this.props.submit} className="uppercase">
              <Translated translationKey="full_test_submit_answers"/>
            </Button>
          </ButtonToolbar>
        </div>
      )
    }
    return (
      <div className="middle">
        <div className="submit-info">
          <div>
            <Translated translationKey="full_test_review_info"/>
          </div>
          {content}
        </div>
      </div>
    )

  }
}

export default FullTestSubmit;