import React, {Component} from 'react';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import Translated from './Translated';
import {loadData, dataTypes} from '../../actions/apiDataActions'
import api from '../../api';
import AnimatedNumber from 'animated-number-react';

let refreshIntervalSecs = 10;

class ActivityCount extends Component {

  constructor(props, context) {
    super(props, context);
    this.loadFreshData = this.loadFreshData.bind(this);
    this.cancelRefreshing = this.cancelRefreshing.bind(this);
    this.refreshTimeout = null;
    this.currentValue = null;
    this.isRefreshActive = true;
  }

  componentDidUpdate() {
    if (this.props.activityCount.error) {
      this.cancelRefreshing();
    }
  }

  componentDidMount() {
    this.loadFreshData();
    this.refreshTimeout = setTimeout(this.loadFreshData, refreshIntervalSecs);
  }

  componentWillUnmount() {
    this.cancelRefreshing();
  }

  loadFreshData() {
    this.refreshCount = this.refreshCount ? this.refreshCount  + 1 : 0;
    this.props.dispatch(loadData(dataTypes.ACTIVITY_COUNT, api.getActivityCount));
    refreshIntervalSecs *= 1.5;
    if (refreshIntervalSecs > 100000) {
      refreshIntervalSecs = 100000;
    }
    if (this.isRefreshActive) {
      this.refreshTimeout = setTimeout(this.loadFreshData, refreshIntervalSecs * 1000);
    }
  }

  cancelRefreshing() {
    this.isRefreshActive = false;
    clearTimeout(this.refreshTimeout);
  }

  renderLoader() {
    return (
      <div>
        <Spinner className="primary semi-transparent"/>
      </div>
    );
  }

  render() {
    if (this.props.activityCount.isFetching && !this.currentValue) {
      // Render loader only on first time, no on updates
      return this.renderLoader();
    }

    let activityCount;

    if (this.props.activityCount.data){
      activityCount = this.props.activityCount.data;
      this.currentValue = activityCount;
    } else if (this.currentValue) {
      activityCount = this.currentValue;
    } else {
      return null;
    }

    const examsToday = activityCount.exams.today;
    const wrapped = (
      <AnimatedNumber
        value={examsToday}
        duration={2000}
        formatValue={Math.floor}
      />
    );

    if (this.props.type === 'large-number') {
      return (
        <div className={this.props.className}>
          <Translated translationKey="exams_today" className="large semi-transparent"/>
          <div className="largest strong">
            {wrapped}
          </div>
        </div>
      );
    }

    if (this.props.type === 'large') {
      return (
        <div className={this.props.className}>
          <Translated translationKey="exams_today" className="large"/>
          <div className="largest strong">
            {wrapped}
          </div>
        </div>
      );

    }

    return (
      <div className={this.props.className}>
        <Translated translationKey="exams_today"/>
        <div className="larger">
          {wrapped}
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return ({
    activityCount: state.apiData[dataTypes.ACTIVITY_COUNT]
  });
};

export default connect(mapStateToProps)(ActivityCount);
