import React, {Component} from 'react';
import {connect} from 'react-redux'
import withTranslate from '../feature/withTranslate';
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import {closeNavigation} from '../../actions/actionCreators';
import {logout} from '../../actions/loginActions';
import {useNavigate} from 'react-router-dom';

const MeMenu = props => {

  const navigate = useNavigate();

  const handleListItemClick = eventId => {
    switch (eventId) {
      case 'contact':
      case 'me':
      case 'settings':
        navigate('/' + eventId);
        break;
      case 'logout':
        props.dispatch(closeNavigation());
        props.dispatch(logout(navigate));
        break;
    }
  }

  const buildMenuItem = (id, labelKey) => {
    const loc = props.translate(labelKey);
    return (
      <Dropdown.Item id={id} key={id} onClick={() => handleListItemClick(id)}><span>{loc}</span></Dropdown.Item>);
  }

  if (!props.token || !props.data) {
    return null;
  }
  let menuTitle = props.translate('menu');

  const infoItems = props.data.map((item) => (buildMenuItem(item.id, item.titleKey)));
  const classes = 'me-menu ' + props.className;
  return (
    <DropdownButton className={classes} aria-label="Menu" role="presentation" id="me-actions" title={menuTitle}>
      {infoItems}
    </DropdownButton>
  )

}

let mapStateToProps = (state) => {
  return ({
    token: state.login.get('token')
  });
};

export default connect(mapStateToProps)(withTranslate(MeMenu));

