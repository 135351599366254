import React, {Component} from 'react';
import Translated from './../Translated';
import withRouter from '../withRouter';

class ShopDeliveryTermsNote extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <Translated translationKey="delivery_terms_label" className="touchable"
                    onClick={() => this.props.navigate('/cancelling-terms')}/>
      </div>
    );
  }
}

export default withRouter(ShopDeliveryTermsNote);
