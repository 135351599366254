import React, { useEffect} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import PreloadMiddlePage from './../pages/PreloadMiddlePage';
import {loadData, dataTypes} from '../../actions/apiDataActions'
import api from '../../api';
import BookStatus from './BookStatus';
import Translated from './Translated';
import Button from 'react-bootstrap/Button';

const ActiveBookStatus = props => {

  const navigate = useNavigate();

  useEffect(() => {
    if (props.userId === null) {
      return;
    }
    if (!props.bookStatus.data && !props.bookStatus.isFetching) {
      props.dispatch(loadData(dataTypes.BOOK_STATUSES, api.getBookStatuses, props.userId, props.locale));
    }
  }, [props]);

  const renderReadButton = () => {
    if (props.hideButton) {
      return null;
    }
    return (
      <Button onClick={() => navigate('/book')}>
        <Translated translationKey="read_book"/>
      </Button>
    );
  }


  if (!props.settings.get('isReceived') || !props.bookStatus.data) {
    return <PreloadMiddlePage/>;
  }

  const selectedCategory = props.settings.get('vehicleCategory');
  if (selectedCategory === null) {
    return null;
  }

  const data = props.bookStatus.data;
  let bookStatus = data.find(status => status.category === selectedCategory);
  if (!bookStatus) {
    return null;
  }

  return (
    <BookStatus bookStatus={bookStatus} className={props.className}>
      {renderReadButton()}
    </BookStatus>
  );

}

let mapStateToProps = (state) => {
  return ({
    settings: state.settings,
    bookStatus: state.apiData[dataTypes.BOOK_STATUSES],
    userId: state.login.get('token') ? state.login.get('token').get('userId') : null,
    locale: state.Intl.locale
  });
};

export default connect(mapStateToProps)(ActiveBookStatus);
