import React, {Component} from 'react';
import {connect} from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics'
import api from '../../../api';

const PRODUCTS_CATEGORY = 'teaching_permit';

function withProductLoader(subCategory) {
  return function (WrappedComponent) {
    class WithProductLoader extends Component {

      constructor(props, context) {
        super(props, context);
        this.state = {
          isLoadingProducts: false,
          products: null,
          productsError: null
        }
      }

      componentDidMount() {
        this.setState({isLoadingProducts: true});
        api.getProducts(PRODUCTS_CATEGORY, subCategory, this.props.locale)
          .then(response => {
            if (response.error) {
              return this.setState({
                isLoadingProducts: false,
                productsError: response.error
              })
            }
            this.setState({
              isLoadingProducts: false,
              products: response.products
            })
          });
      }

      render() {
        return <WrappedComponent {...this.props} {...this.state}/>
      }
    }

    WithProductLoader.displayName = `withProductLoader(${getDisplayName(WrappedComponent)})`;
    hoistNonReactStatics(WithProductLoader, WrappedComponent);

    let mapStateToProps = (state) => {
      return ({
        locale: state.Intl.locale
      });
    };
    return connect(mapStateToProps)(WithProductLoader);
  }
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withProductLoader;

