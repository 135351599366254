import {Map} from 'immutable';
import {
  SET_REFERRAL,
  SET_AD_ID
} from '../actions/referralActions';

function getInitialState() {
  return Map({
    referral: null,
    adId: null
  });
}

function referralReducer(state = getInitialState(), action) {
  switch (action.type) {
    case SET_REFERRAL:
      return state.merge({
        referralId: action.referralId,
      });

    case SET_AD_ID:
      return state.merge({
        adId: action.adId,
      });

    default:
      return state;
  }
}

export default referralReducer;