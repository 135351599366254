import React, {Component} from 'react';
import {connect} from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics'
import api from '../../api';

function withPracticeTypes(WrappedComponent) {
  class WithPracticeTypes extends Component {

    constructor(props, context) {
      super(props, context);
      this.state = {
        category: null,
        isLoadingPracticeTypes: false,
        practiceTypes: null,
        practiceTypesError: null
      }
    }

    componentDidUpdate() {
      const currentCategory = this.state.category;
      const newCategory = this.props.settings && this.props.settings.get('vehicleCategory');
      if (newCategory !== currentCategory) {
        this.update();
      }
    }

    componentDidMount() {
      this.update();
    }

    update() {
      const category = this.props.settings && this.props.settings.get('vehicleCategory');

      if (this.state.isLoadingPracticeTypes) {
        return;
      }

      if (!category) {
        return this.setState({
          category: null,
          practiceTypes: null
        });
      }

      this.setState({
        isLoadingPracticeTypes: true,
        category
      });

      api.getPracticeTypes(category)
        .then(response => {
          if (response.error) {
            return this.setState({
              isLoadingPracticeTypes: false,
              practiceTypesError: response.error
            })
          }
          this.setState({
            isLoadingPracticeTypes: false,
            practiceTypes: response,
          })
        });
    }

    render() {
      return <WrappedComponent {...this.props} {...this.state}/>
    }
  }

  WithPracticeTypes.displayName = `withPracticeTypes(${getDisplayName(WrappedComponent)})`;
  hoistNonReactStatics(WithPracticeTypes, WrappedComponent);

  let mapStateToProps = (state) => {
    return ({
      settings: state.settings,
      locale: state.Intl.locale
    });
  };
  return connect(mapStateToProps)(WithPracticeTypes);

}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withPracticeTypes;

