import {
  DATA_REQUEST,
  DATA_SUCCESS,
  DATA_ERROR,
  DATA_SAVE_REQUEST,
  DATA_SAVE_SUCCESS,
  dataTypes
} from '../actions/apiDataActions';

const initialState = {};
Object.values(dataTypes).forEach(dataType => {
  initialState[dataType] = {
    isFetching: false,
    isSending: false,
    data: null,
    error: null
  };
});

function updateDataType(state, dataType, props) {
  const currentState = state[dataType];
  return {
    ...state,
    [dataType]: {
      ...currentState,
      ...props
    }
  }
}

function apiDataReducer(state = initialState, action) {
  switch (action.type) {
    case DATA_REQUEST:
      return updateDataType(state, action.dataType, {
        isFetching: true,
        data: null,
        error: null
      });

    case DATA_SUCCESS:
      return updateDataType(state, action.dataType, {
        isFetching: false,
        data: action.data,
        error: null
      });

    case DATA_ERROR:
      return updateDataType(state, action.dataType, {
        isFetching: false,
        isSending: false,
        data: null,
        error: action.error
      });

    case DATA_SAVE_REQUEST:
      return updateDataType(state, action.dataType, {
        isSending: true
      });

    case DATA_SAVE_SUCCESS:
      return updateDataType(state, action.dataType, {
        isSending: false,
        data: action.data,
        error: null
      });

    default:
      return state;
  }
}

export default apiDataReducer;
