import React, {Component} from 'react';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import api from '../../api';
import MarkdownUtil from '../../utils/markdown';
import Translated from './Translated';
import Alert from 'react-bootstrap/Alert';
import Logger from '../../utils/logger';
import {overrideData, dataTypes} from '../../actions/apiDataActions'

class BookContent extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      source: null
    }
  }

  componentDidMount() {
    // Book content can be set as a source. Otherwise it will be fetched based on the id.
    const source = this.props.source;
    if (source) {
      this.sendReadLog(source.id, source.materialId);
      return this.setState({source: source.markdown});
    }

    api.getBookContent(this.props.contentId, this.props.locale)
      .then(content => {
        if (content.error) {
          this.setState({
            error: content.error
          })
        } else {
          this.sendReadLog(content.id, content.materialId);
          this.setState({
            source: content.markdown
          })
        }
    })
  }

  sendReadLog(contentId, materialId) {
    if (this.props.token) {
      this.props.dispatch(overrideData(dataTypes.BOOK_STATUSES, api.sendBookContentStatus, contentId, materialId));
    }
  }

  render() {
    let content;
    if (this.state.error) {
      content = <Alert><Translated translationKey={this.state.error}/></Alert>
    } else if (!this.state.source) {
      content = <Spinner className="primary"/>;
    } else {
      content = MarkdownUtil.render(this.state.source, 'section');
    }
    return (
      <div className="book-content">
        {content}
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return ({
    locale: state.Intl.locale,
    token: state.login.get('token')
  });
};

export default connect(mapStateToProps)(BookContent);
