import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withTranslate from '../withTranslate';
import classNames from 'classnames';
import {IoChevronDown} from 'react-icons/io5';
import FullTestButton from './FullTestButton';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import Translated from '../Translated';

class FullTestQuestionSelectorForm extends Component {

  static propTypes = {
    answers: PropTypes.object.isRequired,
    questions: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    select: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.state = {
      showOverlay: false
    };
  }

  render() {
    const allowSelecting = Object.values(this.props.answers).length === this.props.questions.length;
    const onClick = (e) => {
      if (!allowSelecting) {
        this.overlayTarget = e.target;
        this.setState({showOverlay: true});
      }
    };
    let select;
    if (allowSelecting) {
      select = (
          <select onChange={this.onChange} value={this.props.index}>
            {this.props.questions.map((question, index) => {
              const marked = this.props.answers[question.id] === 'd' ? ' ' + this.props.translate('marked') : null;
              return <option key={index} value={index}>{(index + 1) + marked}</option>
            })}
          </select>
      )
    }

    return (
        <div className="hide-sm-and-gt">
          <FullTestButton onClick={onClick} id="full-test-question-selector-form">
            <div id="question-selector-form" className={classNames('question-selector-form', this.props.className)}>
              <span>{this.props.index + 1}</span>
              <IoChevronDown/>
              {select}
            </div>
          </FullTestButton>
          {this.renderOverlay()}
        </div>
    );
  }

  onChange(e) {
    e.target.blur(); // Mobile Safari cannot do unbinding without blurring.
    const index = parseInt(e.target.value);
    this.props.select(index);
  }

  renderOverlay() {
    const onHide = () => {
      this.setState({showOverlay: false});
    };
    return (
        <Overlay
            show={this.state.showOverlay}
            target={this.overlayTarget}
            placement="top"
            onHide={onHide}
            rootClose>
          <Popover id="question-selector-tooltip">
            <Popover.Body>
              <Translated translationKey="select_question_info"/>
            </Popover.Body>
          </Popover>
        </Overlay>
    )
  }

}

export default withTranslate(FullTestQuestionSelectorForm);