import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loginWithAutoliittoIntegration, loginWithAutoliittoSSO} from '../../actions/loginActions'
import Spinner from '../feature/Spinner';
import Alert from 'react-bootstrap/Alert';
import Translated from '../feature/Translated';
import queryString from 'query-string';
import withRouter from "../feature/withRouter";

export class LoginAutoliittoPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.tryLogin = this.tryLogin.bind(this);
  }

  componentDidMount() {
    this.tryLogin();
  }

  componentDidUpdate(prevProps) {
    if (this.props.token && !prevProps.token) {
      this.props.navigate('/', {state: this.props.location.state, replace: true});
    }
  }

  tryLogin() {
    if (!this.props.isLoggingIn) {
      let params = queryString.parse(this.props.location.search);
      if (params.code) {
        const authCode = decodeURIComponent(params.code);
        this.props.dispatch(loginWithAutoliittoSSO(authCode));
      } else {
        let externalUseId = decodeURIComponent(params.user);
        let hash = decodeURIComponent(params.hash);
        let externalTime = decodeURIComponent(params.stamp);
        this.props.dispatch(loginWithAutoliittoIntegration(externalUseId, hash, externalTime));
      }
    }
  };

  render() {
    return (
        <div id="login-page" className="page-container page-gutter max-width-container">
          <div className="front-center-wrapper">
            <div className="login-form-container fade-in">
              {
                this.props.loginError && !this.props.isLoggingIn
                  ? <Alert><Translated translationKey={this.props.loginError}/></Alert>
                  : <Spinner/>
              }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.login.get('token'),
    isLoggingIn: state.login.get('isFetching'),
    loginError: state.login.get('error'),
  }
}

export default withRouter(connect(mapStateToProps)(LoginAutoliittoPage));
