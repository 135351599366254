import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Translated from '../Translated';
import Button from 'react-bootstrap/Button'

class FullTestContinue extends Component {

  static propTypes = {
    restart: PropTypes.func.isRequired,
    restore: PropTypes.func.isRequired
  };

  render() {
    return (
        <div id="full-test-continue-container" className="max-width-container centered">
          <div className="blue-box">
            <h3>
              <Translated translationKey="fulltest_continue"/>
            </h3>
            <div className="centered">
              <br />
              <Button id="full-test-start-new" onClick={this.props.restart} bsstyle="info">
                <Translated translationKey="no"/>
              </Button>&nbsp;&nbsp;&nbsp;
              <Button id="full-test-continue" onClick={this.props.restore}>
                <Translated translationKey="yes"/>
              </Button>
            </div>
          </div>
        </div>
    )
  }
}

export default FullTestContinue;