import React, {Component} from 'react';
import {connect} from 'react-redux';
import { IoCheckmarkSharp } from "react-icons/io5";
import Translated from './Translated';
import Circle from './ProgressBarCircle';
import {overrideData, dataTypes} from '../../actions/apiDataActions';
import api from '../../api';

const MARK_AS_READ_DELAY_S = 30;

class BookReadStatusMarker extends Component {

  constructor(props, context) {
    super(props, context);
    this.tick = this.tick.bind(this);
    this.state = {
      secondsPassed: 0
    };
  }

  componentDidMount() {
    if (!this.props.isRead) {
      this.interval = setInterval(this.tick, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick() {
    const secondsPassed = this.state.secondsPassed + 1;
    if (secondsPassed >= MARK_AS_READ_DELAY_S) {
      clearInterval(this.interval);
      if (this.props.sendStatus) {
        const chapter = this.props.chapter;
        this.props.dispatch(overrideData(dataTypes.BOOK_STATUSES, api.sendBookContentStatus, chapter.id, chapter.materialId));
      }
    }
    this.setState({secondsPassed});
  }

  render() {
    let content;
    let key;
    if (this.props.isRead) {
      key = 'book_chapter_marked_read';
      content = <IoCheckmarkSharp className="glyph fade-in delay" />;
    } else {
      if (!this.props.showProgress) {
        return null;
      }
      key = 'book_chapter_marked_read_in';
      const percentage = this.state.secondsPassed / MARK_AS_READ_DELAY_S;
      content = <Circle
        percentage={percentage * 100}
        initialAnimate={false}
        className="progressbar-small progressbar-yellow progressbar-light-bg progressbar-no-text"
      />;
    }

    return (
      <div className="book-status-marker">
        <Translated className="status-label" translationKey={key}/>
        {content}
      </div>
    )
  }
}

export default connect()(BookReadStatusMarker);
