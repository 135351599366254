import React, {Component} from 'react';
import api from '../../api';

const DELAY_SHORT_S = 5;
const DELAY_LONG_S = 60;

class BookReadStatusMarker extends Component {

  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    this.timeoutShort = setTimeout(() => this.markAsRead(DELAY_SHORT_S), DELAY_SHORT_S * 1000);
    this.timeoutLong = setTimeout(() => this.markAsRead(DELAY_LONG_S), DELAY_LONG_S * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timeoutShort);
    clearInterval(this.timeoutLong);
  }

  render() {
    return null;
  }

  markAsRead(chapterIdPostfix) {
    const id = this.props.chapter.id + '-simple-' + chapterIdPostfix;
    api.sendBookContentStatus(id, this.props.chapter.materialId);
  }
}

export default BookReadStatusMarker;
