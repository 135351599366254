import React, {Component} from 'react';
import api from '../../api';
import Translated from '../feature/Translated';
import Spinner from './../feature/Spinner';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import ControlLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import withTranslate from '../feature/withTranslate';
import {IoPerson, IoMail} from "react-icons/io5";
import {BsFillTelephoneFill} from 'react-icons/bs';
import Button from 'react-bootstrap/Button';
import LicenseAgreementNote from "../feature/LicenseAgreementNote";

class ContactTrialPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitContact = this.submitContact.bind(this);

    this.state = {
      sending: false,
      submitted: false,
      error: null,
      form: {
        schoolName: '',
        streetAddress: '',
        zipCode: '',
        postalLocation: '',
        contactPerson: '',
        phoneNumber: '',
        email: '',
        message: ''
      }
    }
  }

  submitContact() {
    if (this.isFormValid()) {
      this.setState({
        sending: true,
        error: null
      });
      api.sendTrialContact(this.state.form)
        .then((response) => {
          if (response.error) {
            return this.setState({
              sending: false,
              submitted: false,
              error: response.error
            });
          }
          this.setState({
            sending: false,
            submitted: true
          });
        });
    }
  }

  isFormValid() {
    const requiredFields = ['schoolName', 'streetAddress', 'zipCode', 'postalLocation', 'contactPerson', 'phoneNumber', 'email'];
    let isValid = true;
    requiredFields.forEach(key => {
      const value = this.state.form[key];
      if (value === '') {
        isValid = false;
      }
    });
    return isValid;
  }

  handleInputChange(e) {
    let formState = Object.assign(this.state.form, {[e.target.name]: e.target.value});
    this.setState({form: formState});
  }

  makeFormGroup(label, props) {
    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <FormControl {...props}/>
      </FormGroup>
    );
  }

  renderForm() {
    const header = <div className="centered"><Translated translationKey="trial_request"/></div>;
    const errorMessage = this.state.error ?
      <Alert bsstyle="warning"><Translated translationKey="message_error_sending"/></Alert> :
      null;

    return (
      <div id="contact-page" className="contact-wrapper fade-in">

        <div className="contact-description blue-inner-box">
          <Translated translationKey="contact_description"/>
        </div>

        <Card className="contact-panel" header={header}>
          <Card.Body>
            <form>
              <fieldset disabled={this.state.sending}>
                {this.makeFormGroup(
                  this.props.translate('school_name'),
                  {
                    name: 'schoolName',
                    placeholder: this.props.translate('school_name'),
                    value: this.state.form.schoolName,
                    onChange: this.handleInputChange
                  }
                )}

                {this.makeFormGroup(
                  this.props.translate('street_address'),
                  {
                    name: 'streetAddress',
                    placeholder: this.props.translate('street_address'),
                    value: this.state.form.streetAddress,
                    onChange: this.handleInputChange
                  }
                )}

                <FormGroup>
                  <ControlLabel><Translated translationKey="postal_address"/></ControlLabel>
                  <InputGroup>
                    <FormControl
                      type="text"
                      name="zipCode"
                      value={this.state.form.zipCode}
                      onChange={(e) => this.handleInputChange(e)}
                      className="contactform-zip"
                      placeholder={this.props.translate('zip_code')}/>
                    <FormControl
                      type="text"
                      name="postalLocation"
                      value={this.state.form.postalLocation}
                      onChange={(e) => this.handleInputChange(e)}
                      placeholder={this.props.translate('postal_location')}/>
                  </InputGroup>
                </FormGroup>

                <br/>

                <FormGroup id="person">
                  <ControlLabel><Translated translationKey="contact_person"/></ControlLabel>
                  <InputGroup>
                    <InputGroup.Text><IoPerson className="white"/></InputGroup.Text>
                    <FormControl
                      type="text"
                      name="contactPerson"
                      value={this.state.form.contactPerson}
                      onChange={(e) => this.handleInputChange(e)}/>
                  </InputGroup>
                </FormGroup>

                <FormGroup id="phone">
                  <ControlLabel><Translated translationKey="phone_number"/></ControlLabel>
                  <InputGroup>
                    <InputGroup.Text><BsFillTelephoneFill className="white" /></InputGroup.Text>
                    <FormControl
                      type="text"
                      name="phoneNumber"
                      value={this.state.form.phoneNumber}
                      onChange={(e) => this.handleInputChange(e)}/>
                  </InputGroup>
                </FormGroup>

                <FormGroup id="email">
                  <ControlLabel><Translated translationKey="email"/></ControlLabel>
                  <InputGroup>
                    <InputGroup.Text><IoMail className="white" /></InputGroup.Text>
                    <FormControl
                      type="text"
                      name="email"
                      value={this.state.form.email}
                      onChange={(e) => this.handleInputChange(e)}/>
                  </InputGroup>
                </FormGroup>

                <FormGroup controlId="message">
                  <ControlLabel><Translated translationKey="message"/></ControlLabel>
                  <FormControl
                    name="message"
                    componentclass="textarea"
                    value={this.state.form.message}
                    onChange={(e) => this.handleInputChange(e)}
                    className="contact-message-text-area"/>
                </FormGroup>

                <div className="centered">
                  {errorMessage}
                  <Button id="submit-contact" disabled={!this.isFormValid() || this.state.sending}
                          onClick={() => this.submitContact()}>
                    <Translated translationKey="send"/>
                  </Button>{this.state.sending ? <Spinner className="primary"/> : null}
                </div>
              </fieldset>
            </form>
          </Card.Body>
        </Card>
        <LicenseAgreementNote className="semi-dark-inner-box centered"/>
      </div>
    );
  }

  renderSubmittedMessage() {
    return (
      <div id="contact-submitted" className="contact-wrapper fade-in">
        <Card className="blue-box">
          <Card.Body>
            <h3 className="centered"><IoMail />&nbsp;&nbsp;<Translated translationKey="message_sent"/>
            </h3>
          </Card.Body>
        </Card>
      </div>
    );
  }

  render() {
    let content;
    if (this.state.submitted) {
      content = this.renderSubmittedMessage();
    } else {
      content = this.renderForm();
    }

    return (
      <div className="page-container max-width-container">
        <div className="centered">
          {content}
        </div>
      </div>
    );
  }


}

export default withTranslate(ContactTrialPage);
