import React from 'react';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Translated from '../feature/Translated';
import {setVehicleCategory} from '../../actions/settingsActions';

const ResetVehicleCategoryButton = (props) => {
  return (
    <Button
      className={props.className}
      bsstyle={props.bsStyle}
      onClick={() => props.dispatch(setVehicleCategory(null, props.userId))}>
      <Translated translationKey={props.labelKey ? props.labelKey : 'change_category'}/>
    </Button>
  )
};

export default connect()(ResetVehicleCategoryButton);
