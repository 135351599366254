import React, {Component} from 'react';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import Translated from './Translated';
import api from '../../api';
import UserList from '../feature/UserList';

class LatestAcceptedUsers extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      error: null,
      isFetching: false,
      acceptedStudents: []
    }
  }

  fetchStudents() {
    if (this.props.token) {
      this.setState({isFetching: true});
      api.getLatestAcceptedUsers(this.props.token.get('schoolId'))
        .then((response) => {
          // TODO: check that component is still mounted!
          if (response.error) {
            return this.setState({
              error: response.error,
              isFetching: false,
              acceptedStudents: []
            });
          }
          this.setState({
            error: null,
            isFetching: false,
            acceptedStudents: response.users
          });
        });
    }
  }

  componentDidMount() {
    this.fetchStudents();
  }

  renderLoader() {
    return (
      <div>
        <Spinner className="primary"/>
      </div>
    );
  }

  renderUserList() {
    return (
      <div>
        <UserList users={this.state.acceptedStudents} noUsersTranslationKey="no_search_results"/>
      </div>
    )
  }

  render() {
    return (
      <div>
        <h3><Translated translationKey="last_approved_students"/></h3>
        { this.state.isFetching ? this.renderLoader() : this.renderUserList() }
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return ({
    token: state.login.get('token')
  });
};

export default connect(mapStateToProps)(LatestAcceptedUsers);
