import React from 'react';
import Translated from './Translated';
import Circle from './ProgressBarCircle';
import { FiBook } from "react-icons/fi";
import classnames from 'classnames';

const BookStatus = props => {

    const bookStatus = props.bookStatus;
    const progress = Math.ceil(bookStatus.progress * 100); // Ceiling is used to get immediate progress for user
    const progressVisual = Math.max(progress, 1);

    const circle = <Circle
      className="progressbar-large-progress"
      percentage={progressVisual}
      initialAnimate={true}
      progressText={progress + '%'}
    />;

    const classes = classnames('user-category-status white-box vertical-margins', props.className);
    const bookTitleKey = 'book_title_'+bookStatus.bookId;

    return (
      <div id='book-status' className={classes}>
        <div className="user-category-progress-container">
          {circle}
          <div className="user-category-progress-note">
            <Translated translationKey="book_progress"/>
          </div>
        </div>
        <div className="user-category-data">
          <div className="user-category-title">
            <FiBook /> <Translated translationKey={bookTitleKey} />
          </div>
          {props.children}
        </div>
      </div>
    )
}

export default BookStatus;
