import React, {Component} from 'react';
import {connect} from 'react-redux';
import BookBrowserSimple from '../feature/BookBrowserSimple';
import Translated from '../feature/Translated';
import PreloadMiddlePage from './PreloadMiddlePage';
import api from '../../api';
import Alert from 'react-bootstrap/Alert';
import {IoAlertOutline} from 'react-icons/io5';
import withRouter from "../feature/withRouter";

/**
 * Book page that takes book id and language as props.
 * Uses browser that does not show read status.
 * Used for teacher.
 */
class BookSimplePage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      initialized: false,
      bookData: null
    }
  }

  componentDidMount() {
    if (!this.state.initialized) {
      this.tryInit();
    }
  }

  componentDidUpdate() {
    if (!this.state.initialized) {
      this.tryInit();
    }
  }

  tryInit() {
    if (!this.props.settings.get('isReceived')) {
      return;
    }
    this.setState({
      initialized: true
    });
    const bookId = this.props.match.params.bookId;
    const locale = this.props.match.params.language;

    const books = this.props.settings.get('data').get('availableBooks');
    const booksForId = books.filter(book => book.get('id') === bookId);
    if (booksForId.size === 0) {
      return this.setState({
        error: 'book-not-found'
      });
    }
    const book = booksForId.find(book => book.get('lang') === locale);
    if (!book) {
      return this.setState({
        error: 'no-book-for-language'
      });
    }
    if (!book.get('hasLicense')) {
      return this.setState({
        error: 'no-license-for-book'
      });
    }

    api.getBookIndex(bookId, locale)
      .then(bookIndex => {
        if (bookIndex.error) {
          return this.setState({
            error: bookIndex.error
          })
        }
        bookIndex.content.sort((a, b) => a.positionIndex - b.positionIndex);
        this.setState({
          bookData: bookIndex.content,
          status: bookIndex.status
        })
      });
  }

  render() {
    let content;
    if (this.state.error) {
      content = <Alert><IoAlertOutline/> <Translated translationKey={this.state.error}/></Alert>;
    } else if (!this.state.initialized || !this.state.bookData) {
      content = <PreloadMiddlePage />
    } else {
      content = <BookBrowserSimple
        bookId={this.props.match.params.bookId}
        language={this.props.match.params.language}
        chapters={this.state.bookData}
        chapterId={this.props.match.params.chapterId}/>
    }
    return (
      <div id="book-page" className="page-container">
        <div className="max-width-container centered">
          {content}
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return ({
    settings: state.settings
  });
};

export default withRouter(connect(mapStateToProps)(BookSimplePage));

