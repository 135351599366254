import React, {Component} from 'react';
import classNames from 'classnames';
import {resolveOptionClasses} from '../PractiseUtils';

class SignsQuestionFromText extends Component {

  constructor(props) {
    super(props);
    this.setAnswer = this.props.setAnswer;
    this.state = {
      question: props.question,
      isAnswerResolved: false,
      answer: props.answer,
      options: [],
      index: props.index
    };
    this.ANSWER_DELAY_MS = 400;
  }

  componentDidUpdate(prevProps) {
    if (this.props.answer !== prevProps.answer) {
      this.setState({answer: this.props.answer});
    }
  }

  componentWillUnmount() {
    clearTimeout(this.answerDelay);
  }

  setAnswerDelayed(optionId) {
    if (this.state.answer) {
      return;
    }
    this.setState({
      answer: optionId
    });

    if (!this.state.answer) {
      this.answerDelay = setTimeout(() => {
        this.setState({
          isAnswerResolved: true
        });
        this.setAnswer(optionId);
      }, this.ANSWER_DELAY_MS);
    }
  }

  renderTextQuestion(question) {
    return(<div className="practise-text-question centered fade-in short-delay">
      {question.text}?
    </div>);
  }

  renderImageOption(option, optionType, explanationText) {
    const key = option.id + optionType;
    let classes = classNames(
      'practise-option-columns-item',
      'practise-signs-image-option',
      'fade-in',
      'long');
    classes = resolveOptionClasses(classes, optionType, this.state.answer, this.state.isAnswerResolved, this.state.question.correctAnswer);

    const src = option.imageUrl;

    return (
      <div id={'sign-question-image-option-'+optionType} key={key} onClick={() => this.setAnswerDelayed(optionType)} className={classes}>
        <img src={src} />
        <div className="hide-sm-and-gt">{this.renderExplanationText(explanationText)}</div>
      </div>);
  }

  renderExplanationText(text, key) {
    let visibility = this.state.answer ? ' fade-in delay' : ' transparent';
    let classes = classNames('practise-option-columns-item', visibility);
    return(<p key={key} className={classes}>{text}</p>);
  }

  render() {

    // Question
    const questionElement = this.renderTextQuestion(this.state.question.question);

    // Options
    const options = [];
    Object.keys(this.state.question.options).forEach((optionType) => {
      let explanation = this.state.question.options[optionType].text;
      options.push(this.renderImageOption(this.state.question.options[optionType], optionType, explanation));
    });

    // Explanations
    const explanations = [];
    Object.keys(this.state.question.options).forEach((optionType, i) => {
      let explanation = this.state.question.options[optionType].text;
      explanations.push(this.renderExplanationText(explanation, i));
    });

    return (
      <div id="signs-question-container" className={'practise-question-wrapper ' + this.props.className }>
        {questionElement}
        <br />
        <div className={'practise-option-columns fade-in delay'}>
          {options}
        </div>
        <div className={'practise-option-columns hide-xs'}>
          {explanations}
        </div>
      </div>
    )
  }
}

export default SignsQuestionFromText;