import React, {Component} from 'react';
import Translated from './../Translated';
import FacebookLike from '../FacebookLike';
import FullTestDetailsModal from '../fulltest/FullTestDetailsModal';
import Button from "react-bootstrap/Button";

class TestResultStatus extends Component {

  constructor(props, context) {
    super(props, context);
    this.category = this.props.result.category.toLowerCase();
    this.state = {
      showDetailsModal: false,
    }
  }

  literalsFaultAmount() {
    return this.props.result.aggregatedLiteralFaultAmount;
  }

  imagesFaultAmount() {
    return this.props.result.imagesFaultAmount;
  }

  risksFaultAmount() {
    return this.props.result.risksFaultAmount;
  }

  maxImagesFaultAmount() {
    return this.props.result.maxImageFaults;
  }

  maxLiteralFaultAmount() {
    return this.props.result.maxLiteralFaults;
  }

  maxRisksFaultAmount() {
    return this.props.result.maxRiskFaults;
  }

  renderDetailsModal() {
    return (
      <FullTestDetailsModal
        showModal={this.state.showDetailsModal}
        closeModal={() => this.setState({ showDetailsModal: false })}
        fulltest={this.props.result}
      />
    );
  }

  renderFaultElement(typeLocalizationKey, faultAmount, maxFaults) {
    if (faultAmount === undefined) {
      return null;
    }
    const additionalStyle = faultAmount > maxFaults ? ' rejected' : ' accepted';
    return (
      <div className="test-result-type-wrapper">
        <div className={"test-result-status-fault-title" + additionalStyle}>
          <Translated translationKey={typeLocalizationKey}/>
        </div>
        <div className={"test-result-status-fault-content" + additionalStyle}>
          <div className="test-result-fault-amount">
            {faultAmount}
          </div>
          <div className="test-result-fault-max">
            (<Translated translationKey='test_results_max' values={{'max': maxFaults}}/>)
          </div>
        </div>
      </div>
    );
  }

  renderTextResult() {
    if (this.props.result.isAccepted) {
      return (<Translated translationKey="test_result_accepted"/>);
    } else {
      return (<Translated translationKey="test_result_rejected"/>);
    }
  }

  renderFacebookLike() {
    if (this.props.result.isAccepted) {
      return <FacebookLike/>
    } else {
      return null;
    }
  }

  render() {
    return (
      <div id="test-result-status-container" className="test-results-status-wrapper">
        {this.renderDetailsModal()}
        <div className="test-results-title">
          <Translated translationKey="test-results-title"/>
        </div>
        <div className="test-result-types-wrapper">
          {this.renderFaultElement('literals', this.literalsFaultAmount(), this.maxLiteralFaultAmount())}
          {this.renderFaultElement('risks', this.risksFaultAmount(), this.maxRisksFaultAmount())}
          {this.renderFaultElement('images', this.imagesFaultAmount(), this.maxImagesFaultAmount())}
        </div>
        <div className="test-result-text">
          {this.renderTextResult()}<br />
        <Button onClick={() => this.setState({showDetailsModal:true})}><Translated translationKey="more_info" /></Button>
        </div>
        {/*<div>*/}
        {/*  {this.renderFacebookLike()}*/}
        {/*</div>*/}
      </div>
    );
  }
}

export default TestResultStatus;
