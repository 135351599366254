import React, {Component} from 'react';
import {connect} from 'react-redux';
import Translated from '../feature/Translated';
import api from '../../api';
import Button from 'react-bootstrap/Button';
import {acceptTos} from "../../actions/authActions";
import Spinner from "../feature/Spinner";
import Alert from "react-bootstrap/Alert";

class TermsOfServicePage extends Component {

  constructor(props) {
    super(props);
    this.accept = this.accept.bind(this);
    this.state = {
      agreementContent: null,
      isLoading: false,
      error: null
    }
  }

  componentDidMount() {
    this.setState({isLoading: true});
    api.getAgreementContent('terms-of-service', this.props.requiredTos, this.props.lang)
      .then(resp => {
        if (resp.error) {
          return this.setState({
            error: resp.error,
            isLoading: false
          });
        }
        this.setState({
          agreementContent: resp,
          isLoading: false
        })
      })
  }

  accept() {
    const content = this.state.agreementContent;
    this.setState({isLoading: true});
    api.acceptAgreement(content.id)
      .then(resp => {
        if (resp.error) {
          return this.setState({
            error: resp.error,
            isLoading: false
          });
        }

        this.setState({
          isLoading: false
        });
        this.props.dispatch(acceptTos(content.version));
      })
  }

  render() {
    let content;
    if (this.state.isLoading) {
      content = <Spinner/>;
    } else if (this.state.error) {
      content = <Alert><Translated translationKey={this.state.error}/></Alert>
    } else if (this.state.agreementContent) {
      content = (
        <React.Fragment>
          <span className='terms-of-service' dangerouslySetInnerHTML={{__html: this.state.agreementContent.content}}/>
          <div>
            <Button onClick={this.accept}>
              <Translated translationKey="accept"/>
            </Button>
          </div>
        </React.Fragment>
      )
    }

    return (
      <div id="terms-of-service-page" className="page-container">
        <div className="max-width-container">
          {content}

        </div>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return ({
    lang: state.Intl.locale,
    requiredTos: state.login.get('requiredTos'),
    acceptedTos: state.login.get('acceptedTos')
  });
};

export default connect(mapStateToProps)(TermsOfServicePage);

