import React, {Component} from 'react';
import TextInputForm from '../feature/TextInputForm';
import Spinner from '../feature/Spinner';
import {connect} from 'react-redux'
import Translated from '../feature/Translated';
import withTranslate from '../feature/withTranslate';
import Alert from 'react-bootstrap/Alert';
import {loadData, dataTypes} from '../../actions/apiDataActions'
import api from '../../api';
import Tracker from '../../utils/tracker';
import UserList from '../feature/UserList';

class UsersSearchPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.searchUsers = this.searchUsers.bind(this);
    this.state = {
      term: undefined,
    }
  }

  searchUsers(term) {
    if (this.props.isFetching) {
      return;
    }
    // Filter out pass hash
    const cleanedTerm = term ? term.replace(/#/g, '') : '';
    this.props.dispatch(loadData(dataTypes.SEARCH_USERS, api.searchUsers, cleanedTerm));
    Tracker.logEventLabel('search', 'search-users', cleanedTerm);
    this.setState({searchTypeKey: 'search'});
  }

  renderUserList() {
    if (!this.props.hasFetched) {
      return null;
    }
    if (this.props.isFetching) {
      return <Spinner className="primary"/>;
    }
    return (
      <div>
        <UserList users={this.props.users} noUsersTranslationKey="no_search_results"/>
      </div>
    )
  }

  render() {
    const placeholderText = this.props.translate('search_user');
    const submitText = this.props.translate('search_do');
    return (
      <div>
        <h3><Translated translationKey="search"/></h3>
        <TextInputForm
          onSubmit={this.searchUsers}
          minLength={4} id="user-search-container"
          iconAddon='search'
          placeholderText={placeholderText}
          submitText={submitText}
          className='fade-in'
        />
        <br />
        {this.props.isFetching
          ? <Spinner className="primary"/>
          : this.renderUserList()
        }
        {this.props.error
          ? <Alert><Translated translationKey={this.props.error}/></Alert>
          : null
        }
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  const search = state.apiData[dataTypes.SEARCH_USERS];
  return {
    users: search.data ? search.data.users : null,
    isFetching: search.isFetching,
    hasFetched: search.data && !search.isFetching,
    error: search.error,
  }
};

export default connect(mapStateToProps)(withTranslate(UsersSearchPage));
