import React, {Component} from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics'
import LocalStorageUtil from '../../../utils/localStorageUtil';

function withPendingOrderCheck(WrappedComponent) {

  class WithPendingOrderCheck extends Component {

    componentDidMount() {
      const pendingOrder = LocalStorageUtil.getForUser('anon', 'pending-order');
      if (pendingOrder) {
        const orderId = pendingOrder.id;
        const token = pendingOrder.token;
        this.props.navigate(`/order/${orderId}/${token}`);
      }
    }

    render() {
      return <WrappedComponent {...this.props}/>
    }
  }

  WithPendingOrderCheck.displayName = `withPendingOrderCheck(${getDisplayName(WrappedComponent)})`;
  return hoistNonReactStatics(WithPendingOrderCheck, WrappedComponent)
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withPendingOrderCheck;

