import React from 'react';
import withTranslate from '../withTranslate';
import classNames from 'classnames';

const FullTestButton = (props) => {
  const classNameProps = {
    'selected': props.selected,
    'disabled': props.disabled
  };
  const classes = classNames('button uppercase', classNameProps, props.className);
  const clickHandler = props.disabled ? null : props.onClick;
  return (
      <div id={props.id} className={classes} onClick={clickHandler}>
        {props.translate(props.label)}
        {props.children}
      </div>
  )
};

export default withTranslate(FullTestButton)
