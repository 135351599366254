import React, {useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Tracker from '../../utils/tracker';

const NotFoundPage = () => {
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    Tracker.logPageView("404-page_" + pathname);
  }, [location.pathname]);

  return  (
    <div id="error-404-page" className="not-found-page">
      <img src={require('../../assets/man_lost.svg')}/>
      <h2 className="fade-in">404 Sivua ei löytynyt</h2>
      <h4 className="fade-in delay">Unknown page</h4>
      <h4 className="fade-in delay">Webbsidan inte finns</h4>
      <h4 className="fade-in delay">Неизвестный веб-страницы</h4>
      <br />
      <Link className="fade-in long-delay" to="/">Ajokaista &gt;</Link>
  </div>
  );
}

export default NotFoundPage