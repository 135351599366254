import React from 'react';
import {connect} from 'react-redux';
import Translated from './Translated';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {FaGraduationCap} from 'react-icons/fa';
import {useNavigate} from 'react-router-dom';

/**
 * Forward to user's test results. Use user id of one is given and current logged in user otherwise.
 */
const UserResultsPreview = props => {

  const navigate = useNavigate();

  const onClick = (e) => {
    if (props.role !== 'student') {
      navigate('/results/' + props.userId);
    } else {
      navigate('/results');
    }
  }

  const renderNoTests = () => {
    const classes = classnames('blue-box tight vertical-middle on-sides', props.className);
    return (
      <div className={classes}>
        <div className="vertical-middle">
          <FaGraduationCap className="largest right-margin"/>
          <Translated translationKey="no_exercises_done" className="larger"/>
        </div>
      </div>
    );
  }

  const renderTests = () => {
    const classes = classnames('blue-inner-box tight vertical-middle on-sides phone-column', props.className);
    const totalDone = props.acceptedAmount + props.rejectedAmount;
    return (
      <div className={classes}>
        <div className="vertical-middle">
          <FaGraduationCap className="largest right-margin"/>
          <Translated translationKey="total_tests_done" values={{'num': totalDone}}
                      className="larger"/>
        </div>
        <Button onClick={onClick} className="pull-right">
          <Translated translationKey="all_test_results"/>
        </Button>
      </div>
    );
  }

  const totalDone = props.acceptedAmount + props.rejectedAmount;
  if (totalDone === 0) {
    return renderNoTests();
  } else {
    return renderTests();
  }

}

UserResultsPreview.propTypes = {
  userId: PropTypes.string,
  acceptedAmount: PropTypes.number,
  rejectedAmount: PropTypes.number,
};

export default connect()(UserResultsPreview);