import React, {Component} from 'react';
import ImageSet from '../ImageSet';
import PriceDisplay from './PriceDisplay';
import Translated from '../Translated';
import Button from 'react-bootstrap/Button';
import saleImg from '../../../assets/webshop/ale_rot.png';
import withTranslate from "../withTranslate";

class ProductPreview extends Component {

  constructor(props, context) {
    super(props, context);
    this.onInspect = this.onInspect.bind(this);
  }

  renderBook(product) {
    const components = product.productData.components ? product.productData.components : [];
    let bookElem = null;
    components.forEach(c => {
      if (c.type === 'book') {
        bookElem = <div><Translated className="book-promo" translationKey="contains_book"/></div>
      }
    });
    return bookElem;
  }

  renderPeriod(product) {
    const productData = product.productData;
    let content;
    if (productData.periodMonths) {
      content = productData.periodMonths + ' ' + this.props.translate('months');
    } else if (productData.periodDays) {
      content =  productData.periodDays + ' ' + this.props.translate('days');
    }
    return <div className="larger">{content}</div>
  }

  onInspect() {
    this.props.onInspect(this.props.product);
  }

  render() {

    const product = this.props.product;
    const category = product.productData.category;
    const imageAltText = 'License category icon ' + category;
    const imageSrc = '../../assets/category/' + category.toLowerCase() + '_white.png';

    return (
        <div className="product-preview-item" key={product.id}>
          <div className="top heavy-bump-once">
            <ImageSet src={imageSrc} alt={imageAltText}/>
            <div className="title">{product.localizations['title_short']}</div>
            {this.renderPeriod(product)}
            {this.renderBook(product)}
          </div>
          <div className="bottom">
            <div className="bottom-margin"><PriceDisplay size="large" price={product.vatPrice}/></div>
            <Button bsstyle="warning" onClick={this.onInspect} aria-label="Open" role="presentation"><Translated translationKey="open"/></Button>
          </div>
          {
            this.props.product.isSale
              ? <img src={saleImg} className="product-preview-sale" alt="On sale"/>
              : null
          }
        </div>
    );
  }

}

export default withTranslate(ProductPreview);

