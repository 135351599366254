import React, {Component} from 'react';
import { IoMenu, IoChevronForward } from "react-icons/io5";
import Button from 'react-bootstrap/Button';
import Translated from './Translated';

class BookNavigation extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const i = this.props.current;
    const disablePrev = i <= 1;
    const disableNext = i >= this.props.total;
    return (
      <div className="book-navigation">
        <Button className="book-navigation-item" onClick={() => this.props.openChapter(0)}>
          <IoMenu /> <Translated translationKey="book_menu"/>
        </Button>

        <span className="book-navigation-item"/>

        <div>
          <Button className="book-navigation-item" onClick={() => this.props.openChapter(i - 1)} disabled={disablePrev}>
            <IoMenu /> <Translated translationKey="previous"/>
          </Button>

          <span className="book-navigation-item">{this.props.current}/{this.props.total}</span>

          <Button className="book-navigation-item" onClick={() => this.props.openChapter(i + 1)} disabled={disableNext}>
            <Translated translationKey="next"/> <IoChevronForward />
          </Button>
        </div>

      </div>
    );
  }
}

export default BookNavigation;
