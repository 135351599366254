import React from 'react';
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt({html: true, xhtmlOut: true});

class MarkdownUtil {

  static render(source, key) {
    const html = md.render(source);
    return (<div key={key} dangerouslySetInnerHTML={{__html: html}}/>);
  }
}

export default MarkdownUtil;