import React, {Component} from 'react';
import {IoTrashSharp} from 'react-icons/io5';
import PriceDisplay from './PriceDisplay';

class ProductInCart extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const product = this.props.product;
    return (
      <div className="product-cart-item" key={product.id}>
        <div>
          <IoTrashSharp className="touchable largest"
                     onClick={() => this.props.onRemoveFromCart(this.props.product)}/>
          <div className="title inline left-margin">{product.localizations['title']}</div>
        </div>
        <div>
          <PriceDisplay size="medium" price={product.vatPrice}/>
        </div>
      </div>
    )
  }

}

export default ProductInCart;

