import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import PreloadMiddlePage from '../../../pages/PreloadMiddlePage';
import Line from '../../ProgressBarLine';
import Translated from '../../Translated';
import ImagesQuestion from './ImagesQuestion';
import {loadData, dataTypes} from '../../../../actions/apiDataActions';
import api from '../../../../api';
import ResultFeedbackForm from '../../results/ResultFeedbackForm';
import MapUtil from '../../../../utils/map';
import BookModalLink from '../../BookModalLink';
import {IoChatbubblesSharp} from 'react-icons/io5';
import VehicleInfo from '../../VehicleInfo';
import {hasVehicleInfo} from '../../../../data/vehicleInfoCategories';

class PracticeImagesMain extends Component {

  constructor(props, context) {
    super(props, context);
    this.setAnswer = this.setAnswer.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.start = this.start.bind(this);
    this.preloadNextImage = this.preloadNextImage.bind(this);
    this.state = {
      questionIndex: 0,
      answers: [],
      seenIds: [],
      showFeedback: false
    }
  }

  componentDidMount() {
    this.start();
  }

  componentDidUpdate(prevProps, prevState) {
    const prevQuestions = prevProps.images.data ? prevProps.images.data.questions : null;
    const currentQuestions = this.props.images.data ? this.props.images.data.questions : null;
    if (!prevQuestions && currentQuestions || prevState.questionIndex !== this.state.questionIndex) {
      this.preloadNextImage();
    }
  }

  preloadNextImage() {
    if (!this.props.images.data || !this.props.images.data.questions) {
      return;
    }
    const nextIndex = this.state.questionIndex + 1;
    if (nextIndex < this.props.images.data.questions.length) {
      const nextQuestion = this.props.images.data.questions[nextIndex];
      const img = new Image();
      img.src = nextQuestion.imageUrl;
    }
  }

  setAnswer(answer) {
    const answers = this.state.answers.slice();
    if (answers[this.state.questionIndex]) {
      return;
    }
    answers[this.state.questionIndex] = answer;

    const seenIds = this.state.seenIds.slice();
    const question = this.props.images.data.questions[this.state.questionIndex];
    // Mark as seen if answered correctly, otherwise it may be asked even on the very next round again.
    if (question.isAssertionTruthy === answer) {
      if (this.state.seenIds.indexOf(question.id) === -1) {
        seenIds.push(question.id);
        if (seenIds.length > 30) {
          seenIds.shift();
        }
      }
    }

    this.setState({answers, seenIds});
  }

  nextQuestion() {
    const nextIndex = this.state.questionIndex + 1;
    this.setState({
      questionIndex: nextIndex,
      showFeedback: false
    });

    if (nextIndex === this.props.images.data.questions.length) {
      let faults = 0;
      this.props.images.data.questions.forEach((question, index) => {
        let answer = this.state.answers[index];
        if (answer !== question.isAssertionTruthy) {
          faults++;
        }
      });

      const total = this.props.images.data.questions.length;
      const correct = total - faults;

      this.props.setResult({total, correct});

      if (!this.props.isDemo) {
        api.sendPracticeLog(this.props.category, 'images', this.props.images.data.questions.length, faults);
      }
    }
  }

  fetchQuestions() {
    const category = this.props.category;
    const locale = this.props.locale;
    let action;
    if (this.props.isDemo) {
      action = loadData(dataTypes.PRACTICE_IMAGES, api.getDemoImages, category, locale);
    } else {
      action = loadData(dataTypes.PRACTICE_IMAGES, api.createPracticeImages, category, locale, this.state.seenIds);
    }
    this.props.dispatch(action);
  }

  start() {
    BookModalLink.setAllowPopover(true);
    this.fetchQuestions();
    this.setState({
      questionIndex: 0,
      answers: []
    });
  }

  renderQuestion() {
    const question = this.props.images.data.questions[this.state.questionIndex];
    const answer = this.state.questionIndex < this.state.answers.length ? this.state.answers[this.state.questionIndex] : null;
    const current = this.state.questionIndex + 1;
    const total = this.props.images.data.questions.length;
    const progressRatio = current / total;

    let explanations;
    let buttons;
    let bookContent;
    if (answer !== null) {
      explanations = this.renderExplanations(question.descriptions);
      bookContent = this.renderBookContent(question, answer);
      buttons = this.renderAnswerContent(question);
    }

    const vehicleInfo = hasVehicleInfo(this.props.category) && (
      <div className="blue-box semi-dark-inner-box tight fade-in long-delay">
        <VehicleInfo category={this.props.category}/>
      </div>
    );

    return (
      <div className="practise-images-container practise-wrapper">
        <div className="practise-progress-wrapper fade-in long-delay">
          <Line
            className="yellow"
            percentage={progressRatio * 100}
          />
        </div>
        <ImagesQuestion question={question}
                        index={this.state.questionIndex}
                        setAnswer={this.setAnswer}
                        key={question.id}
        />
        {explanations}
        {bookContent}
        {buttons}
        {vehicleInfo}
      </div>
    )
  }

  renderAnswerContent(question) {
    let feedbackButton;
    if (!this.props.isDemo) {
      feedbackButton = (
        <Button bsstyle="link" className="fade-in long-delay"
                onClick={() => this.setState({showFeedback: !this.state.showFeedback})}>
          <IoChatbubblesSharp className="larger text-bottom"/>
          <Translated translationKey="question_report"/>
        </Button>
      )
    } else {
      feedbackButton = <span>&nbsp;</span>
    }


    let feedbackForm;
    if (this.state.showFeedback) {
      feedbackForm = <ResultFeedbackForm keepOpen questionType="image" questionId={question.id} className="fade-in"/>
    }
    return (
      <div>
        <div className="min-button-space literals-buttons-container">
          {feedbackButton}
          <Button id="practise-images-next-button" onClick={this.nextQuestion} className="fade-in delay">
            <Translated translationKey="next"/>
          </Button>
        </div>
        {feedbackForm}
      </div>
    );
  }

  getBookReferencesMap() {
    const bookReferences = this.props.images.data.bookReferences;
    return MapUtil.mapFromObject(bookReferences);
  }

  renderBookContent(question, answer) {
    const bookReferencesMap = this.getBookReferencesMap();

    // Do not add book reference link if there is none, or user does not have them linked (no rights)
    if (question.bookReferenceIds.length === 0 || !bookReferencesMap || bookReferencesMap.size === 0) {
      return null;
    }
    const animate = question.isAssertionTruthy !== answer;
    const bookLinks = question.bookReferenceIds.map(refId => {
      const content = bookReferencesMap.get(refId);
      return <BookModalLink content={content} animate={animate} key={refId}/>;
    });
    return (
      <div className="book-links fade-in">
        {bookLinks}
      </div>
    );
  }

  renderExplanations(explanations) {
    const elems = explanations.map((exp) => {

      const src = exp.imageUrl;

      let img;
      if (src) {
        img = (
          <div>
            <img className="test-result-image-explanation-image" src={src}/>
          </div>
        )
      }
      return (
        <div key={exp.id} className="test-result-image-explanation-wrapper fade-in">
          {img}
          <div>
            {exp.text}
          </div>
        </div>
      )
    });
    return (
      <div className="practise-images-explanations-container blue-box">
        {elems}
      </div>
    )
  }

  render() {
    let content;
    if (this.props.images.error) {
      content = <PreloadMiddlePage error={this.props.images.error}/>
    } else if (this.props.images.isFetching || !this.props.images.data) {
      content = <PreloadMiddlePage/>
    } else if (this.state.questionIndex < this.props.images.data.questions.length) {
      content = this.renderQuestion();
    } else {
      return null;
    }
    return content;
  }
}

let mapStateToProps = (state) => {
  return ({
    images: state.apiData[dataTypes.PRACTICE_IMAGES],
    locale: state.Intl.locale
  });
};

export default connect(mapStateToProps)(PracticeImagesMain);
