import {Map, fromJS} from 'immutable';
import {
  SETTINGS_REQUESTED,
  SETTINGS_RECEIVED,
  SET_VEHICLE_CATEGORY,
  SETTINGS_ERROR,
  SETTINGS_RESET
} from '../actions/settingsActions';

/**
 * Settings contains both server side given values, selectable options
 * and settings previous selected by a user (stored locally)
 */

function getInitialState() {
  return Map({
    isRequesting: false,
    isReceived: false,
    data: null,
    error: null,
    vehicleCategory: null
  });
}

function settingsReducer(state = getInitialState(), action) {
  switch (action.type) {
    case SETTINGS_REQUESTED:
      return state.merge({
        isRequesting: true,
        isReceived: false,
        error: null,
        data: null,
      });
      break;

    case SETTINGS_RECEIVED:
      const newSettings = action.settings;
      return state.merge(fromJS({
        isRequesting: false,
        isReceived: true,
        error: null,
        data: newSettings,
      }));
      break;

    case SET_VEHICLE_CATEGORY:
      return state.merge({
        vehicleCategory: action.category
      });
      break;

    case SETTINGS_ERROR:
      return getInitialState().merge({
        error: action.error,
        isReceived: false
      });
      break;

    case SETTINGS_RESET:
      return getInitialState();

    default:
      return state;
  }
}

export default settingsReducer;