import React, {Component} from 'react';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Translated from './../feature/Translated';
import withRouter from './withRouter';
import LocalStorageUtil from '../../utils/localStorageUtil';
import {Roles} from '../../data/roles';

class LoginMethodsModal extends Component {

  constructor(props, context) {
    super(props, context);

    this.close = this.close.bind(this);
    this.openSettings = this.openSettings.bind(this);

    this.state = {
      show: false
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token) {
      this.setState({
        show: this.resolveStatus()
      });
    }
  }

  resolveStatus() {
    const token = this.props.token;
    if (!token || token.get('facebook') || token.get('email') || token.get('integrationPlatform') || token.get('role') !== Roles.STUDENT) {
      return false;
    }

    const userId = token.get('userId');
    let status = LocalStorageUtil.getForUser(userId, 'login-methods-modal');
    if (!status) {
      status = {
        count: 0,
        prev: 0
      };
    }

    const interval = 12 * 60 * 60 * 1000;
    const now = Date.now();

    if (status.count < 2 && now - status.prev > interval) {
      status.count++;
      status.prev = now;
      LocalStorageUtil.setForUser(userId, 'login-methods-modal', status);
      return true;
    }

    return false;
  }

  close() {
    this.setState({show: false});
  }

  openSettings() {
    this.close();
    this.props.navigate('/settings');
  }

  render() {
    if (!this.state.show) {
      return null;
    }
    return (
      <Modal show={true} className="centered">
        <Modal.Header>
          <Modal.Title>
            <Translated translationKey="login_methods_modal_title"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Translated translationKey="login_methods_modal_body"/>
        </Modal.Body>

        <Modal.Footer className="centered">

          <Button bsstyle="link" onClick={this.close}>
            <Translated translationKey="close"/>
          </Button>
          <Button onClick={this.openSettings}>
            <Translated translationKey="view"/>
          </Button>

        </Modal.Footer>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    token: state.login.get('token')
  }
}

export default withRouter(connect(mapStateToProps)(LoginMethodsModal));