import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import {toggleNavigation, closeNavigation, fetchNavigationData} from '../../actions/actionCreators';
import {logout} from '../../actions/loginActions'
import Button from 'react-bootstrap/Button';
import {IoMenu} from "react-icons/io5";
import MeMenu from './MeMenu';
import NavigationPanel from './../feature/NavigationPanel'
import Collapse from 'react-bootstrap/Collapse';
import withTranslate from '../feature/withTranslate';
import ImageSet from './ImageSet';
import classNames from 'classnames';
import {Roles, fillsRole} from '../../data/roles';
import LanguageSelector from '../feature/LanguageSelector';
import SettingsUtil from '../../utils/settingsUtil';

const Header = props => {
  const {navigation, dispatch} = props;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!navigation.data) {
      dispatch(fetchNavigationData());
    }
  }, [navigation, dispatch])

  const toggleFullMenu = () => {
    props.dispatch(toggleNavigation());
  }

  const navigationListener = eventId => {
    switch (eventId) {
      case 'logout':
        props.dispatch(closeNavigation());
        props.dispatch(logout(navigate));
        break;

      default:
        navigate('/' + eventId);
        props.dispatch(closeNavigation());
        break;
    }
  }

  const buildHeaderNavItem = (id, labelKey) => {
    const label = props.translate(labelKey);
    const isActive = location.pathname.indexOf('/' + id) === 0;

    return (
      <div className={classNames('header-navi-item', {'active': isActive})}
           id={id}
           key={id}
           onClick={() => navigationListener(id)}>
        <span>{label}</span>
      </div>);
  }

  const renderHeaderNavigation = () => {
    // Horizontal non-toggleable menu.
    // Header navigation is visible only on large screens.
    const data = props.navigation.data;

    if (!data) {
      // No data yet, render nothing
      return null;
    }

    const isTeacher = fillsRole(props.role, Roles.TEACHER);

    // Resolve which data to use
    let resolvedData;
    if (!props.loggedIn) {
      // Anonymous front content
      resolvedData = data.anonymousHeader;
    } else {
      if (!props.settings.get('isReceived')) {
        resolvedData = null;
      } else {
        resolvedData = isTeacher ? data.teacherHeader : data.studentHeader;
      }
    }

    let navItems = null;
    if (resolvedData) {
      // Remove 'book' from navigation if no books available or user is teacher.
      if (isTeacher || !SettingsUtil.hasBookForCurrentCategory(props.settings)) {
        resolvedData = resolvedData.filter(item => item.id !== 'book');
      }

      // Remove 'books' item from navigation if user has no licensed books (on any language)
      if (!SettingsUtil.hasLicensedBooks(props.settings)) {
        resolvedData = resolvedData.filter(item => item.id !== 'books');
      }

      // Build each item
      navItems = resolvedData.map((item) => (buildHeaderNavItem(item.id, item.titleKey)));
    }

    // Wrap navigation, it is visible only on large screens
    return (
      <div className="header-navi hide-xs">
        {navItems}
      </div>
    );
  }

  const renderMobileNavigationPanel = () => {
    // Mobile navigation panel opens below header, mainly from the mobile burger menu button
    // It is not visible on large screens.
    const data = props.navigation.data;
    if (!data) {
      return null;
    }

    const isTeacher = fillsRole(props.role, Roles.TEACHER);

    let resolvedData;
    if (!props.loggedIn) {
      resolvedData = data.anonymousNavigation;
    } else {
      resolvedData = isTeacher ? data.teacherNavigation : data.studentNavigation;
    }

    if (isTeacher || !SettingsUtil.hasBookForCurrentCategory(props.settings) && resolvedData) {
      // Filter book menu out if user does not have a license for it
      resolvedData = resolvedData.filter(item => item.id !== 'book');
    }

    if (!SettingsUtil.hasLicensedBooks(props.settings)) {
      // Remove 'books' item from navigation if user has no licensed books (on any language)
      resolvedData = resolvedData.filter(item => item.id !== 'books');
    }

    return (
      <Collapse in={props.navigation.active} className="hide-sm-and-gt full-navigation">
        <div>
          <NavigationPanel data={resolvedData} listener={navigationListener} languageSelector={true}/>
        </div>
      </Collapse>
    );
  }

  const renderHeaderMenu = () => {
    // Renders burger toggle button menu when on small screen
    // and otherwise MeMenu or Language selector (depending on logged in status)

    let resolvedMeMenuData;
    const data = props.navigation.data;
    if (data && props.settings) {
      resolvedMeMenuData = fillsRole(props.role, Roles.TEACHER) ? data.teacherMeMenu : data.studentMeMenu;
      if (!SettingsUtil.hasBookForCurrentCategory(props.settings) && resolvedMeMenuData) {
        // Filter book menu out if user does not have a license for it
        resolvedMeMenuData = resolvedMeMenuData.filter(item => item.id !== 'book');
      }
    } else {
      resolvedMeMenuData = [];
    }

    // Language selector is at header when user is anonymous (at front) and in footer when logged in.
    const largeScreenMenu = props.loggedIn
      ? <MeMenu data={resolvedMeMenuData}/>
      : <LanguageSelector/>;

    const mobileBurgerMenu = (
      <Button aria-label="Main menu" role="presentation" onClick={() => toggleFullMenu()}>
        <IoMenu/>
      </Button>
    );

    return (
      <div className="header-menu">
        <div className="hide-xs">
          {largeScreenMenu}
        </div>
        <div className="hide-sm-and-gt">
          {mobileBurgerMenu}
        </div>
      </div>
    );
  }

  const renderLogo = () => {
    const logo = SERVICE_LOGO;
    const logoUrl = '../../assets/' + logo + '-small.png';
    // Logo is visible everywhere but on front page.
    const isOnHomePage = location.pathname === '/' && props.loggedIn === false;

    return isOnHomePage ? null : (
      <div className="header-logo">
        <ImageSet src={logoUrl} className="fade-in" onClick={() => navigationListener('')} alt="Home logo"/>
      </div>
    );
  }

  const renderProfileSchool = () => {
    if (!fillsRole(props.role, Roles.TEACHER)) {
      return null;
    }
    return (<div className="header-content page-gutter header-school">{props.schoolName}</div>);
  }

  return (
    <div id="header-container" className="header-container">
      <div className="header-content page-gutter">
        {renderLogo()}
        {renderHeaderNavigation()}
        {renderHeaderMenu()}
      </div>
      {renderMobileNavigationPanel()}
      <div className="break"/>
      {renderProfileSchool()}
    </div>
  )

}

let mapStateToProps = (state) => {
  const token = state.login.get('token');
  const role = token ? token.get('role') : Roles.STUDENT;
  const schoolName = token ? token.get('schoolName') : null;

  return {
    navigation: state.navigation,
    role: role,
    loggedIn: token !== null,
    schoolName,
    settings: state.settings
  };
};


export default connect(mapStateToProps)(withTranslate(Header));
