import React, {Component} from 'react';
import {connect} from 'react-redux';
import DateUtil from '../../../utils/date';
import api from '../../../api';
import withRouter from '../withRouter';
import Spinner from '../Spinner';
import { FcApproval , FcHighPriority } from "react-icons/fc";
import Button from 'react-bootstrap/Button';
import Translated from '../Translated';
import ImageSet from '../ImageSet';

const MAX_RESULTS_LIMIT = 50;

class TestResultsList extends Component {

  constructor(props, context) {
    super(props, context);
    this.renderResultItem = this.renderResultItem.bind(this);

    this.state = {
      isFetching: true,
      results: null,
      error: null,
      isShowingAll: false,
    }
  }

  componentDidMount() {
    return api.getResultsOverview(this.props.userId)
      .then(response => {
        if (response.error) {
          return this.setState({
            isFetching: false,
            results: null,
            error: response.error
          });
        }

        let results = response.results.sort((a,b) => a.endDate > b.endDate ? -1 : 1);

        results.forEach((result,i) => {
          result.index = results.length - i;
        })

        this.setState({
          isFetching: false,
          results,
          error: null
        });
      });
  }

  onResultClicked(resultId, canShowResult) {
    if (!canShowResult){
      return;
    }
    this.props.navigate('/result/' + resultId);
  }

  renderResultItem(entry, canShowTestResult) {
    const qualificationIcon = entry.isAccepted
      ? <span><FcApproval fontSize="large" className="results-list-accepted"/></span>
      : <span><FcHighPriority fontSize="large" className="results-list-rejected"/></span>;
    const categoryIcon = `../../assets/category/${entry.category}.png`;
    const rowClass = canShowTestResult ? 'user-result-row touchable' : 'user-result-row not-allowed semi-transparent';
    
    return (
      <div
        key={entry.id}
        onClick={() => this.onResultClicked(entry.id, canShowTestResult)}
        className={rowClass}
      >
        <div className="user-results-data">
          <strong>{entry.index}.</strong>&nbsp;
          {DateUtil.dateTime(entry.endDate)}&nbsp;
          <ImageSet src={categoryIcon} alt={"category-icon"} />
        </div>
        <div>
          {this.getFaultsString(entry)}&nbsp;
          {qualificationIcon}
        </div>
      </div>
    );
  }

  getFaultsString(result) {
    const faults = [];
    if (result.literalIds || result.signIds) {
      faults.push(result.aggregatedLiteralFaultAmount);
    }
    if (result.riskIds) {
      faults.push(result.risksFaultAmount);
    }
    if (result.imageIds) {
      faults.push(result.imagesFaultAmount);
    }
    return faults.join('+');
  }

  render() {

    if (this.state.isFetching) {
      return <Spinner />
    }

    let resultItems = [];

    if (this.state.results) {
      resultItems = this.state.results
        .slice(
          0,
          this.state.isShowingAll
            ? this.state.results.length
            : MAX_RESULTS_LIMIT
        )
        .map((entry) => {
          let canShowTestResult = DateUtil.getYear(entry.endDate) >= 2016; // Older results have photographs that are not online anymore
          return this.renderResultItem(entry, canShowTestResult);
        });
    }

    const resultsView = (
      <div className='user-results' >
        {resultItems}
      </div>
    );
    
    return (
        <div id="test-results-list-container" className="white-box">
          {resultsView}
          {!this.state.isShowingAll && this.state.results.length > MAX_RESULTS_LIMIT && <Button onClick={() => this.setState({isShowingAll: true})}>
          <Translated translationKey="show_all" /></Button>}
        </div>
    )
  }
}

let mapStateToProps = (state) => {
  return ({
    results: state.results,
  });
};

export default withRouter(connect(mapStateToProps)(TestResultsList));
