import api from '../api';

export const SET_LOCALE = 'SET_LOCALE';
export const LOCALIZATION_REQUEST = 'LOCALIZATION_REQUEST';
export const LOCALIZATION_SUCCESS = 'LOCALIZATION_SUCCESS';

function localizationsRequested(locale) {
  return {
    type: LOCALIZATION_REQUEST,
    locale
  }
}

function localizationsReceived(locale, localizations) {
  return {
    type: LOCALIZATION_SUCCESS,
    locale,
    localizations
  }
}

/**
 * Set client side localizations objects in an array ({locale: {[localizationKey: localization}}]).
 * Must be done before sever side localization are fetched.
 * @param localizationsObj
 */
let clientSideLocalizationsObjArr = null;

export function setClientSideLocalizations(localizationsObjArr) {
  clientSideLocalizationsObjArr = localizationsObjArr;
}

function injectClientSideLocalizations(localizations, locale) {
  if (clientSideLocalizationsObjArr) {
    clientSideLocalizationsObjArr.forEach(clientSideLocalizationsObj => {
      let clientSideLocalizations = clientSideLocalizationsObj[locale];
      if (clientSideLocalizations) {
        for (let key in clientSideLocalizations) {
          if (clientSideLocalizations.hasOwnProperty(key)) {
            if (localizations[key]) {
              if (!IS_PRODUCTION) {
                alert('Duplicate localization key in client side injections: '+key);
              }
            }
            localizations[key] = clientSideLocalizations[key];
          }
        }
      }
    });
  }
  return localizations;
}

function replaceSecrets(localizations) {
  Object.keys(localizations).forEach(key => {
    const value = localizations[key];
    localizations[key] = value.replace('{admin_email}', atob(ADMIN_EMAIL)); // Base64 decode
  });
  return localizations;
}

export function fetchLocalizations(locale) {
  return function (dispatch, getState) {
    // Some api call replies might have localized content, so clear it.
    api.cacheClear();
    const state = getState().Intl;
    if (state.fetchingLocale === locale) {
      return null;
    }
    dispatch(localizationsRequested(locale));

    return api.getLocalizations(locale).then(response => {
      if (response.error) {
        return Promise.reject(response.error);
      }
      response = injectClientSideLocalizations(response, locale);
      response = replaceSecrets(response);
      dispatch(localizationsReceived(locale, response))
    })
  }
}
