import React, {Component} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {setLocale} from './TranslationProvider';
import {closeNavigation} from '../../actions/actionCreators';
import {IoLanguage} from 'react-icons/io5';

class LanguageSelectorForm extends Component {

  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    e.target.blur(); // Mobile Safari cannot do unbinding without blurring.
    const lang = e.target.value;
    setLocale(lang);
    this.props.dispatch(closeNavigation());
  }

  render() {
    const langCodes = Object.keys(this.props.availableLangs);
    if (langCodes.length <= 1) {
      return null;
    }
    return (
        <div id="language-selector-form" className={classNames('lang-selector-form-container', this.props.className)}>
          <span className="larger">
            <IoLanguage />&nbsp;
            {this.props.availableLangs[this.props.locale]}
          </span>
          <select id="language-selector-select" onChange={this.onChange} value={this.props.locale}>
            {langCodes.map((langCode, index) =>
                <option key={index} value={langCode}>{this.props.availableLangs[langCode]}</option>
            )}
          </select>
        </div>
    );
  }
}

let mapStateToProps = (state) => {
  return ({
    availableLangs: state.availableLangs,
    locale: state.Intl.locale
  });
};

export default connect(mapStateToProps)(LanguageSelectorForm);
