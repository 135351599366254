import React, {Component} from 'react';
import {connect} from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import api from '../../api';
import BookStatus from './BookStatus';
import Translated from './Translated';

class UserBookStatuses extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      isFetching: false,
      statuses: null,
      error: null
    };
  }

  componentDidMount() {
    this.setState({
      isFetching: true,
      statuses: null,
      error: null
    });

    api.getBookStatuses(this.props.userId, this.props.locale)
      .then(response => {
        if (response.error) {
          this.setState({
            statuses: null,
            isFetching: false,
            error: response.error
          })
        } else {
          this.setState({
            statuses: response,
            isFetching: false,
            error: null
          })
        }
      });
  }

  render() {
    if (this.state.error) {
      return <Alert><Translated translationKey={this.state.error}/></Alert>;
    }

    let bookStatuses;
    if (this.state.statuses) {
      bookStatuses = this.state.statuses.map((bookStatus, i) => {
        return <BookStatus key={i} bookStatus={bookStatus}/>
      })
    }
    return (
      <div>
        {bookStatuses}
      </div>
    )
  }

}

let mapStateToProps = (state) => {
  return ({
    locale: state.Intl.locale
  });
};

export default connect(mapStateToProps)(UserBookStatuses);
