import React, {Component} from 'react';
import { IoCheckmarkSharp, IoChevronForward } from "react-icons/io5";
import classNames from 'classnames';
import Circle from './ProgressBarCircle';

class BookMenuItem extends Component {

  render() {
    let {progress, showStatus} = this.props;
    let glyph;

    const completed = progress === 1;
    const started = progress > 0 && !completed;

    if (showStatus && completed) {
      glyph = <IoCheckmarkSharp className="completed" />
    } else if (showStatus && started) {
      glyph = <Circle percentage={Math.ceil(progress * 100)}
                      initialAnimate={false}
                      className="progressbar-small progressbar-yellow progressbar-light-bg progressbar-no-text"  />
    } else {
      glyph = <IoChevronForward />
    }

    return (
      <div className={classNames('book-menu-item', {completed}, 'touchable', this.props.className)}
           onClick={this.props.onClick}>
        <div className={classNames('chapter-index', {completed})}>
          {this.props.index}
        </div>
        <div className="chapter-title">
          {this.props.title}
        </div>
        <div className="chapter-status">
          {glyph}
        </div>
      </div>
    )
  }
}

export default BookMenuItem;
