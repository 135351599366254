import React, {Component} from 'react';
import {connect} from 'react-redux';
import DemoImagesStart from '../feature/practise/images/DemoImagesStart';
import DemoPractiseResults from '../feature/practise/DemoPractiseResults';
import PracticeImagesMain from '../feature/practise/images/PracticeImagesMain';
import TrackerAB from '../../utils/trackerAB';
import withRouter from "../feature/withRouter";

class DemoImagesPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.start = this.start.bind(this);
    this.setResult = this.setResult.bind(this);
    this.backToShop = this.backToShop.bind(this);
    this.state = {
      started: false,
      result: null
    }
  }

  componentWillUnmount() {
    if (this.state.started && !this.state.result) {
      TrackerAB.track('demo-images-canceled')
    }
  }

  start() {
    TrackerAB.track('demo-images-started', {category: this.props.match.params.category});
    this.setState({
      started: true,
      result: null
    });
  }

  setResult(result) {
    TrackerAB.track('demo-images-finished', {'demo-images-score': result.correct});
    this.setState({result});
  }

  backToShop() {
    // TODO Use currect vehicle category to take user back to subcategory page (opetuslupa, traktori etc)
    this.props.navigate('/kauppa');
  }

  render() {
    let content;
    if (!this.state.started) {
      content = <DemoImagesStart start={this.start}
                                 category={this.props.match.params.category}
                                 toShop={this.backToShop}/>
    } else if (!this.state.result) {
      content = <PracticeImagesMain category={this.props.match.params.category}
                                    setResult={this.setResult}
                                    isDemo={true}/>
    } else {
      content = <DemoPractiseResults correct={this.state.result.correct}
                                     total={this.state.result.total}
                                     restart={this.start}
                                     buttonId="practise-images-restart"
                                     category={this.props.match.params.category}
                                     toShop={this.backToShop}/>
    }

    return (
      <div id="practise-images-page" className="page-container max-width-container">
        {content}
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return ({
    locale: state.Intl.locale
  });
};

export default withRouter(connect(mapStateToProps)(DemoImagesPage));
