import {Map} from 'immutable';
import {
  CONNECTION_STATUS,
  MAINTENANCE_STATUS,
  FB_READY,
  FB_PROCESSING
} from '../actions/actionCreators';

function getInitialState() {
  return Map({
    hasConnection: true,
    hasMaintenance: false,
    fbReady: false,
    fbProcessing: false
  });
}

function networkReducer(state = getInitialState(), action) {
  switch (action.type) {
    case CONNECTION_STATUS:
      return state.merge({
        hasConnection: action.hasConnection,
      });

    case MAINTENANCE_STATUS:
      return state.merge({
        hasMaintenance: action.hasMaintenance,
      });

    case FB_READY:
      return state.merge({
        fbReady: action.isReady
      });

    case FB_PROCESSING:
      return state.merge({
        fbProcessing: action.isProcessing
      });

    default:
      return state;
  }
}

export default networkReducer;