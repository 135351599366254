import {PAGE_DATA} from '../actions/actionCreators'

const initialState = {
  //PAGE_ID: PAGE_DATA
};

function pageDataReducer(state = initialState, action) {
  if (!action) {
    return state;
  }

  if (action.type !== PAGE_DATA) {
    return state;
  }

  const pageState = Object.assign({}, state[action.pageId], action.data);
  state = Object.assign({}, state, {[action.pageId]: pageState});
  return state;
}

export default pageDataReducer;
