import React, {Component} from 'react';
import Spinner from './../feature/Spinner';
import Alert from 'react-bootstrap/Alert';
import api from '../../api';
import Translated from './../feature/Translated';
import Logger from '../../utils/logger';
import EmailSetPassword from '../feature/EmailSetPassword'
import EmailSetFacebook from '../feature/EmailSetFacebook'
import {IoAlertOutline} from 'react-icons/io5';
import DateUtil from '../../utils/date';
import queryString from 'query-string';
import withRouter from "../feature/withRouter";

/**
 * Landing page from links in email requests (create user, connect email, reset password...)
 * Resolves action from server and displays page content according to that.
 */
class EmailRequestPage extends Component {

  constructor(props, context) {
    super(props, context);

    // Token is an URL parameter if redirected from /req/:requestToken
    let params = queryString.parse(this.props.location.search);
    let token = params.token;
    if (!token) {
      // If redirected from FB login, token is the state URL fragment
      params = queryString.parse(this.props.location.hash);
      token = params.state;
    }

    this.state = {
      requestToken: token,
      error: null,
      isFetching: false,
      responseData: null,
    };
  }

  componentDidMount() {
    this.setState({
      error: null,
      isFetching: true,
      responseData: null
    });
    api.userEmailRequestAction(this.state.requestToken)
      .then(response => {
        if (response.error) {
          this.setState({
            error: response.error,
            isFetching: false,
            responseData: null
          })
        } else {
          this.setState({
            error: null,
            isFetching: false,
            responseData: response.data
          })
        }
      });
  }

  renderEmailOverrideWarning() {
    const data = this.state.responseData;
    const existingUserData = data.existingUserData;
    if (!existingUserData) {
      return null;
    }
    return (
      <Alert>
        <Translated
          translationKey="email_override_warning"
          values={{
            email: data.email,
            userName: existingUserData.userName,
            schoolName: existingUserData.schoolName,
            createdDate: DateUtil.date(existingUserData.createdDate),
            newSchoolName: data.schoolName,
            newUserName: data.userName
            }}/>
      </Alert>
    );
  }

  renderSetPassword() {
    return (
      <div>
        {this.renderEmailOverrideWarning()}
        <EmailSetPassword
          email={this.state.responseData.email}
          requestToken={this.state.requestToken}/>
      </div>
    );
  }

  renderCreateUser() {
    return (
      <div className="centered top-margin">
        <h2><Translated translationKey="welcome_to_service"/></h2>
        {this.renderEmailOverrideWarning()}
        <div>
          <EmailSetFacebook
            requestToken={this.state.requestToken}
            className="large-top-margin"
          />
        </div>
        <h4 className="large-top-margin">- <Translated translationKey="or"/> -</h4>
        <div>
          <EmailSetPassword
            email={this.state.responseData.email}
            requestToken={this.state.requestToken}
            className="large-top-margin"
          />
        </div>
      </div>
    );
  }

  renderError() {
    let error;

    switch(this.state.error) {
      case 'invalid-input':
        error = 'invalid_request_token';
        break;
      case 'token-expired':
        error = 'expired_request_token';
        break;
      case 'token-consumed':
        error = 'consumed_request_token';
        break;
      default:
        error = this.state.error;
    }
    return <Alert><IoAlertOutline/> <Translated translationKey={error}/></Alert>
  }

  renderFetching() {
    return <Spinner/>
  }

  resolvePageContent() {
    if (this.state.error) {
      return this.renderError();
    }

    if (this.state.isFetching || !this.state.responseData) {
      return this.renderFetching();
    }

    let content;
    switch (this.state.responseData.action) {
      case null:
        content = null;
        break;
      case 'create-user-with-email':
      case 'finalize-user-with-email':
        content = this.renderCreateUser();
        break;
      case 'reset-email-password':
      case 'connect-email':
        content = this.renderSetPassword();
        break;
      default:
        let err = new Error('Unknown email action: ' + this.state.responseData.action);
        Logger.error(err);
        content = null;
    }
    return content;
  }

  render() {
    const content = this.resolvePageContent();

    return (
      <div id="email-request-page" className="page-container max-width-container fade-in">
        {content}
      </div>
    );
  }
}

export default withRouter(EmailRequestPage);
