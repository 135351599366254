import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import Translated from '../../Translated';

class SignsStart extends Component {

  render() {
    return (
      <div>
        <h3><Translated translationKey="signs_title"/></h3>
        <p>
          <Translated translationKey="signs_description"/>
        </p>
        <p>
          <Button id="practise-signs-start" onClick={this.props.start}>
            <Translated translationKey="start"/>
          </Button>
        </p>
      </div>
    );
  }
}

export default SignsStart;