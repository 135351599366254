import axios from 'axios';
import cache from 'memory-cache';
import {resetAuth, tokenUpdated, requireTos} from './actions/authActions';
import {connectionStatus, maintenanceStatus} from './actions/actionCreators';
import Logger from './utils/logger';
import Tracker from './utils/tracker';

const API_VERSION = '/api/v1';
const BASE_URL = '' + API_VERSION;

// Login
const LOG_DIRECT_TOKEN_LOGIN = BASE_URL + '/login/log-direct-token-login';
const LOGIN_URL = BASE_URL + '/login/one-time-key';
const LOGIN_EMAIL_URL = BASE_URL + '/login/email';
const LOGIN_FACEBOOK_URL = BASE_URL + '/login/facebook';
const LOGIN_EAUTOKOULU_URL = BASE_URL + '/login/eautokoulu';
const LOGIN_EA2INTEGRATION_URL = BASE_URL + '/login/ea2';
const LOGIN_AAINTEGRATION_URL = BASE_URL + '/login/aaintegration';
const LOGIN_AATEORIAINTEGRATION_URL = BASE_URL + '/login/aateoriaintegration';
const LOGIN_AUTOLIITTO_URL = BASE_URL + '/login/autoliitto';
const LOGIN_AUTOLIITTO_SSO_URL = BASE_URL + '/login/autoliitto/sso';
const LOGIN_LIIKENNEOPETUS_URL = BASE_URL + '/login/liikenneopetus';

//Connect
const CONNECT_FACEBOOK_URL = BASE_URL + '/connect/facebook';

// Common
const BOOK_STATUS_URL = BASE_URL + '/book-status/:userId/:lang';
const CATEGORY_STATUS_URL = BASE_URL + '/category-status/:userId/:category';
const CONTACT_TRIAL = BASE_URL + '/contact/trial';
const LOCALIZATIONS_URL = BASE_URL + '/localizations/:lang';
const NEWS_URL = BASE_URL + '/news/:lang';
const PRACTICE_TYPES_URL = BASE_URL + '/practice-types/:category';
const PRACTICE_LITERALS_URL = BASE_URL + '/test-practice-literals/:category/:lang';
const PRACTICE_IMAGES_URL = BASE_URL + '/test-practice-images/:category/:lang';
const PRACTICE_SIGNS_URL = BASE_URL + '/test-practice-signs/:lang';
const PROFILE_URL = BASE_URL + '/profile/:userId';
const SEND_FEEDBACK = BASE_URL + '/messaging/feedback';
const SEND_POLL_ANSWER = BASE_URL + '/poll-answer';
const SETTINGS_URL = BASE_URL + '/user-settings';
const TEST_CREATE_URL = BASE_URL + '/test-create/:category/:lang';
const TEST_ANSWER_URL = BASE_URL + '/test-answer/:testId';
const TEST_RESULT_URL = BASE_URL + '/test-result/:resultId/:lang';
const TEST_RESULTS_URL = BASE_URL + '/test-results/:userId/';
const REPORT_QUESTION_URL = BASE_URL + '/report/question';
const PRACTICE_LOG_URL = BASE_URL + '/practice-log';
const BOOK_INDEX_URL = BASE_URL + '/book-index/:bookId/:lang';
const BOOK_CONTENT_URL = BASE_URL + '/book-content/:contentId/:lang';
const BOOK_CONTENT_STATUS_URL = BASE_URL + '/book-content-status';
const USER_EMAIL_REQUEST_ACTION = BASE_URL + '/user-email-request/action/:dataToken';
const USER_EMAIL_REQUEST_SET_PASSWORD = BASE_URL + '/user-email-request/set-password/:dataToken';
const USER_EMAIL_REQUEST_CREATE_USER_WITH_FACEBOOK = BASE_URL + '/user-email-request/create-user-facebook/:dataToken';
const USER_EMAIL_REQUEST_EMAIL_RECOVERY = BASE_URL + '/user-email-request/recovery/:lang';
const USER_EMAIL_REQUEST_PENDING_CREATES = BASE_URL + '/user-email-request/pending-creates/:schoolId';
const ACTIVITY_COUNT_URL = BASE_URL + '/activity-count';
const DEMO_IMAGES_URL = BASE_URL + '/demo-practice-images/:category/:lang';
const AGREEMENT_CONTENT_URL = BASE_URL + '/agreement/:name/:version/:lang';
const ACCEPT_AGREEMENT_URL = BASE_URL + '/agreement/sign';

//Teacher
const LATEST_ACCEPTED_USERS_URL = BASE_URL + '/search/latest-accepted/:schoolId';
const ALL_ACTIVE_STUDENTS = BASE_URL + '/search/active-students/:schoolId';
const SEARCH_USERS_URL = BASE_URL + '/search/user/:term';
const LIST_NEWEST_USERS = BASE_URL + '/search/newest-added/:schoolId';
const LIST_NEWEST_ACTIVATED_USERS = BASE_URL + '/search/newest-activated/:schoolId';
const SCHOOL_TEACHERS = BASE_URL + '/school/:schoolId/teacher';
const ONE_TIME_KEY_ORDERS_URL = BASE_URL + '/orders/one-time-keys/:schoolId';
const SCHOOL_DATA_URL = BASE_URL + '/school/:schoolId';
const SCHOOL_CATEGORY_SETTINGS_URL = BASE_URL + '/school/category-settings/:schoolId';
const EMAIL_ONE_TIME_KEYS = BASE_URL + '/email-one-time-keys';
const ADD_USER_WITH_EMAIL = BASE_URL + '/user-email-request/create/:schoolId';
const ADD_TEACHER_WITH_EMAIL = BASE_URL + '/school/:schoolId/teacher';
const USER_CONNECT_EMAIL = BASE_URL + '/user-email-request/connect/:userId/:lang';
const CONNECT_EMAIL = BASE_URL + '/user-email-request/connect/:lang';

//Shop
const EMAIL_VERIFICATION_URL = BASE_URL + '/shop/verify-email/:email';
const SUB_CATEGORIES_URL = BASE_URL + '/shop/subcategories/:category';
const PRODUCTS_URL = BASE_URL + '/shop/products/:category/:subCategory/:lang';
const PURCHASE_URL = BASE_URL + '/shop/purchase';
const WEBSHOP_ORDER_URL = BASE_URL + '/shop/order/:orderId/:token';
const WEBSHOP_ORDER_UPDATE_PAYMENT_METHOD_URL = BASE_URL + '/shop/order/:orderId/:token/:methodId';

const CODE_REDEEM_URL = BASE_URL + '/redeem/:code';

const LOGIN_TIMEOUT_MS = IS_PRODUCTION ? 10000 : Number.MAX_SAFE_INTEGER; // Debugging requires longer timeouts.
const REGULAR_TIMEOUT_MS = IS_PRODUCTION ? 20000 : Number.MAX_SAFE_INTEGER;

// Cache time in seconds
const CACHE_TIME_INFINITY = 0;
const CACHE_TIME_NEVER = -1;
const CACHE_TIME_VERY_SHORT = 3;
const CACHE_TIME_SHORT = 60;
const CACHE_TIME_LONG = 60 * 15;

let _store;

class AjokaistaApiClient {

  set store(value) {
    _store = value;
  }

  /**
   * Clear memory cache
   */

  cacheClear() {
    cache.clear();
  }

  /**
   * Login
   */

  logDirectTokenLogin(token) {
    // Logs jwt url login (user had a link with jwt token)
    return this.post(LOG_DIRECT_TOKEN_LOGIN, {token}, LOGIN_TIMEOUT_MS);
  }

  login(name, key, schoolId) {
    let data = {
      username: name,
      key: key,
      schoolId: schoolId,
    };
    Tracker.logEvent('api', 'login-one-time-key');
    return this.post(LOGIN_URL, data, LOGIN_TIMEOUT_MS);
  }

  loginEmail(email, password, profileSchoolId) {
    Tracker.logEvent('api', 'login-email');
    return this.post(LOGIN_EMAIL_URL, {email, password, profileSchoolId}, LOGIN_TIMEOUT_MS);
  }

  loginFacebook(fbAccessToken) {
    let data = {accessToken: fbAccessToken};
    Tracker.logEvent('api', 'login-facebook');
    return this.post(LOGIN_FACEBOOK_URL, data, LOGIN_TIMEOUT_MS);
  }

  loginEautokoulu(challengedUrl) {
    let data = {url: challengedUrl};
    Tracker.logEvent('api', 'login-eautokoulu');
    return this.post(LOGIN_EAUTOKOULU_URL, data, LOGIN_TIMEOUT_MS);
  }

  loginAAIntegration(name, email, licenseType, licensesBase64, hash) {
    let data = {name, email, licenseType, licensesBase64, hash};
    Tracker.logEvent('api', 'login-aa');
    return this.post(LOGIN_AAINTEGRATION_URL, data, LOGIN_TIMEOUT_MS);
  }

  loginAATeoriaIntegration(challengedUrl) {
    let data = {url: challengedUrl};
    Tracker.logEvent('api', 'login-aa-teoria');
    return this.post(LOGIN_AATEORIAINTEGRATION_URL, data, LOGIN_TIMEOUT_MS);
  }

  loginEA2Integration(challengedUrl) {
    let data = {url: challengedUrl};
    Tracker.logEvent('api', 'login-ea2');
    return this.post(LOGIN_EA2INTEGRATION_URL, data, LOGIN_TIMEOUT_MS);
  }

  loginAutoliittoIntegration(externalUserId, hash, externalTime) {
    let data = {externalUserId, hash, externalTime};
    Tracker.logEvent('api', 'login-autoliitto');
    return this.post(LOGIN_AUTOLIITTO_URL, data, LOGIN_TIMEOUT_MS);
  }

  loginAutoliittoSSO(authCode) {
    let data = {authCode};
    Tracker.logEvent('api', 'login-autoliitto-sso');
    return this.post(LOGIN_AUTOLIITTO_SSO_URL, data, LOGIN_TIMEOUT_MS);
  }

  loginLiikenneopetusIntegration(email, name, categories, epoch, hash) {
    let data = {email, name, categories, epoch, hash};
    Tracker.logEvent('api', 'login-liikenneopetus');
    return this.post(LOGIN_LIIKENNEOPETUS_URL, data, LOGIN_TIMEOUT_MS);
  }

  /**
   * Connect
   */
  connectFacebook(fbAccessToken, forceTransfer) {
    let data = {
      accessToken: fbAccessToken,
      forceTransfer
    };
    Tracker.logEvent('api', 'connect-facebook');
    return this.post(CONNECT_FACEBOOK_URL, data, REGULAR_TIMEOUT_MS);
  }


  /**
   * Common
   */

  getLocalizations(language) {
    let url = LOCALIZATIONS_URL.replace(':lang', language.toLowerCase());
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_LONG);
  }

  getNews(language) {
    let url = NEWS_URL.replace(':lang', language);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_LONG);
  }

  getActivityCount() {
    return this.get(ACTIVITY_COUNT_URL, REGULAR_TIMEOUT_MS, CACHE_TIME_VERY_SHORT);
  }

  getUserSettings() {
    return this.get(SETTINGS_URL, REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER);
  }

  getProfile(userId, forceReload) {
    let url = PROFILE_URL.replace(':userId', encodeURIComponent(userId));
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT, forceReload);
  }

  saveProfile(userData) {
    let url = PROFILE_URL.replace(':userId', encodeURIComponent(userData.id));
    Tracker.logEvent('api', 'save-profile');
    return this.post(url, userData);
  }

  getCategoryStatus(userId, category) {
    let url = CATEGORY_STATUS_URL.replace(':userId', encodeURIComponent(userId));
    url = url.replace(':category', category);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  getCategoryStatuses(userId) {
    let url = CATEGORY_STATUS_URL.replace(':userId', encodeURIComponent(userId));
    url = url.replace(':category', '');
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  getBookStatuses(userId, lang) {
    let url = BOOK_STATUS_URL.replace(':userId', encodeURIComponent(userId));
    url = url.replace(':lang', lang);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  getResultsOverview(userId) {
    let url = TEST_RESULTS_URL.replace(':userId', encodeURIComponent(userId));
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  getResult(id, lang) {
    let url = TEST_RESULT_URL.replace(':resultId', id);
    url = url.replace(':lang', lang);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_INFINITY);
  }

  getPracticeTypes(category) {
    let url = PRACTICE_TYPES_URL.replace(':category', category.toLowerCase());
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_LONG);
  }

  createPracticeLiterals(category, lang, seenIds) {
    let url = PRACTICE_LITERALS_URL.replace(':category', category.toLowerCase());
    url = url.replace(':lang', lang.toLowerCase());
    Tracker.logEvent('api', 'practise-literals');
    return this.post(url, {seenIds}, REGULAR_TIMEOUT_MS);
  }

  createPracticeSigns(lang, seenIds) {
    let url = PRACTICE_SIGNS_URL.replace(':lang', lang.toLowerCase());
    Tracker.logEvent('api', 'practise-signs');
    return this.post(url, {seenIds}, REGULAR_TIMEOUT_MS);
  }

  createPracticeImages(category, lang, seenIds) {
    let url = PRACTICE_IMAGES_URL.replace(':category', category.toLowerCase());
    url = url.replace(':lang', lang.toLowerCase());
    Tracker.logEvent('api', 'practise-images');
    return this.post(url, {seenIds}, REGULAR_TIMEOUT_MS);
  }

  getDemoImages(category, lang) {
    let url = DEMO_IMAGES_URL.replace(':category', category.toLowerCase());
    url = url.replace(':lang', lang.toLowerCase());
    Tracker.logEvent('api', 'demo-images');
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_LONG);
  }

  getFullTest(category, lang) {
    let url = TEST_CREATE_URL.replace(':category', category.toLowerCase());
    url = url.replace(':lang', lang.toLowerCase());
    Tracker.logEvent('api', 'get-full-test');
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_VERY_SHORT);
  }

  sendFullTestAnswers(testId, answers) {
    let url = TEST_ANSWER_URL.replace(':testId', testId);
    return this.post(url, answers);
  }

  sendFeedback(userName, phone, email, feedback, extra) {
    return this.post(SEND_FEEDBACK, {userName, phone, email, feedback, extra});
  }

  sendPollAnswer(pollId, answer) {
    return this.post(SEND_POLL_ANSWER, {pollId, answer});
  }

  sendTrialContact(fields) {
    return this.post(CONTACT_TRIAL, {...fields});
  }

  sendQuestionReport(questionType, questionId, message) {
    Tracker.logEvent('api', 'question-report');
    return this.post(REPORT_QUESTION_URL, {questionType, questionId, message});
  }

  sendPracticeLog(category, practiceType, numQuestions, numFaults) {
    return this.post(PRACTICE_LOG_URL, {category, practiceType, numQuestions, numFaults});
  }

  getBookIndex(bookId, lang) {
    let url = BOOK_INDEX_URL.replace(':bookId', bookId);
    url = url.replace(':lang', lang.toLowerCase());
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  getBookContent(contentId, lang) {
    let url = BOOK_CONTENT_URL.replace(':contentId', contentId);
    url = url.replace(':lang', lang.toLowerCase());
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_LONG);
  }

  sendBookContentStatus(materialContentId, materialId) {
    return this.post(BOOK_CONTENT_STATUS_URL, {materialContentId, materialId});
  }

  userEmailRequestAction(dataToken) {
    let url = USER_EMAIL_REQUEST_ACTION.replace(':dataToken', dataToken);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_LONG);
  }

  userEmailRequestSetPassword(dataToken, newPassword, locale) {
    let url = USER_EMAIL_REQUEST_SET_PASSWORD.replace(':dataToken', dataToken);
    return this.post(url, {newPassword, locale}, REGULAR_TIMEOUT_MS);
  }

  userEmailRequestCreateWithFacebook(dataToken, fbUserName, fbAccessToken, forceConnect) {
    let url = USER_EMAIL_REQUEST_CREATE_USER_WITH_FACEBOOK.replace(':dataToken', dataToken);
    return this.post(url, {fbUserName, fbAccessToken, forceConnect}, REGULAR_TIMEOUT_MS);
  }

  userEmailRequestEmailRecovery(email, lang) {
    let url = USER_EMAIL_REQUEST_EMAIL_RECOVERY.replace(':lang', lang);
    return this.post(url, {email}, REGULAR_TIMEOUT_MS);
  }

  pendingUserCreates(schoolId) {
    let url = USER_EMAIL_REQUEST_PENDING_CREATES.replace(':schoolId', schoolId);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER, false);
  }

  /**
   * Teacher
   */

  getLatestAcceptedUsers(schoolId) {
    const url = LATEST_ACCEPTED_USERS_URL.replace((':schoolId'), schoolId);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER);
  }

  getAllActiveStudents(schoolId) {
    const url = ALL_ACTIVE_STUDENTS.replace((':schoolId'), schoolId);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER);
  }

  searchUsers(term) {
    const url = SEARCH_USERS_URL.replace((':term'), term);
    Tracker.logEvent('api', 'search-users');
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER);
  }

  listLatestAddedStudents(schoolId) {
    const url = LIST_NEWEST_USERS.replace(':schoolId', schoolId);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  listLatestActivatedStudents(schoolId) {
    const url = LIST_NEWEST_ACTIVATED_USERS.replace(':schoolId', schoolId);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  listTeachers(schoolId) {
    const url = SCHOOL_TEACHERS.replace(':schoolId', schoolId);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER);
  }

  getLatestOneTimeKeyOrders(schoolId) {
    const url = ONE_TIME_KEY_ORDERS_URL.replace(':schoolId', schoolId);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER);
  }

  doOneTimeKeyOrder(schoolId, deliveryMethod) {
    const url = ONE_TIME_KEY_ORDERS_URL.replace(':schoolId', schoolId);
    Tracker.logEvent('api', 'order-passes');
    return this.post(url, {deliveryMethod}, REGULAR_TIMEOUT_MS);
  }

  getSchoolData(schoolId) {
    const url = SCHOOL_DATA_URL.replace(':schoolId', schoolId);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER);
  }

  setSchoolData(schoolId, data) {
    const url = SCHOOL_DATA_URL.replace(':schoolId', schoolId);
    Tracker.logEvent('api', 'set-school-data');
    return this.post(url, data, REGULAR_TIMEOUT_MS);
  }

  getSchoolCategorySettings(schoolId) {
    const url = SCHOOL_CATEGORY_SETTINGS_URL.replace(':schoolId', schoolId);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER);
  }

  setSchoolCategorySettings(schoolId, category, settings) {
    const url = SCHOOL_CATEGORY_SETTINGS_URL.replace(':schoolId', schoolId);
    const data = {category, ...settings};
    return this.post(url, data, REGULAR_TIMEOUT_MS);
  }

  emailOneTimeKeys(userId, emailAddress) {
    const url = EMAIL_ONE_TIME_KEYS;
    Tracker.logEvent('api', 'email-one-time-keys');
    return this.post(url, {userId, emailAddress}, REGULAR_TIMEOUT_MS);
  }

  addUserWithEmail(schoolId, email, userName, lang, role, force) {
    let url = ADD_USER_WITH_EMAIL;
    url = url.replace(':schoolId', schoolId);
    Tracker.logEvent('api', 'add-user-with-email');
    return this.post(url, {email, userName, lang, role, force}, REGULAR_TIMEOUT_MS);
  }

  addTeacher(schoolId, email, userName, lang, force) {
    let url = ADD_TEACHER_WITH_EMAIL;
    url = url.replace(':schoolId', schoolId);
    Tracker.logEvent('api', 'add-user-with-email');
    return this.post(url, {email, userName, lang, force}, REGULAR_TIMEOUT_MS);
  }

  userConnectEmail(userId, email, lang, forceConnect) {
    let url = USER_CONNECT_EMAIL;
    url = url.replace(':userId', encodeURIComponent(userId));
    url = url.replace(':lang', lang);
    Tracker.logEvent('api', 'connect-user-with-email');
    return this.post(url, {email, forceConnect}, REGULAR_TIMEOUT_MS);
  }

  connectEmail(email, lang, forceConnect) {
    let url = CONNECT_EMAIL;
    url = url.replace(':lang', lang);
    Tracker.logEvent('api', 'connect-own-user-with-email');
    return this.post(url, {email, forceConnect}, REGULAR_TIMEOUT_MS);
  }

  verifyEmail(email) {
    let url = EMAIL_VERIFICATION_URL;
    url = url.replace(':email', email);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  getSubcategories(category) {
    let url = SUB_CATEGORIES_URL.replace(':category', category);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  getProducts(category, subCategory, lang) {
    let url = PRODUCTS_URL;
    url = url.replace(':category', category);
    url = url.replace(':subCategory', subCategory);
    url = url.replace(':lang', lang);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  purchaseProducts(filledProducts, customerData, lang, referralId, adId, allReferralsObj) {
    return this.post(PURCHASE_URL, {filledProducts, customerData, lang, referralId, adId, allReferrals: allReferralsObj}, REGULAR_TIMEOUT_MS);
  }

  updatePaymentMethodId(orderId, orderToken, paymentMethodId) {
    let url = WEBSHOP_ORDER_UPDATE_PAYMENT_METHOD_URL;
    url = url.replace(':orderId', orderId);
    url = url.replace(':token', orderToken);
    url = url.replace(':methodId', paymentMethodId);
    return this.post(url, {paymentMethodId}, REGULAR_TIMEOUT_MS);
  }

  getOrder(orderId, token) {
    let url = WEBSHOP_ORDER_URL;
    url = url.replace(':orderId', orderId);
    url = url.replace(':token', token);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  validateCode(code) {
    let url = CODE_REDEEM_URL;
    url = url.replace(':code', code);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  redeemCode(code, data) {
    let url = CODE_REDEEM_URL;
    url = url.replace(':code', code);
    return this.post(url, data, REGULAR_TIMEOUT_MS);
  }

  getAgreementContent(name, version, lang = 'fi') {
    let url = AGREEMENT_CONTENT_URL;
    url = url.replace(':name', name);
    url = url.replace(':version', version);
    url = url.replace(':lang', lang);
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT); 
  }

  acceptAgreement(agreementContentId) {
    return this.post(ACCEPT_AGREEMENT_URL, {agreementContentId}, REGULAR_TIMEOUT_MS);
  }

  /**
   * Private
   */

  get(url, timeout, cacheTimeSec, forceReload = false) {
    return this.apiCall(url, 'get', undefined, cacheTimeSec, timeout, forceReload);
  }

  post(url, data, timeout) {
    return this.apiCall(url, 'post', data, CACHE_TIME_NEVER, timeout, true)
  }

  apiCall(url, method, data, cacheTimeSec, timeout, forceReload) {
    if (!forceReload) {
      const cachedResponse = cache.get(method + url);
      if (cachedResponse) {
        return new Promise((resolve, reject) => {
          resolve(cachedResponse);
        });
      }
    }

    const callParams =
      {
        method: method,
        url: url,
        data: data,
        headers: this.getHeader(),
        timeout: timeout
      };
    return axios(callParams)
      .then(response => {
        const token = response.headers.authorization;
        if (token) {
          // Token was updated from this api call, take new one into use.
          Logger.setUserData({token});
          _store.dispatch(tokenUpdated(token));
        }

        // Server adds custom header if this user is required to accept new terms of service.
        const tosVersion = parseInt(response.headers['x-required-tos']);
        if (tosVersion) {
          _store.dispatch(requireTos(tosVersion));
        }

        const data = response.data;
        if (cacheTimeSec !== CACHE_TIME_NEVER) {
          cache.put(method + url, data, cacheTimeSec === CACHE_TIME_INFINITY ? undefined : cacheTimeSec * 1000);
        }
        // Data should always be object or array
        return data;
      }, error => { // Use then(success, failure) syntax to prevent global catch
        if (error.response) {
          switch (error.response.status) {
            case 401:
              if (error.response.data.error === 'old-app-version') {
                if (!window.location.origin) { // Origin missing in old IE
                  window.location.reload(true);
                } else {
                  // Reload doesn't seem to work on Firefox
                  window.location.href = window.location.origin + '?' + Math.round(Math.random() * 1000000);
                }
              }
              // Unauthorized, log out
              console.log('Request was unauthorized');
              this.cacheClear();
              _store.dispatch(resetAuth(error.response.data.error));
              Tracker.logEvent('api', 'Unauthorized');
              break;
            case 404:
              window.location.reload(true);
              break;
            case 503:
              // Server is in maintenance
              _store.dispatch(maintenanceStatus(true));
              break;
          }
        } else {
          // No response, network maybe unavailable
          _store.dispatch(connectionStatus(false));
          Tracker.logEvent('api', 'Could not connect to server');
        }
        let errorMessage = AjokaistaApiClient.resolveErrorMessage(error);
        cache.clear();
        // Always return objects, errors have error field.
        return Promise.resolve({error: errorMessage});
      });
  }

  getAuthToken() {
    const token = _store.getState().login.get('token');
    return token ? token.get('encoded') : null;
  }

  getProfileSchoolId() {
    const token = _store.getState().login.get('token');
    if (!token){
      return null;
    }
    return token.get('schoolId');
  }

  getUserId() {
    const token = _store.getState().login.get('token');
    return token ? token.get('userId') : 'anon';
  }

  getHeader() {
    const token = this.getAuthToken();
    const userId = this.getUserId();
    const profileSchoolId = this.getProfileSchoolId();
    return {
      'Authorization': `Bearer ${token}`,
      'user-id': userId,
      'profile-school-id': profileSchoolId,
      'app-version': APP_VERSION,
      'build-version': BUILD_VERSION
    };
  }

  static resolveErrorMessage(error) {
    let errorMessage = null;

    /** Parse error and try to take the error or message from the response data */

    if (error.message) {
      errorMessage = error.message;
    }

    if (error.response && error.response.status === 500) {
      //Not a correct error, should be replaced in server too.
      errorMessage = 'unknown-error';
    }

    if (error.response && error.response.status === 503) {
      errorMessage = 'maintenance';
    }

    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    }

    if (error.response && error.response.data.error) {
      errorMessage = error.response.data.error;
    }

    /** Try to resolve a more defined (localized code) out of the message. */

    if (errorMessage === 'Network Error') {
      errorMessage = 'network-error';
      Tracker.logEvent('api', 'Network error');
    }

    if (errorMessage === 'Unexpected token < in JSON at position 0') {
      errorMessage = 'invalid-response-format';
      Tracker.logEvent('api', 'Invalid response format');
    }

    if (errorMessage.indexOf('timeout') === 0) {
      errorMessage = 'request-timeout';
      Tracker.logEvent('api', 'Network timeout');
    }

    /** Check if all parsing failed */

    if (errorMessage === null || errorMessage === undefined || errorMessage === '') {
      errorMessage = 'unknown-error';
      Tracker.logEvent('api', 'Unknown error');
    }

    if (typeof errorMessage !== 'string') {
      errorMessage = 'unknown-error';
      Tracker.logEvent('api', 'Unknown error')
    }

    return errorMessage;
  }

}

const api = new AjokaistaApiClient();

export default api;
