import React, {Component} from 'react';
import {FaCircle, FaRegCircle} from 'react-icons/fa';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import Translated from './Translated';
import withTranslate from '../feature/withTranslate';
import classnames from 'classnames';

class OptionsMenu extends Component {

  constructor(props, context) {
    super(props, context);
  }

  static propTypes = {
    selectedIndex: PropTypes.number.isRequired,
    itemData: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired
  };

  createItems() {
    const items = [];
    this.props.itemData.forEach((item, index) => {
      let id = item.id;
      let locId = item.localizationId;

      const isSelected = index === this.props.selectedIndex;

      items.push(
        <div key={id}
             className="vertical-middle touchable"
             onClick={() => {
               this.props.onSelect(index, id);
             }}
          >
          {isSelected
            ? <FaCircle className="right-margin" aria-hidden="true"/>
            : <FaRegCircle className="right-margin" aria-hidden="true"/>
          }
          <div
            id={id}>
            {isSelected
              ?<strong><Translated translationKey={locId}/></strong>
              :<Translated translationKey={locId}/>}
          </div>
        </div>
      );
    });
    return items;
  }

  render() {
    const classes = classnames('options-menu', this.props.className);
    const items = this.createItems();

    return (
      <div className={classes}>
        {items}
      </div>
    );
  }
}

export default connect()(withTranslate(OptionsMenu));
