import React from 'react';
import PropTypes from 'prop-types';
import Translated from './Translated';
import Button from 'react-bootstrap/Button'
import ImageSet from '../feature/ImageSet';

const CategoryStart = (props) => {
  const categoryKey = 'category_' + props.category.toLowerCase();
  const catImgSrc = '../../assets/category/' + props.category + '.png';

  return (
      <div id="category-start-container" className="questions-start-category fade-in">
        <div>
          <h3 className="no-top-margin"><Translated translationKey={categoryKey}/></h3>
          <div>
            <ImageSet src={catImgSrc}/>
          </div>
        </div>
        <div className="start-btn">
          <Button id={props.id} onClick={props.start}><Translated translationKey="start"/></Button>
        </div>
      </div>
  )
};

CategoryStart.propTypes = {
  category: PropTypes.string.isRequired,
  start: PropTypes.func.isRequired,
  id: PropTypes.string
};

export default CategoryStart
