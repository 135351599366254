import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withTranslate from './withTranslate';
import {connect} from 'react-redux'
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Translated from '../feature/Translated';
import withRouter from './withRouter';
import api from '../../api';
import {IoAlertOutline} from 'react-icons/io5';
import Logger from '../../utils/logger';
import FacebookLoginButton from './FacebookLoginButton';
import Spinner from './Spinner';
import queryString from 'query-string';

export class EmailSetFacebook extends Component {

  constructor(props, context) {
    super(props, context);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.facebookAuthResponse = this.facebookAuthResponse.bind(this);

    this.state = {
      facebookAccessToken: '',
      facebookUserName: '',
      isFetching: false,
      isCreated: false,
      error: null,
      errorData: null
    }
  }

  static propTypes = {
    requestToken: PropTypes.string.isRequired
  };

  componentDidMount() {
    const params = queryString.parse(window.location.hash);
    let fbToken = params['access_token'];
    if (fbToken) {
      const name = '';
      this.setState({
        facebookAccessToken: fbToken,
        facebookUserName: name
      });
      this.handleSubmit(name, fbToken, false);
    }
  }

  handleSubmit(facebookUserName, facebookAccessToken, forceConnect) {
    this.setState({
      isFetching: true,
      isCreated: false,
      error: null,
      errorData: null

    });
    api.userEmailRequestCreateWithFacebook(this.props.requestToken, facebookUserName, facebookAccessToken, forceConnect)
      .then(response => {
        if (response.error) {
          this.setState({
            isFetching: false,
            isCreated: false,
            error: response.error,
            errorData: response.data
          });
        } else {
          this.setState({
            isFetching: false,
            isCreated: true,
            error: null,
            errorData: null
          });
        }
      })
  }

  facebookAuthResponse(response) {
    if (!response) {
      Logger.info('Facebook auth response was empty');
      return;

    } else if (!response.accessToken) {
      return;
    }
    let token = response.accessToken;
    let name = response.name;
    this.setState({facebookAccessToken: token, facebookUserName: name});
    this.handleSubmit(name, token, false);
  }

  renderAlreadyConnectedError() {
    return (
      <div className="top-margin">
        <Alert bsstyle="warning">
          <IoAlertOutline />&nbsp;
          <Translated translationKey="facebook_already_connected_to_user" values={
            {
              facebookName: this.state.errorData.userNameFB,
              schoolName: this.state.errorData.clashingUsersSchoolName
            }
          }/>
          <br/>
          <br/>
          <Translated translationKey="create_new_user_facebook_question"/>
        </Alert>
        <div className="top-margin">
          <Button bsstyle="info"
                  onClick={() => this.setState({error: null, errorData: null, fbUserName: '', fbAccessToken: ''})}>
            <Translated translationKey="no"/>
          </Button>&nbsp;
          <Button onClick={() => this.handleSubmit(this.state.facebookUserName, this.state.facebookAccessToken, true)}>
            <Translated translationKey="yes"/>
          </Button>
        </div>
      </div>
    );
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }
    switch (this.state.error) {
      case 'facebook-already-connected':
        return this.renderAlreadyConnectedError();
        break;
      default:
        return (
          <div>
            <Alert>
              <IoAlertOutline/> <Translated translationKey={this.state.error}/>
            </Alert>
          </div>
        );
    }
  }

  renderDone() {
    return (
      <div>
        <Alert>
          <Translated translationKey="facebook_connected"/> <Translated translationKey="login_using_facebook"/>
        </Alert>
        <Button onClick={() => this.props.navigate('/')}><Translated translationKey="ok"/></Button>
      </div>
    );
  }

  render() {
    if (this.state.isCreated) {
      return this.renderDone();
    }

    if (this.state.isFetching) {
      return <Spinner/>;
    }

    return (
      <div className={this.props.className}>
        <FacebookLoginButton callback={this.facebookAuthResponse}
                             label={this.props.translate('login_with_facebook')}
                             redirectState={this.props.requestToken}/>
        {this.renderError()}
      </div>
    );
  }
}

export default withRouter(connect()(withTranslate(EmailSetFacebook)));
