import React, {Component} from 'react';
import Translated from '../Translated';
import withRouter from '../withRouter';

export class OtherProductCategories extends Component {

  render() {
    return (
      <div>
        <a className="touchable" onClick={() => {this.props.navigate('/kauppa')}} ><Translated translationKey="other_categories"/></a>
      </div>
    );
  }
}
export default withRouter(OtherProductCategories);