import React, {Component} from 'react';
import {connect} from 'react-redux'
import Button from 'react-bootstrap/Button';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Translated from '../feature/Translated';
import Spinner from './../feature/Spinner';
import api from '../../api';
import {IoAlertOutline} from 'react-icons/io5';
import withTranslate from '../feature/withTranslate';
import EmailUtil from '../../utils/EmailUtil';
import withRouter from "../feature/withRouter";

export class EmailPasswordCreate extends Component {

  constructor(props, context) {
    super(props, context);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      email: '',
      isFetching: false,
      isRecoverySent: false,
      error: null,
      responseEmail: null
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      isFetching: true,
      isRecoverySent: false,
      error: null,
      responseEmail: null

    });

    if (!EmailUtil.isEmail(this.state.email)) {
      return this.setState({
        error: 'invalid_email_form',
        isFetching: false,
        isRecoverySent: false,
      });
    }

    api.userEmailRequestEmailRecovery(this.state.email, this.props.locale)
      .then(response => {
        if (response.error) {
          this.setState({
            email: '',
            isFetching: false,
            isRecoverySent: false,
            error: response.error,
          });
        } else {
          this.setState({
            email: '',
            isFetching: false,
            isRecoverySent: true,
            error: null,
            responseEmail: response.email
          });
        }
      })
  }

  updateField = (field) => (e) => {
    this.setState({[field]: e.target.value});
  };

  renderError() {
    return this.state.error
      ? <Alert><IoAlertOutline/> <Translated translationKey={this.state.error}/></Alert>
      : null;
  }

  renderDone() {
    return (
      <div>
        <Alert>
          <Translated translationKey="recovery_is_sent" values={{email: this.state.responseEmail}}/>
        </Alert>
        <Button onClick={() => this.props.navigate('/')}><Translated translationKey="ok"/></Button>
      </div>
    );
  }

  render() {
    const t = this.props.translate;
    const inputEmpty = !this.state.email;

    let content;

    if (this.state.isRecoverySent) {
      content = this.renderDone();
    } else {
      content = (
        <div className="login-form-container">
          <Card id="recover-email-password-panel" className="login-form-well" header={this.props.header}>
            <Card.Body>
              <h3><Translated translationKey="recover_email_password_info" values={{email: this.props.email}}/></h3>
              <form id="email-login-form" onSubmit={(e) => this.handleSubmit(e)}>
                <FormGroup onSubmit={this.handleSubmit}>
                  <FormControl id="email-to-recover" disabled={this.state.isFetching} type="text" name="email"
                               placeholder={t('email')}
                               value={this.state.email}
                               onChange={this.updateField('email')}/>

                  <div className="field-spacer"/>
                  {this.renderError()}
                </FormGroup>
                <Button
                  id="email-request-create-password-submit"
                  type="submit"
                  disabled={this.state.isFetching || inputEmpty}
                  className="submit-login-button strong-text"
                  onClick={this.handleSubmit}>
                  {this.state.isFetching ? <Spinner/> : t('recover')}
                </Button>
              </form>
            </Card.Body>
          </Card>
        </div>
      );
    }

    return (
      <div id="email-recovery-page" className="page-container max-width-container fade-in">
        <div className="front-center-wrapper">
          {content}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.Intl.locale
  }
};

export default withRouter(connect(mapStateToProps)(withTranslate(EmailPasswordCreate)));
