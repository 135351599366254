import React, {Component} from 'react';
import Translated from './../Translated';
import ZoomableImage from '../ZoomableImage';
import ResultFeedbackForm from './ResultFeedbackForm';
import {IoChatbubblesSharp} from 'react-icons/io5';
import BookModalLink from '../BookModalLink';
import { FcApproval, FcHighPriority } from "react-icons/fc";


class TestResultImage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      showPinch: -1,
      showCommenting: false,
    }
  }

  toggleCommenting() {
    this.setState({showCommenting: !this.state.showCommenting});
  }

  renderQuestionAndAnswer(question) {
    const correctAnswer = (
      <div>
        <div>
          <Translated translationKey="correct_answer"/>:&nbsp;
          <span className="test-result-image-answer-highlight">
            {
              question.isAssertionTruthy === true
                ? <strong><Translated translationKey="yes" /></strong>
                : <strong><Translated translationKey="no" /></strong>
            }
            {
              question.isAssertionTruthy === question.answer
                ? <FcApproval className='test-result-icon images' />
                : <FcHighPriority className='test-result-icon images' />
            }
          </span>
        </div>
      </div>
    );

    return (
      <div>
        <div className="test-result-image-question">{question.assertion}</div>
        {correctAnswer}
      </div>
    );
  }

  renderExplanations(explanationsArr) {
    const elements = explanationsArr.map((exp) => {
      const src = exp.imageUrl;

      let img = src ? <div><img className="test-result-image-explanation-image" src={src}/></div> : null;
      let text = <div>{exp.text}</div>;
      return (<div key={exp.id} className="test-result-image-explanation-wrapper">{img}{text}</div>)
    });
    return elements;
  }

  renderCommenting(question){
    if (!this.state.showCommenting) {
      return null;
    }
    return(
      <ResultFeedbackForm questionType="image" questionId={question.id} keepOpen={true} className="large-top-margin"/>
    );
  }

  renderBookContent(question) {
    const bookReferencesMap = this.props.bookReferences;

    // Do not add book reference link if there is none, or user does not have them linked (no rights)
    if (question.bookReferenceIds.length === 0 || !bookReferencesMap || bookReferencesMap.size === 0) {
      return null;
    }
    return question.bookReferenceIds.map(refId => {
      const content = bookReferencesMap.get(refId);
      return <BookModalLink content={content} style="warning" key={refId} animate={true}/>;
    });
  }

  renderResult(question) {
    const key = question.id;
    return (
      <div className="test-result-image-wrapper relative" key={key}>
        <ZoomableImage src={question.imageUrl} className="test-result-image-image"
                       modalClassName="test-result-image-modal"/>
        <div className="test-result-image-explanations-wrapper">
          {this.renderQuestionAndAnswer(question)}
          <br />
          {this.renderExplanations(question.descriptions)}
          {this.renderBookContent(question)}
        </div>
        <div className="white-box-bottom padded-small">
          <div className="test-result-question-bottom touchable" onClick={(e) => this.toggleCommenting(e)}>
            <Translated  translationKey="question_report" />
            <div className=" test-result-question-bottom-item icon " >
              <IoChatbubblesSharp className="larger pull-right"/>
            </div>
          </div>
          {this.renderCommenting(question)}
        </div>
      </div>
    );
  }

  render() {
    const question = this.props.question;
    return this.renderResult(question);
  }
}

export default TestResultImage;
