import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {TranslationContext} from './TranslationProvider';

function filterProps(props) {
  const spanProps = Object.assign({}, props);
  delete spanProps['translationKey'];
  delete spanProps['values'];
  delete spanProps['translate'];
  delete spanProps['options'];
  delete spanProps['html'];
  return spanProps;
}

export class Translated extends Component {
  static propTypes = {
    translationKey: PropTypes.string.isRequired
  };

  render() {
    const props = this.props;
    const translation = this.context.translate(props.translationKey, props.values, props.options);
    if (props.html) {
      return <span dangerouslySetInnerHTML={{__html: translation}} {...filterProps(props)}/>
    } else {
      return <span {...filterProps(props)}>{translation}</span>;
    }
  }
}

Translated.contextType = TranslationContext;

export default Translated;
