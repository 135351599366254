import React, {Component} from 'react';
import ProductPreview from './ProductPreview';

class ProductSelector extends Component {

  render() {
    this.props.products.sort((a, b) => a.sortNo - b.sortNo);
    const productElems = this.props.products.map(product => {
      return (<ProductPreview key={product.id} product={product} onInspect={this.props.onSelect}/>)
    });
    return (
      <div className='product-preview-items fade-in'>
        {productElems}
      </div>
    )
  }
}

export default ProductSelector;

