import React, {Component} from 'react';
import TextInput from '../feature/TextInput';
import {connect} from 'react-redux'
import Translated from '../feature/Translated';
import withTranslate from '../feature/withTranslate';
import Alert from 'react-bootstrap/Alert';
import api from '../../api';
import {IoAlertOutline} from 'react-icons/io5';
import withRouter from './withRouter';
import EmailUtil from '../../utils/EmailUtil';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import dateFormat from '../../utils/date';
import Modal from 'react-bootstrap/Modal';
import {Roles, fillsRole} from '../../data/roles';
import PropTypes from 'prop-types';
import Spinner from './Spinner'

class UserCreate extends Component {

  static propTypes = {
    schoolId: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    requestCreatedCallback: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.sendRequest = this.sendRequest.bind(this);
    this.resend = this.resend.bind(this);
    this.reset = this.reset.bind(this);
    this.state = this.getDefaultState();
  }

  getDefaultState() {
    return {
      lang: this.props.locale,
      inputEmail: '',
      nameImplicit: '',
      nameExplicit: '',
      useImplicitName: true,
      isSending: false,
      responseData: null,
      error: null,
      errorData: null,
      refreshNum: 0
    }
  }

  sendRequest(data) {
    const schoolId = data.schoolId;
    const name = data.name;
    const email = data.email;
    const lang = data.lang;
    const force = data.force;

    if (!EmailUtil.isEmail(email) || !name) {
      return this.setState({
        error: 'invalid_input',
        isSending: false,
        responseData: null,
      })
    }

    this.setState(
        {
          error: null,
          errorData: null,
          isSending: true,
          responseData: null,
        }
    );

    let promise;
    if (fillsRole(this.props.role, Roles.TEACHER)) {
      promise = api.addTeacher(schoolId, email, name, lang, force)
    }else {
      promise = api.addUserWithEmail(schoolId, email, name, lang, this.props.role, force);
    }

    promise.then(response => {
      if (response.error) {
        return this.setState({
          error: response.error,
          errorData: response.data,
          isSending: false,
          responseData: null,
        });
      } else {
        if (this.props.requestCreatedCallback) {
          this.props.requestCreatedCallback();
        }
        console.log(JSON.stringify(response.data));
        return this.setState({
          inputEmail: '',
          nameImplicit: '',
          nameExplicit: '',
          useImplicitName: true,
          error: null,
          errorData: null,
          isSending: false,
          refreshNum: this.state.refreshNum + 1,
          responseData: response.data,
        });
      }
    });
  }

  resend(request) {
    this.sendRequest({
      name: request.userName,
      email: request.email,
      schoolId: this.props.schoolId,
      lang: this.state.lang,
      force: false
    })
  }

  reset() {
    this.setState(this.getDefaultState());
  }

  updateEmail(email) {
    email = email.trim();
    const resolvedName = EmailUtil.resolveNameFromEmail(email);
    this.setState(
        {
          inputEmail: email,
          error: null,
          errorData: null,
          nameImplicit: resolvedName,
          responseData: null,
        }
    );
  }

  updateName(name) {
    this.setState({nameExplicit: name, useImplicitName: false}); // Don't use implicit name after name is manually altered
  }

  renderEmailField() {
    const emailKey = this.props.role === Roles.STUDENT ? 'student_email' : 'teacher_email';
    const errorLabel = this.state.error && <Translated translationKey={emailKey}/>;
    const emailText = this.props.translate(emailKey);
    return (
        <div>
          {errorLabel}
          <TextInput
              disabled={this.state.error === 'email-already-connected'}
              listener={(email) => this.updateEmail(email)}
              value={this.state.inputEmail}
              type="email"
              placeholder={emailText}
              className="full-width"
          />
        </div>
    );
  }

  renderNameField() {
    const errorLabel = this.state.error && <Translated translationKey="name"/>;
    const nameText = this.props.translate('name');
    const currentName = this.getCurrentName();
    return (
        <div>
          {errorLabel}
          <TextInput
              disabled={this.state.error === 'email-already-connected'}
              listener={(name) => this.updateName(name)}
              placeholder={currentName && currentName !== '' ? '' : nameText} // Safari bug, causes to placeholder to stick before focus event.
              value={currentName}
              className="full-width"
          />
        </div>
    );
  }

  getCurrentName() {
    if (this.state.nameExplicit || !this.state.useImplicitName) {
      return this.state.nameExplicit;
    } else {
      return this.state.nameImplicit || '';
    }
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }
    let errorElem;
    switch (this.state.error) {
      case 'email-already-connected':
        return this.renderAlreadyConnectedWarning();

      default:
        errorElem = (
            <Translated translationKey={this.state.error}/>
        );
    }

    return (
        <Alert className="fade-in top-margin-small" bsstyle="warning">
          <IoAlertOutline/> {errorElem}
        </Alert>
    );
  }

  renderAlreadyConnectedWarning() {
    const ed = this.state.errorData;
    const existingUser = ed.existingUserData;

    let actionButton;
    if (existingUser.schoolId === this.props.schoolId) {
      actionButton = (
          <Button onClick={() => {
            this.props.navigate('/user/'+existingUser.userId);
          }}>
            <Translated translationKey="view"/>
          </Button>);
    } else {
      actionButton = (
          <Button bsstyle="warning" onClick={() => {
            const ed = this.state.errorData;
            const addData = {
              schoolId: ed.schoolId,
              name: ed.userName,
              email: ed.email,
              lang: ed.lang,
              force: true
            };
            this.sendRequest(addData);
          }}>
            <Translated translationKey="continue"/>
          </Button>);
    }

    return (
        <Modal show={true}>
          <Modal.Body>
            <Translated translationKey={this.state.error}/>
            <br/>
            <Translated translationKey="id"/>: {existingUser.userId}
            <br/>
            <Translated translationKey="name"/>: {existingUser.userName}
            <br/>
            <Translated translationKey="school_name"/>: {existingUser.schoolName}
            <br/>
            <Translated translationKey="creation_date"/>: {dateFormat.date(existingUser.createdDate)}
          </Modal.Body>

          <Modal.Footer className="centered">
            <Button bsstyle="info" onClick={this.reset}>
              <Translated translationKey="cancel"/>
            </Button>
            {actionButton}
          </Modal.Footer>

        </Modal>
    )
  }

  renderRequest() {
    return !this.state.responseData
        ? null
        : (
            <Alert bsstyle="success" className="top-margin fade-in">
              <Translated translationKey="user_email_request_sent" values={{'email': this.state.responseData.email}}/>
              <br/>
              <Translated translationKey="last_usage_date"
                          values={{date: dateFormat.date(this.state.responseData.expiresAt)}}/>
            </Alert>
        );
  }

  createLanguageItems() {
    const langCodes = Object.keys(this.props.availableLangs);
    if (langCodes.length <= 1) {
      return null;
    }

    return langCodes.map((langCode) => {
      return (
          <Dropdown.Item
              id={langCode}
              key={langCode}
              onClick={() => {
                this.setState({lang: langCode});
              }}>
            <span>{this.props.availableLangs[langCode]}</span>
          </Dropdown.Item>
      );
    });
  }

  renderLanguageSelection() {
    const langId = this.state.lang;
    const langLocale = this.props.availableLangs[langId];
    return (
        <ButtonToolbar className="settings-category-limit">
          <DropdownButton
              id='addUserLanguageSelection'
              bsstyle="warning"
              title={langLocale}>
            {this.createLanguageItems()}
          </DropdownButton>
        </ButtonToolbar>
    );
  }

  renderButtons() {

    if (this.state.isSending) {
      return <Spinner />
    }

    const addBtnKey = this.props.role === Roles.STUDENT ? 'add_student' : 'add_teacher';
    const sendDisabled = !this.state.inputEmail || !this.getCurrentName();
    return (
        <div className="vertical-middle on-sides">
          {this.renderLanguageSelection()}
          <Button
              disabled={sendDisabled}
              onClick={() => {
                const addData = {
                  schoolId: this.props.schoolId,
                  name: this.getCurrentName().trim(),
                  email: this.state.inputEmail,
                  lang: this.state.lang
                };
                this.sendRequest(addData);
              }
              }>
            <Translated translationKey={addBtnKey}/>
          </Button>
        </div>
    );
  }

  render() {
    return (
        <div>
          <div className="add-student-container">
            {this.renderEmailField()}
            {this.renderNameField()}
            {this.renderButtons()}
          </div>
          {this.renderError()}
          <div>
            {this.renderRequest()}
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    locale: state.Intl.locale,
    availableLangs: state.availableLangs
  });
};

export default withRouter(connect(mapStateToProps)(withTranslate(UserCreate)));
