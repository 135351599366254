import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import withTranslate from '../feature/withTranslate';
import classNames from 'classnames';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import {BsQuestionCircleFill} from 'react-icons/bs';
import Translated from "./Translated";

function Help(props) {
  const [showOverlay, setShowOverlay] = useState(false);
  const overlayTarget = useRef(null);

  const popover = (
      <Popover id="help-popover" onClick={() => setShowOverlay(!showOverlay)}>
        <Popover.Body>
          <Translated translationKey={props.content}/>
        </Popover.Body>
      </Popover>
  );

  const placement = props.placement || 'top';
  const classes = classNames("help-icon", props.className);

  return (
      <div className="icon-container">
          <span ref={overlayTarget}>
            <BsQuestionCircleFill
                className={classes}
                onClick={() => {
                  setShowOverlay(!showOverlay)
                }}
            />
          </span>
        <Overlay
            target={overlayTarget.current}
            show={showOverlay}
            placement={placement}
        >
          {popover}
        </Overlay>
      </div>
  );
}

Help.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
};

export default withTranslate(Help);
