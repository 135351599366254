import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {resolveOptionClasses} from '../PractiseUtils';
import ZoomableImage from '../../../feature/ZoomableImage';
import Translated from '../../../feature/Translated';
import Spinner from '../../../feature/Spinner';

class ImagesQuestion extends Component {

  static propTypes = {
    question: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    setAnswer: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isAnswerResolved: false,
      answer: null,
      imageReady: false
    };
    this.ANSWER_DELAY_MS = 400;
  }

  componentDidMount() {
    this.checkImageLoadStatus();
  }

  componentWillUnmount() {
    clearTimeout(this.answerDelay);
  }

  checkImageLoadStatus() {
    const question = this.props.question;
    if (question) {
      const img = new Image();
      img.src = question.imageUrl;
      if (this.state.imageReady != img.complete) {
        this.setState({
          imageReady: img.complete
        });
      }
      if (!img.complete) {
        img.onload = () => {
          this.setState({
            imageReady: true
          });
        }
      }
    }
  }

  setAnswerDelayed(optionId) {
    if (this.state.answer !== null) {
      return;
    }
    this.setState({
      answer: optionId
    });

    if (!this.state.answer) {
      this.answerDelay = setTimeout(() => {
        this.setState({
          isAnswerResolved: true
        });
        this.props.setAnswer(optionId);
      }, this.ANSWER_DELAY_MS);
    }
  }

  render() {
    const question = this.props.question;
    let content;
    if (this.state.imageReady) {
      const options = [];
      [true, false].forEach((option, index) => {
          let className = resolveOptionClasses('practise-images-option', option, this.state.answer,
            this.state.isAnswerResolved, this.props.question.isAssertionTruthy, false);
          options.push(
            <div key={index} onClick={() => this.setAnswerDelayed(option)}
                 className={className}>
              <Translated translationKey={option ? 'yes' : 'no'}/>
            </div>
          );
        }
      );
      content = (
        <div>
          <ZoomableImage src={question.imageUrl} className="practise-images-image fade-in"/>
          <div className="practise-images-options-container">
            {options}
          </div>
        </div>
      )
    } else {
      content = (
        <div className="spinner-container">
          <Spinner/>
        </div>
      )
    }
    const questionNum = this.props.index + 1;
    return (
      <div className={this.props.className}>
        <div className="practise-text-question">
          {questionNum}.&nbsp;{question.assertion}
        </div>
        {content}
      </div>
    );
  }
}

export default ImagesQuestion;