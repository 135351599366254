import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withTranslate from './withTranslate';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormLabel from 'react-bootstrap/FormLabel';

/**
 * Simple Text input that emits onChange to prop.listener.
 */
class TextInput extends Component {

  onChange(e) {
    const input = e.target.value;
    this.props.listener(input);
  }

  static propTypes = {
    listener: PropTypes.func
  };

  render() {
    const t = this.props.translate;
    const label = this.props.label && <FormLabel>{this.props.label}</FormLabel>;

    return (
      <Form onSubmit={(e) => {e.preventDefault()}} className={this.props.className}>
        <Form.Group >
          {label}
          <InputGroup className={'mb-3 ' + this.props.className} >
            <Form.Control
              type={this.props.type ? this.props.type : 'text'}
              name="input"
              disabled={this.props.disabled}
              className={this.props.className}
              placeholder={t(this.props.placeholder)}
              value={this.props.value}
              bssize={this.props.size}
              autoComplete={this.props.autoComplete}
              onChange={(e) => this.onChange(e)}
            />
          </InputGroup>
        </Form.Group>
      </Form>
    )
  }
}

export default withTranslate(TextInput);
