import React, {Component} from 'react';
import {connect} from 'react-redux';

import Button from 'react-bootstrap/Button';
import {resetLoginError} from '../../actions/loginActions'
import Translated from '../feature/Translated';
import ImageSet from '../feature/ImageSet';
import Alert from 'react-bootstrap/Alert';
import ActivityCount from '../feature/ActivityCount'
import withRouter from '../feature/withRouter';

export class HomePage extends Component {

  constructor(props, context) {
    super(props, context);
  }

  componentWillUnmount() {
    this.props.dispatch(resetLoginError());
  }

  browseTo(to) {
    this.props.navigate('/'+to);
  }

  render() {

    let loginError = null;
    if (this.props.loginError) {
      loginError = (
        <Alert className="front-login-error">
          <Translated className="strong-text" translationKey="error-login"/>:<br/>
          <Translated translationKey={this.props.loginError}/>
        </Alert>
      );
    }

    let loggedOutMessage = null;
    if (this.props.loggedOutMessage) {
      loggedOutMessage = (
        <Alert className="front-logged-out-message">
          <Translated translationKey={this.props.loggedOutMessage}/>
        </Alert>
      );
    }

    const logo = SERVICE_LOGO;
    const logoUrl = '../../assets/'+logo+'.png';

    return (
      <div id="home-page" className="front-center-wrapper">
        <div className="front-logo-wrapper">
          <ImageSet src={logoUrl} className="front-logo slide-in" alt="logo"/>
          <br/>
          <Translated className="front-slogan fade-in delay" translationKey="slogan"/>
        </div>
        <div className="large-top-margin fade-in long-delay">
          <Button id="home-login-button" aria-label="Login" size="lg"className="horizontal-padding" onClick={() => this.browseTo('login')} role="button">
            <Translated className="strong-text" translationKey="login" aria-label="login"/>
          </Button>
        </div>
        {loginError}
        {loggedOutMessage}
        <div className="centered">
          <div className="inline extra-large-top-margin semi-dark-inner-box tight fade-in longer-delay">
            <ActivityCount type="large-number"/>
        </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginError: state.login.get('error'),
    loggedOutMessage: state.login.get('loggedOutMessage'),
  }
}

export default withRouter(connect(mapStateToProps)(HomePage));
