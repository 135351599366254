import classNames from 'classnames';

export function resolveOptionClasses(baseClass, optionType, answer, isAnswerResolved, correctAnswer, showCorrectDelayed = true) {

  let classes = baseClass;

  if (answer === null) {
    // No answer yet, use base class
    return classes;
  }

  if (!isAnswerResolved) {
    // Answer is given but not yet resolved
    if (optionType === answer) {
      // User answered this option
      classes = classNames(classes, 'unresolved-answer');
    } else {
      // Idle answer before resolving, show as disabled.
      classes = classNames(classes, 'disabled');
    }

  } else {
    // Answer is resolved
    if (optionType === correctAnswer) {
      // This would be the correct option
      if (optionType === answer) {
        // User answered correctly
        classes = classNames(classes, 'correct');
      } else {
        if (showCorrectDelayed) {
          // User answered incorrectly, delay this option's highlighting a bit
          classes = classNames(classes, 'correct', 'delayed-correct');
        } else {
          classes = classNames(classes, 'disabled');
        }
      }
    } else {
      // This is incorrect option
      if (optionType === answer) {
        // User answered incorrectly
        classes = classNames(classes, 'incorrect');
      } else {
        // This is incorrect unanswered option after answer is resolved
        classes = classNames(classes, 'disabled');
      }
    }
  }

  return classes;
}
