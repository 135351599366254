import React, {Component} from 'react';
import {connect} from 'react-redux';
import {setVehicleCategory} from '../../actions/settingsActions';
import PreloadMiddlePage from './../pages/PreloadMiddlePage';
import Translated from './Translated';
import ImageSet from './ImageSet';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

class CategorySelector extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    }
  }

  selectCategory(category) {
    if (this.hasLanguageSupport(category)) {
      this.props.dispatch(setVehicleCategory(category, this.props.userId));
    } else {
      this.setState({showModal: true})
    }
  }

  renderCategoryElem(category, isSelected) {
    const categoryKey = 'category_' + category.toLowerCase();
    const selectedClass = isSelected ? 'selected' : '';
    const imageSrc = '../../assets/category/' + category + '.png';
    const buttonKey = this.hasLanguageSupport(category) ? 'select' : 'category_selector_button_info';

    return (
      <div key={category} className={'vehicle-category-select-item ' + selectedClass}>
        <div>
          <ImageSet src={imageSrc} onClick={() => this.selectCategory(category)}/>
        </div>
        <div>
          <Translated className="vehicle-category-select-item-title" translationKey={categoryKey}/>
        </div>
        <Button id={'category-select-button-' + category}
                type="submit"
                onClick={() => this.selectCategory(category)}>
          <Translated translationKey={buttonKey}/>
        </Button>
      </div>
    );
  }

  hasLanguageSupport(category) {
    const settings = this.props.settings.get('data').get('categorySettings').get(category);
    return settings.get('langs').indexOf(this.props.locale) !== -1;
  }

  renderModal() {
    return (
      <Modal show={this.state.showModal}>
        <Modal.Body>
          <Translated translationKey={'category_unsupported_lang'}/>
        </Modal.Body>
        <Modal.Footer>
          <Button id="vehicle_category_unsupported_lang" onClick={() => this.setState({showModal: false})}>
            <Translated translationKey="close"/>
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  renderCategorySelector(currentlySelectedCategory) {
    const cats = this.props.settings.get('data').get('vehicleCategories');
    if (!cats || cats.size === 0) {
      return (
          <Alert>
            <Translated translationKey="no_valid_categories_available"/>
          </Alert>
      )
    }
    const catElems = cats.map(cat => {
      return this.renderCategoryElem(cat, cat === currentlySelectedCategory);
    });
    const modal = this.renderModal();
    return (
      <div id={'category-selector-' + currentlySelectedCategory} className={this.props.className}>
        <h3><Translated translationKey="select_vehicle_category"/></h3>
        <div className="vehicle-category-select-wrapper">
          {catElems}
        </div>
        {modal}
      </div>
    );
  }

  render() {
    const settings = this.props.settings;

    const data = settings.get('data');
    if (!settings.get('isReceived') || data === null) {
      return (<PreloadMiddlePage />)
    }

    const category = settings.get('vehicleCategory');
    return this.renderCategorySelector(category)
  }

}

const mapStateToProps = (state) => {
  return ({
    settings: state.settings,
    userId: state.login.get('token') ? state.login.get('token').get('userId') : null,
    locale: state.Intl.locale
  });
};

export default connect(mapStateToProps)(CategorySelector);