import api from '../api';
import Logger from '../utils/logger';
import Tracker from '../utils/tracker';
import FacebookUtil from '../utils/FacebookUtil';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const PROFILE_ID_REQUIRED = 'PROFILE_ID_REQUIRED';
export const LOGOUT_COMPLETE = 'LOGOUT';
export const FB_CONNECT_REQUEST = 'FB_CONNECT_REQUEST';
export const FB_CONNECT_SUCCESS = 'FB_CONNECT_SUCCESS';
export const FB_CONNECT_ERROR = 'FB_CONNECT_ERROR';
export const FB_SET_ACCESS_TOKEN = 'FB_SET_ACCESS_TOKEN';
export const FB_CONNECT_RESET = 'FB_CONNECT_RESET';
export const REQUIRE_LICENSE_FOR_FB_LOGIN = 'REQUIRE_LICENSE_FOR_FB_LOGIN';
export const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR';

function loginRequest() {
  return {
    type: LOGIN_REQUEST
  }
}

function loginSuccess(userData) {
  return {
    type: LOGIN_SUCCESS,
    userData
  }
}

function loginFailed(error, data) {
  return {
    type: LOGIN_FAILED,
    error,
    data
  }
}

function requireProfileSelection(profiles) {
  return {
    type: PROFILE_ID_REQUIRED,
    profiles
  }
}

function fbConnectRequest() {
  return {
    type: FB_CONNECT_REQUEST
  }
}

function fbConnectSuccess() {
  return {
    type: FB_CONNECT_SUCCESS
  }
}

function fbConnectError(error, info) {
  return {
    type: FB_CONNECT_ERROR,
    error,
    info
  }
}

function fbConnectionReset() {
  return {
    type: FB_CONNECT_RESET
  }
}

function requireLicenseForFBLogin(fbUserName, fbAccessToken) {
  return {
    type: REQUIRE_LICENSE_FOR_FB_LOGIN,
    payload: {
      userName: fbUserName,
      accessToken: fbAccessToken
    }
  }
}

export function login(name, schoolId, oneTimeKey) {
  return function (dispatch, getState) {
    api.cacheClear();
    dispatch(loginRequest());
    api.login(name.trim(), oneTimeKey.trim(), schoolId.trim())
        .then(response => {
          if (response.error) {
            return dispatch(loginFailed(response.error, response.data));
          }
          dispatch(loginSuccess());
          const state = getState();
          const fbUserData = state.login.get('fb');
          if (state.login.get('autoConnectFb') && fbUserData) {
            dispatch(connectFacebook(fbUserData.get('accessToken')));
          }
        });
  }
}

export function loginWithEmail(email, password, profileSchoolId) {
  return dispatch => {
    api.cacheClear();
    dispatch(loginRequest());
    api.loginEmail(email.trim(), password.trim(), profileSchoolId)
        .then(resp => {
          if (resp.error) {
            return dispatch(loginFailed(resp.error, resp.data));
          } else if (resp.profiles) {
            return dispatch(requireProfileSelection(resp.profiles));
          } else {
            dispatch(loginSuccess(
                {
                  userId: resp.userId,
                  schoolId: resp.schoolId,
                  email: resp.email,
                  facebook: resp.facebook,
                  integrationPlatform: resp.integrationPlatform,
                  loginMethod: resp.loginMethod
                }
            ));
            // Notice! api has already dispatched the 'tokenUpdated' action
          }
        });
  }
}

export function connectFacebook(fbAccessToken, forceTransfer) {
  return function (dispatch) {
    api.cacheClear();
    dispatch(fbConnectRequest());
    Tracker.logEventValue('facebook', 'connect', forceTransfer ? 1 : 0);
    api.connectFacebook(fbAccessToken, forceTransfer)
        .then(response => {
          if (response.error) {
            return dispatch(fbConnectError(response.error, response.data));
          }
          dispatch(fbConnectSuccess());
        })
  }
}

export function setFbAccessToken(token) {
  return {
    type: FB_SET_ACCESS_TOKEN,
    token
  }
}

export function resetFacebookConnection() {
  return function (dispatch) {
    return dispatch(fbConnectionReset());
  };
}

export function loginWithFacebook(fbUserName, fbAccessToken, navigate) {
  return function (dispatch) {
    api.cacheClear();
    dispatch(loginRequest());
    api.loginFacebook(fbAccessToken)
        .then((response) => {
          if (response.error) {
            if (response.error === 'user-license-required') {
              // Auto connect FB disabled now that there are other than one-time-key logins.
              // NOTE: auto connect has a problem that it is remembered (connection happens) even
              // in the event that other than original triggering user logs in.
              //dispatch(requireLicenseForFBLogin(fbUserName, fbAccessToken));
              dispatch(loginFailed(response.error, {fb: {fbUserName, fbAccessToken}}));
              navigate('/login-fb-not-connected');
            } else {
              dispatch(loginFailed(response.error));
            }
          } else {
            dispatch(loginSuccess());
          }
        });
  }
}

export function loginWithEautokoulu(challengedUrl) {
  return function (dispatch) {
    api.cacheClear();
    dispatch(loginRequest());
    api.loginEautokoulu(challengedUrl)
        .then((response) => {
          if (response.error) {
            dispatch(loginFailed(response.error));
          } else {
            dispatch(loginSuccess());
          }
        });
  }
}

export function loginWithAAIntegration(name, email, licenseType, licensesBase64, hash) {
  return function (dispatch) {
    api.cacheClear();
    dispatch(loginRequest());
    api.loginAAIntegration(name, email, licenseType, licensesBase64, hash)
        .then((response) => {
          if (response.error) {
            dispatch(loginFailed(response.error));
          } else {
            dispatch(loginSuccess());
          }
        });
  }
}

export function loginWithAATeoriaIntegration(challengedUrl) {
  return function (dispatch) {
    api.cacheClear();
    dispatch(loginRequest());
    api.loginAATeoriaIntegration(challengedUrl)
        .then((response) => {
          if (response.error) {
            dispatch(loginFailed(response.error));
          } else {
            dispatch(loginSuccess());
          }
        });
  }
}

export function loginWithEA2Integration(challengedUrl) {
  return function (dispatch) {
    api.cacheClear();
    dispatch(loginRequest());
    api.loginEA2Integration(challengedUrl)
        .then((response) => {
          if (response.error) {
            dispatch(loginFailed(response.error));
          } else {
            dispatch(loginSuccess());
          }
        });
  }
}

export function loginWithAutoliittoIntegration(externalUserId, hash, externalTime) {
  return function (dispatch) {
    api.cacheClear();
    dispatch(loginRequest());
    api.loginAutoliittoIntegration(externalUserId, hash, externalTime)
        .then((response) => {
          if (response.error) {
            dispatch(loginFailed(response.error));
          } else {
            dispatch(loginSuccess());
          }
        });
  }
}

export function loginWithAutoliittoSSO(authCode) {
  return function (dispatch) {
    api.cacheClear();
    dispatch(loginRequest());
    api.loginAutoliittoSSO(authCode)
      .then((response) => {
        if (response.error) {
          dispatch(loginFailed(response.error));
        } else {
          dispatch(loginSuccess());
        }
      });
  }
}

export function loginWithLiikenneopetusIntegration(email, name, categories, epoch, hash) {
  return function (dispatch) {
    api.cacheClear();
    dispatch(loginRequest());
    api.loginLiikenneopetusIntegration(email, name, categories, epoch, hash)
        .then((response) => {
          if (response.error) {
            dispatch(loginFailed(response.error));
          } else {
            dispatch(loginSuccess());
          }
        });
  }
}

function logoutComplete(message) {
  return {
    type: LOGOUT_COMPLETE,
    loggedOutMessage: message
  }
}

export function logout(navigate) {
  return function (dispatch) {
    FacebookUtil.logout();
    Logger.setUserData();
    api.cacheClear();
    navigate('/');
    dispatch(logoutComplete('logged_out'));
  }
}

export function resetLoginError() {
  return {
    type: RESET_LOGIN_ERROR
  }
}
