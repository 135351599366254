import React, {Component} from 'react';
import withTranslate from '../feature/withTranslate';
import classNames from 'classnames';

class SubMenu extends Component {

  buildMenuItem(id, labelKey, index) {
    const label = this.props.translate(labelKey);
    const isActive = this.props.selectedIndex === index;

    return (
      <div className={classNames('sub_menu-item', {'active strong': isActive})}
           id={id}
           key={id}
           onClick={() => this.props.selectionListener(index)}>
        <span>{label}</span>
      </div>);
  }

  render() {
    const menuItems = this.props.items.map((itemData, index) => {
      return this.buildMenuItem(itemData.pageId, itemData.localizationId, index);
    });

    return (
      <div id="sub_menu-container" className="sub_menu-container">
        {menuItems}
      </div>
    )
  }
}

export default withTranslate(SubMenu);
